import * as React from "react"
import { PermissionsDigest } from "@modules/graphql/types"

export type PermissionsResource = keyof Pick<
  PermissionsDigest,
  "billing" | "contributor" | "organization" | "sites"
>

export type UseHasPermissionsArgs<T extends PermissionsResource> = {
  digest: Record<T, PermissionsDigest[T]> | null
  resource: T
  action: Exclude<keyof NonNullable<PermissionsDigest[T]>, "__typename">
}

export function useHasPermissions<T extends PermissionsResource>({
  digest,
  resource,
  action,
}: UseHasPermissionsArgs<T>): boolean {
  const hasPermissions = Boolean(digest?.[resource]?.[action])

  return hasPermissions
}

export type PermissionsCheckProps<
  T extends PermissionsResource
> = UseHasPermissionsArgs<T> & {
  children: React.ReactNode
  fallback?: React.ReactNode
}

export function PermissionsCheck<T extends PermissionsResource>({
  digest,
  resource,
  action,
  children,
  fallback = null,
}: PermissionsCheckProps<T>) {
  const hasPermissions = useHasPermissions<T>({
    digest,
    resource,
    action,
  })

  return <React.Fragment>{hasPermissions ? children : fallback}</React.Fragment>
}
