import * as React from "react"
import ScalableSvg from "@modules/ui/components/ScalableSvg"

export function GatsbyCloudCdnLogoIcon(
  props: React.ComponentPropsWithoutRef<"svg">
) {
  const gradientId = `${props.id ?? `GatsbyCloudCdnLogoIcon`}__gradient`
  return (
    <ScalableSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <ellipse
        cx="7.30972"
        cy="8.16165"
        rx="3.37661"
        ry="3.37661"
        fill="#159CF4"
      />
      <ellipse
        cx="6.60344"
        cy="13.1453"
        rx="3.01799"
        ry="3.01799"
        fill="white"
      />
      <ellipse
        cx="14.2399"
        cy="10.945"
        rx="5.38333"
        ry="5.38333"
        fill="white"
      />
      <rect
        x="6.60339"
        y="13.1636"
        width="8.20622"
        height="3.00042"
        fill="white"
      />
      <ellipse
        cx="8.65863"
        cy="10.6264"
        rx="0.99225"
        ry="0.99225"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4161 8.22125C17.5078 6.85232 15.9527 5.94981 14.1867 5.94981C11.6055 5.94981 9.66545 7.90117 9.40577 9.56482C9.34725 9.93969 9.20265 10.1856 8.95459 10.4484C8.75095 10.5817 8.59453 10.613 8.41033 10.5817C8.2852 10.5605 8.17921 10.5159 8.06104 10.4662C7.82956 10.3689 7.55136 10.252 6.99114 10.252C5.42074 10.252 4.14768 11.5251 4.14768 13.0955C4.14768 14.6659 5.42074 15.9389 6.99114 15.9389H10.3906C10.4079 16.4297 10.4727 16.9084 10.5803 17.3707L6.99133 17.3707C4.63313 17.3707 2.72144 15.459 2.72144 13.1008C2.72144 10.7426 4.63313 8.8309 6.99133 8.8309C7.34011 8.8309 7.67911 8.87271 8.00362 8.95161C8.86075 6.40177 11.2704 4.56506 14.1091 4.56506C16.7888 4.56506 19.0862 6.20178 20.0562 8.53007C19.5318 8.3686 18.9827 8.26332 18.4161 8.22125Z"
        fill={`url(#${gradientId})`}
      />
      <ellipse
        cx="8.25764"
        cy="2.47394"
        rx="0.947821"
        ry="0.947821"
        fill="#159BF3"
      />
      <ellipse
        cx="1.62311"
        cy="8.16096"
        rx="0.947821"
        ry="0.947821"
        fill="#159BF3"
      />
      <ellipse
        cx="3.51887"
        cy="3.42353"
        rx="0.947821"
        ry="0.947821"
        fill="#159BF3"
      />
      <path
        d="M22.6396 15.7595H19.6396V16.6166H21.6967C21.3967 17.9023 20.4539 18.9737 19.211 19.4023L14.2825 14.4737C14.7967 12.9737 16.2539 11.9023 17.9253 11.9023C19.211 11.9023 20.3682 12.5452 21.0967 13.5309L21.7396 12.9737C20.8825 11.8166 19.511 11.0452 17.9253 11.0452C15.6967 11.0452 13.811 12.6309 13.3396 14.7309L18.9967 20.388C21.0539 19.8737 22.6396 17.988 22.6396 15.7595Z"
        fill="white"
      />
      <path
        d="M13.2111 15.8022C13.2111 17.0022 13.6825 18.1594 14.5825 19.0594C15.4825 19.9594 16.6825 20.4308 17.8396 20.4308L13.2111 15.8022Z"
        fill="white"
      />
      <path
        d="M17.9253 9.7594C14.6253 9.7594 11.9253 12.4594 11.9253 15.7594C11.9253 19.0594 14.6253 21.7594 17.9253 21.7594C21.2253 21.7594 23.9253 19.0594 23.9253 15.7594C23.9253 12.4594 21.2253 9.7594 17.9253 9.7594ZM14.5824 19.1023C13.6824 18.2023 13.211 17.0023 13.211 15.8451L17.8824 20.4737C16.6824 20.4308 15.4824 20.0023 14.5824 19.1023ZM18.9539 20.3451L13.3396 14.7308C13.811 12.6308 15.6967 11.0451 17.9253 11.0451C19.511 11.0451 20.8824 11.8165 21.7396 12.9737L21.0967 13.5308C20.3682 12.5451 19.211 11.9023 17.9253 11.9023C16.2539 11.9023 14.8396 12.9737 14.2824 14.4737L19.211 19.4023C20.4539 18.9737 21.3967 17.9023 21.6967 16.6165H19.6396V15.7594H22.6396C22.6396 17.988 21.0539 19.8737 18.9539 20.3451Z"
        fill="#663399"
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="19.9106"
          y1="8.02153"
          x2="9.79993"
          y2="8.02153"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#663399" />
          <stop offset="1" stopColor="#159BF3" />
        </linearGradient>
      </defs>
    </ScalableSvg>
  )
}
