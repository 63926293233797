import React from "react"
import { ErrorMessage as FormikErrorMessage } from "formik"
import { css } from "@emotion/core"
import ErrorMessage from "../../ui/components/ErrorMessage"

export const fieldErrorMessageCss = theme => css`
  padding: ${theme.space[2]} ${theme.space[0]} 0;
`

export default function FieldError({ name, className }) {
  return (
    <FormikErrorMessage name={name}>
      {msg => (
        <ErrorMessage className={className} css={fieldErrorMessageCss}>
          {msg}
        </ErrorMessage>
      )}
    </FormikErrorMessage>
  )
}
