import React, { Fragment, useState, createRef, forwardRef } from "react"
import { MdInfo } from "react-icons/md"
import { useEventListener } from "@modules/ui/utils/useEventListener"

function ToggleTip({
  variant = "PRIMARY",
  children,
  tip,
  customCss,
  className,
}) {
  const [visible, setVisible] = useState(false)

  const tipRef = createRef()

  function hideTip() {
    setVisible(false)
  }

  function checkClick(e) {
    if (visible && e.target !== tipRef.current) {
      hideTip()
    }
  }

  function checkKeydown(e) {
    if ((e.keyCode || e.which) === 27) {
      hideTip()
    }
  }

  useEventListener(`click`, checkClick)
  useEventListener(`keydown`, checkKeydown)

  function showTip() {
    setVisible(false)
    const reopen = setTimeout(() => {
      setVisible(true)
      clearTimeout(reopen)
    }, 100)
  }

  return (
    <span
      css={[
        {
          display: `flex`,
          position: `relative`,
        },
        customCss,
      ]}
      className={className}
    >
      {
        <Fragment>
          <ToggleTip.Button onClick={showTip} visible={visible}>
            {children}
          </ToggleTip.Button>
          <ToggleTip.Tip
            tip={tip}
            visible={visible}
            ref={tipRef}
            variant={variant}
          />
        </Fragment>
      }
    </span>
  )
}

ToggleTip.Button = function({ children, visible, customCss, ...rest }) {
  return (
    <button
      aria-expanded={visible}
      aria-haspopup="true"
      aria-label={`more info`}
      type="button"
      css={theme => {
        return [
          {
            background: `transparent`,
            cursor: `pointer`,
            border: `0`,
            display: `inline-flex`,
            justifyContent: `center`,
            height: `1.4em`,
            padding: 0,
            verticalAlign: `text-bottom`,
            width: `1.4em`,
            borderRadius: `50%`,

            ":focus": {
              boxShadow: `0 0 0 3px ${theme.colors.purple[30]}`,
              outline: `none`,
            },

            svg: {
              height: `100%`,
              width: `100%`,
              fill: theme.colors.grey[50],
            },
          },
          customCss,
        ]
      }}
      {...rest}
    >
      {children ? children : <MdInfo />}
    </button>
  )
}

ToggleTip.Tip = forwardRef(
  ({ tip, visible, width = `12rem`, customCss, variant }, ref) => {
    const isPrimaryVariant = [`PRIMARY`].includes(variant)
    return (
      <span
        ref={ref}
        role="status"
        dangerouslySetInnerHTML={{
          __html: visible && tip ? tip : null,
        }}
        css={theme => {
          return visible
            ? [
                {
                  background: theme.colors.grey[80],
                  borderRadius: theme.radii[2],
                  bottom: isPrimaryVariant ? `calc(100% + .75rem)` : `auto`,
                  top: isPrimaryVariant ? `auto` : `calc(100% + .75rem)`,
                  color: theme.colors.white,
                  fontSize: theme.fontSizes[1],
                  right: `-1rem`,
                  lineHeight: 1.4,
                  opacity: 0.25,
                  padding: `${theme.space[3]} ${theme.space[4]}`,
                  position: `absolute`,
                  transformOrigin: `right bottom`,
                  width: width,
                  zIndex: 10,

                  ":after": {
                    content: `""`,
                    background: theme.colors.grey[80],
                    bottom: 0,
                    top: isPrimaryVariant ? `auto` : `-8px`,
                    height: `.5rem`,
                    right: `1.25rem`,
                    position: `absolute`,
                    transform: `translate(0,50%) rotate(45deg)`,
                    transformOrigin: `center center`,
                    width: `.5rem`,
                    zIndes: -1,
                  },

                  "p:last-of-type": {
                    margin: 0,
                  },
                },
                customCss,
              ]
            : null
        }}
      />
    )
  }
)

ToggleTip.propTypes = {}

export default ToggleTip
