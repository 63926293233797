import React, { Fragment } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { useTracker, GAEventType } from "@modules/analytics"
import { GatsbyCloudLogo } from "@modules/brand/assets/GatsbyCloudLogo"
import UserMenu from "./UserMenu"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { DASHBOARD_HEADER_HEIGHT } from "@modules/ui/layouts/constants"
import { Heading, Spacer } from "gatsby-interface"
import { header as text } from "@modules/locales/default.json"
import VisuallyHidden from "@modules/a11y/components/VisuallyHidden"
import { UserOrganizationsHeader } from "./UserOrganizationsHeader"
import { HelpMenu } from "./HelpMenu"
import { Announcement } from "@modules/announcement"
import { Link } from "gatsby"

const HeaderRoot = styled(`header`)`
  background: ${p => p.theme.colors.primaryBackground};

  width: 100%;
  z-index: 1000;

  .dashboardGreyBg & {
    background: ${p => p.theme.colors.secondaryBackground};
  }
`

const HeaderNav = styled(StandardSingleColumn.withComponent(`nav`))`
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: ${DASHBOARD_HEADER_HEIGHT} auto;

  justify-items: stretch;
  width: 100%;

  ${p => p.theme.mediaQueries.desktop} {
    display: flex;
    height: ${DASHBOARD_HEADER_HEIGHT};
    grid-template-rows: ${DASHBOARD_HEADER_HEIGHT} ${DASHBOARD_HEADER_HEIGHT};
  }
`

const titleCss = theme => ({
  color: theme.colors.black,
  fontSize: theme.fontSizes[4],
  marginLeft: theme.space[2],
  fontWeight: theme.fontWeights.bold,
  textRendering: `optimizeLegibility`,
})

const logoContainerCss = _theme => ({
  display: "flex",
  alignItems: "center",
  flexGrow: 0,
  textDecoration: "none",
  gridColumn: `1 / 2`,
  gridRow: `1 / 2`,
})

const headerLogoCss = _theme => ({
  width: `36px`,
  height: `36px`,
})

function Header({ pathname }) {
  const { trackGoogleAnalytics } = useTracker()
  const welcomeFormScreen = pathname.includes(`dashboard/welcome`)

  const hiddenOrganizationHeadersOnPaths = [
    `dashboard/welcome`,
    `dashboard/sites/provision`,
    `dashboard/sites/create`,
    `sites/provision`,
    `sites/create`,
    `sites/import`,
    `release-notes`,
  ]

  const hideOrganizationHeader = hiddenOrganizationHeadersOnPaths.some(item =>
    pathname.includes(item)
  )

  const logo = (
    <Fragment>
      <GatsbyCloudLogo css={headerLogoCss} alt="Gatsby" />
      <Heading css={titleCss} as="span">
        <VisuallyHidden>Gatsby</VisuallyHidden>
        {` `}
        {text.title}
      </Heading>
    </Fragment>
  )

  return (
    <HeaderRoot>
      <HeaderNav aria-label="Header navigation">
        {!welcomeFormScreen ? (
          <Link
            to="/dashboard/sites"
            data-cy="header-logo-link"
            onClick={() =>
              trackGoogleAnalytics({
                eventType: GAEventType.Click,
                category: `Product Engagement - Dashboard`,
                label: { loc: `Header`, text: `logo` },
              })
            }
            css={logoContainerCss}
          >
            {logo}
          </Link>
        ) : (
          <div css={logoContainerCss}>{logo}</div>
        )}
        {!hideOrganizationHeader && (
          <UserOrganizationsHeader pathname={pathname} />
        )}
        <div css={{ display: "flex", flexGrow: 1, justifyContent: `flex-end` }}>
          <HelpMenu />
          <Spacer direction="horizontal" size={5} />
          <Announcement />
          <Spacer direction="horizontal" size={5} />
          <UserMenu />
        </div>
      </HeaderNav>
    </HeaderRoot>
  )
}

Header.propTypes = {
  pathname: PropTypes.string.isRequired,
}

export default Header
