// THIS IS A GENERATED FILE
import * as Types from "../../graphql/types"

import { SiteDetailsFieldsFragment } from "./fragments.generated"
import { BuildFieldsFragment } from "../../build/shared/fragments.generated"
import gql from "graphql-tag"
import { SiteDetailsFieldsFragmentDoc } from "./fragments.generated"
import { BuildFieldsFragmentDoc } from "../../build/shared/fragments.generated"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type ValidateSitePublicNameQueryVariables = Types.Exact<{
  name: Types.Scalars["String"]
  organizationId?: Types.Maybe<Types.Scalars["UUID"]>
  sourceOrganizationId?: Types.Maybe<Types.Scalars["String"]>
  sourceControlProvider?: Types.Maybe<Types.SourceControlProvider>
  siteId?: Types.Maybe<Types.Scalars["UUID"]>
}>

export type ValidateSitePublicNameQuery = { __typename?: "Query" } & {
  validateSitePublicName?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<Types.MutationResult, "success">
  >
}

export type BranchesForSiteQueryVariables = Types.Exact<{
  endCursor?: Types.Maybe<Types.Scalars["String"]>
  searchText?: Types.Maybe<Types.Scalars["String"]>
  siteId: Types.Scalars["UUID"]
}>

export type BranchesForSiteQuery = { __typename?: "Query" } & {
  branchesForSite: { __typename?: "BranchListResults" } & Pick<
    Types.BranchListResults,
    "branches"
  > & {
      pageInfo?: Types.Maybe<
        { __typename?: "GithubPagination" } & Pick<
          Types.GithubPagination,
          "lastId" | "hasNextPage"
        >
      >
    }
}

export type SiteDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
}>

export type SiteDetailsQuery = { __typename?: "Query" } & {
  siteDetails?: Types.Maybe<{ __typename?: "Site" } & SiteDetailsFieldsFragment>
}

export type SiteChangedSubscriptionVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
}>

export type SiteChangedSubscription = { __typename?: "Subscription" } & {
  siteChanged?: Types.Maybe<
    { __typename?: "Site" } & Pick<
      Types.Site,
      "id" | "previewStatus" | "updatedAt"
    > & {
        latestPreview?: Types.Maybe<
          { __typename?: "Build" } & Pick<Types.Build, "buildStatus">
        >
        latestBuild?: Types.Maybe<
          { __typename?: "Build" } & BuildFieldsFragment
        >
      }
  >
}

export const ValidateSitePublicNameDocument = gql`
  query ValidateSitePublicName(
    $name: String!
    $organizationId: UUID
    $sourceOrganizationId: String
    $sourceControlProvider: SourceControlProvider
    $siteId: UUID
  ) {
    validateSitePublicName(
      name: $name
      organizationId: $organizationId
      sourceOrganizationId: $sourceOrganizationId
      sourceControlProvider: $sourceControlProvider
      siteId: $siteId
    ) {
      success
    }
  }
`

/**
 * __useValidateSitePublicNameQuery__
 *
 * To run a query within a React component, call `useValidateSitePublicNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateSitePublicNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateSitePublicNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      organizationId: // value for 'organizationId'
 *      sourceOrganizationId: // value for 'sourceOrganizationId'
 *      sourceControlProvider: // value for 'sourceControlProvider'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useValidateSitePublicNameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ValidateSitePublicNameQuery,
    ValidateSitePublicNameQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ValidateSitePublicNameQuery,
    ValidateSitePublicNameQueryVariables
  >(ValidateSitePublicNameDocument, baseOptions)
}
export function useValidateSitePublicNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ValidateSitePublicNameQuery,
    ValidateSitePublicNameQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ValidateSitePublicNameQuery,
    ValidateSitePublicNameQueryVariables
  >(ValidateSitePublicNameDocument, baseOptions)
}
export type ValidateSitePublicNameQueryHookResult = ReturnType<
  typeof useValidateSitePublicNameQuery
>
export type ValidateSitePublicNameLazyQueryHookResult = ReturnType<
  typeof useValidateSitePublicNameLazyQuery
>
export type ValidateSitePublicNameQueryResult = ApolloReactCommon.QueryResult<
  ValidateSitePublicNameQuery,
  ValidateSitePublicNameQueryVariables
>
export const BranchesForSiteDocument = gql`
  query BranchesForSite(
    $endCursor: String
    $searchText: String
    $siteId: UUID!
  ) {
    branchesForSite(
      endCursor: $endCursor
      searchText: $searchText
      siteId: $siteId
    ) {
      branches @connection(key: "branchesForSite")
      pageInfo {
        lastId
        hasNextPage
      }
    }
  }
`

/**
 * __useBranchesForSiteQuery__
 *
 * To run a query within a React component, call `useBranchesForSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesForSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesForSiteQuery({
 *   variables: {
 *      endCursor: // value for 'endCursor'
 *      searchText: // value for 'searchText'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useBranchesForSiteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BranchesForSiteQuery,
    BranchesForSiteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    BranchesForSiteQuery,
    BranchesForSiteQueryVariables
  >(BranchesForSiteDocument, baseOptions)
}
export function useBranchesForSiteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BranchesForSiteQuery,
    BranchesForSiteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    BranchesForSiteQuery,
    BranchesForSiteQueryVariables
  >(BranchesForSiteDocument, baseOptions)
}
export type BranchesForSiteQueryHookResult = ReturnType<
  typeof useBranchesForSiteQuery
>
export type BranchesForSiteLazyQueryHookResult = ReturnType<
  typeof useBranchesForSiteLazyQuery
>
export type BranchesForSiteQueryResult = ApolloReactCommon.QueryResult<
  BranchesForSiteQuery,
  BranchesForSiteQueryVariables
>
export const SiteDetailsDocument = gql`
  query SiteDetails($id: UUID!) {
    siteDetails(id: $id) {
      ...siteDetailsFields
    }
  }
  ${SiteDetailsFieldsFragmentDoc}
`

/**
 * __useSiteDetailsQuery__
 *
 * To run a query within a React component, call `useSiteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSiteDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SiteDetailsQuery,
    SiteDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SiteDetailsQuery, SiteDetailsQueryVariables>(
    SiteDetailsDocument,
    baseOptions
  )
}
export function useSiteDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SiteDetailsQuery,
    SiteDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SiteDetailsQuery,
    SiteDetailsQueryVariables
  >(SiteDetailsDocument, baseOptions)
}
export type SiteDetailsQueryHookResult = ReturnType<typeof useSiteDetailsQuery>
export type SiteDetailsLazyQueryHookResult = ReturnType<
  typeof useSiteDetailsLazyQuery
>
export type SiteDetailsQueryResult = ApolloReactCommon.QueryResult<
  SiteDetailsQuery,
  SiteDetailsQueryVariables
>
export const SiteChangedDocument = gql`
  subscription siteChanged($id: UUID!) {
    siteChanged(id: $id) {
      id
      previewStatus
      updatedAt
      latestPreview {
        buildStatus
      }
      latestBuild {
        ...buildFields
      }
    }
  }
  ${BuildFieldsFragmentDoc}
`

/**
 * __useSiteChangedSubscription__
 *
 * To run a query within a React component, call `useSiteChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSiteChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteChangedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSiteChangedSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    SiteChangedSubscription,
    SiteChangedSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    SiteChangedSubscription,
    SiteChangedSubscriptionVariables
  >(SiteChangedDocument, baseOptions)
}
export type SiteChangedSubscriptionHookResult = ReturnType<
  typeof useSiteChangedSubscription
>
export type SiteChangedSubscriptionResult = ApolloReactCommon.SubscriptionResult<
  SiteChangedSubscription
>
