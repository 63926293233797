export const FullStoryClassName = {
  Block: `fs-block`,
}

export const FullStoryEvent = {
  CreateSite: `Create site`,
  FirstTimeVisitor: `First time visitor`,
  AutoprovisionNewSite: `Autoprovision a new site`,
  UseExistingSite: `Use existing site`,
}
