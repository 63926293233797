import React from "react"

export default function(props) {
  return (
    <svg
      width="663"
      height="729"
      viewBox="0 0 663 729"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M452.979 -184C452.979 204.245 138.245 518.979 -250 518.979"
        stroke="#FCFAFF"
        strokeWidth="420"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle r="16" transform="matrix(-1 0 0 1 51 183)" fill="#3FA9F5" />
      <circle
        r="30"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 -11 426)"
        fill="#F67300"
      />
    </svg>
  )
}
