import * as React from "react"
import { useMatch } from "@reach/router"
import { useUserOrganizationsQuery } from "@modules/header/queries.generated"
import {
  setPersistentOrganizationId,
  getPersistentOrganizationId,
} from "@modules/organization/shared/utils"

export function useOrganizationInfo() {
  const { data, loading, error } = useUserOrganizationsQuery({
    fetchPolicy: `cache-and-network`,
  })

  const matchedOrganizationId = useMatchedOrganizationId()

  usePersistMatchedOrganizationId(matchedOrganizationId)

  const organizations = data?.currentOrganizations || []

  const currentOrgData = organizations
    ? organizations.find(org => org.id === matchedOrganizationId)
    : null

  return {
    loading: loading && !data?.currentOrganizations,
    error,
    currentOrganizations: organizations,
    selectedOrganizationName: currentOrgData?.name || null,
    selectedOrganizationId: matchedOrganizationId,
  }
}

function useMatchedOrganizationId(): string | null {
  // This match should cover org details view ("Sites", "Settings")
  const orgViewMatch = useMatch(`/dashboard/organization/:organizationId/*`)
  const orgViewId =
    orgViewMatch?.organizationId !== `create`
      ? orgViewMatch?.organizationId
      : null

  // This match should cover site details view ("Deploys", "Site Settings", "Preview")
  const siteViewMatch = useMatch(`/dashboard/:organizationId/sites/*`)

  // This match should cover plan change view
  const billingViewMatch = useMatch(`/dashboard/pricing/:organizationId`)

  return (
    orgViewId ||
    siteViewMatch?.organizationId ||
    billingViewMatch?.organizationId ||
    null
  )
}

/**
 * Synchronize organization ID with the persistent ID
 */
function usePersistMatchedOrganizationId(matchedOrganizationId: string | null) {
  React.useEffect(() => {
    if (
      matchedOrganizationId &&
      matchedOrganizationId !== getPersistentOrganizationId()
    ) {
      setPersistentOrganizationId(matchedOrganizationId)
    }
  }, [matchedOrganizationId])
}
