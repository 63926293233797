import React from "react"
import ScalableSvg from "@modules/ui/components/ScalableSvg"

export default function DatoCmsLogoIcon(props) {
  return (
    <ScalableSvg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.9804 1H8.78432C12.7686 1 16 4.18454 16 8.11111C16 12.0377 12.7686 15.2222 8.78432 15.2222H2.9804C2.19608 15.2222 1.56863 14.6039 1.56863 13.8309V2.3913C1.56863 1.61836 2.19608 1 2.9804 1ZM5.23922 8.11111C5.23922 10.0589 6.83922 11.6048 8.78432 11.6048C10.7294 11.6048 12.3294 10.028 12.3294 8.11111C12.3294 6.16328 10.7608 4.61739 8.78432 4.61739C6.80785 4.61739 5.23922 6.1942 5.23922 8.11111Z"
        fill="url(#DatoCmsLogoIcon__paint--0)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.9804 1H8.78432C12.7686 1 16 4.18454 16 8.11111C16 12.0377 12.7686 15.2222 8.78432 15.2222H2.9804C2.19608 15.2222 1.56863 14.6039 1.56863 13.8309V2.3913C1.56863 1.61836 2.19608 1 2.9804 1ZM5.23922 8.11111C5.23922 10.0589 6.83922 11.6048 8.78432 11.6048C10.7294 11.6048 12.3294 10.028 12.3294 8.11111C12.3294 6.16328 10.7608 4.61739 8.78432 4.61739C6.80785 4.61739 5.23922 6.1942 5.23922 8.11111Z"
        fill="black"
        fillOpacity="0.1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.41176 1H7.21569C11.2 1 14.4314 4.18454 14.4314 8.11111C14.4314 12.0377 11.2 15.2222 7.21569 15.2222H1.41176C0.627451 15.2222 0 14.6039 0 13.8309V2.3913C0 1.61836 0.627451 1 1.41176 1ZM3.67059 8.11111C3.67059 10.0589 5.27059 11.6048 7.21569 11.6048C9.16078 11.6048 10.7608 10.028 10.7608 8.11111C10.7608 6.16328 9.19216 4.61739 7.21569 4.61739C5.23922 4.61739 3.67059 6.1942 3.67059 8.11111Z"
        fill="url(#DatoCmsLogoIcon__paint--1)"
      />
      <defs>
        <linearGradient
          id="DatoCmsLogoIcon__paint--0"
          x1="11.1489"
          y1="14.4147"
          x2="15.1272"
          y2="4.3961"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7059" />
          <stop offset="0.3418" stopColor="#FF664E" />
          <stop offset="1" stopColor="#F2543A" />
        </linearGradient>
        <linearGradient
          id="DatoCmsLogoIcon__paint--1"
          x1="17.4713"
          y1="18.2682"
          x2="29.7273"
          y2="-7.95392"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7751" />
          <stop offset="0.3418" stopColor="#FF664E" />
          <stop offset="1" stopColor="#F2543A" />
        </linearGradient>
      </defs>
    </ScalableSvg>
  )
}
