import { useSubscription } from "react-apollo"

import { PULL_REQUEST_CREATED_SUBSCRIPTION } from "@modules/build/shared/queries"

const PULL_REQUEST_PAGE_LIMIT = 10

type UsePullRequestCreatedSubscriptionArguments = {
  siteId: string
  refetch: ({
    siteId,
    pagination,
  }: {
    siteId: string
    pagination: { limit: number }
  }) => void
}

export const usePullRequestCreatedSubscription = ({
  siteId,
  refetch,
}: UsePullRequestCreatedSubscriptionArguments) =>
  useSubscription(PULL_REQUEST_CREATED_SUBSCRIPTION, {
    variables: {
      siteId: siteId,
    },
    onSubscriptionData: () => {
      refetch({
        siteId,
        pagination: {
          limit: PULL_REQUEST_PAGE_LIMIT,
        },
      })
    },
  })
