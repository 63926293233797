import { SourceControlProvider } from "@modules/graphql/types"
import { useFlags } from "@modules/featureFlags"

export function useSupportedSourceControlProviders(): SourceControlProvider[] {
  const { flags } = useFlags()

  const availableSourceControlProviders = [
    SourceControlProvider.Github,
    SourceControlProvider.Gitlab,
  ]

  if (flags.bitbucketSupport) {
    availableSourceControlProviders.push(SourceControlProvider.Bitbucket)
  }

  return availableSourceControlProviders
}
