import React from "react"
import { Redirect } from "@reach/router"
import Loading from "@modules/ui/components/Loading"
import { useFlags } from "@modules/featureFlags"
import { useTracker, GAEventType } from "@modules/analytics"
import { useUserOrganizationsQuery } from "@modules/header/queries.generated"
import { getPersistentOrganizationId } from "@modules/organization/shared/utils"
import { organizations as orgText } from "@modules/locales/default.json"

function SitesListView() {
  const { flags } = useFlags()
  const {
    data: userOrganizationsData,
    loading: loadingUserOrganizations,
  } = useUserOrganizationsQuery()
  const { trackGoogleAnalytics } = useTracker()

  React.useEffect(() => {
    if (flags.acquisitionFunnel) {
      trackGoogleAnalytics({
        eventType: GAEventType.View,
        category: `Signup Flow`,
        label: {
          loc: `Dashboard - Sites`,
          text: `Visited /dashboard/sites. arm: expA`,
        },
      })
    }
  }, [flags.acquisitionFunnel])

  /**
   * In spaces world, the list of all orgs with their sites does not exist
   * Instead, we should redirect the user either of these views:
   *   - space creation flow, if the user has no organizations
   *   - details view for the first of user organizations, if we cannot find a persisted org for this user in local storage
   *   - details view for the persisted organization
   */
  if (loadingUserOrganizations) {
    return <Loading delay={1000} message={orgText.messages.loadingDetails} />
  }

  const userOrganizations = userOrganizationsData?.currentOrganizations || []
  const persistentOrgId = getPersistentOrganizationId()
  const persistentOrg = userOrganizations.find(
    ({ id }) => id === persistentOrgId
  )
  const currentOrganizationId = persistentOrg?.id || userOrganizations[0]?.id

  const redirectTo = currentOrganizationId
    ? `/dashboard/organization/${currentOrganizationId}`
    : `/dashboard/organization/create`

  return <Redirect to={redirectTo} replace noThrow />
}

export default SitesListView
