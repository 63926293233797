import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { InvitationRedirect } from "@modules/invitation"

export function AcceptInvitation({
  invitationId,
}: RouteComponentProps<{ invitationId: string }>) {
  if (!invitationId) {
    return <React.Fragment>Error: missing invitationId</React.Fragment>
  }

  return <InvitationRedirect invitationId={invitationId} />
}
