import * as React from "react"

export function LeadCaptureBackgroundLeft(
  props: React.ComponentPropsWithoutRef<"svg">
) {
  return (
    <svg
      width="235"
      height="559"
      viewBox="0 0 235 559"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M194.5 188C194.5 194.351 189.351 199.5 183 199.5C176.648 199.5 171.5 194.351 171.5 188C171.5 181.649 176.648 176.5 183 176.5C189.351 176.5 194.5 181.649 194.5 188Z"
        stroke="#D9D7E0"
      />
      <path
        d="M158.531 123.406C158.531 123.406 189.994 132.752 194.618 135.162C199.33 137.523 206.037 139.54 204.425 142.549C202.814 145.557 191.543 143.635 191.543 143.635"
        fill="white"
      />
      <path
        d="M158.531 123.406C158.531 123.406 189.994 132.752 194.618 135.162C199.33 137.523 206.037 139.54 204.425 142.549C202.814 145.557 191.543 143.635 191.543 143.635"
        stroke="#D9D7E0"
        strokeMiterlimit="10"
      />
      <path
        d="M168.959 128.046L195.784 146.383C195.784 146.383 209.597 156.306 206.919 159.468C204.192 162.544 190.766 153.09 190.766 153.09"
        fill="white"
      />
      <path
        d="M168.959 128.046L195.784 146.383C195.784 146.383 209.597 156.306 206.919 159.468C204.192 162.544 190.766 153.09 190.766 153.09"
        stroke="#D9D7E0"
        strokeMiterlimit="10"
      />
      <path
        d="M-39.9889 195.211C-39.9889 195.211 -32.6971 161.441 11.7807 127.679C28.7163 114.823 45.4873 108.08 60.7042 104.642C73.8105 101.114 89.6872 98.8801 107.219 100.983C109.901 101.305 112.482 101.703 114.982 102.154C115.531 102.165 129.407 104.693 136.899 108.155C154.771 114.885 165.124 123.016 168.843 126.719C172.562 130.421 181.19 138.519 188.08 148.637C198.36 163.736 201.059 170.357 198.132 172.048C196.389 173.054 191.164 167.179 186.101 161.211C181.039 155.242 171.911 140.074 161.948 145.826C154.154 150.326 154.873 160.908 155.529 164.659C157.439 175.565 166.235 190.001 158.094 194.701C158.094 194.701 148.625 176.898 147.38 174.843C138.195 159.684 128.385 150.193 109.039 144.735C79.6384 136.44 57.4661 150.608 49.3987 155.035C41.3313 159.462 26.2303 172.106 23.4306 178.457"
        fill="white"
      />
      <path
        d="M-39.9889 195.211C-39.9889 195.211 -32.6971 161.441 11.7807 127.679C28.7163 114.823 45.4873 108.08 60.7042 104.642C73.8105 101.114 89.6872 98.8801 107.219 100.983C109.901 101.305 112.482 101.703 114.982 102.154C115.531 102.165 129.407 104.693 136.899 108.155C154.771 114.885 165.124 123.016 168.843 126.719C172.562 130.421 181.19 138.519 188.08 148.637C198.36 163.736 201.059 170.357 198.132 172.048C196.389 173.054 191.164 167.179 186.101 161.211C181.039 155.242 171.911 140.074 161.948 145.826C154.154 150.326 154.873 160.908 155.529 164.659C157.439 175.565 166.235 190.001 158.094 194.701C158.094 194.701 148.625 176.898 147.38 174.843C138.195 159.684 128.385 150.193 109.039 144.735C79.6384 136.44 57.4661 150.608 49.3987 155.035C41.3313 159.462 26.2303 172.106 23.4306 178.457"
        stroke="#D9D7E0"
        strokeMiterlimit="10"
      />
      <rect
        x="-248.076"
        y="161"
        width="339.711"
        height="339.711"
        transform="rotate(15 -248.076 161)"
        fill="#FFDF37"
      />
    </svg>
  )
}

export function LeadCaptureBackgroundRight(
  props: React.ComponentPropsWithoutRef<"svg">
) {
  return (
    <svg
      width="165"
      height="352"
      viewBox="0 0 165 352"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M55.0916 178.465L44.0107 171.05L97.4035 91.2673L108.484 98.6829L55.0916 178.465Z"
        fill="white"
        stroke="#D9D7E0"
        strokeMiterlimit="10"
      />
      <path
        d="M61.1428 130.717C61.1428 130.717 63.3177 107.573 64.5768 100.198C65.7885 92.7352 67.174 88.1308 69.3749 88.4238C71.7112 88.7576 71.4595 98.4291 71.4595 98.4291C71.4595 98.4291 66.4171 115.798 66.3015 116.428"
        fill="white"
      />
      <path
        d="M61.1428 130.717C61.1428 130.717 63.3177 107.573 64.5768 100.198C65.7885 92.7352 67.174 88.1308 69.3749 88.4238C71.7112 88.7576 71.4595 98.4291 71.4595 98.4291C71.4595 98.4291 66.4171 115.798 66.3015 116.428"
        stroke="#D9D7E0"
        strokeMiterlimit="10"
      />
      <path
        d="M82.0782 93.2663C82.0782 93.2663 85.4111 82.8908 80.8924 82.6741C75.5654 82.7694 69.7743 103.11 69.7743 103.11L62.3864 122.277"
        fill="white"
      />
      <path
        d="M82.0782 93.2663C82.0782 93.2663 85.4111 82.8908 80.8924 82.6741C75.5654 82.7694 69.7743 103.11 69.7743 103.11L62.3864 122.277"
        stroke="#D9D7E0"
        strokeMiterlimit="10"
      />
      <path
        d="M64.8154 120.327C65.9966 117.669 67.5733 114.797 69.6576 111.735C81.9599 93.6666 86.9258 85.6357 88.07 84.2189C91.0665 80.5086 95.2094 80.4987 93.6189 85.5258L85.6853 104.282"
        fill="white"
      />
      <path
        d="M64.8154 120.327C65.9966 117.669 67.5733 114.797 69.6576 111.735C81.9599 93.6666 86.9258 85.6357 88.07 84.2189C91.0665 80.5086 95.2094 80.4987 93.6189 85.5258L85.6853 104.282"
        stroke="#D9D7E0"
        strokeMiterlimit="10"
      />
      <path
        d="M60.9273 145.771C60.9273 145.771 58.543 133.037 64.3334 120.005C64.3334 120.005 66.4007 112.87 77.7446 106.932C89.0885 100.994 93.3908 99.0606 93.3908 99.0606C93.3908 99.0606 97.9353 97.4392 95.8473 101.908C93.7592 106.376 86.5377 112.223 82.8603 113.672"
        fill="white"
      />
      <path
        d="M60.9273 145.771C60.9273 145.771 58.543 133.037 64.3334 120.005C64.3334 120.005 66.4007 112.87 77.7446 106.932C89.0885 100.994 93.3908 99.0606 93.3908 99.0606C93.3908 99.0606 97.9353 97.4392 95.8473 101.908C93.7592 106.376 86.5377 112.223 82.8603 113.672"
        stroke="#D9D7E0"
        strokeMiterlimit="10"
      />
      <path
        d="M261.286 317.46C261.286 317.46 209.468 330.612 148.384 289.733C75.4584 240.928 64.0829 165.03 64.0829 165.03L84.7375 130.421L94.8989 108.944C94.8989 108.944 100.886 100.447 102.355 110.389C102.355 110.389 99.6376 125.679 98.1129 134.258C96.5882 142.837 106.801 213.873 172.732 257.996C172.732 257.996 216.271 286.733 263.297 278.496"
        fill="white"
      />
      <path
        d="M261.286 317.46C261.286 317.46 209.468 330.612 148.384 289.733C75.4584 240.928 64.0829 165.03 64.0829 165.03L84.7375 130.421L94.8989 108.944C94.8989 108.944 100.886 100.447 102.355 110.389C102.355 110.389 99.6376 125.679 98.1129 134.258C96.5882 142.837 106.801 213.873 172.732 257.996C172.732 257.996 216.271 286.733 263.297 278.496"
        stroke="#D9D7E0"
        strokeMiterlimit="10"
      />
      <path
        d="M117.177 73.7067L108.484 98.683L102.944 94.9752L97.4033 91.2673L117.177 73.7067Z"
        fill="white"
        stroke="#D9D7E0"
        strokeMiterlimit="10"
      />
      <path
        d="M115.206 76.6899L109.739 81.4983L112.846 83.5777L115.206 76.6899Z"
        fill="#D9BAE8"
        stroke="#D9D7E0"
      />
      <path
        d="M82.6185 113.36L44.0107 171.05"
        stroke="#D9D7E0"
        strokeMiterlimit="10"
      />
      <circle
        cx="165.352"
        cy="40"
        r="40"
        transform="rotate(-90 165.352 40)"
        fill="#159BF3"
      />
    </svg>
  )
}
