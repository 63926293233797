import React from "react"

export default function({ className }) {
  return (
    <svg
      width="77"
      height="14"
      viewBox="0 0 77 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M70 0C73.866 -3.37976e-07 77 3.13401 77 7C77 10.866 73.866 14 70 14C66.134 14 63 10.866 63 7C63 3.13401 66.134 3.37976e-07 70 0Z"
        fill="#F8E1F1"
      />
      <path
        d="M28 14C31.866 14 35 10.866 35 7C35 3.13401 31.866 0 28 0C24.134 0 21 3.13401 21 7C21 10.866 24.134 14 28 14Z"
        fill="#EEC0DF"
      />
      <path
        d="M49 14C52.866 14 56 10.866 56 7C56 3.13401 52.866 0 49 0C45.134 0 42 3.13401 42 7C42 10.866 45.134 14 49 14Z"
        fill="#FFEF9B"
      />
      <path
        d="M7 0C10.866 -3.37976e-07 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 3.37976e-07 10.866 0 7C-3.37975e-07 3.13401 3.13401 3.37976e-07 7 0Z"
        fill="#CCFFFC"
      />
    </svg>
  )
}
