import * as React from "react"
import { WindowLocation } from "@reach/router"
import { Heading, Text, Link, ThemeCss } from "gatsby-interface"
import { LoginOption, LoginOptionLabels } from "@modules/userAccount/constants"
import { Logo } from "./logo"
import { LoginScreenContainer } from "./LoginScreen.helpers"
import { useFlags } from "@modules/featureFlags"
import { useTracker, GAEventType, SegmentEventType } from "@modules/analytics"
import { auth as authText } from "@modules/locales/default.json"
import { LoginErrorMessage } from "./LoginErrorMessage"
import { FormattedMessage } from "@modules/locales"
import { LoginButtons } from "@modules/login/shared/components/LoginButtons"

const mainCss: ThemeCss = theme => ({
  textAlign: `center`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `space-between`,
  minHeight: `100vh`,
  paddingTop: theme.space[12],
  paddingBottom: theme.space[7],
  position: `relative`,
  zIndex: 1,
})

const sectionCss: ThemeCss = theme => ({
  flexGrow: 1,
  display: `flex`,
  flexDirection: `column`,
})

const topSectionCss: ThemeCss = theme => [
  sectionCss(theme),
  {
    justifyContent: `space-between`,
  },
]

const bottomSectionCss: ThemeCss = theme => [
  sectionCss(theme),
  {
    justifyContent: `flex-end`,
  },
]

const logoContainerCss: ThemeCss = theme => ({
  display: `flex`,
  justifyContent: `center`,
})

const titleCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[9],
  fontWeight: theme.fontWeights.extraBold,
  marginTop: 0,
  marginBottom: theme.space[7],
})

const descriptionCss: ThemeCss = theme => ({
  color: theme.colors.grey[60],
  fontSize: theme.fontSizes[3],
  lineHeight: theme.lineHeights.dense,
  margin: 0,
})

const ctaBlockCss: ThemeCss = theme => ({
  paddingTop: theme.space[11],
  paddingBottom: theme.space[11],
  paddingLeft: theme.space[5],
  paddingRight: theme.space[5],
  display: `grid`,
  gridTemplateColumns: `minmax(100%, 360px)`,
  gridGap: theme.space[5],
  margin: `0 auto`,
  [theme.mediaQueries.phablet]: {
    gridTemplateColumns: `360px`,
  },
})

const textCss: ThemeCss = theme => ({
  color: theme.colors.grey[60],
  margin: 0,
})

const separatorCss: ThemeCss = theme => ({
  margin: `${theme.space[7]} auto`,
  width: 72,
  backgroundColor: theme.colors.grey[30],
})

export type LoginScreenProps = {
  location?: WindowLocation
  actionType?: `signup` | `login`
  onLoginOptionClick?: (loginOption: LoginOption) => void
}

export function LoginScreen({
  location,
  actionType = "login",
  onLoginOptionClick,
}: LoginScreenProps) {
  const { flags } = useFlags()
  const { trackGoogleAnalytics, trackSegment } = useTracker()
  const isSignUp = actionType === `signup`

  React.useEffect(() => {
    if (flags.acquisitionFunnel) {
      trackGoogleAnalytics({
        eventType: GAEventType.View,
        category: isSignUp ? `Signup` : `Login`,
        label: {
          loc: isSignUp ? `Signup Page` : `Login Page`,
          text: isSignUp
            ? `viewed /dashboard/signup. arm: expA`
            : `viewed /dashboard/login. arm: expA`,
        },
      })
    }
  }, [flags.acquisitionFunnel])

  return (
    <LoginScreenContainer>
      <main css={mainCss}>
        <div css={topSectionCss}>
          <header css={logoContainerCss}>
            <Logo />
          </header>
          <div>
            <Heading as="h1" css={titleCss}>
              {isSignUp
                ? authText.headers.signUpPage
                : authText.headers.logInPage}
            </Heading>
            <Text css={descriptionCss}>
              {isSignUp
                ? authText.messages.signupIntroText
                : authText.messages.loginIntroText}
            </Text>
          </div>
        </div>
        <div css={ctaBlockCss}>
          <LoginButtons
            actionType={actionType}
            onLoginOptionClick={loginOption => {
              trackSegment({
                type: SegmentEventType.Track,
                event: isSignUp ? `Clicked to Sign Up` : `Clicked to Sign In`,
                properties: {
                  loginType: LoginOptionLabels[loginOption],
                },
              })
              if (flags.acquisitionFunnel) {
                trackGoogleAnalytics({
                  eventType: GAEventType.Click,
                  category: isSignUp ? `Signup` : `Login`,
                  label: {
                    loc: isSignUp ? `Signup Page Body` : `Login Page Body`,
                    text: `clicked to ${
                      isSignUp ? `sign up` : `log in`
                    } with ${loginOption}. arm: expA`,
                  },
                })
              }
              onLoginOptionClick && onLoginOptionClick(loginOption)
            }}
          />
        </div>
        <div css={bottomSectionCss}>
          <Text size="M" css={textCss}>
            {isSignUp ? (
              <FormattedMessage<never, "link">
                message={authText.messages.haveAnAccount}
                tags={{
                  link: function(content) {
                    return <Link to="/dashboard/login">{content}</Link>
                  },
                }}
              />
            ) : (
              <FormattedMessage<never, "link">
                message={authText.messages.noAccount}
                tags={{
                  link: function(content) {
                    return <Link to="/dashboard/signup">{content}</Link>
                  },
                }}
              />
            )}
          </Text>
          {isSignUp && <hr css={separatorCss} />}
          {isSignUp && (
            <Text size="M" css={textCss}>
              <FormattedMessage<never, "termsLink" | "privacyLink">
                message={authText.messages.termsAndPolicy}
                tags={{
                  termsLink: function(content) {
                    return <Link to="/terms-of-use">{content}</Link>
                  },
                  privacyLink: function(content) {
                    return <Link to="/privacy-policy">{content}</Link>
                  },
                }}
              />
            </Text>
          )}
        </div>
      </main>
      <LoginErrorMessage queryString={location?.search || ""} />
    </LoginScreenContainer>
  )
}
