import * as React from "react"
import ScalableSvg from "@modules/ui/components/ScalableSvg"

export function VercelLogoIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <ScalableSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path d="M24 20.7848L12 0L0 20.7848H24Z" fill="black" />
    </ScalableSvg>
  )
}
