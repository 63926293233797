import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import { supportedPlans, SupportedPlanTier } from "@modules/billing/constants"
import { PlanInformationCard } from "@modules/billing/components/PlanInformationCard"
import { getPlanButtonCss } from "@modules/billing/shared/stylesheets"

const plansListCss: ThemeCss = theme => ({
  display: `grid`,
  gridGap: theme.space[5],
  marginBottom: theme.space[9],
})

export type TrialPlanSelectorProps = {
  planOptions: readonly SupportedPlanTier[]
  onSelectPlan: (tier: SupportedPlanTier) => void
  getPlanButtonLabel: (tier: SupportedPlanTier) => React.ReactNode
  getPlanButtonStyle: (tier: SupportedPlanTier) => string
  getPlanHint?: (tier: SupportedPlanTier) => React.ReactNode
}

export function TrialPlanSelector({
  planOptions,
  onSelectPlan,
  getPlanButtonLabel,
  getPlanButtonStyle,
  getPlanHint,
}: TrialPlanSelectorProps) {
  const optionsContainerCss: ThemeCss = theme => [
    plansListCss(theme),
    {
      [theme.mediaQueries.desktop]: {
        gridTemplateColumns: `repeat(${planOptions.length}, 1fr)`,
      },
    },
  ]

  return (
    <div>
      <div css={optionsContainerCss}>
        {planOptions.map(tier => {
          const planInformation = supportedPlans[tier]
          const planButtonVariant = getPlanButtonStyle(tier)
          const buttonCss = getPlanButtonCss(tier, planButtonVariant)

          return (
            <PlanInformationCard
              key={tier}
              tier={tier}
              title={planInformation.name}
              features={planInformation.features}
              hint={getPlanHint ? getPlanHint(tier) : undefined}
              action={
                <button css={buttonCss} onClick={() => onSelectPlan(tier)}>
                  {getPlanButtonLabel(tier)}
                </button>
              }
            />
          )
        })}
      </div>
    </div>
  )
}
