import React from "react"

export const ZendeskContext = React.createContext({
  hideWidget: () => undefined,
  showWidget: () => undefined,
  prepareForm: _data => undefined,
  markWidgetIsFloating: _isFloating => undefined,
  status: undefined,
  activateWidget: () => undefined,
  finishLoading: () => undefined,
})
