export function updateCacheOnReportAdded({
  query,
  payloadName,
  resolverName,
  vendor,
  siteId,
  branch,
  buildId,
}) {
  return ({ client, subscriptionData }) => {
    try {
      const newReport =
        subscriptionData &&
        subscriptionData.data &&
        subscriptionData.data[payloadName]

      if (newReport.branch !== branch) {
        return
      }

      const variablesMap = {
        reportsForBuild: {
          siteId,
          buildId,
        },
        reportsForSite: {
          siteId,
          branch,
          vendor,
        },
      }

      const reportsResult = client.readQuery({
        query,
        variables: variablesMap[resolverName],
      })

      const reportsList = (reportsResult && reportsResult[resolverName]) || []

      const selectedReport = reportsList.find(rpt => rpt.id === newReport.id)

      if (selectedReport) {
        return
      }

      const newReportSet = [newReport, ...reportsList]

      reportsResult[resolverName] = newReportSet

      return client.writeQuery({
        query,
        variables: variablesMap[resolverName],
        data: reportsResult,
      })
    } catch (e) {
      console.error(e.message)
    }
  }
}
