import * as qs from "query-string"
import { navigate } from "gatsby"

export function makeClosableWindow(path, onChildWindowClose) {
  const open = () => {
    if (onChildWindowClose) {
      const messageHandler = e => {
        const { activePath, data } = e.data

        if (activePath === path) {
          const query = qs.parse(data)
          onChildWindowClose(query?.installationId)

          window.removeEventListener(`message`, messageHandler)
        }
      }

      window.addEventListener(`message`, messageHandler, false)
    }

    return window.open(path, `_blank`, `height=600,width=1012`)
  }

  const close = pathToForward => {
    if (window.opener) {
      window.opener.postMessage({ activePath: path, data: pathToForward }, "*")
      window.close()
    } else {
      navigate("/dashboard/sites")
    }
  }

  return [open, close]
}
