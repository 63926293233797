import { SubscriptionStatus, SubscriptionPlan } from "@modules/graphql/types"
import { Theme } from "gatsby-interface"
import { SupportedPlanTier, supportedPlans } from "@modules/billing/constants"

/**
 * The information about the trials and the subscription plans can be found:
 * https://app.getguru.com/card/cjK7g57i/Plans-Free-Trial
 */

export function isTrialingPlan(billingStatus: SubscriptionStatus) {
  return billingStatus === SubscriptionStatus.Trialing
}

export function isFreePlan(billingStatus: SubscriptionStatus) {
  return billingStatus === SubscriptionStatus.Free
}

export function isActivePlan(billingStatus: SubscriptionStatus) {
  return billingStatus === SubscriptionStatus.Active
}

export type PlanMeta = {
  order: number
  getColor: (theme: Theme) => string | undefined
  ctaTo?: string
}

export enum SupportedPlanNames {
  Free = `Free`,
  Professional = `Professional`,
  Business = `Business`,
  Enterprise = `Enterprise`,
  Individual = `Individual`,
  Team = `Team`,
}

export const plansMetaData: Record<SupportedPlanNames, PlanMeta> = {
  Free: {
    order: 0,
    getColor: theme => theme.colors.orange[80],
  },
  Individual: {
    order: 1,
    getColor: theme => theme.colors.blue[70],
  },
  Professional: {
    order: 1,
    getColor: theme => theme.colors.blue[70],
  },
  Team: {
    order: 3,
    getColor: theme => theme.colors.purple[60],
  },
  Business: {
    order: 3,
    getColor: theme => theme.colors.purple[60],
  },
  Enterprise: {
    order: 4,
    getColor: _theme => undefined,
    ctaTo: `/contact-us`,
  },
}

type PlanColors = {
  primaryColor: string
  primaryColorDark: string
  gradient?: string
}

export function getColorsForPlanTier(
  planTier: SupportedPlanTier,
  isTrialPlan = false
) {
  return (theme: Theme): PlanColors => {
    if (isTrialPlan) {
      return {
        primaryColor: theme.colors.purple[60],
        primaryColorDark: theme.colors.purple[80],
        gradient: `linear-gradient(90deg, ${theme.colors.gatsby} 0%, ${theme.colors.blue[40]} 100%)`,
      }
    }
    return planColorsByTier[planTier](theme)
  }
}

const planColorsByTier: Record<
  SupportedPlanTier,
  (theme: Theme) => PlanColors
> = {
  Free: theme => ({
    primaryColor: theme.colors.blue[80],
    primaryColorDark: theme.colors.blue[90],
    gradient: `linear-gradient(90deg, ${theme.colors.gatsby} 0%, ${theme.colors.blue[40]} 100%)`,
  }),
  Individual: theme => ({
    primaryColor: theme.colors.orange[90],
    primaryColorDark: theme.colors.red[80],
    gradient: `linear-gradient(90deg, ${theme.colors.blue[40]} 0%, ${theme.colors.yellow[60]} 100%)`,
  }),
  Team: theme => ({
    primaryColor: theme.colors.magenta[60],
    primaryColorDark: theme.colors.magenta[80],
    gradient: `linear-gradient(90deg, ${theme.colors.yellow[60]} 0%, ${theme.colors.magenta[60]} 100%)`,
  }),
  Enterprise: theme => ({
    primaryColor: theme.colors.purple[60],
    primaryColorDark: theme.colors.purple[80],
    gradient: `linear-gradient(90deg, ${theme.colors.magenta[60]} 0%, ${theme.colors.gatsby} 100%)`,
  }),
}

export function normalizePlanTier(
  planTierString: string
): SupportedPlanTier | null {
  const normalized = planTierString.toLowerCase()

  if (normalized === SupportedPlanTier.Free.toLowerCase()) {
    return SupportedPlanTier.Free
  }
  if (normalized === SupportedPlanTier.Individual.toLowerCase()) {
    return SupportedPlanTier.Individual
  }
  if (normalized === SupportedPlanTier.Team.toLowerCase()) {
    return SupportedPlanTier.Team
  }
  if (normalized === SupportedPlanTier.Enterprise.toLowerCase()) {
    return SupportedPlanTier.Enterprise
  }

  return null
}

export function normalizePlan<
  T extends Pick<SubscriptionPlan, "name" | "features">
>(plan: T): T {
  let features = plan.features

  const planName = plan.name
  if (planName === SupportedPlanTier.Free) {
    features = supportedPlans.Free.features
  } else if (planName === SupportedPlanTier.Individual) {
    features = supportedPlans.Individual.features
  } else if (planName === SupportedPlanTier.Team) {
    features = supportedPlans.Team.features
  } else if (planName === SupportedPlanTier.Enterprise) {
    features = supportedPlans.Enterprise.features
  }

  return {
    ...plan,
    features,
  }
}
