import gql from "graphql-tag"

const reportFragment = `
  fragment reportFields on Report {
    id
    vendor
    siteId
    buildId
    branch
    createdAt
    
    metrics {
      id
      key
      contextValue
      value
      unit
      time
      __typename
    }
    __typename
  }
`

export const REPORTS_FOR_BUILD = gql`
  query reportsForBuild($siteId: UUID!, $buildId: UUID!) {
    reportsForBuild(siteId: $siteId, buildId: $buildId) {
      ...reportFields
    }
  }
  ${reportFragment}
`

export const REPORTS_FOR_SITE = gql`
  query reportsForSite(
    $siteId: UUID!
    $vendor: ReportPluginVendor
    $branch: String
  ) {
    reportsForSite(siteId: $siteId, vendor: $vendor, branch: $branch) {
      ...reportFields
    }
  }
  ${reportFragment}
`

export const REPORT_FOR_SITE_CREATED = gql`
  subscription siteReportCreated($siteId: UUID!, $vendor: ReportPluginVendor!) {
    siteReportCreated(siteId: $siteId, vendor: $vendor) {
      ...reportFields
    }
  }
  ${reportFragment}
`

export const BUILD_REPORT_CREATED = gql`
  subscription buildReportCreated($buildId: UUID!) {
    buildReportCreated(buildId: $buildId) {
      ...reportFields
    }
  }
  ${reportFragment}
`
