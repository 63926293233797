import { useFlags } from "@modules/featureFlags"
import { LoginOption } from "@modules/userAccount/constants"

export function useAvailableLoginOptions() {
  const { flags } = useFlags()

  const availableLoginOptions = [LoginOption.Github, LoginOption.Gitlab]

  if (flags.bitbucketSupport) {
    availableLoginOptions.push(LoginOption.Bitbucket)
  }

  return availableLoginOptions
}
