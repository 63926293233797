import * as React from "react"

export type SuccessIconProps = React.ComponentPropsWithoutRef<"svg"> & {
  a11yId?: string
}

export default function SuccessIcon({ a11yId, ...rest }: SuccessIconProps) {
  const id = a11yId ? a11yId : `SuccessIcon`

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      {...rest}
    >
      <circle cx="10" cy="10" r="10" fill="#37B635" />
      <path
        d="M7.87721 13.7412L5.38946 10.3449C5.13934 9.98294 5.20578 9.49008 5.54283 9.20726L5.54283 9.20726C5.87988 8.92444 6.37679 8.94459 6.68984 9.25377L9.60256 12.2935C10.0767 12.7618 10.0453 13.5363 9.53479 13.9647L9.53479 13.9647C9.02432 14.393 8.25604 14.2895 7.87721 13.7412Z"
        fill="white"
      />
      <path
        d="M8.08081 13.9562C7.56224 13.521 7.51131 12.7413 7.96888 12.2424L13.7477 5.94164C14.0412 5.62168 14.5345 5.58945 14.8671 5.86853L14.8671 5.86853C15.1997 6.1476 15.2537 6.63905 14.9895 6.98362L9.78798 13.7688C9.37613 14.306 8.59938 14.3913 8.08081 13.9562L8.08081 13.9562Z"
        fill="white"
      />
      <path
        d="M8.212 10.8633C8.50311 11.2126 8.71659 11.4455 9.08533 11.0379C9.45407 10.6304 8.96889 12.0665 8.96889 12.0665L7.76562 11.6784L8.212 10.8633Z"
        fill="white"
      />
    </svg>
  )
}
