import * as React from "react"
import { getUnixTime } from "date-fns"

export type useBuildDurationProps = {
  startedAt: Date | string | null
  endedAt: Date | string | null
  duration: number | null
}

export default function useBuildDuration({
  startedAt,
  endedAt,
  duration,
}: useBuildDurationProps) {
  const endedAtDate = endedAt && new Date(endedAt)
  const [currentDate, setCurrentDate] = React.useState(new Date())
  const buildIsFinished = Boolean(duration) || Boolean(endedAt)

  React.useEffect(() => {
    if (buildIsFinished) {
      return
    }
    const refreshInterval = window.setInterval(() => {
      setCurrentDate(new Date())
    }, 1000)

    return () => {
      window.clearInterval(refreshInterval)
    }
  }, [buildIsFinished])

  let buildDuration = null

  if (buildIsFinished && Boolean(duration)) {
    buildDuration = duration
  } else if (startedAt) {
    buildDuration =
      getUnixTime(buildIsFinished && endedAtDate ? endedAtDate : currentDate) -
      getUnixTime(new Date(startedAt))
  }

  if (buildDuration === null) {
    return {
      duration: null,
      formatted: "",
      accessible: "",
    }
  }

  const formattedDuration = formatDuration(buildDuration)

  return {
    duration: buildDuration,
    formatted: formattedDuration.duration,
    accessible: formattedDuration.a11yDuration,
  }
}

/**
 * Format duration using two different formats,
 * one to be displayed on screen and one for screen readers
 *   - h:mm:ss; or m:ss if durations is less than 1 hour
 *   - h hour(s), m minute(s), s second(s); with hours and/or minutes omitted if they are 0
 */
export function formatDuration(durationInSeconds: number) {
  const { hours, minutes, seconds } = breakDurationIntoUnits(durationInSeconds)

  if (hours === 0 && minutes === 0 && seconds === 0) {
    return {
      duration: `less than 1s`,
      a11yDuration: `less than 1 second`,
    }
  }

  const duration = []
  const a11yDuration = []

  if (hours > 0) {
    duration.push(hours)
    a11yDuration.push(hours === 1 ? `${hours} hour` : `${hours} hours`)
  }

  const formattedMinutes = String(minutes).padStart(2, `0`)
  if (minutes === 0) {
    /**
     * If the number of minutes in duration is 0, we want do display it like "0:17" instead of just "17"
     * We are checking for "duration.length" to display durations longer than 1 hour properly:
     * "2:00:17" instead of "2:0"17
     */
    duration.push(duration.length > 0 ? formattedMinutes : minutes)
  } else {
    duration.push(formattedMinutes)
    a11yDuration.push(
      minutes === 1 ? `${minutes} minute` : `${minutes} minutes`
    )
  }

  /**
   * If total duration is more or equal than 60 seconds,
   * our "duration" array will contain at least one element at this point
   */
  duration.push(
    duration.length > 0 ? String(seconds).padStart(2, `0`) : seconds
  )
  a11yDuration.push(seconds === 1 ? `${seconds} second` : `${seconds} seconds`)

  return {
    duration: duration.join(`:`),
    a11yDuration: a11yDuration.join(`, `),
  }
}

function breakDurationIntoUnits(durationInSeconds: number) {
  const seconds = durationInSeconds % 60

  const totalMinutes = (durationInSeconds - seconds) / 60

  let minutes = totalMinutes
  let hours = 0

  if (totalMinutes >= 60) {
    minutes = totalMinutes % 60
    hours = (totalMinutes - minutes) / 60
  }

  return {
    hours,
    minutes,
    seconds,
  }
}
