import { BuildRunnerType, Build } from "@modules/graphql/types"

import {
  useBuildsForSiteQuery,
  useBuildsForPullRequestQuery,
  useBuildCreatedSubscription,
} from "@modules/build/shared/queries.generated"

import { usePullRequestsForSiteWithSearchQuery } from "@modules/deploys/shared/queries.generated"
import { usePullRequestCreatedSubscription } from "@modules/build/shared/hooks/usePullRequestCreatedSubscription"
import { isProperlyConnected } from "@modules/site/cdnIntegrations/helpers"

export const usePullRequestById = (pullRequestId: string, siteId: string) => {
  const variables = {
    id: pullRequestId,
    pagination: {
      limit: 10,
    },
  }

  const {
    data,
    loading,
    error,
    fetchMore,
    refetch,
  } = useBuildsForPullRequestQuery({
    variables,
    fetchPolicy: "cache-and-network",
  })

  useBuildCreatedSubscription({
    variables: {
      id: siteId,
      runnerType: BuildRunnerType.Builds,
    },
    onSubscriptionData: () => {
      refetch({
        id: pullRequestId,
        pagination: {
          limit: 10,
        },
      })
    },
  })

  const loadMore = () => {
    const actualPageInfo = data?.buildsForPullRequest?.pageInfo

    if (!actualPageInfo?.hasNextPage || !actualPageInfo?.endCursor || loading) {
      return
    }

    fetchMore({
      variables: {
        id: pullRequestId,
        pagination: {
          limit: 10,
          endCursor: actualPageInfo.endCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newBuilds = fetchMoreResult?.buildsForPullRequest?.builds
        const pageInfo = fetchMoreResult?.buildsForPullRequest?.pageInfo

        if (!newBuilds || newBuilds.length === 0) {
          return previousResult
        }

        return {
          ...previousResult,
          buildsForPullRequest: {
            __typename: "BuildResults",
            builds: [
              ...(previousResult.buildsForPullRequest?.builds || []),
              ...newBuilds,
            ],
            pageInfo,
          },
        }
      },
    })
  }

  return {
    builds: data?.buildsForPullRequest?.builds || [],
    pullRequestById: data?.pullRequestById,
    loading: loading && !data,
    loadingMore: Boolean(loading && data),
    error,
    refetch,
    loadMore,
  }
}

export const usePullRequests = (siteId: string, searchText = "") => {
  const variables = {
    siteId,
    searchText,
    pagination: {
      limit: 10,
    },
  }

  const {
    loading,
    data,
    error,
    refetch,
    fetchMore,
  } = usePullRequestsForSiteWithSearchQuery({
    variables,
    fetchPolicy: "cache-and-network",
  })

  usePullRequestCreatedSubscription({ siteId, refetch })

  const loadMore = () => {
    const actualPageInfo = data?.pullRequestsForSite?.pageInfo

    if (!actualPageInfo?.hasNextPage || !actualPageInfo?.endCursor || loading) {
      return
    }

    fetchMore({
      variables: {
        ...variables,
        pagination: {
          limit: 10,
          endCursor: actualPageInfo.endCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newPullRequests =
          fetchMoreResult?.pullRequestsForSite?.pullRequests
        const pageInfo = fetchMoreResult?.pullRequestsForSite?.pageInfo

        if (!newPullRequests || newPullRequests.length === 0) {
          return previousResult
        }

        return {
          ...previousResult,
          pullRequestsForSite: {
            __typename: "PullRequestResults",
            pullRequests: [
              ...(previousResult.pullRequestsForSite?.pullRequests || []),
              ...newPullRequests,
            ],
            pageInfo,
          },
        }
      },
    })
  }

  const pullRequests = data?.pullRequestsForSite?.pullRequests || []
  const builds = pullRequests
    .map(pr => pr?.latestBuild)
    .filter(Boolean) as Build[]

  return [
    builds,
    {
      loading: loading && !data,
      loadingMore: Boolean(loading && data),
      error,
      loadMore,
    },
  ] as const
}

export const useBuilds = (siteId: string, branch: string, skip?: boolean) => {
  const variables = {
    siteId,
    branch,
    runnerType: BuildRunnerType.IncrementalBuilds,
    pagination: {
      limit: 10,
    },
  }

  const { data, loading, error, refetch, fetchMore } = useBuildsForSiteQuery({
    variables,
    fetchPolicy: "cache-and-network",
    skip,
  })

  useBuildCreatedSubscription({
    variables: {
      id: siteId,
      runnerType: BuildRunnerType.Builds,
    },
    onSubscriptionData: () => {
      refetch({
        branch,
        siteId,
        pagination: {
          limit: 10,
        },
      })
    },
  })

  const supportedIntegrations = data?.supportedCDNIntegrations || []
  const mainIntegration = supportedIntegrations.find(isProperlyConnected)

  const loadMore = () => {
    const actualPageInfo = data?.buildsForSite?.pageInfo

    if (!actualPageInfo?.hasNextPage || !actualPageInfo?.endCursor || loading) {
      return
    }

    fetchMore({
      variables: {
        ...variables,
        pagination: {
          limit: 10,
          endCursor: actualPageInfo.endCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newBuilds = fetchMoreResult?.buildsForSite?.builds
        const pageInfo = fetchMoreResult?.buildsForSite?.pageInfo

        if (!newBuilds || newBuilds.length === 0) {
          return previousResult
        }

        return {
          ...previousResult,
          buildsForSite: {
            __typename: "BuildResults",
            builds: [
              ...(previousResult.buildsForSite?.builds || []),
              ...newBuilds,
            ],
            pageInfo,
          },
        }
      },
    })
  }

  const builds = data?.buildsForSite?.builds || []

  return [
    builds,
    mainIntegration?.vendor || null,
    {
      loading: loading && !data,
      loadingMore: Boolean(loading && data),
      error,
      loadMore,
      refetch,
    },
  ] as const
}
