import * as React from "react"
import * as qs from "query-string"
import { RouteComponentProps } from "@reach/router"
import { LoginScreen } from "@modules/login/cloud/components/LoginScreen"
import { processSignupAttempt } from "@modules/auth/utils"
import { AnonymousFeatureFlagProvider } from "@modules/featureFlags"
import { LeadCapture } from "@modules/onboarding/gaFlow/components/LeadCapture"

export function SignupPage(props: RouteComponentProps) {
  const [welcomeFormValues, setWelcomeFormValues] = React.useState({})

  const hasNotFinishedForm = Object.entries(welcomeFormValues).length === 0

  return (
    <AnonymousFeatureFlagProvider>
      {hasNotFinishedForm && (
        <LeadCapture
          user={{}}
          location={props.location}
          onSubmit={setWelcomeFormValues}
        />
      )}
      {!hasNotFinishedForm && (
        <LoginScreen
          location={props.location}
          actionType="signup"
          onLoginOptionClick={loginOption => {
            const params = qs.parse(props.location?.search || "")
            processSignupAttempt(loginOption, params, welcomeFormValues)
          }}
        />
      )}
    </AnonymousFeatureFlagProvider>
  )
}
