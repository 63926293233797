import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import {
  fragmentCacheRedirect,
  fragmentLinkState,
} from "apollo-link-state-fragment"
import gql from "graphql-tag"
import apolloLogger from "apollo-link-logger"
import { BatchHttpLink } from "apollo-link-batch-http"
import { onError } from "apollo-link-error"
import { ApolloLink, split } from "apollo-link"
import { WebSocketLink } from "apollo-link-ws"
import { getMainDefinition } from "apollo-utilities"
import { setContext } from "apollo-link-context"
import { LocalStorageItems } from "@modules/localStorage/constants"

const localTypeDefs = gql`
  extend type Query {
    cmsProvisioningOptions: CmsProvisioningOptions
  }

  type CmsProvisioningOptions {
    provider: String!
    starter: String!
  }
`

const localResolvers = {}

/* set up apollo graphql client */
const graphqlEndpoint = (useWebSocket = false) => {
  const httpProtocol = process.env.GATSBY_HTTP_PROTOCOL || `https`
  const wssProtocol = httpProtocol === `https` ? `wss` : `ws`

  return useWebSocket
    ? `${wssProtocol}://${process.env.GATSBY_DASHBOARD_GRAPHQL_URL}/subscriptions`
    : `${httpProtocol}://${process.env.GATSBY_DASHBOARD_GRAPHQL_URL}`
}

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      ...fragmentCacheRedirect(),
    },
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.info(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  if (networkError) {
    console.info(`[Network error]: ${networkError}`)
  }
})

const authLink = setContext((_, { headers }) => {
  const authToken =
    typeof localStorage !== `undefined` &&
    localStorage.getItem(LocalStorageItems.GatsbyToken)

  return {
    headers: authToken
      ? {
          ...headers,
          Authorization: `Bearer ${authToken}`,
        }
      : headers,
  }
})

/* create apollo client */
export default function createApolloClient() {
  const batchHttpLink = new BatchHttpLink({
    uri: graphqlEndpoint(),
  })

  const websocketLink = new WebSocketLink({
    uri: graphqlEndpoint(true),
    options: {
      reconnect: true,
    },
  })

  let links = [errorLink, fragmentLinkState(cache), authLink]

  if (process.env.NODE_ENV === `development`) {
    links = [apolloLogger, ...links]
  }

  let transportLink = ApolloLink.from(links)

  transportLink = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === `OperationDefinition` && operation === `subscription`
    },
    websocketLink,
    batchHttpLink
  )

  const link = ApolloLink.from([...links, transportLink])

  const apolloClient = new ApolloClient({
    cache,
    link,
    typeDefs: localTypeDefs,
    resolvers: {},
    localResolvers,
  })

  return apolloClient
}

export const apolloCache = cache
