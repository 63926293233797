import React from "react"
import { css } from "@emotion/core"

const baseCss = theme => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${theme.space[7]};
  width: 100%;
`

export default function FormActions(props) {
  return <div css={baseCss} {...props} />
}
