import React from "react"

export default function(props) {
  return (
    <svg
      width="354"
      height="397"
      viewBox="0 0 354 397"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M224.107 168.716L211.483 164.427L242.369 73.531L254.993 77.8207L224.107 168.716Z"
        fill="white"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M217.574 121.031C217.574 121.031 213.675 98.1147 212.979 90.6658C212.215 83.1441 212.359 78.3379 214.561 78.0504C216.904 77.7671 219.168 87.1732 219.168 87.1732C219.168 87.1732 218.8 105.256 218.852 105.894"
        fill="white"
      />
      <path
        d="M217.574 121.031C217.574 121.031 213.675 98.1147 212.979 90.6658C212.215 83.1441 212.359 78.3379 214.561 78.0504C216.904 77.7671 219.168 87.1732 219.168 87.1732C219.168 87.1732 218.8 105.256 218.852 105.894"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M228.086 79.4342C228.086 79.4342 228.615 68.5494 224.195 69.5115C219.074 70.9844 218.754 92.1306 218.754 92.1306L216.587 112.558"
        fill="white"
      />
      <path
        d="M228.086 79.4342C228.086 79.4342 228.615 68.5494 224.195 69.5115C219.074 70.9844 218.754 92.1306 218.754 92.1306L216.587 112.558"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M218.428 110.045C218.879 107.171 219.658 103.989 220.877 100.492C228.075 79.8516 230.79 70.808 231.527 69.1429C233.46 64.7828 237.458 63.6992 237.225 68.9668L234.425 89.1383"
        fill="white"
      />
      <path
        d="M218.428 110.045C218.879 107.171 219.658 103.989 220.877 100.492C228.075 79.8516 230.79 70.808 231.527 69.1429C233.46 64.7828 237.458 63.6992 237.225 68.9668L234.425 89.1383"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M221.269 135.627C221.269 135.627 215.665 123.947 217.879 109.859C217.879 109.859 218.026 102.432 227.443 93.7565C236.86 85.0809 240.514 82.0979 240.514 82.0979C240.514 82.0979 244.483 79.3539 243.624 84.211C242.766 89.0681 237.307 96.5865 234.131 98.9398"
        fill="white"
      />
      <path
        d="M221.269 135.627C221.269 135.627 215.665 123.947 217.879 109.859C217.879 109.859 218.026 102.432 227.443 93.7565C236.86 85.0809 240.514 82.0979 240.514 82.0979C240.514 82.0979 244.483 79.3539 243.624 84.211C242.766 89.0681 237.307 96.5865 234.131 98.9398"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M459.284 249.509C459.284 249.509 412.646 275.644 343.054 251.997C259.97 223.765 229.308 153.409 229.308 153.409L240.285 114.629L244.532 91.2526C244.532 91.2526 248.112 81.4936 252.108 90.7153C252.108 90.7153 253.447 106.187 254.198 114.868C254.95 123.549 283.227 189.509 358.343 215.033C358.343 215.033 407.843 231.501 451.126 211.356"
        fill="white"
      />
      <path
        d="M459.284 249.509C459.284 249.509 412.646 275.644 343.054 251.997C259.97 223.765 229.308 153.409 229.308 153.409L240.285 114.629L244.532 91.2526C244.532 91.2526 248.112 81.4936 252.108 90.7153C252.108 90.7153 253.447 106.187 254.198 114.868C254.95 123.549 283.227 189.509 358.343 215.033C358.343 215.033 407.843 231.501 451.126 211.356"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M256.915 51.4447L254.993 77.8207L248.681 75.6758L242.369 73.5309L256.915 51.4447Z"
        fill="white"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M255.784 54.837L251.75 60.8984L255.29 62.1012L255.784 54.837Z"
        fill="#D9BAE8"
        stroke="#D9BAE8"
      />
      <path
        d="M233.816 98.7009L211.483 164.427"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <circle cx="282.455" cy="20.2727" r="20" fill="#B17ACC" />
      <circle cx="10" cy="141" r="10" fill="#90CDF9" />
      <circle
        cx="369.511"
        cy="355.5"
        r="41.0455"
        transform="rotate(-90 369.511 355.5)"
        fill="#FEC21E"
      />
    </svg>
  )
}
