/**
 * These dots run along either the left or right edge of the screen.
 * Comes in 3 sizes.
 */
import React from "react"
import { DecorativeDots } from "gatsby-interface"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import { Sizes, SizeIds, DotSizes } from "../constants"

const propTypes = {
  side: PropTypes.oneOf([`left`, `right`]).isRequired,
  size: PropTypes.oneOf(SizeIds),
  verticalOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fadeStrength: PropTypes.number,
}

export const dotDimensions = {
  [Sizes.Small]: {
    width: 200,
    height: 120,
  },
  [Sizes.Medium]: {
    width: 400,
    height: 140,
  },
  [Sizes.Large]: {
    width: 360,
    height: 240,
  },
}

const Wrapper = styled.div`
  position: absolute;
  z-index: 0;
  transform: translateY(-50%);
`

function DotsAlongScreenEdge({
  side,
  size = Sizes.Medium,
  verticalOffset = 0,
  fadeStrength = 0.15,
}) {
  const dotSize = DotSizes[size]
  const dimensions = dotDimensions[size]
  const angle = side === `left` ? 180 : 0

  const verticalOffsetString =
    typeof verticalOffset === `number` ? `${verticalOffset}px` : verticalOffset

  const positionStyles = {
    [side]: 0,
    top: `calc(50vh + ${verticalOffsetString})`,
  }

  // DecorativeDots produces random values, which can mess up snapshot tests
  // which expect the same output every time.
  // Because this component is decorative anyway, not core functionality, we
  // don't need to worry about testing it.
  if (process.env.NODE_ENV === `test`) {
    return null
  }

  return (
    <Wrapper style={positionStyles}>
      <DecorativeDots
        width={dimensions.width}
        height={dimensions.height}
        dotSize={dotSize}
        angle={angle}
        fadeStrength={fadeStrength}
      />
    </Wrapper>
  )
}

DotsAlongScreenEdge.propTypes = propTypes

export default DotsAlongScreenEdge
