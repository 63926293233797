// THIS IS A GENERATED FILE
import * as Types from "../graphql/types"

import { BillingPlanFieldsFragment } from "../organization/fragments.generated"
import gql from "graphql-tag"
import { BillingPlanFieldsFragmentDoc } from "../organization/fragments.generated"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type AvailablePlansQueryVariables = Types.Exact<{
  organizationId?: Types.Maybe<Types.Scalars["UUID"]>
  interval: Types.BillingInterval
  group?: Types.Maybe<Types.Scalars["String"]>
}>

export type AvailablePlansQuery = { __typename?: "Query" } & {
  availableSubscriptionPlans?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "SubscriptionPlan" } & Pick<
          Types.SubscriptionPlan,
          | "isRecommended"
          | "nextBillingDate"
          | "formattedMonthlyAmount"
          | "quantity"
          | "buildsTier"
          | "hostingTier"
        > &
          BillingPlanFieldsFragment
      >
    >
  >
}

export type AllAvailablePlansQueryVariables = Types.Exact<{
  organizationId?: Types.Maybe<Types.Scalars["UUID"]>
  group?: Types.Maybe<Types.Scalars["String"]>
}>

export type AllAvailablePlansQuery = { __typename?: "Query" } & {
  monthlyPlans?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "SubscriptionPlan" } & Pick<
          Types.SubscriptionPlan,
          | "isRecommended"
          | "nextBillingDate"
          | "formattedMonthlyAmount"
          | "quantity"
          | "buildsTier"
          | "hostingTier"
        > &
          BillingPlanFieldsFragment
      >
    >
  >
  annualPlans?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "SubscriptionPlan" } & Pick<
          Types.SubscriptionPlan,
          | "isRecommended"
          | "nextBillingDate"
          | "formattedMonthlyAmount"
          | "quantity"
          | "buildsTier"
          | "hostingTier"
        > &
          BillingPlanFieldsFragment
      >
    >
  >
}

export const AvailablePlansDocument = gql`
  query availablePlans(
    $organizationId: UUID
    $interval: BillingInterval!
    $group: String
  ) {
    availableSubscriptionPlans(
      organizationId: $organizationId
      interval: $interval
      group: $group
    ) {
      ...billingPlanFields
      isRecommended
      nextBillingDate
      formattedMonthlyAmount
      quantity
      buildsTier
      hostingTier
    }
  }
  ${BillingPlanFieldsFragmentDoc}
`

/**
 * __useAvailablePlansQuery__
 *
 * To run a query within a React component, call `useAvailablePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailablePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailablePlansQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      interval: // value for 'interval'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useAvailablePlansQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AvailablePlansQuery,
    AvailablePlansQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AvailablePlansQuery,
    AvailablePlansQueryVariables
  >(AvailablePlansDocument, baseOptions)
}
export function useAvailablePlansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AvailablePlansQuery,
    AvailablePlansQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AvailablePlansQuery,
    AvailablePlansQueryVariables
  >(AvailablePlansDocument, baseOptions)
}
export type AvailablePlansQueryHookResult = ReturnType<
  typeof useAvailablePlansQuery
>
export type AvailablePlansLazyQueryHookResult = ReturnType<
  typeof useAvailablePlansLazyQuery
>
export type AvailablePlansQueryResult = ApolloReactCommon.QueryResult<
  AvailablePlansQuery,
  AvailablePlansQueryVariables
>
export const AllAvailablePlansDocument = gql`
  query allAvailablePlans($organizationId: UUID, $group: String) {
    monthlyPlans: availableSubscriptionPlans(
      organizationId: $organizationId
      interval: MONTHLY
      group: $group
    ) {
      ...billingPlanFields
      isRecommended
      nextBillingDate
      formattedMonthlyAmount
      quantity
      buildsTier
      hostingTier
    }
    annualPlans: availableSubscriptionPlans(
      organizationId: $organizationId
      interval: ANNUAL
      group: $group
    ) {
      ...billingPlanFields
      isRecommended
      nextBillingDate
      formattedMonthlyAmount
      quantity
      buildsTier
      hostingTier
    }
  }
  ${BillingPlanFieldsFragmentDoc}
`

/**
 * __useAllAvailablePlansQuery__
 *
 * To run a query within a React component, call `useAllAvailablePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAvailablePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAvailablePlansQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useAllAvailablePlansQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllAvailablePlansQuery,
    AllAvailablePlansQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllAvailablePlansQuery,
    AllAvailablePlansQueryVariables
  >(AllAvailablePlansDocument, baseOptions)
}
export function useAllAvailablePlansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllAvailablePlansQuery,
    AllAvailablePlansQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllAvailablePlansQuery,
    AllAvailablePlansQueryVariables
  >(AllAvailablePlansDocument, baseOptions)
}
export type AllAvailablePlansQueryHookResult = ReturnType<
  typeof useAllAvailablePlansQuery
>
export type AllAvailablePlansLazyQueryHookResult = ReturnType<
  typeof useAllAvailablePlansLazyQuery
>
export type AllAvailablePlansQueryResult = ApolloReactCommon.QueryResult<
  AllAvailablePlansQuery,
  AllAvailablePlansQueryVariables
>
