import * as React from "react"
import BuildTypeLabel from "./BuildTypeLabel"
import { ThemeCss } from "gatsby-interface"
import { BuildType, BuildRunnerType, BuildCommit } from "@modules/graphql/types"
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@reach/disclosure"
import { MdMoreHoriz } from "react-icons/md"

export type BuildEventTextProps = {
  buildType: BuildType
  runnerType: BuildRunnerType
  buildSource?: string
  commit?: BuildCommit
  collapsible?: boolean
  truncate?: boolean
}

export function BuildEventText({
  buildType,
  runnerType,
  commit,
  buildSource,
  collapsible = false,
  truncate = true,
}: BuildEventTextProps) {
  const showCommit = buildType === BuildType.SourceUpdate && commit
  const commitMessage = commit?.message

  if (showCommit && commitMessage) {
    return truncate ? (
      <CommitTextDisclosure
        commitMessage={commitMessage}
        expandable={collapsible}
      />
    ) : (
      <span>{commitMessage}</span>
    )
  }

  return (
    <BuildTypeLabel
      buildType={buildType}
      buildSource={buildSource}
      runnerType={runnerType}
    />
  )
}

const disclosureButtonCss: ThemeCss = theme => ({
  border: `none`,
  backgroundColor: theme.tones.NEUTRAL.light,
  color: theme.tones.NEUTRAL.dark,
  padding: `${theme.space[1]} ${theme.space[2]}`,
})

const COMMIT_TEXT_MAX_LENGTH = 80

type CommitTextDisclosureProps = {
  commitMessage: string
  expandable?: boolean
}

function CommitTextDisclosure({
  commitMessage,
  expandable,
}: CommitTextDisclosureProps) {
  if (commitMessage.length < 80) {
    return <span>{commitMessage}</span>
  }

  // Commit title is separated from the body by at least one new line
  // which means we can get it by splitting the commit message by "\n"
  // and picking the first item from the resulting array
  const [commitTitle] = commitMessage.split(/\n/)
  // Next we can extract the "visible" part of the commit no longer than COMMIT_TEXT_MAX_LENGTH characters
  const truncatedCommitTitle = commitTitle.substr(0, COMMIT_TEXT_MAX_LENGTH)
  // and get the cutoff part to be displayed when user clicks the button to see full commit message
  const commitTitleRest = commitTitle.substr(truncatedCommitTitle.length)
  // Everything in the commit message that goes after the title is its body,
  // We also trim the body since we are going to handle spacing ourselves
  const commitBody = commitMessage.substr(commitTitle.length || 0).trim()

  return (
    <Disclosure>
      {truncatedCommitTitle}
      {commitTitleRest && <React.Fragment>&hellip;</React.Fragment>}&nbsp;
      {expandable && (
        <React.Fragment>
          <DisclosureButton
            aria-label="Show full commit message"
            css={disclosureButtonCss}
          >
            <MdMoreHoriz css={{ verticalAlign: `middle` }} />
          </DisclosureButton>
          <DisclosurePanel
            css={{
              whiteSpace: `pre-wrap`,
            }}
          >
            {commitTitleRest && (
              <React.Fragment>
                &hellip;{commitTitleRest}
                <br />
              </React.Fragment>
            )}
            <br />
            {commitBody}
          </DisclosurePanel>
        </React.Fragment>
      )}
    </Disclosure>
  )
}
