// THIS IS A GENERATED FILE
import * as Types from "../../graphql/types"

import { BuildFieldsFragment } from "./fragments.generated"
import gql from "graphql-tag"
import { BuildFieldsFragmentDoc } from "./fragments.generated"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type BuildByIdQueryVariables = Types.Exact<{
  buildId: Types.Scalars["UUID"]
}>

export type BuildByIdQuery = { __typename?: "Query" } & {
  buildById?: Types.Maybe<{ __typename?: "Build" } & BuildFieldsFragment>
}

export type BuildsForSiteQueryVariables = Types.Exact<{
  siteId: Types.Scalars["UUID"]
  pagination?: Types.Maybe<Types.CursorPagination>
  branch?: Types.Maybe<Types.Scalars["String"]>
  runnerType?: Types.Maybe<Types.BuildRunnerType>
}>

export type BuildsForSiteQuery = { __typename?: "Query" } & {
  buildsForSite?: Types.Maybe<
    { __typename?: "BuildResults" } & {
      builds?: Types.Maybe<
        Array<Types.Maybe<{ __typename?: "Build" } & BuildFieldsFragment>>
      >
      pageInfo?: Types.Maybe<
        { __typename?: "CursorPageInfo" } & Pick<
          Types.CursorPageInfo,
          "hasNextPage" | "endCursor"
        >
      >
    }
  >
  supportedCDNIntegrations?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "CdnIntegration" } & Pick<
          Types.CdnIntegration,
          "siteId" | "vendor" | "connected" | "config"
        >
      >
    >
  >
}

export type BuildsForPullRequestQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
  pagination?: Types.Maybe<Types.CursorPagination>
}>

export type BuildsForPullRequestQuery = { __typename?: "Query" } & {
  pullRequestById?: Types.Maybe<
    { __typename?: "PullRequest" } & Pick<
      Types.PullRequest,
      "title" | "number" | "stableBuildURL"
    >
  >
  buildsForPullRequest?: Types.Maybe<
    { __typename?: "BuildResults" } & {
      builds?: Types.Maybe<
        Array<Types.Maybe<{ __typename?: "Build" } & BuildFieldsFragment>>
      >
      pageInfo?: Types.Maybe<
        { __typename?: "CursorPageInfo" } & Pick<
          Types.CursorPageInfo,
          "endCursor" | "hasNextPage"
        >
      >
    }
  >
}

export type TriggerBuildForSiteBranchMutationVariables = Types.Exact<{
  branch?: Types.Maybe<Types.Scalars["String"]>
  siteId: Types.Scalars["UUID"]
  deleteCache?: Types.Maybe<Types.Scalars["Boolean"]>
}>

export type TriggerBuildForSiteBranchMutation = { __typename?: "Mutation" } & {
  triggerCloudBuilder?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
  >
}

export type TriggerBuildForSitePullRequestMutationVariables = Types.Exact<{
  pullRequestId: Types.Scalars["UUID"]
  siteId: Types.Scalars["UUID"]
  deleteCache?: Types.Maybe<Types.Scalars["Boolean"]>
}>

export type TriggerBuildForSitePullRequestMutation = {
  __typename?: "Mutation"
} & {
  triggerDeployPreview?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
  >
}

export type LatestBuildByStatusByPullRequestIdQueryVariables = Types.Exact<{
  siteId: Types.Scalars["UUID"]
  pullRequestId: Types.Scalars["UUID"]
  status: Types.BuildStatus
}>

export type LatestBuildByStatusByPullRequestIdQuery = {
  __typename?: "Query"
} & {
  latestBuildByStatusByPullRequestId?: Types.Maybe<
    { __typename?: "Build" } & BuildFieldsFragment
  >
}

export type BuildCreatedSubscriptionVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
  runnerType: Types.BuildRunnerType
}>

export type BuildCreatedSubscription = { __typename?: "Subscription" } & {
  buildCreated?: Types.Maybe<{ __typename?: "Build" } & BuildFieldsFragment>
}

export const BuildByIdDocument = gql`
  query buildById($buildId: UUID!) {
    buildById(buildId: $buildId) {
      ...buildFields
    }
  }
  ${BuildFieldsFragmentDoc}
`

/**
 * __useBuildByIdQuery__
 *
 * To run a query within a React component, call `useBuildByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildByIdQuery({
 *   variables: {
 *      buildId: // value for 'buildId'
 *   },
 * });
 */
export function useBuildByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BuildByIdQuery,
    BuildByIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<BuildByIdQuery, BuildByIdQueryVariables>(
    BuildByIdDocument,
    baseOptions
  )
}
export function useBuildByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BuildByIdQuery,
    BuildByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<BuildByIdQuery, BuildByIdQueryVariables>(
    BuildByIdDocument,
    baseOptions
  )
}
export type BuildByIdQueryHookResult = ReturnType<typeof useBuildByIdQuery>
export type BuildByIdLazyQueryHookResult = ReturnType<
  typeof useBuildByIdLazyQuery
>
export type BuildByIdQueryResult = ApolloReactCommon.QueryResult<
  BuildByIdQuery,
  BuildByIdQueryVariables
>
export const BuildsForSiteDocument = gql`
  query buildsForSite(
    $siteId: UUID!
    $pagination: CursorPagination
    $branch: String
    $runnerType: BuildRunnerType
  ) {
    buildsForSite(
      siteId: $siteId
      pagination: $pagination
      branch: $branch
      runnerType: $runnerType
    ) {
      builds {
        ...buildFields
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    supportedCDNIntegrations(siteId: $siteId) {
      siteId
      vendor
      connected
      config
    }
  }
  ${BuildFieldsFragmentDoc}
`

/**
 * __useBuildsForSiteQuery__
 *
 * To run a query within a React component, call `useBuildsForSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildsForSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildsForSiteQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      pagination: // value for 'pagination'
 *      branch: // value for 'branch'
 *      runnerType: // value for 'runnerType'
 *   },
 * });
 */
export function useBuildsForSiteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BuildsForSiteQuery,
    BuildsForSiteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    BuildsForSiteQuery,
    BuildsForSiteQueryVariables
  >(BuildsForSiteDocument, baseOptions)
}
export function useBuildsForSiteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BuildsForSiteQuery,
    BuildsForSiteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    BuildsForSiteQuery,
    BuildsForSiteQueryVariables
  >(BuildsForSiteDocument, baseOptions)
}
export type BuildsForSiteQueryHookResult = ReturnType<
  typeof useBuildsForSiteQuery
>
export type BuildsForSiteLazyQueryHookResult = ReturnType<
  typeof useBuildsForSiteLazyQuery
>
export type BuildsForSiteQueryResult = ApolloReactCommon.QueryResult<
  BuildsForSiteQuery,
  BuildsForSiteQueryVariables
>
export const BuildsForPullRequestDocument = gql`
  query BuildsForPullRequest($id: UUID!, $pagination: CursorPagination) {
    pullRequestById(id: $id) {
      title
      number
      stableBuildURL
    }
    buildsForPullRequest(id: $id, pagination: $pagination) {
      builds {
        ...buildFields
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${BuildFieldsFragmentDoc}
`

/**
 * __useBuildsForPullRequestQuery__
 *
 * To run a query within a React component, call `useBuildsForPullRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildsForPullRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildsForPullRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBuildsForPullRequestQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BuildsForPullRequestQuery,
    BuildsForPullRequestQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    BuildsForPullRequestQuery,
    BuildsForPullRequestQueryVariables
  >(BuildsForPullRequestDocument, baseOptions)
}
export function useBuildsForPullRequestLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BuildsForPullRequestQuery,
    BuildsForPullRequestQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    BuildsForPullRequestQuery,
    BuildsForPullRequestQueryVariables
  >(BuildsForPullRequestDocument, baseOptions)
}
export type BuildsForPullRequestQueryHookResult = ReturnType<
  typeof useBuildsForPullRequestQuery
>
export type BuildsForPullRequestLazyQueryHookResult = ReturnType<
  typeof useBuildsForPullRequestLazyQuery
>
export type BuildsForPullRequestQueryResult = ApolloReactCommon.QueryResult<
  BuildsForPullRequestQuery,
  BuildsForPullRequestQueryVariables
>
export const TriggerBuildForSiteBranchDocument = gql`
  mutation TriggerBuildForSiteBranch(
    $branch: String
    $siteId: UUID!
    $deleteCache: Boolean
  ) {
    triggerCloudBuilder(
      branch: $branch
      siteId: $siteId
      deleteCache: $deleteCache
    ) {
      success
      message
    }
  }
`
export type TriggerBuildForSiteBranchMutationFn = ApolloReactCommon.MutationFunction<
  TriggerBuildForSiteBranchMutation,
  TriggerBuildForSiteBranchMutationVariables
>

/**
 * __useTriggerBuildForSiteBranchMutation__
 *
 * To run a mutation, you first call `useTriggerBuildForSiteBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerBuildForSiteBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerBuildForSiteBranchMutation, { data, loading, error }] = useTriggerBuildForSiteBranchMutation({
 *   variables: {
 *      branch: // value for 'branch'
 *      siteId: // value for 'siteId'
 *      deleteCache: // value for 'deleteCache'
 *   },
 * });
 */
export function useTriggerBuildForSiteBranchMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TriggerBuildForSiteBranchMutation,
    TriggerBuildForSiteBranchMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    TriggerBuildForSiteBranchMutation,
    TriggerBuildForSiteBranchMutationVariables
  >(TriggerBuildForSiteBranchDocument, baseOptions)
}
export type TriggerBuildForSiteBranchMutationHookResult = ReturnType<
  typeof useTriggerBuildForSiteBranchMutation
>
export type TriggerBuildForSiteBranchMutationResult = ApolloReactCommon.MutationResult<
  TriggerBuildForSiteBranchMutation
>
export type TriggerBuildForSiteBranchMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TriggerBuildForSiteBranchMutation,
  TriggerBuildForSiteBranchMutationVariables
>
export const TriggerBuildForSitePullRequestDocument = gql`
  mutation TriggerBuildForSitePullRequest(
    $pullRequestId: UUID!
    $siteId: UUID!
    $deleteCache: Boolean
  ) {
    triggerDeployPreview(
      pullRequestId: $pullRequestId
      siteId: $siteId
      deleteCache: $deleteCache
    ) {
      success
      message
    }
  }
`
export type TriggerBuildForSitePullRequestMutationFn = ApolloReactCommon.MutationFunction<
  TriggerBuildForSitePullRequestMutation,
  TriggerBuildForSitePullRequestMutationVariables
>

/**
 * __useTriggerBuildForSitePullRequestMutation__
 *
 * To run a mutation, you first call `useTriggerBuildForSitePullRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerBuildForSitePullRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerBuildForSitePullRequestMutation, { data, loading, error }] = useTriggerBuildForSitePullRequestMutation({
 *   variables: {
 *      pullRequestId: // value for 'pullRequestId'
 *      siteId: // value for 'siteId'
 *      deleteCache: // value for 'deleteCache'
 *   },
 * });
 */
export function useTriggerBuildForSitePullRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TriggerBuildForSitePullRequestMutation,
    TriggerBuildForSitePullRequestMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    TriggerBuildForSitePullRequestMutation,
    TriggerBuildForSitePullRequestMutationVariables
  >(TriggerBuildForSitePullRequestDocument, baseOptions)
}
export type TriggerBuildForSitePullRequestMutationHookResult = ReturnType<
  typeof useTriggerBuildForSitePullRequestMutation
>
export type TriggerBuildForSitePullRequestMutationResult = ApolloReactCommon.MutationResult<
  TriggerBuildForSitePullRequestMutation
>
export type TriggerBuildForSitePullRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TriggerBuildForSitePullRequestMutation,
  TriggerBuildForSitePullRequestMutationVariables
>
export const LatestBuildByStatusByPullRequestIdDocument = gql`
  query LatestBuildByStatusByPullRequestId(
    $siteId: UUID!
    $pullRequestId: UUID!
    $status: BuildStatus!
  ) {
    latestBuildByStatusByPullRequestId(
      siteId: $siteId
      pullRequestId: $pullRequestId
      status: $status
    ) {
      ...buildFields
    }
  }
  ${BuildFieldsFragmentDoc}
`

/**
 * __useLatestBuildByStatusByPullRequestIdQuery__
 *
 * To run a query within a React component, call `useLatestBuildByStatusByPullRequestIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestBuildByStatusByPullRequestIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestBuildByStatusByPullRequestIdQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      pullRequestId: // value for 'pullRequestId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useLatestBuildByStatusByPullRequestIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LatestBuildByStatusByPullRequestIdQuery,
    LatestBuildByStatusByPullRequestIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    LatestBuildByStatusByPullRequestIdQuery,
    LatestBuildByStatusByPullRequestIdQueryVariables
  >(LatestBuildByStatusByPullRequestIdDocument, baseOptions)
}
export function useLatestBuildByStatusByPullRequestIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LatestBuildByStatusByPullRequestIdQuery,
    LatestBuildByStatusByPullRequestIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LatestBuildByStatusByPullRequestIdQuery,
    LatestBuildByStatusByPullRequestIdQueryVariables
  >(LatestBuildByStatusByPullRequestIdDocument, baseOptions)
}
export type LatestBuildByStatusByPullRequestIdQueryHookResult = ReturnType<
  typeof useLatestBuildByStatusByPullRequestIdQuery
>
export type LatestBuildByStatusByPullRequestIdLazyQueryHookResult = ReturnType<
  typeof useLatestBuildByStatusByPullRequestIdLazyQuery
>
export type LatestBuildByStatusByPullRequestIdQueryResult = ApolloReactCommon.QueryResult<
  LatestBuildByStatusByPullRequestIdQuery,
  LatestBuildByStatusByPullRequestIdQueryVariables
>
export const BuildCreatedDocument = gql`
  subscription BuildCreated($id: UUID!, $runnerType: BuildRunnerType!) {
    buildCreated(id: $id, runnerType: $runnerType) {
      ...buildFields
    }
  }
  ${BuildFieldsFragmentDoc}
`

/**
 * __useBuildCreatedSubscription__
 *
 * To run a query within a React component, call `useBuildCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBuildCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildCreatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      runnerType: // value for 'runnerType'
 *   },
 * });
 */
export function useBuildCreatedSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    BuildCreatedSubscription,
    BuildCreatedSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    BuildCreatedSubscription,
    BuildCreatedSubscriptionVariables
  >(BuildCreatedDocument, baseOptions)
}
export type BuildCreatedSubscriptionHookResult = ReturnType<
  typeof useBuildCreatedSubscription
>
export type BuildCreatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<
  BuildCreatedSubscription
>
