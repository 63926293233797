import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { FunctionDetails } from "@modules/function/components/FunctionDetails"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"

export function FunctionDetailsPage({
  organizationId,
  siteId,
  functionId,
}: RouteComponentProps<{
  organizationId: string
  siteId: string
  functionId: string
}>) {
  if (!organizationId || !siteId || !functionId) {
    return (
      <ErrorAlert>
        Error: missing organizationId, siteId or functionId
      </ErrorAlert>
    )
  }

  return (
    <FunctionDetails
      organizationId={organizationId}
      siteId={siteId}
      functionId={functionId}
    />
  )
}
