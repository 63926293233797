import * as React from "react"
import * as Yup from "yup"
import { useShowSuccessToast } from "gatsby-interface"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { FormikConfig } from "formik"
import {
  UserNameFieldsFragmentDoc,
  UserNameFieldsFragment,
} from "@modules/auth/fragments.generated"
import { useCurrentUserQuery } from "@modules/auth/queries.generated"
import { useUpdateUserInfoMutation } from "@modules/user-settings/queries.generated"

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(`The field is required`),
  lastName: Yup.string().required(`The field is required`),
  email: Yup.string()
    .email(`Invalid email`)
    .required(`Required`),
})

export type ProfileSettingsFormValues = {
  firstName: string
  lastName: string
  email: string
}

export const useFormSubmitHandler = () => {
  const [mutateUserInformation] = useUpdateUserInfoMutation()
  const { data } = useCurrentUserQuery()
  const [setError, errorAlert] = useTriggerErrorAlert()
  const showSuccessToast = useShowSuccessToast()

  const handleSubmitForm: FormikConfig<
    ProfileSettingsFormValues
  >["onSubmit"] = async (values, isSubmitting) => {
    let result
    try {
      result = await mutateUserInformation({
        variables: {
          user: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          },
        },
        refetchQueries: [`UserInfo`],
        update: proxy => {
          const currentUserId = data?.currentUser?.id

          if (!currentUserId) {
            return
          }

          const fragmentInfo = {
            id: `User:${currentUserId}`,
            fragment: UserNameFieldsFragmentDoc,
          }

          proxy.writeFragment<UserNameFieldsFragment>({
            ...fragmentInfo,
            data: {
              firstName: values.firstName,
              lastName: values.lastName,
              name:
                values.firstName && values.lastName
                  ? `${values.firstName} ${values.lastName}`
                  : data?.currentUser?.name || "",
              __typename: `User`,
            },
          })
        },
        awaitRefetchQueries: true,
      })
    } catch (err) {
      setError(err)
      return
    }

    const { success, message } = result.data?.updateUserInformation || {}

    if (success) {
      showSuccessToast(
        <React.Fragment>
          <strong>User settings</strong> updated sucessfully.
        </React.Fragment>
      )
    } else if (message) {
      setError({ message })
    }

    isSubmitting.setSubmitting(false)
  }

  return [handleSubmitForm, { errorAlert }] as const
}
