import React from "react"
import ScalableSvg from "@modules/ui/components/ScalableSvg"

export default function StrapiLogoIcon(props) {
  return (
    <ScalableSvg
      width="16px"
      height="16px"
      viewBox="0 0 1025 1032"
      fill="none"
      {...props}
    >
      <path
        d="M341.328 0V344.061H682.661V688.121H1023.99V0H341.328Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M683 343.725H343V688.457H683V343.725Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M341.333 344.061H0L341.333 0V344.061Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M682.367 1031.18V687.457H1024.37L682.367 1031.18Z"
        fill="url(#paint3_linear)"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 365.162 314.663)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 374.502 314.663)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 383.843 314.663)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 393.184 314.663)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 402.525 314.663)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 411.866 314.663)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 421.206 314.663)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 430.546 314.663)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 439.887 314.663)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 449.228 314.663)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 458.569 314.663)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 365.162 306.041)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 374.502 306.041)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 383.843 306.041)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 393.184 306.041)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 402.525 306.041)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 411.866 306.041)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 421.206 306.041)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 430.546 306.041)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 365.162 297.419)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 374.502 297.419)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 383.843 297.419)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 393.184 297.419)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 402.525 297.419)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 365.162 288.796)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 374.502 288.797)"
        fill="#956FFF"
      />
      <circle
        r="1.7963"
        transform="matrix(1 8.74228e-08 8.74228e-08 -1 383.843 288.797)"
        fill="#956FFF"
      />
      <circle cx="716.162" cy="638.796" r="1.7963" fill="#956FFF" />
      <circle cx="725.502" cy="638.796" r="1.7963" fill="#956FFF" />
      <circle cx="734.843" cy="638.796" r="1.7963" fill="#956FFF" />
      <circle cx="744.184" cy="638.796" r="1.7963" fill="#956FFF" />
      <circle cx="753.525" cy="638.796" r="1.7963" fill="#956FFF" />
      <circle cx="762.866" cy="638.796" r="1.7963" fill="#956FFF" />
      <circle cx="772.206" cy="638.796" r="1.7963" fill="#956FFF" />
      <circle cx="716.162" cy="647.419" r="1.7963" fill="#956FFF" />
      <circle cx="725.502" cy="647.419" r="1.7963" fill="#956FFF" />
      <circle cx="734.843" cy="647.419" r="1.7963" fill="#956FFF" />
      <circle cx="744.184" cy="647.419" r="1.7963" fill="#956FFF" />
      <circle cx="753.525" cy="647.419" r="1.7963" fill="#956FFF" />
      <circle cx="762.866" cy="647.419" r="1.7963" fill="#956FFF" />
      <circle cx="716.162" cy="656.041" r="1.7963" fill="#956FFF" />
      <circle cx="725.502" cy="656.04" r="1.7963" fill="#956FFF" />
      <circle cx="734.843" cy="656.04" r="1.7963" fill="#956FFF" />
      <circle cx="744.184" cy="656.04" r="1.7963" fill="#956FFF" />
      <circle cx="753.525" cy="656.04" r="1.7963" fill="#956FFF" />
      <circle cx="716.162" cy="664.664" r="1.7963" fill="#956FFF" />
      <circle cx="725.502" cy="664.663" r="1.7963" fill="#956FFF" />
      <circle cx="734.843" cy="664.663" r="1.7963" fill="#956FFF" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1072.36"
          y1="-51.4075"
          x2="553.031"
          y2="446.945"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0208333" stopColor="#956FFF" />
          <stop offset="1" stopColor="#1C1B7E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="634.365"
          y1="396.5"
          x2="277.177"
          y2="712.901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#956FFF" />
          <stop offset="1" stopColor="#1A2670" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="170.667"
          y1="0"
          x2="170.667"
          y2="344.061"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0208333" stopColor="#956FFF" />
          <stop offset="1" stopColor="#1C1B7E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="1085.18"
          y1="556.22"
          x2="695.654"
          y2="967.042"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#956FFF" />
          <stop offset="0.838542" stopColor="#1C1B7E" />
        </linearGradient>
      </defs>
    </ScalableSvg>
  )
}
