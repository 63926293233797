import React from "react"
import PropTypes from "prop-types"
import { BuildStatus } from "@modules/graphql/types"
import { formatDuration } from "@modules/build/card/hooks/useBuildDuration"
import { useTimer } from "@modules/build/card/hooks/useTimer"
import { cdnVendorNames } from "@modules/cdn/shared/constants"

const propTypes = {
  status: PropTypes.oneOf(Object.values(BuildStatus)),
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date),
  vendor: PropTypes.string.isRequired,
}

const resetParagraphCss = { padding: 0, margin: 0 }

function DeploymentDuration({ startDate }) {
  const duration = useTimer(startDate.getTime())

  return <React.Fragment>{duration}</React.Fragment>
}

export function DeploymentTiming({
  status,
  startDate,
  endDate,
  vendor,
  showVendorName = true,
}) {
  const vendorName = vendor && showVendorName ? cdnVendorNames[vendor] : ""

  let durationMessage = null

  if (startDate && endDate) {
    const { duration } = formatDuration(
      Math.floor((endDate - startDate) / 1000)
    )
    durationMessage = ` in ${duration}`
  }

  if (status === BuildStatus.Success) {
    return (
      <p data-testid="deployment-success" css={resetParagraphCss}>
        {formatDeployStatusText(`Deployed`, vendorName)}
        {durationMessage}
      </p>
    )
  }

  if (status === BuildStatus.PublishError) {
    return (
      <p data-testid="deployment-fail" css={resetParagraphCss}>
        {formatDeployStatusText(`Failed to deploy`, vendorName)}
        {durationMessage}
      </p>
    )
  }

  if (status === BuildStatus.PublishCanceled) {
    return (
      <p data-testid="deployment-canceled" css={resetParagraphCss}>
        {formatDeployStatusText(`Canceled deploy`, vendorName)}
      </p>
    )
  }

  if (status === BuildStatus.Publishing) {
    return (
      <p data-testid="deployment-pending" css={resetParagraphCss}>
        {formatDeployStatusText(`Deploying`, vendorName)}&hellip;{" "}
        <DeploymentDuration startDate={startDate} />
      </p>
    )
  }

  return null
}

DeploymentTiming.propTypes = propTypes

function formatDeployStatusText(statusText, vendorName) {
  return vendorName && statusText
    ? `${statusText} to ${vendorName}`
    : statusText
}
