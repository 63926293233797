export function getPathToCreateSite(organizationId: string) {
  return organizationId
    ? `/dashboard/organization/${organizationId}/sites/create`
    : `/dashboard/sites/create`
}

export function getPathToImportSite(organizationId: string) {
  return organizationId
    ? `/dashboard/organization/${organizationId}/sites/import`
    : `/dashboard/sites/create`
}

export function getPathToSelectProvider(organizationId: string) {
  return organizationId
    ? `/dashboard/organization/${organizationId}/sites/import/repository`
    : `/dashboard/sites/create`
}

export function getPathToProvisionSite(organizationId: string) {
  return organizationId
    ? `/dashboard/organization/${organizationId}/sites/provision`
    : `/dashboard/sites/provision`
}
