// THIS IS A GENERATED FILE
import * as Types from "../../graphql/types"

import gql from "graphql-tag"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type VerifyPreviewPasswordMutationVariables = Types.Exact<{
  hostname: Types.Scalars["String"]
  password: Types.Scalars["String"]
}>

export type VerifyPreviewPasswordMutation = { __typename?: "Mutation" } & {
  verifyPreviewPassword?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
  >
}

export const VerifyPreviewPasswordDocument = gql`
  mutation VerifyPreviewPassword($hostname: String!, $password: String!) {
    verifyPreviewPassword(hostname: $hostname, password: $password) {
      success
      message
    }
  }
`
export type VerifyPreviewPasswordMutationFn = ApolloReactCommon.MutationFunction<
  VerifyPreviewPasswordMutation,
  VerifyPreviewPasswordMutationVariables
>

/**
 * __useVerifyPreviewPasswordMutation__
 *
 * To run a mutation, you first call `useVerifyPreviewPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPreviewPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPreviewPasswordMutation, { data, loading, error }] = useVerifyPreviewPasswordMutation({
 *   variables: {
 *      hostname: // value for 'hostname'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useVerifyPreviewPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VerifyPreviewPasswordMutation,
    VerifyPreviewPasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    VerifyPreviewPasswordMutation,
    VerifyPreviewPasswordMutationVariables
  >(VerifyPreviewPasswordDocument, baseOptions)
}
export type VerifyPreviewPasswordMutationHookResult = ReturnType<
  typeof useVerifyPreviewPasswordMutation
>
export type VerifyPreviewPasswordMutationResult = ApolloReactCommon.MutationResult<
  VerifyPreviewPasswordMutation
>
export type VerifyPreviewPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VerifyPreviewPasswordMutation,
  VerifyPreviewPasswordMutationVariables
>
