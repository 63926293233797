import styled from "@emotion/styled"
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js"

export const CardLabel = styled(`label`)(props => ({
  color: props.theme.colors.grey[70],
  display: `flex`,
  fontSize: props.theme.fontSizes[1],
  flexDirection: `column`,
  lineHeight: props.theme.lineHeights.default,
  marginTop: props.theme.space[5],
  width: `100%`,

  ".StripeElement--focus": {
    border: `1px solid ${props.theme.colors.purple[40]}`,
    boxShadow: `0 0 0 3px ${props.theme.colors.purple[20]}`,
    outline: 0,
    transition: `box-shadow 0.15s ease-in-out`,
  },

  [props.theme.mediaQueries.tablet]: {
    width: `25%`,

    ":first-of-type": {
      width: `50%`,
    },

    ":last-child": {
      paddingLeft: props.theme.space[7],
    },
  },
}))

const paymentInputCss = theme => ({
  backgroundColor: `white`,
  borderRadius: theme.radii[2],
  border: `1px solid ${theme.colors.grey[30]}`,
  height: `2.25rem`,
  padding: `0 ${theme.space[4]}`,
  display: `flex`,
  alignItems: `center`,

  "& > *": {
    flex: 1,
  },
})

export const CardNumber = styled(CardNumberElement)(props => ({
  ...paymentInputCss(props.theme),
  marginRight: 0,

  [props.theme.mediaQueries.tablet]: {
    marginRight: props.theme.space[7],
  },
}))

export const CardExpiry = styled(CardExpiryElement)(props => ({
  ...paymentInputCss(props.theme),
}))

export const CardCVC = styled(CardCvcElement)(props => ({
  ...paymentInputCss(props.theme),
}))
