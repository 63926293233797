import React from "react"

export default function() {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M22.72 0H1.28C0.573076 0 0 0.573076 0 1.28V13.792C0 14.4989 0.573076 15.072 1.28 15.072H22.72C23.4269 15.072 24 14.4989 24 13.792V1.28C24 0.573076 23.4269 0 22.72 0Z"
          fill="#0E4595"
        />
        <path
          d="M8.90234 10.6953L9.9699 4.43094H11.6774L10.6091 10.6953H8.90234Z"
          fill="white"
        />
        <path
          d="M16.7778 4.56605C16.4395 4.43914 15.9095 4.30295 15.2475 4.30295C13.5603 4.30295 12.3718 5.15258 12.3617 6.37027C12.3522 7.2704 13.2102 7.77255 13.8578 8.07219C14.5225 8.37923 14.7459 8.57507 14.7427 8.84925C14.7385 9.26915 14.212 9.46099 13.7212 9.46099C13.0378 9.46099 12.6747 9.36605 12.114 9.13216L11.8939 9.03258L11.6543 10.4349C12.0531 10.6098 12.7906 10.7613 13.5563 10.7692C15.3512 10.7692 16.5163 9.92925 16.5296 8.62893C16.536 7.91632 16.0811 7.37399 15.096 6.92691C14.4992 6.63712 14.1337 6.44375 14.1376 6.15031C14.1376 5.88992 14.4469 5.61149 15.1154 5.61149C15.6738 5.60282 16.0782 5.72458 16.3933 5.85149L16.5464 5.92378L16.7778 4.56605Z"
          fill="white"
        />
        <path
          d="M21.1717 4.43094H19.8523C19.4436 4.43094 19.1377 4.54249 18.9582 4.95043L16.4224 10.6913H18.2154C18.2154 10.6913 18.5085 9.91941 18.5748 9.74994C18.7708 9.74994 20.5126 9.75263 20.7616 9.75263C20.8127 9.97193 20.9693 10.6913 20.9693 10.6913H22.5537L21.1717 4.43094ZM19.0784 8.47596C19.2196 8.11503 19.7587 6.72483 19.7587 6.72483C19.7486 6.7415 19.8988 6.36214 19.9851 6.12694L20.1005 6.66703C20.1005 6.66703 20.4274 8.16236 20.4957 8.47593H19.0784V8.47596Z"
          fill="white"
        />
        <path
          d="M7.45284 4.43094L5.78116 8.70278L5.60305 7.83465C5.29185 6.83388 4.32225 5.74966 3.23828 5.20684L4.76683 10.6853L6.57339 10.6833L9.26151 4.43094H7.45284Z"
          fill="white"
        />
        <path
          d="M4.22139 4.43094H1.46808L1.44629 4.56127C3.58834 5.0798 5.00571 6.33289 5.59406 7.83853L4.99538 4.9598C4.89202 4.56313 4.59224 4.44476 4.22139 4.43094Z"
          fill="#F2AE14"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="15.072" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
