import React from "react"
import { ModalError, ModalEmphasizedText, ModalMessage } from "@modules/modal"
import AlertHexagon from "@modules/assets/icons/AlertHexagon"
import { Text } from "@modules/ui/typography"
import { modalErrors as text } from "@modules/locales/default.json"

export function UnAuthorizedError({ onClose }) {
  return (
    <ModalError
      heading={text.unAuthorized.heading}
      onClose={onClose}
      closeModal={onClose}
    >
      <ModalMessage data-testid="error-modal-msg">
        <ModalEmphasizedText>
          <AlertHexagon />
          {text.unAuthorized.subHeading}
        </ModalEmphasizedText>
        <Text>{text.unAuthorized.content}</Text>
        <Text>{text.emailSupport}</Text>
      </ModalMessage>
    </ModalError>
  )
}
