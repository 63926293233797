import React from "react"
import ScalableSvg from "@modules/ui/components/ScalableSvg"

export default function ContentfulLogoIcon(props) {
  return (
    <ScalableSvg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M5.81553 11.1845C4.96938 10.3407 4.49381 9.19494 4.49381 8.00001C4.49381 6.80508 4.96938 5.65928 5.81553 4.81555C6.50186 4.12922 6.50186 3.01645 5.81553 2.33012C5.12919 1.64379 4.01643 1.64379 3.33009 2.33012C1.84282 3.84331 1.00653 5.87829 1 8.00001C1.0211 10.1188 1.85524 12.1485 3.33009 13.6699C3.77407 14.1139 4.42119 14.2873 5.02768 14.1248C5.63416 13.9623 6.10788 13.4885 6.27039 12.8821C6.4329 12.2756 6.25951 11.6285 5.81553 11.1845Z"
        fill="#FFD85F"
      />
      <path
        d="M5.81552 4.81553C6.65925 3.96938 7.80505 3.49381 8.99998 3.49381C10.1949 3.49381 11.3407 3.96938 12.1844 4.81553C12.8708 5.50186 13.9835 5.50186 14.6699 4.81553C15.3562 4.12919 15.3562 3.01643 14.6699 2.33009C13.1567 0.842819 11.1217 0.00652573 8.99998 0C6.88119 0.0210989 4.85145 0.85524 3.33009 2.33009C2.64375 3.01643 2.64375 4.12919 3.33009 4.81553C4.01642 5.50186 5.12919 5.50186 5.81552 4.81553Z"
        fill="#3BB4E7"
      />
      <path
        d="M12.1844 11.1845C11.3407 12.0306 10.1949 12.5062 8.99998 12.5062C7.80505 12.5062 6.65925 12.0306 5.81552 11.1845C5.12919 10.4981 4.01642 10.4981 3.33009 11.1845C2.64375 11.8708 2.64375 12.9836 3.33009 13.6699C4.84328 15.1572 6.87826 15.9935 8.99998 16C11.1188 15.9789 13.1485 15.1448 14.6699 13.6699C15.1139 13.2259 15.2872 12.5788 15.1247 11.9723C14.9622 11.3658 14.4885 10.8921 13.882 10.7296C13.2755 10.5671 12.6284 10.7405 12.1844 11.1845Z"
        fill="#ED5C68"
      />
      <path
        d="M5.87067 4.83852C6.56806 4.14064 6.56766 3.00955 5.86978 2.31216C5.17191 1.61477 4.04082 1.61516 3.34343 2.31304C2.64604 3.01092 2.64643 4.14201 3.34431 4.8394C4.04219 5.53679 5.17327 5.53639 5.87067 4.83852Z"
        fill="#308BC5"
      />
      <path
        d="M5.86267 13.6837C6.56006 12.9859 6.55967 11.8548 5.86179 11.1574C5.16391 10.46 4.03282 10.4604 3.33543 11.1583C2.63804 11.8561 2.63843 12.9872 3.33631 13.6846C4.03419 14.382 5.16528 14.3816 5.86267 13.6837Z"
        fill="#D5465F"
      />
    </ScalableSvg>
  )
}
