import * as React from "react"
import { Formik, FormikValues, FormikConfig, Field } from "formik"
import FormActions from "./FormActions"
import { StyledFormikForm } from "@modules/ui/components/Primitives"
import { ThemeCss } from "gatsby-interface"

export function Form<
  Values extends FormikValues = FormikValues,
  ExtraProps = {}
>(props: FormikConfig<Values> & ExtraProps) {
  return <Formik validateOnBlur={false} {...props} />
}

const formFieldsSpacingCss: ThemeCss = theme => ({
  display: `grid`,
  gridRowGap: theme.space[4],
})

export type FormElementProps = React.ComponentPropsWithoutRef<"form"> & {
  applySpacing?: boolean
}

export function FormElement({ applySpacing, ...rest }: FormElementProps) {
  return (
    <StyledFormikForm css={applySpacing && formFieldsSpacingCss} {...rest} />
  )
}

export type FormFieldsProps = React.ComponentPropsWithoutRef<"div">
export function FormFields(props: FormFieldsProps) {
  return <div css={formFieldsSpacingCss} {...props} />
}

Form.FormElement = FormElement

Form.Field = Field

Form.Actions = FormActions

export default Form
