import React, { Fragment } from "react"
import PropTypes from "prop-types"
import FullStory from "react-fullstory"
import { MainLayout } from "@modules/ui/layouts/MainLayout"
import ProductDashboard from "../dashboard-root"
import { fullStoryEnabled } from "@modules/env/constants"
import { ZendeskProvider } from "@modules/zendesk"

const isBrowser = global.window

function Dashboard({ location }) {
  return isBrowser ? (
    <Fragment>
      {fullStoryEnabled ? (
        <FullStory org={process.env.GATSBY_FULLSTORY_ORG} />
      ) : (
        ``
      )}
      <ZendeskProvider>
        <MainLayout pathname={location.pathname}>
          <ProductDashboard />
        </MainLayout>
      </ZendeskProvider>
    </Fragment>
  ) : null
}

Dashboard.propTypes = {
  location: PropTypes.object,
}

export default Dashboard
