import * as React from "react"
import { WindowLocation } from "@reach/router"
import Loading from "@modules/ui/components/Loading"
import {
  EmptyState,
  Link,
  Spacer,
  ThemeCss,
  Card,
  CardSection,
  Heading,
} from "gatsby-interface"
import { Feed, FeedItem } from "@modules/a11y/components/Feed"
import { DeployCard } from "@modules/build/card/components/DeployCard"
import { MdArrowBack } from "react-icons/md"
import {
  getPathToBuildDetails,
  getPathToSiteDetails,
} from "@modules/site/details/utils"
import {
  PageWithTabsContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"
import { Waypoint } from "react-waypoint"
import { FormattedMessage } from "@modules/locales"
import { buildsByBranchView as buildsByBranchViewText } from "@modules/locales/default.json"
import { useBuilds } from "../hooks/useDeploys"
import { useLatestBuildByStatus } from "@modules/build/shared/hooks/useLatestBuildByStatus"
import { BuildStatus } from "@modules/graphql/types"
import { useSiteDetailsQuery } from "@modules/site/shared/queries.generated"
import { useOrganizationDetailsQuery } from "@modules/organization/queries.generated"
import { LastBuildInfo } from "@modules/site/buildsView/components/LastBuildInfo"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import BuildPublishedDate from "@modules/build/shared/components/BuildPublishedDate"
import TriggerBuild from "@modules/build/shared/components/TriggerBuild"
import { deploysView as deploysViewText } from "@modules/locales/default.json"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { ExternalLink } from "@modules/ui/components/ExternalLink"

/**
 * When modifying this file, make sure you modify the PullRequestBuildsPage accordingly
 * to have the same UI for the builds by branches and builds by pull requests
 */
export type BuildsListPageProps = {
  organizationId: string
  siteId: string
  location?: WindowLocation
  branch: string
}

const linkCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
})

const cardSectionCss: ThemeCss = theme => ({
  display: "flex",
  flexDirection: "column",

  [theme.mediaQueries.tablet]: {
    flexDirection: "row",
  },
})

const triggerBuildCss: ThemeCss = theme => ({
  marginTop: theme.space[3],
  marginLeft: "auto",
  [theme.mediaQueries.tablet]: {
    marginTop: 0,
    marginLeft: 0,
  },
})

export function BuildsListPage({
  branch: rawBranch,
  siteId,
  organizationId,
}: BuildsListPageProps) {
  const {
    data: siteDetailsData,
    error: errorSiteDetails,
    loading: loadingSiteDetails,
  } = useSiteDetailsQuery({
    variables: { id: siteId },
    fetchPolicy: "cache-and-network",
  })

  // In the event that a branch name contains a `/`, the
  // routing is disturbed unless encoded (and decoded).
  const branch = decodeURIComponent(rawBranch)

  const siteDetails = siteDetailsData?.siteDetails
  const repositoryUrl = siteDetails?.repository?.url

  const [
    loadingLastBuild,
    lastBuild,
    refreshLatestBuild,
  ] = useLatestBuildByStatus({
    siteId,
    siteBranch: branch,
    status: BuildStatus.Success,
  })
  const [builds, , { loading, error, loadingMore, loadMore }] = useBuilds(
    siteId,
    branch
  )

  const { data } = useOrganizationDetailsQuery({
    variables: { id: organizationId },
  })

  const organization = data?.organizationDetails

  if (loadingSiteDetails || loading || loadingLastBuild) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <Loading message={buildsByBranchViewText.messages.loadingBuilds} />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  const actualError = error || errorSiteDetails
  if (actualError) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <p data-testid="builds-list-page-error">
            <FormattedMessage<"error">
              message={buildsByBranchViewText.messages.errorLoadingBuilds}
              values={{ error: actualError?.message }}
            />
          </p>
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  if (builds.length === 0) {
    return (
      <PageWithTabsContentSection>
        <StandardSingleColumn>
          <EmptyState
            variant="BORDERED"
            heading={buildsByBranchViewText.headers.noBuilds}
            text={buildsByBranchViewText.messages.noBuildsForBranch}
          />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    )
  }

  const backLink = getPathToSiteDetails(siteId, organizationId)

  return (
    <main>
      <PageWithTabsContentSection>
        <StandardSingleColumn id="builds">
          <Link variant="SIMPLE" to={backLink} css={linkCss}>
            <MdArrowBack />
            {buildsByBranchViewText.labels.backToSiteDetails}
          </Link>

          <Spacer size={7} />

          <Heading css={{ fontWeight: "normal" }} as="h1">
            <FormattedMessage<"branch", "strong">
              message={buildsByBranchViewText.headers.listOfBuilds}
              values={{ branch }}
              tags={{
                strong: function(content) {
                  return <strong>{content}</strong>
                },
              }}
            />
          </Heading>

          <Spacer size={5} />

          <div>
            <h2 css={visuallyHiddenCss}>
              <FormattedMessage<"productionBranch">
                message={
                  deploysViewText.headers
                    .latestSuccessfulBuildForProductionBranch
                }
                values={{
                  productionBranch: branch,
                }}
              />
            </h2>
            <Card>
              <CardSection css={cardSectionCss}>
                <div css={{ flex: 1 }}>
                  {/** TODO: remove the "else" part of the ternary when pretty URL will reach out */}
                  {siteDetails?.stableBuildURL ? (
                    <ExternalLink href={siteDetails?.stableBuildURL} truncate />
                  ) : (
                    <LastBuildInfo buildId={lastBuild?.id} />
                  )}

                  <Spacer size={3} />

                  <div>
                    <SiteBranch>
                      <strong>{branch}</strong>
                    </SiteBranch>

                    <Spacer size={5} direction="horizontal" />

                    {lastBuild?.endedAt && (
                      <React.Fragment>
                        <BuildPublishedDate
                          buildPublishedDate={lastBuild.endedAt}
                        />
                        <Spacer size={5} direction="horizontal" />
                      </React.Fragment>
                    )}
                  </div>
                </div>

                {organization && (
                  <div css={triggerBuildCss}>
                    <TriggerBuild
                      siteId={siteId}
                      selectedBranch={branch}
                      orgStatus={organization?.status}
                      size={`M`}
                    />
                  </div>
                )}
              </CardSection>
            </Card>
          </div>

          <Spacer size={5} />

          <h2 css={visuallyHiddenCss}>
            <FormattedMessage<"productionBranch">
              message={deploysViewText.headers.allBuildsOfBranch}
              values={{
                productionBranch: branch,
              }}
            />
          </h2>
          <Feed loading={loadingMore} labelledBy="builds" count={builds.length}>
            <div>
              {builds.map((build, index) => (
                <div key={`build-${build.id}`}>
                  <FeedItem
                    labelledBy={`build-${build.id}`}
                    describedBy={`deploy-status-${build.id}`}
                    position={index + 1}
                    id={build.id}
                  >
                    <DeployCard
                      id={`build-${build.id}`}
                      siteId={siteId}
                      organizationId={organizationId}
                      pullRequestId={build?.pullRequest?.id}
                      title={build.commit?.message || build.branch || ``}
                      buildId={build.id}
                      status={build.buildStatus}
                      createdAt={build.createdAt}
                      startedAt={build.startedAt}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      duration={build.duration || undefined}
                      endedAt={build.endedAt}
                      branch={build.branch || ``}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      commit={build.commit || undefined}
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      runnerType={build.runnerType!}
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      buildType={build.buildType!}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      source={build.source || undefined}
                      /* backend allows for null. Fallbacking to undefined for safe types in child components */
                      author={build.author || undefined}
                      isProductionBranch={branch === build.branch}
                      viewDetailsHref={getPathToBuildDetails(
                        build.id,
                        siteId,
                        organizationId
                      )}
                      deployStartedAt={build?.deployStartedAt}
                      deployEndedAt={build?.deployEndedAt}
                      onBuildSucceed={refreshLatestBuild}
                      repositoryUrl={repositoryUrl}
                    />
                  </FeedItem>
                  <Spacer size={5} />
                </div>
              ))}
            </div>
          </Feed>

          {loadingMore && builds.length !== 0 && (
            <div css={{ textAlign: "center" }}>
              <Loading
                variant="baby"
                message={buildsByBranchViewText.messages.loadingBuilds}
              />
            </div>
          )}
          <Waypoint onEnter={loadMore} />
        </StandardSingleColumn>
      </PageWithTabsContentSection>
    </main>
  )
}
