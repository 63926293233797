import React from "react"
import PropTypes from "prop-types"
import { LIGHTHOUSE_SCORE_COLORS } from "@modules/metrics/lighthouse"
import getLighthouseScore from "@modules/metrics/helpers/getLighthouseScore"

function DonutChart({ value = 0, size = 22, strokewidth = 5, ...rest }) {
  const halfsize = size * 0.5
  const radius = halfsize - strokewidth * 0.5
  const circumference = 2 * Math.PI * radius
  const strokeval = (value * circumference) / 100
  const dashval = strokeval + ` ` + circumference

  const trackstyle = {
    strokeWidth: strokewidth,
    fill: `transparent`,
    stroke: `#ddd`,
  }
  const indicatorstyle = {
    strokeWidth: strokewidth,
    strokeDasharray: dashval,
    fill: `transparent`,
    stroke: LIGHTHOUSE_SCORE_COLORS[getLighthouseScore(value)],
  }
  const rotateval = `rotate(-90 ` + halfsize + `,` + halfsize + `)`

  return (
    <svg
      width={size}
      height={size}
      className="donutchart"
      aria-hidden
      {...rest}
    >
      <circle
        r={radius}
        cx={halfsize}
        cy={halfsize}
        transform={rotateval}
        style={trackstyle}
      />
      <circle
        r={radius}
        cx={halfsize}
        cy={halfsize}
        transform={rotateval}
        style={indicatorstyle}
      />
    </svg>
  )
}

DonutChart.propTypes = {
  value: PropTypes.number,
  valuelabel: PropTypes.string,
  size: PropTypes.number,
  strokewidth: PropTypes.number,
}

export default DonutChart
