import * as React from "react"
import * as Yup from "yup"
import { Form as FormikForm } from "formik"
import { Button, InputConnectedField, ThemeCss } from "gatsby-interface"
import { MdArrowForward } from "react-icons/md"
import { Form } from "@modules/form/components/Form"
import {
  previewLogin as text,
  ui as uiText,
} from "@modules/locales/default.json"
import { useVerifyPreviewPasswordMutation } from "../queries.generated"

type FormValues = {
  previewPass: string
}

const validationSchema = Yup.object().shape<FormValues>({
  previewPass: Yup.string().required(uiText.messages.validationIsRequired),
})

const formWrapperCss: ThemeCss = theme => ({
  display: "grid",
  gridTemplateColumns: "1fr auto",
  gridGap: theme.space[5],
  alignItems: `self-end`,
})

export type PreviewPasswordFormProps = {
  hostname: string
  loginRedirectUrl: string
  onError: (error: Error) => void
}

export function PreviewPasswordForm({
  hostname,
  loginRedirectUrl,
  onError,
}: PreviewPasswordFormProps) {
  const [mutate] = useVerifyPreviewPasswordMutation()

  const verifyPassword = async (previewPass: string) => {
    return mutate({
      variables: {
        hostname,
        password: previewPass,
      },
    })
      .then(({ data }) => {
        if (data?.verifyPreviewPassword?.success) {
          document.cookie = `PreviewPasswordAuth=${previewPass};max-age=86400;path=/;SameSite=None; Secure`

          return window.location.assign(loginRedirectUrl)
        }

        throw new Error(
          data?.verifyPreviewPassword?.message ??
            text.messages.verificationFailed
        )
      })
      .catch(err => onError(err))
  }

  return (
    <Form<FormValues>
      initialValues={{ previewPass: `` }}
      validationSchema={validationSchema}
      onSubmit={values => verifyPassword(values.previewPass)}
    >
      {() => (
        <FormikForm>
          <div css={formWrapperCss}>
            <div css={{ flex: 1 }}>
              <InputConnectedField
                name="previewPass"
                type="password"
                label={text.labels.passwordField}
              />
            </div>

            <Button
              size="L"
              variant="PRIMARY"
              tone="BRAND"
              type="submit"
              rightIcon={<MdArrowForward />}
            >
              {text.actions.viewSite}
            </Button>
          </div>
        </FormikForm>
      )}
    </Form>
  )
}
