export const QueryError = {
  InvitationError: `invitation-error`,
  InvitationMismatch: `invitation-mismatch`,
  OauthError: `oauth-error`,
  InvitationRequired: `invitation-required`,
  UserBlocked: `blocked`,
  InvitationExpired: `invitation_expired`,
  InvitationAccepted: `invitation_accepted`,
  PreviewAccessError: `preview-access`,
  TicketNotFoundError: `ticket-not-found`,
}
