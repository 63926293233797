import * as React from "react"
import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"
import { EmptyState, EmptyStatePrimaryAction, ThemeCss } from "gatsby-interface"
import { notFound as notFoundText } from "@modules/locales/default.json"
import { ServiceRouteLayout } from "./ServiceRouteLayout"
import { FormattedMessage } from "@modules/locales"
import { isAuthenticated } from "@modules/auth"

const linkCss: ThemeCss = theme => ({
  color: theme.colors.blue[40],
  textDecoration: `none`,
})

export function NotFound() {
  return (
    <ServiceRouteLayout>
      <EmptyState
        heading={notFoundText.headers.pageNotFound}
        text={
          <React.Fragment>
            {notFoundText.messages.pageNotFoundIntro}
            <br />
            <br />
            <strong>{notFoundText.messages.needHelp}</strong>
            <br />
            <FormattedMessage<never, "link">
              message={notFoundText.messages.contactSupport}
              tags={{
                link: function(content) {
                  return (
                    <a css={linkCss} href="mailto:sales@gatsbyjs.com">
                      {content}
                    </a>
                  )
                },
              }}
            />
          </React.Fragment>
        }
        graphic={<EmptyStateGraphic />}
        primaryAction={
          <EmptyStatePrimaryAction
            to={isAuthenticated() ? `/dashboard/sites` : `/dashboard/login`}
          >
            {notFoundText.actions.goToHomepage}
          </EmptyStatePrimaryAction>
        }
        variant="BORDERED"
      />
    </ServiceRouteLayout>
  )
}
