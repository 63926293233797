import { getTheme, NumberBadgeTone } from "gatsby-interface"

const { colors } = getTheme()

export const LIGHTHOUSE_SCORE_COLORS = {
  good: colors.green[50],
  average: colors.orange[50],
  bad: colors.red[50],
}

export type LighthouseScore = `good` | `average` | `bad`

export const lighthouseScoreTones: Record<LighthouseScore, NumberBadgeTone> = {
  good: `SUCCESS`,
  average: `WARNING`,
  bad: `DANGER`,
}
