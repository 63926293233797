// THIS IS A GENERATED FILE
import * as Types from "../graphql/types"

import gql from "graphql-tag"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type FunctionsForSiteQueryVariables = Types.Exact<{
  siteId: Types.Scalars["UUID"]
}>

export type FunctionsForSiteQuery = { __typename?: "Query" } & {
  functionsForSite?: Types.Maybe<
    Array<
      { __typename?: "SiteFunctionEntry" } & Pick<
        Types.SiteFunctionEntry,
        "id" | "creationBuildId" | "path" | "sha" | "status"
      >
    >
  >
}

export type FunctionDetailsQueryVariables = Types.Exact<{
  siteId: Types.Scalars["UUID"]
  functionId: Types.Scalars["UUID"]
}>

export type FunctionDetailsQuery = { __typename?: "Query" } & {
  functionEntry: { __typename?: "SiteFunctionEntry" } & Pick<
    Types.SiteFunctionEntry,
    "id" | "creationBuildId" | "path" | "sha" | "status"
  >
  functionLogs?: Types.Maybe<
    Array<
      { __typename?: "Log" } & Pick<
        Types.Log,
        "id" | "message" | "timestamp" | "sourceStream" | "command"
      >
    >
  >
}

export const FunctionsForSiteDocument = gql`
  query FunctionsForSite($siteId: UUID!) {
    functionsForSite(siteId: $siteId) {
      id
      creationBuildId
      path
      sha
      status
    }
  }
`

/**
 * __useFunctionsForSiteQuery__
 *
 * To run a query within a React component, call `useFunctionsForSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useFunctionsForSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFunctionsForSiteQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useFunctionsForSiteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FunctionsForSiteQuery,
    FunctionsForSiteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FunctionsForSiteQuery,
    FunctionsForSiteQueryVariables
  >(FunctionsForSiteDocument, baseOptions)
}
export function useFunctionsForSiteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FunctionsForSiteQuery,
    FunctionsForSiteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FunctionsForSiteQuery,
    FunctionsForSiteQueryVariables
  >(FunctionsForSiteDocument, baseOptions)
}
export type FunctionsForSiteQueryHookResult = ReturnType<
  typeof useFunctionsForSiteQuery
>
export type FunctionsForSiteLazyQueryHookResult = ReturnType<
  typeof useFunctionsForSiteLazyQuery
>
export type FunctionsForSiteQueryResult = ApolloReactCommon.QueryResult<
  FunctionsForSiteQuery,
  FunctionsForSiteQueryVariables
>
export const FunctionDetailsDocument = gql`
  query FunctionDetails($siteId: UUID!, $functionId: UUID!) {
    functionEntry(siteId: $siteId, functionId: $functionId) {
      id
      creationBuildId
      path
      sha
      status
    }
    functionLogs(siteId: $siteId, functionId: $functionId) {
      id
      message
      timestamp
      sourceStream
      command
    }
  }
`

/**
 * __useFunctionDetailsQuery__
 *
 * To run a query within a React component, call `useFunctionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFunctionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFunctionDetailsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      functionId: // value for 'functionId'
 *   },
 * });
 */
export function useFunctionDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FunctionDetailsQuery,
    FunctionDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FunctionDetailsQuery,
    FunctionDetailsQueryVariables
  >(FunctionDetailsDocument, baseOptions)
}
export function useFunctionDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FunctionDetailsQuery,
    FunctionDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FunctionDetailsQuery,
    FunctionDetailsQueryVariables
  >(FunctionDetailsDocument, baseOptions)
}
export type FunctionDetailsQueryHookResult = ReturnType<
  typeof useFunctionDetailsQuery
>
export type FunctionDetailsLazyQueryHookResult = ReturnType<
  typeof useFunctionDetailsLazyQuery
>
export type FunctionDetailsQueryResult = ApolloReactCommon.QueryResult<
  FunctionDetailsQuery,
  FunctionDetailsQueryVariables
>
