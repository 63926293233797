import loadable from "@loadable/component"

export const BuildDetailsPage = loadable(() =>
  import(
    /* webpackChunkName: "build-detail-chunk" */
    /* webpackPrefetch: true */
    "@modules/build/details/components/Details"
  )
)

export const BuildLogsPage = loadable(() =>
  import(
    /* webpackChunkName: "build-logs-chunk" */
    /* webpackPrefetch: true */
    "@modules/build/details/components/LogsPage"
  )
)
