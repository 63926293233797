import * as React from "react"

export function UpgradeBackgroundLeft(
  props: React.ComponentPropsWithoutRef<"svg">
) {
  return (
    <svg
      width="365"
      height="398"
      viewBox="0 0 365 398"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx="230" cy="255.753" rx="135" ry="141.872" fill="white" />
      <path
        d="M181 197.953C187.627 197.953 193 192.307 193 185.343C193 178.378 187.627 172.732 181 172.732C174.373 172.732 169 178.378 169 185.343C169 192.307 174.373 197.953 181 197.953Z"
        fill="#D9BAE8"
      />
      <path
        d="M161.531 126.149C161.531 126.149 193.03 135.598 197.649 138.075C202.356 140.499 209.07 142.539 207.419 145.714C205.769 148.89 194.469 147.001 194.469 147.001"
        fill="white"
      />
      <path
        d="M161.531 126.149C161.531 126.149 193.03 135.598 197.649 138.075C202.356 140.499 209.07 142.539 207.419 145.714C205.769 148.89 194.469 147.001 194.469 147.001"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M171.96 130.94L198.816 149.936C198.816 149.936 212.64 160.224 209.905 163.583C207.121 166.851 193.683 157.053 193.683 157.053"
        fill="white"
      />
      <path
        d="M171.96 130.94L198.816 149.936C198.816 149.936 212.64 160.224 209.905 163.583C207.121 166.851 193.683 157.053 193.683 157.053"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M-36.9887 201.328C-36.9887 201.328 -29.6968 165.839 14.7809 130.358C31.7166 116.848 48.4875 109.762 63.7045 106.148C76.8107 102.441 92.6874 100.093 110.219 102.303C112.902 102.641 115.482 103.061 117.983 103.534C118.532 103.546 132.408 106.203 139.899 109.841C157.771 116.913 168.124 125.458 171.843 129.349C175.563 133.24 184.19 141.75 191.08 152.383C201.361 168.251 204.06 175.209 201.132 176.985C199.389 178.043 194.164 171.869 189.102 165.597C184.039 159.325 174.911 143.385 164.948 149.429C157.154 154.158 157.873 165.279 158.53 169.22C160.439 180.682 169.235 195.853 161.095 200.792C161.095 200.792 151.625 182.082 150.38 179.923C141.195 163.993 131.385 154.018 112.039 148.282C82.6387 139.565 60.4664 154.454 52.399 159.106C44.3315 163.759 29.2306 177.047 26.4309 183.721"
        fill="white"
      />
      <path
        d="M-36.9887 201.328C-36.9887 201.328 -29.6968 165.839 14.7809 130.358C31.7166 116.848 48.4875 109.762 63.7045 106.148C76.8107 102.441 92.6874 100.093 110.219 102.303C112.902 102.641 115.482 103.061 117.983 103.534C118.532 103.546 132.408 106.203 139.899 109.841C157.771 116.913 168.124 125.458 171.843 129.349C175.563 133.24 184.19 141.75 191.08 152.383C201.361 168.251 204.06 175.209 201.132 176.985C199.389 178.043 194.164 171.869 189.102 165.597C184.039 159.325 174.911 143.385 164.948 149.429C157.154 154.158 157.873 165.279 158.53 169.22C160.439 180.682 169.235 195.853 161.095 200.792C161.095 200.792 151.625 182.082 150.38 179.923C141.195 163.993 131.385 154.018 112.039 148.282C82.6387 139.565 60.4664 154.454 52.399 159.106C44.3315 163.759 29.2306 177.047 26.4309 183.721"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <circle
        cx="161"
        cy="239"
        r="14.5"
        transform="rotate(-90 161 239)"
        stroke="#D9BAE8"
      />
      <circle
        cx="181"
        cy="239"
        r="14.5"
        transform="rotate(-90 181 239)"
        stroke="#D9BAE8"
      />
      <circle
        cx="201"
        cy="239"
        r="14.5"
        transform="rotate(-90 201 239)"
        stroke="#D9BAE8"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="203"
        y="226"
        width="30"
        height="31"
      >
        <circle
          cx="218"
          cy="241"
          r="13"
          transform="rotate(-90 218 241)"
          fill="white"
          stroke="#D9BAE8"
          strokeWidth="4"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect
          x="224.625"
          y="272.419"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 224.625 272.419)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="223.493"
          y="273.551"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 223.493 273.551)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="226.888"
          y="270.155"
          width="42.1469"
          height="0.266754"
          transform="rotate(-135 226.888 270.155)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266754"
        />
        <rect
          x="225.757"
          y="271.287"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 225.757 271.287)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="229.152"
          y="267.892"
          width="42.1469"
          height="0.266753"
          transform="rotate(-135 229.152 267.892)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266753"
        />
        <rect
          x="228.02"
          y="269.024"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 228.02 269.024)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="231.416"
          y="265.628"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 231.416 265.628)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="230.284"
          y="266.76"
          width="42.1469"
          height="0.266756"
          transform="rotate(-135 230.284 266.76)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266756"
        />
        <rect
          x="233.679"
          y="263.365"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 233.679 263.365)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="232.547"
          y="264.497"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 232.547 264.497)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="235.942"
          y="261.101"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 235.942 261.101)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="234.811"
          y="262.233"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 234.811 262.233)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="238.206"
          y="258.838"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 238.206 258.838)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="237.074"
          y="259.97"
          width="42.1469"
          height="0.266759"
          transform="rotate(-135 237.074 259.97)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266759"
        />
        <rect
          x="240.469"
          y="256.575"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 240.469 256.575)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="239.337"
          y="257.706"
          width="42.1469"
          height="0.266751"
          transform="rotate(-135 239.337 257.706)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266751"
        />
        <rect
          x="242.732"
          y="254.311"
          width="42.1469"
          height="0.266753"
          transform="rotate(-135 242.732 254.311)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266753"
        />
        <rect
          x="241.601"
          y="255.443"
          width="42.1469"
          height="0.266751"
          transform="rotate(-135 241.601 255.443)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266751"
        />
        <rect
          x="244.996"
          y="252.048"
          width="42.1469"
          height="0.266753"
          transform="rotate(-135 244.996 252.048)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266753"
        />
        <rect
          x="243.864"
          y="253.179"
          width="42.1469"
          height="0.266751"
          transform="rotate(-135 243.864 253.179)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266751"
        />
        <rect
          x="247.26"
          y="249.784"
          width="42.1469"
          height="0.266754"
          transform="rotate(-135 247.26 249.784)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266754"
        />
        <rect
          x="246.128"
          y="250.916"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 246.128 250.916)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="249.523"
          y="247.521"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 249.523 247.521)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="248.391"
          y="248.652"
          width="42.1469"
          height="0.26675"
          transform="rotate(-135 248.391 248.652)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.26675"
        />
      </g>
      <circle
        cx="216"
        cy="239"
        r="14.5"
        transform="rotate(-90 216 239)"
        fill="white"
        stroke="#D9BAE8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M232.307 253.283L239.019 250.898L220.981 240.982L226.24 260.882L230.178 254.947L234.892 260.982L237.022 259.318L232.307 253.283Z"
        fill="#D9BAE8"
      />
    </svg>
  )
}

export function UpgradeBackgroundRight(
  props: React.ComponentPropsWithoutRef<"svg">
) {
  return (
    <svg
      width="203"
      height="417"
      viewBox="0 0 203 417"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M72.1068 177.38L59.4824 172.872L90.3685 77.349L102.993 81.8571L72.1068 177.38Z"
        fill="white"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M65.8579 127.267C65.8579 127.267 61.6344 103.16 60.8406 95.3318C59.9765 87.426 60.0655 82.3826 62.2923 82.1089C64.6624 81.8415 67.0682 91.7446 67.0682 91.7446C67.0682 91.7446 66.9121 110.722 66.9719 111.393"
        fill="white"
      />
      <path
        d="M65.8579 127.267C65.8579 127.267 61.6344 103.16 60.8406 95.3318C59.9765 87.426 60.0655 82.3826 62.2923 82.1089C64.6624 81.8415 67.0682 91.7446 67.0682 91.7446C67.0682 91.7446 66.9121 110.722 66.9719 111.393"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M76.1247 83.6386C76.1247 83.6386 76.5341 72.1873 72.0317 73.1434C66.821 74.6282 66.7486 96.8842 66.7486 96.8842L64.7825 118.36"
        fill="white"
      />
      <path
        d="M76.1247 83.6386C76.1247 83.6386 76.5341 72.1873 72.0317 73.1434C66.821 74.6282 66.7486 96.8842 66.7486 96.8842L64.7825 118.36"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M66.4277 115.722C66.8794 112.702 67.6578 109.357 68.8773 105.682C76.0752 83.9915 78.7895 74.4875 79.5274 72.7377C81.4596 68.1556 85.4583 67.0168 85.2254 72.5526L82.425 93.7509"
        fill="white"
      />
      <path
        d="M66.4277 115.722C66.8794 112.702 67.6578 109.357 68.8773 105.682C76.0752 83.9915 78.7895 74.4875 79.5274 72.7377C81.4596 68.1556 85.4583 67.0168 85.2254 72.5526L82.425 93.7509"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M69.2683 142.606C69.2683 142.606 63.6644 130.331 65.8786 115.526C65.8786 115.526 66.0258 107.721 75.4427 98.6041C84.8596 89.4869 88.5136 86.3521 88.5136 86.3521C88.5136 86.3521 92.4824 83.4684 91.6241 88.5728C90.7658 93.6771 85.3066 101.578 82.1306 104.051"
        fill="white"
      />
      <path
        d="M69.2683 142.606C69.2683 142.606 63.6644 130.331 65.8786 115.526C65.8786 115.526 66.0258 107.721 75.4427 98.6041C84.8596 89.4869 88.5136 86.3521 88.5136 86.3521C88.5136 86.3521 92.4824 83.4684 91.6241 88.5728C90.7658 93.6771 85.3066 101.578 82.1306 104.051"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M307.284 262.285C307.284 262.285 260.646 289.75 191.054 264.899C107.97 235.23 77.3084 161.293 77.3084 161.293L88.2855 120.539L92.5322 95.9727C92.5322 95.9727 96.1123 85.717 100.108 95.4081C100.108 95.4081 101.447 111.667 102.198 120.79C102.95 129.913 131.227 199.231 206.343 226.054C206.343 226.054 255.843 243.36 299.126 222.189"
        fill="white"
      />
      <path
        d="M307.284 262.285C307.284 262.285 260.646 289.75 191.054 264.899C107.97 235.23 77.3084 161.293 77.3084 161.293L88.2855 120.539L92.5322 95.9727C92.5322 95.9727 96.1123 85.717 100.108 95.4081C100.108 95.4081 101.447 111.667 102.198 120.79C102.95 129.913 131.227 199.231 206.343 226.054C206.343 226.054 255.843 243.36 299.126 222.189"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M104.915 54.1385L102.993 81.8571L96.6809 79.6031L90.3687 77.349L104.915 54.1385Z"
        fill="white"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M103.789 57.6883L99.7337 64.0918L103.292 65.3626L103.789 57.6883Z"
        fill="#D9BAE8"
        stroke="#D9BAE8"
      />
      <path
        d="M81.8159 103.8L59.4824 172.872"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <ellipse cx="130.455" cy="21.3794" rx="20" ry="21.018" fill="#B17ACC" />
      <ellipse cx="10" cy="154.558" rx="10" ry="10.509" fill="#90CDF9" />
      <ellipse
        cx="217.511"
        cy="373.67"
        rx="43.1347"
        ry="41.0455"
        transform="rotate(-90 217.511 373.67)"
        fill="#FEC21E"
      />
    </svg>
  )
}
