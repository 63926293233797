import * as React from "react"
import { GoGitBranch } from "react-icons/go"
import { ThemeCss, Spacer } from "gatsby-interface"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"

export type DeployCommitProps = {
  branch: string
  sha: string
}

const commitContentCss: ThemeCss = theme => ({
  display: `flex`,
  fontFamily: theme.fonts.monospace,
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[60],
  wordBreak: `break-word`,
})

const commitIconCss: ThemeCss = () => ({
  fontSize: `1.2em`,
  transform: `translateY(0.1em)`,
  flexShrink: 0,
})

export function DeployCommit({ branch, sha }: DeployCommitProps) {
  return (
    <React.Fragment>
      <div css={visuallyHiddenCss}>{`Commit ${sha} on branch ${branch}`}</div>
      <div css={commitContentCss} aria-hidden={true}>
        <GoGitBranch css={commitIconCss} />
        <Spacer size={3} direction="horizontal" />
        {`${branch}@${sha}`}
      </div>
    </React.Fragment>
  )
}
