import React from "react"
import PropTypes from "prop-types"
import {
  formatDistance,
  parseISO,
  formatDistanceToNow,
  isAfter,
} from "date-fns"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"

const dateType = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.number,
  PropTypes.string,
])

const propTypes = {
  fromDate: dateType.isRequired,
  toDate: dateType.isRequired,
}

export default function DateTimeDistance({ fromDate, toDate }) {
  return formatDistance(parseDate(fromDate), parseDate(toDate), {
    addSuffix: true,
  })
}

DateTimeDistance.propTypes = propTypes

function FormattedDateTimeDistanceToNow({ fromDate }) {
  const nowDate = new Date()
  // fromDate is after the current date when it first gets triggered so this is the fallback message to show
  if (isAfter(parseDate(fromDate), nowDate)) {
    const FALLBACK_MESSAGE = `less than a minute ago`
    return FALLBACK_MESSAGE
  }
  return formatDistanceToNow(parseDate(fromDate), {
    addSuffix: true,
  })
}

export function DateTimeDistanceToNow({ fromDate, refreshMs }) {
  const [distanceToNow, setDistanceToNow] = React.useState(
    FormattedDateTimeDistanceToNow({ fromDate })
  )
  React.useEffect(() => {
    const timer = setInterval(
      () => setDistanceToNow(FormattedDateTimeDistanceToNow({ fromDate })),
      refreshMs
    )

    return () => {
      clearInterval(timer)
    }
  }, [fromDate, setDistanceToNow])

  return (
    <React.Fragment>
      <span css={visuallyHiddenCss}>{`Build started ${distanceToNow}`}</span>
      <span aria-hidden={true}>{distanceToNow}</span>
    </React.Fragment>
  )
}

DateTimeDistanceToNow.propTypes = {
  fromDate: dateType.isRequired,
  refreshMs: PropTypes.number,
}

function parseDate(date) {
  return typeof date === `string` ? parseISO(date) : date
}
