// THIS IS A GENERATED FILE
import * as Types from "../../graphql/types"

import { PermissionsForOrganizationFragment } from "../../organization/fragments.generated"
import gql from "graphql-tag"
import { PermissionsForOrganizationFragmentDoc } from "../../organization/fragments.generated"
import { useApolloFragment } from "apollo-fragment-react"

export type PermissionsForSiteFragment = {
  __typename?: "PermissionsDigest"
} & Pick<Types.PermissionsDigest, "role"> & {
    sites?: Types.Maybe<
      { __typename?: "ResourcePermissionMap" } & Pick<
        Types.ResourcePermissionMap,
        "read" | "create" | "edit" | "delete"
      >
    >
    contributor?: Types.Maybe<
      { __typename?: "ContributorPermissionMap" } & Pick<
        Types.ContributorPermissionMap,
        "read" | "invite" | "delete" | "deleteSelf"
      >
    >
  }

export type SiteDetailsFieldsFragment = { __typename?: "Site" } & Pick<
  Types.Site,
  | "id"
  | "organizationId"
  | "name"
  | "publicName"
  | "description"
  | "previewUrl"
  | "buildsEnabled"
  | "previewBuildsEnabled"
  | "buildChecksEnabled"
  | "incrementalBuildsEnabled"
  | "branch"
  | "publishBranch"
  | "previewStatus"
  | "previewPassword"
  | "previewProtection"
  | "previewDirectory"
  | "previewTriggerType"
  | "stableBuildURL"
  | "sitePrefix"
> & {
    latestBuild?: Types.Maybe<
      { __typename?: "Build" } & Pick<
        Types.Build,
        | "id"
        | "siteId"
        | "branch"
        | "siteName"
        | "buildType"
        | "buildStatus"
        | "runnerType"
        | "structuredBuild"
        | "createdAt"
        | "startedAt"
        | "endedAt"
        | "deployStartedAt"
        | "deployEndedAt"
        | "duration"
        | "source"
        | "cdnVendor"
      > & {
          commit?: Types.Maybe<
            { __typename?: "BuildCommit" } & Pick<
              Types.BuildCommit,
              "message" | "avatarUrl" | "id" | "name" | "sha"
            >
          >
          author?: Types.Maybe<
            { __typename?: "User" } & Pick<
              Types.User,
              "id" | "name" | "avatarUrl"
            >
          >
        }
    >
    repository?: Types.Maybe<
      { __typename?: "SourceControlRepository" } & Pick<
        Types.SourceControlRepository,
        "id" | "name" | "nameWithOwner" | "url" | "provider"
      >
    >
    sourceOrganization?: Types.Maybe<
      { __typename?: "SourceOrganization" } & Pick<
        Types.SourceOrganization,
        "sourceId" | "name" | "permissionsURL"
      > & {
          permissions?: Types.Maybe<
            Array<
              Types.Maybe<
                { __typename?: "SourcePermission" } & Pick<
                  Types.SourcePermission,
                  "key" | "active"
                >
              >
            >
          >
        }
    >
    versionsForUpgrade?: Types.Maybe<
      Array<
        Types.Maybe<
          { __typename?: "GatsbyPluginVersion" } & Pick<
            Types.GatsbyPluginVersion,
            "id" | "name" | "incrementalBuilds" | "builds" | "preview"
          >
        >
      >
    >
    permissions?: Types.Maybe<
      { __typename?: "PermissionsDigest" } & PermissionsForSiteFragment
    >
    cdnIntegrations?: Types.Maybe<
      Array<
        Types.Maybe<
          { __typename?: "CdnIntegration" } & Pick<
            Types.CdnIntegration,
            "id" | "siteId" | "vendor" | "connected" | "config"
          >
        >
      >
    >
    organization?: Types.Maybe<
      { __typename?: "Organization" } & Pick<
        Types.Organization,
        "id" | "name" | "status" | "enforceCap"
      > & {
          billing?: Types.Maybe<
            { __typename?: "BillingInformation" } & Pick<
              Types.BillingInformation,
              "id" | "status" | "trialStart" | "trialEnd" | "trialDaysRemaining"
            >
          >
          permissions?: Types.Maybe<
            {
              __typename?: "PermissionsDigest"
            } & PermissionsForOrganizationFragment
          >
        }
    >
  }

export type SiteRepositoryFieldsFragment = { __typename?: "Site" } & Pick<
  Types.Site,
  "id"
> & {
    repository?: Types.Maybe<
      { __typename?: "SourceControlRepository" } & Pick<
        Types.SourceControlRepository,
        "id" | "provider"
      >
    >
  }

export type SitePermissionsFieldsFragment = { __typename?: "Site" } & Pick<
  Types.Site,
  "id"
> & {
    permissions?: Types.Maybe<
      { __typename?: "PermissionsDigest" } & PermissionsForSiteFragment
    >
  }

export const PermissionsForSiteFragmentDoc = gql`
  fragment permissionsForSite on PermissionsDigest {
    role
    sites {
      read
      create
      edit
      delete
    }
    contributor {
      read
      invite
      delete
      deleteSelf
    }
  }
`
export const SiteDetailsFieldsFragmentDoc = gql`
  fragment siteDetailsFields on Site {
    id
    organizationId
    name
    publicName
    description
    previewUrl
    buildsEnabled
    previewBuildsEnabled
    buildChecksEnabled
    incrementalBuildsEnabled
    branch
    publishBranch
    previewStatus
    previewPassword
    previewProtection
    previewDirectory
    previewTriggerType
    stableBuildURL
    sitePrefix
    latestBuild {
      id
      siteId
      branch
      siteName
      commit {
        message
        avatarUrl
        id
        name
        sha
      }
      author {
        id
        name
        avatarUrl
      }
      buildType
      buildStatus
      runnerType
      structuredBuild
      createdAt
      startedAt
      endedAt
      deployStartedAt
      deployEndedAt
      duration
      source
      cdnVendor
    }
    repository {
      id
      name
      nameWithOwner
      url
      provider
    }
    sourceOrganization {
      sourceId
      name
      permissionsURL
      permissions {
        key
        active
      }
    }
    versionsForUpgrade {
      id
      name
      incrementalBuilds
      builds
      preview
    }
    permissions {
      ...permissionsForSite
    }
    cdnIntegrations {
      id
      siteId
      vendor
      connected
      config
    }
    organization {
      id
      name
      status
      enforceCap
      billing {
        id
        status
        trialStart
        trialEnd
        trialDaysRemaining
      }
      permissions {
        ...permissionsForOrganization
      }
    }
  }
  ${PermissionsForSiteFragmentDoc}
  ${PermissionsForOrganizationFragmentDoc}
`
export const SiteRepositoryFieldsFragmentDoc = gql`
  fragment SiteRepositoryFields on Site {
    id
    repository {
      id
      provider
    }
  }
`
export const SitePermissionsFieldsFragmentDoc = gql`
  fragment SitePermissionsFields on Site {
    id
    permissions {
      ...permissionsForSite
    }
  }
  ${PermissionsForSiteFragmentDoc}
`

/**
 * __usePermissionsForSiteFragment__
 * To read a fragment data from Apollo Cache, call `usePermissionsForSiteFragment` and pass it the ID of the cached object.
 * When your component renders, `usePermissionsForSiteFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = usePermissionsForSiteFragment('fragment-id');
 */
export function usePermissionsForSiteFragment(id: string) {
  return useApolloFragment<PermissionsForSiteFragment>(
    PermissionsForSiteFragmentDoc,
    id
  )
}
export type PermissionsForSiteFragmentHookResult = ReturnType<
  typeof usePermissionsForSiteFragment
>

/**
 * __useSiteDetailsFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useSiteDetailsFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useSiteDetailsFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useSiteDetailsFieldsFragment('fragment-id');
 */
export function useSiteDetailsFieldsFragment(id: string) {
  return useApolloFragment<SiteDetailsFieldsFragment>(
    SiteDetailsFieldsFragmentDoc,
    id
  )
}
export type SiteDetailsFieldsFragmentHookResult = ReturnType<
  typeof useSiteDetailsFieldsFragment
>

/**
 * __useSiteRepositoryFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useSiteRepositoryFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useSiteRepositoryFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useSiteRepositoryFieldsFragment('fragment-id');
 */
export function useSiteRepositoryFieldsFragment(id: string) {
  return useApolloFragment<SiteRepositoryFieldsFragment>(
    SiteRepositoryFieldsFragmentDoc,
    id
  )
}
export type SiteRepositoryFieldsFragmentHookResult = ReturnType<
  typeof useSiteRepositoryFieldsFragment
>

/**
 * __useSitePermissionsFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useSitePermissionsFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useSitePermissionsFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useSitePermissionsFieldsFragment('fragment-id');
 */
export function useSitePermissionsFieldsFragment(id: string) {
  return useApolloFragment<SitePermissionsFieldsFragment>(
    SitePermissionsFieldsFragmentDoc,
    id
  )
}
export type SitePermissionsFieldsFragmentHookResult = ReturnType<
  typeof useSitePermissionsFieldsFragment
>
