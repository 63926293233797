import React from "react"

export default function(props) {
  return (
    <svg
      width="1094"
      height="648"
      viewBox="0 0 1094 648"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M936.624 392.144C560.252 296.864 332.383 -85.4857 427.664 -461.857"
        stroke="white"
        strokeWidth="420"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
