import * as React from "react"
import ScalableSvg from "@modules/ui/components/ScalableSvg"

export function FastlyLogoIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <ScalableSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M13.919 3.036V1.3H14.551V0H9.37698V1.3H10.008V3.049C7.59448 3.51326 5.41814 4.8039 3.85319 6.699C2.28823 8.59411 1.43244 10.9753 1.43298 13.433C1.43298 19.275 6.16998 24 12 24C17.842 24 22.567 19.263 22.567 13.433C22.567 8.247 18.838 3.947 13.919 3.036ZM12.291 18.862V18.255H11.672V18.862C8.91498 18.704 6.71698 16.482 6.57098 13.725H7.17798V13.105H6.57098C6.65334 11.7802 7.21755 10.5314 8.15721 9.59395C9.09686 8.6565 10.347 8.09524 11.672 8.016V8.623H12.292V8.016C13.6274 8.0871 14.8898 8.64807 15.8375 9.59157C16.7853 10.5351 17.3519 11.7949 17.429 13.13H16.822V13.749H17.429C17.3497 15.0834 16.7822 16.342 15.8347 17.2849C14.8871 18.2279 13.6258 18.7892 12.291 18.862ZM14.551 11.15L14.161 10.761L12.182 12.486C12.081 12.4474 11.974 12.4271 11.866 12.426C11.332 12.426 10.895 12.874 10.895 13.421C10.895 13.968 11.332 14.417 11.866 14.417C12.401 14.417 12.838 13.967 12.838 13.421C12.8405 13.3175 12.8239 13.2144 12.789 13.117L14.551 11.15Z"
        fill="#E82C2A"
      />
    </ScalableSvg>
  )
}
