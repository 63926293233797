import * as React from "react"
import { ThemeCss, Heading } from "gatsby-interface"
import { createSite as text } from "@modules/locales/default.json"
import { NarrowSingleColumn } from "@modules/ui/layouts/Containers"

const baseCss: ThemeCss = theme => ({
  textAlign: `center`,
  fontSize: theme.fontSizes[7],
  fontWeight: 800,
  letterSpacing: `-0.02em`,
  lineHeight: theme.lineHeights.solid,
  margin: 0,

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[9],
  },
})

export function SiteCreationWizardHeader() {
  return (
    <NarrowSingleColumn>
      <Heading as="h1" css={baseCss} variant="EMPHASIZED">
        {text.headers.wizardTitle}
      </Heading>
    </NarrowSingleColumn>
  )
}
