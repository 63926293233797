import * as React from "react"
import { ThemeCss } from "gatsby-interface"

import UserDetails from "./UserDetails"
import ProfileSettingsForm from "./ProfileSettingsForm"
import OrganizationsAndBilling from "./OrganizationsAndBilling"
import UserSettingsHeader from "./UserSettingsHeader"
import { User } from "@modules/graphql/types"

const settingsSectionCss: ThemeCss = theme => ({
  padding: `${theme.space[9]} 0`,
  display: `grid`,
  gridGap: theme.space[5],
  gridTemplateAreas: `"content"`,
  gridTemplateColumns: `1 fr`,

  [theme.mediaQueries.tablet]: {
    gridGap: 0,
    gridTemplateAreas: `". content ."`,
    gridTemplateColumns: "minmax(150px, 1fr) 420px minmax(150px, 1fr)",
  },
})

const userSettingsRootCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
})

const userSettingsHeaderSectionCss: ThemeCss = theme => [
  settingsSectionCss(theme),
  {
    padding: `${theme.space[9]} 0 0 0`,
  },
]

const userDetailsSectionCss: ThemeCss = theme => [
  settingsSectionCss(theme),
  {
    padding: 0,
    gridTemplateAreas: `
      "details"
      "content"
    `,

    [theme.mediaQueries.tablet]: {
      gridTemplateAreas: `
      "details content ."
    `,
    },
  },
]

const orgDetailsSectionCss: ThemeCss = theme => [
  settingsSectionCss(theme),
  {
    borderTop: `1px solid ${theme.colors.standardLine}`,
  },
]

export type UserSettingsProps = {
  user: User
}

function UserSettings({ user }: UserSettingsProps) {
  return (
    <main css={userSettingsRootCss}>
      <section css={userSettingsHeaderSectionCss}>
        <UserSettingsHeader />
      </section>
      <section css={userDetailsSectionCss}>
        <UserDetails user={user} />
        <ProfileSettingsForm user={user} />
      </section>
      <section css={orgDetailsSectionCss}>
        <OrganizationsAndBilling />
      </section>
    </main>
  )
}

export default UserSettings
