import React, { Component } from "react"
import PropTypes from "prop-types"
import * as Sentry from "@sentry/browser"
import { Link } from "gatsby-interface"
import { ModalError } from "./ModalError"
import { UnAuthorizedError } from "./UnAuthorizedError"
import { ModalEmphasizedText, ModalMessage } from "./ModalElements"
import { useGenericError } from "./GenericErrorModal"
import { Text } from "@modules/ui/typography"
import AlertHexagon from "@modules/assets/icons/AlertHexagon"
import { modalErrors as text } from "@modules/locales/default.json"

export function UpdateGithubPermissions({ errMsg, closeModal, settingsUrl }) {
  const heading = text[`defaultError`].heading

  return (
    <ModalError heading={heading} closeModal={closeModal}>
      <ModalMessage data-testid="error-modal-msg">
        <ModalEmphasizedText>
          <AlertHexagon />
          {errMsg}
          {` `}
          <Link variant="SIMPLE" href={settingsUrl} target="_blank">
            Update Permissions
          </Link>
        </ModalEmphasizedText>
        <Text>{text[`defaultError`].message[0]}</Text>
      </ModalMessage>
    </ModalError>
  )
}

export function NoGatsbyError({ closeModal }) {
  const heading = text[`noGatsby`].heading

  return (
    <ModalError heading={heading} closeModal={closeModal}>
      <ModalMessage>
        <ModalEmphasizedText>{text[`noGatsby`].message[0]}</ModalEmphasizedText>
        <Text>{text[`noGatsby`].message[1]}</Text>
        <Text>{text[`noGatsby`].message[2]}</Text>
        <Text>{text.emailSupport}</Text>
      </ModalMessage>
    </ModalError>
  )
}

export function InvalidGatsbyVersion({ closeModal }) {
  const heading = text[`invalidVersion`].heading

  return (
    <ModalError heading={heading} closeModal={closeModal}>
      <ModalMessage>
        <ModalEmphasizedText>
          {text[`invalidVersion`].message[0]}
        </ModalEmphasizedText>
        <Text>{text[`invalidVersion`].message[1]}</Text>
        <Text>{text[`invalidVersion`].message[2]}</Text>
        <Text>{text.emailSupport}</Text>
      </ModalMessage>
    </ModalError>
  )
}

export function BlacklistedDependenciesFound({ closeModal }) {
  const heading = text[`blacklistedDeps`].heading

  return (
    <ModalError heading={heading} closeModal={closeModal}>
      <ModalMessage>
        <ModalEmphasizedText>
          {text[`blacklistedDeps`].message[0]}
        </ModalEmphasizedText>
        <Text>{text[`blacklistedDeps`].message[1]}</Text>
      </ModalMessage>
    </ModalError>
  )
}

export function NoOrganizationPermissionError({ closeModal }) {
  const heading = text[`noOrganizationPermission`].heading

  return (
    <ModalError heading={heading} closeModal={closeModal}>
      <ModalMessage>
        <ModalEmphasizedText>
          {text.noOrganizationPermission.message[0]}
        </ModalEmphasizedText>
        <Text>{text.noOrganizationPermission.message[1]}</Text>
        <Text>{text.emailSupport}</Text>
      </ModalMessage>
    </ModalError>
  )
}

export function DefaultError({
  errMsg = ``,
  onClose = undefined,
  closeModal = undefined,
  className = ``,
}) {
  const {
    emailSupport,
    defaultError: { heading, message },
  } = text

  const error = errMsg.replace(`GraphQL error:`, ``)
  const isAuthorizationError = error.includes(`Not Authorised!`)

  if (isAuthorizationError) {
    return <UnAuthorizedError onClose={closeModal} />
  }

  const isServerSideError = error.includes(`Failed to fetch`)
  const textIndex = isServerSideError ? 1 : 0

  return (
    <ModalError
      heading={heading[0]}
      onClose={onClose}
      closeModal={closeModal}
      hideSupportContact={isServerSideError}
    >
      <ModalMessage data-testid="error-modal-msg">
        <ModalEmphasizedText className={className || ``}>
          <AlertHexagon />
          {error}
        </ModalEmphasizedText>
        <Text>{message[textIndex]}</Text>
        {!isServerSideError && <Text>{emailSupport}</Text>}
      </ModalMessage>
    </ModalError>
  )
}

export const GlobalError = ({ errorType }) => {
  let ModalComponent
  switch (errorType) {
    case `NEED_GITHUB_APP_FUTURE_PERMISSIONS`:
    case `UPDATE_GITHUB_PERMISSIONS`:
      ModalComponent = UpdateGithubPermissions
      break
    case `NO_ORGANIZATION_PERMISSION`:
      ModalComponent = NoOrganizationPermissionError
      break
    case `BLACKLISTED_PACKAGE`:
      ModalComponent = BlacklistedDependenciesFound
      break
    case `NO_GATSBY_REPO`:
      ModalComponent = NoGatsbyError
      break
    case `INVALID_GATSBY_VERSION`:
      ModalComponent = InvalidGatsbyVersion
      break
    default:
      ModalComponent = NoGatsbyError
      break
  }
  return ModalComponent
}

class RenderErrorContainer extends Component {
  state = {
    error: null,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render() {
    return null
  }
}

export function ErrorModal({ errMsg, onClose = undefined }) {
  const throwError = useGenericError()

  React.useEffect(() => {
    throwError({ message: errMsg, onDismiss: onClose })
  }, [errMsg])

  return <RenderErrorContainer />
}

ErrorModal.propTypes = {
  errMsg: PropTypes.string.isRequired,
}
