import styled from "@emotion/styled"
import { Text } from "@modules/ui/typography"

export const ModalEmphasizedText = styled(Text)`
  font-weight: bold;
  margin-top: 0;

  svg {
    margin-right: ${p => p.theme.space[3]};
    vertical-align: middle;
    margin-top: -0.1rem;
  }
`

export const ModalMessage = styled(`div`)`
  ${Text} {
    width: 100%;

    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
