import React from "react"
import { Global, css } from "@emotion/core"

function GlobalStyles() {
  return (
    <Global
      styles={theme => css`
        html {
          overflow-y: unset;
        }

        body {
          overflow: visible;
          &.dashboardGreyBg {
            background: ${theme.colors.secondaryBackground};
          }
        }

        /* CSS reset */

        /* Reset for <fieldset> based on https://thatemil.com/blog/2015/01/03/reset-your-fieldset/ */
        fieldset {
          border: 0;
          margin: 0;
          padding: 0.01em 0 0 0;
          min-width: 0;
        }
      `}
    />
  )
}

export default GlobalStyles
