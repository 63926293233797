import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { ToastProvider, ThemeProvider } from "gatsby-interface"
import GlobalStyles from "../stylesheets/GlobalStyles"
import { isAuthenticated } from "@modules/auth"
import { SkipNavTrigger, SkipNavTarget } from "@modules/a11y"
import { GenericErrorProvider } from "@modules/modal"
import { Header } from "@modules/header/components"
import { AuthorizedFeatureFlagProvider } from "@modules/featureFlags"
import SurveyBanner from "@modules/survey"

/* Apollo Configuration */
import { ApolloProvider } from "react-apollo"

/* Product Dashboard */
import createApolloClient from "@modules/graphql/client"

import "@modules/assets/fonts/futura-pt/Webfonts/futurapt_book_macroman/stylesheet.css"
import "@modules/assets/fonts/futura-pt/Webfonts/futurapt_bookitalic_macroman/stylesheet.css"
import "@modules/assets/fonts/futura-pt/Webfonts/futurapt_demi_macroman/stylesheet.css"
import "@modules/assets/fonts/futura-pt/Webfonts/futurapt_demiitalic_macroman/stylesheet.css"
import "@modules/assets/fonts/futura-pt/Webfonts/futurapt_bold/stylesheet.css"
import { SourceControlProvider } from "@modules/graphql/types"
import { useAvailableSourceProvidersQuery } from "@modules/site/create/Import/queries.generated"
import { useCurrentUserQuery } from "@modules/auth/queries.generated"
import { useTracker, SegmentEventType } from "@modules/analytics"

function testPathnameForBg(path) {
  return (
    /\/dashboard\/sites\/*$/.test(path) ||
    /\/get-started\/*$/.test(path) ||
    /\/details\/*$/.test(path) ||
    /\/dashboard\/pricing\//.test(path)
  )
}

let bodyRef
let lastPathname
let greyBg = false

/*
 * TODO: Ideally, we should just setup gatsbyjs.com like described here https://github.com/gatsbyjs/gatsby/issues/11225#issuecomment-457211628
 * However, the least potentially impactful solution is to just check if we are not on server side
 **/
const apolloClient = typeof window !== `undefined` ? createApolloClient() : null

export function MainLayout({ children, pathname, dashboard = true }) {
  if (typeof window === `undefined`) {
    return null
  }

  if (!bodyRef) {
    bodyRef = document.querySelector(`body`)
  }

  if (!lastPathname || pathname !== lastPathname) {
    greyBg = testPathnameForBg(pathname)

    if (greyBg) {
      bodyRef.classList.add(`dashboardGreyBg`)
    } else {
      bodyRef.classList.remove(`dashboardGreyBg`)
    }
  }

  lastPathname = pathname

  const shouldShowHeader =
    !pathname.includes(`/previews/login`) &&
    !pathname.includes(`maintenance`) &&
    !pathname.includes(`/dashboard/signup`) &&
    !pathname.includes(`/dashboard/login`)

  const content = (
    <React.Fragment>
      {dashboard && <SkipNavTarget createAnchor />}
      {children}
    </React.Fragment>
  )

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider>
        <SkipNavTrigger />
        <GlobalStyles />
        <Helmet
          defaultTitle="Gatsby Cloud"
          titleTemplate="Gatsby Cloud | %s"
          htmlAttributes={{ lang: "en" }}
        />
        <IdentifyUser />
        <ToastProvider>
          <GenericErrorProvider>
            {isAuthenticated() && dashboard ? (
              <AuthorizedFeatureFlagProvider>
                {process.env.GATSBY_MAINTENANCE !== `true` && shouldShowHeader && (
                  <React.Fragment>
                    <Banners />
                    <Header pathname={pathname} />
                  </React.Fragment>
                )}
                {content}
              </AuthorizedFeatureFlagProvider>
            ) : process.env.GATSBY_MAINTENANCE !== `true` &&
              shouldShowHeader ? (
              <React.Fragment>
                <Header pathname={pathname} />
                {content}
              </React.Fragment>
            ) : (
              content
            )}
          </GenericErrorProvider>
        </ToastProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}

MainLayout.propTypes = {
  pathname: PropTypes.string,
}

/**
 * HELPERS
 */

function Banners() {
  return (
    <React.Fragment>
      {/*
        EXAMPLE:
        <Banner id="intro-builds" />
      */}
      <SurveyBanner />
    </React.Fragment>
  )
}

function IdentifyUser() {
  const { data } = useCurrentUserQuery({
    fetchPolicy: `cache-only`,
  })
  const { data: sourceProvidersData } = useAvailableSourceProvidersQuery()
  const { trackSegment } = useTracker()

  const currentUser = data?.currentUser
  const providers = sourceProvidersData?.availableSourceProviders

  React.useEffect(() => {
    const canTrack = currentUser?.name && currentUser?.email && providers

    if (!canTrack) {
      return
    }

    const connectedProviders = []

    for (const provider of providers) {
      if (provider.connected && provider.source) {
        connectedProviders.push(provider.source)
      }
    }

    trackSegment({
      type: SegmentEventType.Identify,
      traits: {
        name: currentUser.name,
        email: currentUser.email,
      },
      properties: {
        integrations: {
          GitHub: connectedProviders.includes(SourceControlProvider.Github),
          GitLab: connectedProviders.includes(SourceControlProvider.Gitlab),
          Bitbucket: connectedProviders.includes(
            SourceControlProvider.Bitbucket
          ),
        },
      },
    })
  }, [providers, currentUser?.name, currentUser?.email])

  return null
}
