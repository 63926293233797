import * as React from "react"
import { Button } from "gatsby-interface"
import { MdArrowForward } from "react-icons/md"

import ContentSection from "./ContentSection"
import Form, { FormElement } from "@modules/form/components/Form"
import { InputConnectedField } from "gatsby-interface"
import {
  validationSchema,
  useFormSubmitHandler,
} from "./ProfileSettingsForm.helpers"
import { userSettings as text } from "@modules/locales/default.json"
import { User } from "@modules/graphql/types"

export type ProfileSettingsFormProps = {
  user: User
}

function ProfileSettingsForm({ user }: ProfileSettingsFormProps) {
  const [handleSubmitForm, { errorAlert }] = useFormSubmitHandler()

  return (
    <ContentSection>
      <h2>{text.personalDetails}</h2>
      <Form
        initialValues={{
          firstName: user.firstName || ``,
          lastName: user.lastName || ``,
          email: user.email || ``,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
      >
        {({ isSubmitting, submitForm, isValid }) => (
          <FormElement applySpacing>
            <InputConnectedField
              name="firstName"
              label="First name:"
              data-cy="profile-settings-first-name-input"
            />
            <InputConnectedField
              name="lastName"
              label="Last name:"
              data-cy="profile-settings-last-name-input"
            />
            <InputConnectedField
              name="email"
              label="Email:"
              data-cy="profile-settings-email-input"
            />
            {errorAlert}
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
              rightIcon={<MdArrowForward />}
              css={theme => ({
                margin: `${theme.space[4]} auto`,
              })}
            >
              {text.saveButton}
            </Button>
          </FormElement>
        )}
      </Form>
    </ContentSection>
  )
}

export default ProfileSettingsForm
