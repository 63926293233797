import React from "react"

const listCss = _theme => ({
  listStyleType: "none",
  margin: 0,
  padding: 0,
})

const listItemCss = theme => ({
  margin: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderTop: `1px solid ${theme.colors.grey[20]}`,

  [theme.mediaQueries.phablet]: {
    flexDirection: "row",
  },
})

const listItemContent = theme => ({
  flex: 1,
  padding: theme.cardStyles.space.M,
})

const listItemActions = theme => ({
  background: theme.colors.purple[5],
  display: "flex",
  flex: 1,
  width: "100%",
  justifyContent: "center",

  [theme.mediaQueries.phablet]: {
    flex: "unset",
    display: "unset",
    width: "auto",
    background: "transparent",
    padding: theme.cardStyles.space.M,
  },
})

export function List(props) {
  return <ul {...props} css={listCss} />
}

export function ListItem(props) {
  return <li {...props} css={listItemCss} />
}

export function ListItemContent({ children }) {
  return <div css={listItemContent}>{children}</div>
}

export function ListItemActions({ children }) {
  return <div css={listItemActions}>{children}</div>
}
