/*
 * These enums should be moved to more suitable modules, e.g.
 * UsagePlanType, AvailablePlanNames, BillingPlanLimits -> @modules/billing
 */
export const UsagePlanType = {
  Business: `Business`,
  Free: `Free`,
  Professional: `Professional`,
  Enterprise: `Enterprise`,
  InkTeam: `Pro (InkTeam)`,
  Trialing: `Trialing`,
  Individual: `Individual`,
  Team: `Team`,
}

export const AvailablePlanNames = [
  UsagePlanType.Business,
  UsagePlanType.Professional,
  UsagePlanType.Enterprise,
  UsagePlanType.Team,
  UsagePlanType.Individual,
]

export const ApolloStatus = {
  Refetching: 4,
  Polling: 6,
}

export const GatsbyVersions = {
  IncrementalBuilds: {
    gatsby: ">=2.20.4",
  },
  Builds: {
    gatsby: ">=2.19.25",
    "gatsby-plugin-sharp": ">=2.4.0",
  },
}

export const BillingPlanLimits = {
  trialingDays: 14,
}
