import * as React from "react"
import { ThemeCss } from "gatsby-interface"

export type FixedFooterWithLipProps = React.ComponentPropsWithoutRef<"div">

export function FixedFooterWithLip(props: FixedFooterWithLipProps) {
  const fixedContainerRef = React.useRef<HTMLDivElement | null>(null)
  const sentinelRef = React.useRef<HTMLDivElement | null>(null)
  const observerRef = React.useRef<IntersectionObserver | null>(null)

  const [isIntersecting, setIsIntersecting] = React.useState<boolean>(false)
  const [ghostHeight, setGhostHeight] = React.useState<number>(0)

  React.useEffect(() => {
    setGhostHeight(fixedContainerRef.current?.clientHeight || 0)
  }, [fixedContainerRef.current])

  React.useEffect(() => {
    if (!sentinelRef.current) {
      return
    }

    observerRef.current = new IntersectionObserver(
      entries => {
        if (entries[0].intersectionRatio < 1) {
          setIsIntersecting(true)
        } else if (entries[0].intersectionRatio === 1) {
          setIsIntersecting(false)
        }
      },
      {
        threshold: [0, 1],
      }
    )

    observerRef.current.observe(sentinelRef.current)

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [sentinelRef.current])

  const baseCss: ThemeCss = theme => [
    {
      position: `fixed`,
      width: `100%`,
      bottom: 0,
      left: 0,
      zIndex: 1,
      transition: `box-shadow ${theme.transitions.default}`,
    },
    isIntersecting && {
      boxShadow: `
              0px -2px 2px ${theme.colors.blackFade[10]},
              0px -4px 4px ${theme.colors.blackFade[20]}
          `,
    },
  ]

  return (
    <React.Fragment>
      <div ref={sentinelRef} style={{ height: ghostHeight }} />
      <div {...props} ref={fixedContainerRef} css={baseCss} />
    </React.Fragment>
  )
}
