import * as React from "react"
import {
  ThemeCss,
  CheckIcon,
  Theme,
  AriaFormGroupFieldData,
} from "gatsby-interface"
import { SubscriptionPlan } from "@modules/graphql/types"
import {
  RadioButtonCard,
  RadioButtonCardInput,
} from "@modules/ui/components/RadioButtonCard"
import {
  plansMetaData,
  SupportedPlanNames,
  getColorsForPlanTier,
} from "@modules/billing/shared/utils"
import { supportedPlans, SupportedPlanTier } from "@modules/billing/constants"

const optionCardCss: ThemeCss = theme => ({
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 0,
  paddingTop: theme.space[7],
  paddingBottom: theme.space[7],
  paddingLeft: theme.space[6],
  paddingRight: theme.space[6],

  ":not(:first-of-type)": {
    marginTop: theme.space[8],
  },

  [theme.mediaQueries.desktop]: {
    ":not(:first-of-type)": {
      marginTop: 0,
      marginLeft: theme.space[8],
    },
  },
})

const planOptionLabelCss: ThemeCss = theme => ({
  fontFamily: theme.fonts.heading,
  fontSize: theme.fontSizes[3],
  fontWeight: theme.fontWeights.bold,
  cursor: `pointer`,
  marginLeft: theme.space[3],
  flexGrow: 1,
  display: `inline-flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
})

const featuresListCss: ThemeCss = theme => ({
  listStyle: `none`,
  margin: 0,
  flexGrow: 1,
  paddingTop: theme.space[5],
  paddingBottom: theme.space[5],
  paddingLeft: theme.space[5],
  paddingRight: theme.space[5],
  background: theme.colors.grey[10],
  color: theme.colors.grey[60],
  fontSize: theme.fontSizes[1],
  lineHeight: theme.lineHeights.default,
})

const featureIconCss: ThemeCss = theme => ({
  color: theme.colors.grey[30],
})

type ExpectedPlanFields = Pick<
  SubscriptionPlan,
  "id" | "name" | "features" | "formattedMonthlyAmount"
>

export type SubscriptionPlanCardProps = {
  plan: ExpectedPlanFields
  isSelected?: boolean
  onSelect: (value: ExpectedPlanFields) => void
} & Pick<
  AriaFormGroupFieldData,
  "getOptionControlProps" | "getOptionLabelProps"
>

export function SubscriptionPlanCard({
  plan,
  isSelected,
  onSelect,
  getOptionControlProps,
  getOptionLabelProps,
}: SubscriptionPlanCardProps) {
  const optionValue = plan.id
  const featuresSectionId = `features_${plan.id}`
  const metaData = plansMetaData[plan.name as SupportedPlanNames]

  const planTier = plan.name as SupportedPlanTier
  const isSupportedPlan = Boolean(supportedPlans[planTier])

  return (
    <RadioButtonCard
      key={`planOption_${plan.id}`}
      checked={isSelected}
      css={optionCardCss}
    >
      <div
        css={theme => [
          {
            display: `flex`,
            alignItems: `center`,
            marginBottom: theme.space[7],
          },
        ]}
      >
        <RadioButtonCardInput
          value={optionValue}
          name="planOption"
          checked={isSelected}
          onChange={() => {
            onSelect(plan)
          }}
          aria-describedby={featuresSectionId}
          {...getOptionControlProps(optionValue)}
        />
        <label {...getOptionLabelProps(optionValue)} css={planOptionLabelCss}>
          <span
            css={(theme: Theme) => ({
              color: isSupportedPlan
                ? getColorsForPlanTier(planTier)(theme).primaryColor
                : metaData.getColor(theme),
            })}
          >
            {plan.name}
          </span>
          <span>
            <span css={(theme: Theme) => ({ color: theme.colors.grey[80] })}>
              ${Math.floor(plan.formattedMonthlyAmount.replace(`$`, ``))}
            </span>
            <span
              css={(theme: Theme) => ({
                color: theme.colors.grey[60],
                fontSize: theme.fontSizes[0],
                fontWeight: theme.fontWeights.bold,
              })}
            >
              /month
            </span>
          </span>
        </label>
      </div>
      <ul css={featuresListCss} id={featuresSectionId}>
        {(plan.features as string[]).map(feature => (
          <li key={feature}>
            <CheckIcon css={featureIconCss} /> {feature}
          </li>
        ))}
      </ul>
    </RadioButtonCard>
  )
}
