import * as React from "react"
import {
  Button,
  ToggleSwitch,
  ThemeCss,
  Spacer,
  Link,
  useAriaFormGroupField,
  FormFieldset,
  FormLegend,
} from "gatsby-interface"
import VisuallyHidden from "@modules/a11y/components/VisuallyHidden"
import { BillingInterval, MachinePricingTier } from "@modules/graphql/types"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { SubscriptionPlanCard } from "./SubscriptionPlanCard"
import { WizardStepHeader } from "@modules/ui/components/WizardStepHeader"
import { WizardFooter } from "@modules/ui/components/WizardFooter"
import { MdArrowForward } from "react-icons/md"
import {
  SupportedPlanNames,
  plansMetaData,
} from "@modules/billing/shared/utils"
import {
  useFilteredPlans,
  useAllPlans,
} from "@modules/billing/components/Billing.helpers"
import {
  createOrganization as createOrgText,
  ui as uiText,
} from "@modules/locales/default.json"
import { FormattedMessage } from "@modules/locales"
import { useFlags } from "@modules/featureFlags"
import { MultiPlanSelector } from "@modules/billing/shared/components/MultiPlanSelector"
import {
  PlanInfo,
  SupportedPricingTier,
} from "@modules/billing/shared/constants/tiers"
import { PlanTierInfo } from "@modules/billing/shared/components/PlanTierInfo"
import capitalizeStr from "@modules/toolkit/helpers/capitalizeStr"

const fieldsGroupCss: ThemeCss = theme => ({
  marginBottom: theme.space[10],
})

const multiSelectorCss: ThemeCss = theme => ({
  marginBottom: theme.space[10],
})

const selectorCss: ThemeCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `center`,
  maxWidth: `60rem`,
  margin: `0 auto`,

  [theme.mediaQueries.desktop]: {
    flexDirection: `row`,
    alignItems: `stretch`,
  },
})

const selectionPreviewCss: ThemeCss = theme => ({
  display: `flex`,
  justifyContent: `center`,
  columnGap: theme.space[5],
  paddingTop: theme.space[7],
  marginBottom: `-${theme.space[6]}`,
  flexDirection: `column`,
  alignItems: `center`,

  [theme.mediaQueries.desktop]: {
    flexDirection: `row`,
  },
})

export type PickOrganizationPlanProps = {
  selectedPlanId: string | null
  selectedPlanName: string | null
  selectedBillingInterval: BillingInterval
  onGoNext: (
    planInfo: { [P in keyof PlanInfo]: Exclude<PlanInfo[P], null> }
  ) => void
  onGoBack: () => void
}

export function PickOrganizationPlan({
  selectedPlanId,
  selectedPlanName,
  selectedBillingInterval,
  onGoNext,
  onGoBack,
}: PickOrganizationPlanProps) {
  const { flags } = useFlags()
  const [planInfo, setPlanInfo] = React.useState<PlanInfo>({
    planId: selectedPlanId,
    planName: selectedPlanName,
    billingInterval: flags.multiTiersPlans
      ? BillingInterval.Annual
      : selectedBillingInterval,
    buildsTier: MachinePricingTier.Standard,
    hostingTier: MachinePricingTier.Standard,
  })

  const filteredPlans = useFilteredPlans({
    interval: planInfo.billingInterval,
    skip: flags.multiTiersPlans,
  })

  const allPlans = useAllPlans({
    skip: !flags.multiTiersPlans,
  })

  const {
    getLegendProps,
    getOptionControlProps,
    getOptionLabelProps,
  } = useAriaFormGroupField(`plan`, {
    required: true,
  })

  const selectedPlan = filteredPlans.plans.find(
    plan => plan.id === planInfo.planId
  )

  /**
   * We want to automatically select a plan in one of these cases:
   *  - the user gets to this step for the first time (they have not selected a plan yet),
   *    in which case we select either the suggested plan ("Professional")
   *    or the first available plan if the suggested one cannot be found
   *  - the user changes the billing interval, in which case we select the plan with the same name
   */
  const shouldPreselectPlan = filteredPlans.plans.length > 0 && !selectedPlan

  React.useEffect(() => {
    if (!flags.multiTiersPlans && shouldPreselectPlan) {
      const DEFAULT_PLAN_NAME = SupportedPlanNames.Individual

      const planWithMatchingName = filteredPlans.plans.find(
        ({ name }) => name === planInfo.planName
      )

      const defaultPlan = filteredPlans.plans.find(
        ({ name }) => name === DEFAULT_PLAN_NAME
      )

      const preselectedPlan =
        planWithMatchingName || defaultPlan || filteredPlans.plans[0]

      setPlanInfo(info => ({
        ...info,
        planId: preselectedPlan.id,
        planName: preselectedPlan.name,
      }))
    }
  }, [shouldPreselectPlan])

  if (filteredPlans.loading && filteredPlans.plans.length === 0) {
    return null
  }

  let finalAvailablePlans
  if (!flags.multiTiersPlans) {
    finalAvailablePlans = filteredPlans.plans.filter(
      ({ name }) => name !== SupportedPlanNames.Enterprise
    )
    finalAvailablePlans.sort(
      (planA, planB) =>
        plansMetaData[planA.name as SupportedPlanNames].order -
        plansMetaData[planB.name as SupportedPlanNames].order
    )
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        onGoNext({
          planId: planInfo.planId as string,
          planName: planInfo.planName as string,
          billingInterval: planInfo.billingInterval,
        })
      }}
    >
      <div
        css={{ display: `flex`, flexDirection: `column`, alignItems: `center` }}
      >
        <WizardStepHeader title="Pick a plan for your space">
          <FormattedMessage<never, "link">
            message={createOrgText.messages.pickPlanDescription}
            tags={{
              link: function(linkText) {
                return <Link to="/contact-us">{linkText}</Link>
              },
            }}
          />
        </WizardStepHeader>
        {flags.multiTiersPlans ? (
          <MultiPlanSelector
            planInfo={planInfo}
            setPlanInfo={setPlanInfo}
            availablePlans={allPlans.plans}
            diyTiersOnly={true}
            css={multiSelectorCss}
          />
        ) : (
          <React.Fragment>
            <div css={fieldsGroupCss}>
              <VisuallyHidden id="intervalTitle">
                Billing interval
              </VisuallyHidden>
              <ToggleSwitch
                id="interval"
                valueOn={BillingInterval.Annual}
                labelOn="Yearly"
                valueOff={BillingInterval.Monthly}
                labelOff="Monthly"
                value={planInfo.billingInterval}
                onChange={e => {
                  const billingInterval = e.target.value as BillingInterval

                  setPlanInfo({
                    ...planInfo,
                    billingInterval,
                  })
                }}
                aria-describedby="intervalTitle"
                tone="SUCCESS"
                css={theme => ({
                  textTransform: `uppercase`,
                  fontFamily: theme.fonts.heading,
                  display: `inline-flex`,
                })}
              />
              <Spacer size={5} />
              <FormFieldset css={{ display: `inline-block` }}>
                <FormLegend
                  {...getLegendProps(`Select a plan`)}
                  css={visuallyHiddenCss}
                />
                <div css={selectorCss}>
                  {finalAvailablePlans?.map(plan => {
                    return (
                      <SubscriptionPlanCard
                        key={plan.id}
                        plan={plan}
                        isSelected={plan.id === selectedPlan?.id}
                        onSelect={plan => {
                          setPlanInfo(info => ({
                            ...info,
                            planId: plan.id,
                            planName: plan.name,
                          }))
                        }}
                        getOptionControlProps={getOptionControlProps}
                        getOptionLabelProps={getOptionLabelProps}
                      />
                    )
                  })}
                </div>
              </FormFieldset>
            </div>
          </React.Fragment>
        )}
      </div>
      <WizardFooter
        goBackButton={
          <Button variant="SECONDARY" onClick={() => onGoBack()}>
            {uiText.actions.back}
          </Button>
        }
        goNextButton={
          <Button type="submit" rightIcon={<MdArrowForward />}>
            {uiText.actions.next}
          </Button>
        }
      >
        {flags.multiTiersPlans && (
          <div css={selectionPreviewCss}>
            <PlanTierInfo
              tierType="builds"
              tier={planInfo.buildsTier as SupportedPricingTier}
              showTip={true}
              showDetails={false}
              billingInterval={planInfo.billingInterval}
            />
            <Spacer size={6} direction="horizontal" />
            <PlanTierInfo
              tierType="hosting"
              tier={planInfo.hostingTier as SupportedPricingTier}
              showTip={true}
              showDetails={false}
              billingInterval={planInfo.billingInterval}
            />
            <Spacer size={6} direction="horizontal" />
            <span>
              Billed:{" "}
              {capitalizeStr({ str: planInfo.billingInterval.toLowerCase() })}
            </span>
          </div>
        )}
      </WizardFooter>
    </form>
  )
}
