import React from "react"
import { keyframes } from "@emotion/core"
import { MdClose } from "react-icons/md"
import { useFlags } from "@modules/featureFlags"

const fadeInAnimation = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
})

const dropDownAnimation = keyframes({
  from: {
    transform: `scaleY(0)`,
  },
  to: {
    transform: `scaleY(1)`,
  },
})

const BANNER_HEIGHT = 40
const LOCALSTORAGE_PREFIX = `gatsby:banner`
const ANIMATION_DELAY = 500
const FIRST_STAGE_ANIMATION_DURATION = 300

const wrapperStyles = theme => ({
  padding: `${theme.space[3]} 0`,
  background: theme.colors.purple[90],
  color: theme.colors.whiteFade[80],
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
  fontSize: theme.fontSizes[1],
  animation: `${dropDownAnimation} ${FIRST_STAGE_ANIMATION_DURATION}ms ${ANIMATION_DELAY}ms both`,
  transformOrigin: `top center`,
})

const edgeColumnStyles = () => ({
  width: BANNER_HEIGHT,
  display: `grid`,
  placeItems: `center`,
})

const textStyles = () => ({
  flex: 1,
  textAlign: `center`,
  animation: `${fadeInAnimation} 400ms ${ANIMATION_DELAY +
    FIRST_STAGE_ANIMATION_DURATION}ms both`,
})
const closeButtonStyles = theme => ({
  display: `flex`,
  placeContent: `center`,
  width: BANNER_HEIGHT,
  border: `none`,
  background: `transparent`,
  color: `inherit`,
  fontSize: theme.fontSizes[4],
  padding: 0,
})

const availableBanners = {
  /* EXAMPLE: */
  // "banner-slug": {
  //   contents: (
  //     <>
  //       Hello world!
  //     </>
  //   ),
  //   condition: _flags => {
  //     return false
  //   },
  // },
}

const bannerLinkCss = theme => ({
  color: theme.colors.white,
  display: "inline-flex",
  alignItems: "center",
})

export function BannerLink({ children, href }) {
  return (
    <a
      href={href}
      css={bannerLinkCss}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

export function BannerLayout({ children, onClose, ...props }) {
  return (
    <aside css={wrapperStyles} {...props}>
      <div css={edgeColumnStyles} />
      <span css={textStyles}>{children}</span>
      <div css={edgeColumnStyles}>
        <button
          css={closeButtonStyles}
          aria-label="Dismiss banner"
          onClick={onClose}
          data-testid="banner-close-button"
        >
          <MdClose />
        </button>
      </div>
    </aside>
  )
}

function Banner({ id }) {
  const { flags } = useFlags()

  const { contents, condition } = availableBanners[id]

  const localStorageKey = `${LOCALSTORAGE_PREFIX}-${id}-dismissed`

  const bannerStatus = getBannerStatus(localStorageKey)

  const [showBanner, setShowBanner] = React.useState(bannerStatus === "visible")

  if (!condition(flags)) {
    return null
  }

  if (!showBanner) {
    return null
  }

  return (
    <BannerLayout
      onClose={() => {
        window.localStorage.setItem(localStorageKey, true)
        setShowBanner(false)
      }}
    >
      {contents}
    </BannerLayout>
  )
}

const getBannerStatus = localStorageKey => {
  // Don't include visible banner in SSR
  if (typeof window === "undefined") {
    return "dismissed"
  }

  const hasPreviouslyDismissed =
    window.localStorage.getItem(localStorageKey) === "true"

  return hasPreviouslyDismissed ? "dismissed" : "visible"
}

export default Banner
