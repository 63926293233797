import {
  webhookCard,
  visitorAccess,
  siteSettings as siteSettingsText,
} from "@modules/locales/default.json"
import { MdLockOpen, MdLockOutline, MdLock } from "react-icons/md"
import { PreviewProtectionType } from "@modules/graphql/types"

export enum SiteSettingsSection {
  Reports = `reports`,
  General = `general`,
  Integrations = `integrations`,
  Preview = `preview`,
  Danger = `danger`,
  SiteDetails = `site-details`,
  EnvVars = `env-vars`,
  Webhook = `webhook`,
  PreviewWebhook = `preview-webhook`,
  BuildsWebhook = `build-webhook`,
  VisitorAccess = `visitor-access`,
  DataSource = `data-source`,
  ManualIntegrations = `manual-integrations`,
  HostingIntegrations = `deploy-targets`,
  ExperimentalFeatures = `experimental`,
  Builds = `builds`,
  NotificationDeploys = `notification-deploys`,
  DataSources = `data-sources`,
  Hosting = `hosting`,
}

export const SiteSettingsSectionLabel: Record<SiteSettingsSection, string> = {
  [SiteSettingsSection.Reports]: `Reports`,
  [SiteSettingsSection.General]: `General`,
  [SiteSettingsSection.Integrations]: `Integrations`,
  [SiteSettingsSection.Preview]: siteSettingsText.headers.preview,
  [SiteSettingsSection.Danger]: `Danger Zone`,
  [SiteSettingsSection.SiteDetails]: `Site Details`,
  [SiteSettingsSection.EnvVars]: siteSettingsText.headers.environmentVariables,
  [SiteSettingsSection.Webhook]: `Webhook`,
  [SiteSettingsSection.PreviewWebhook]: webhookCard.previewWebhook,
  [SiteSettingsSection.BuildsWebhook]: webhookCard.buildsWebhook,
  [SiteSettingsSection.VisitorAccess]: visitorAccess.visitorAccess,
  [SiteSettingsSection.DataSource]: `Data Source`,
  [SiteSettingsSection.ManualIntegrations]: `Manual`,
  [SiteSettingsSection.HostingIntegrations]: `Hosting`,
  [SiteSettingsSection.ExperimentalFeatures]: `Experimental Features`,
  [SiteSettingsSection.Builds]: `Builds`,
  [SiteSettingsSection.NotificationDeploys]: `Outgoing Notifications`,
  [SiteSettingsSection.DataSources]: siteSettingsText.headers.dataSources,
  [SiteSettingsSection.Hosting]: siteSettingsText.headers.hosting,
}

export type VisitorAccessOption = {
  value: PreviewProtectionType
  label: string
  description: string
  icon: React.ComponentType<React.ComponentPropsWithoutRef<"svg">>
}

export const visitorAccessOptions: VisitorAccessOption[] = [
  {
    value: PreviewProtectionType.Public,
    label: visitorAccess.public,
    description: visitorAccess.anyoneCanVisit,
    icon: MdLockOpen,
  },
  {
    value: PreviewProtectionType.Password,
    label: visitorAccess.passwordProtected,
    description: visitorAccess.visitorHasToKnowPassword,
    icon: MdLockOutline,
  },
  {
    value: PreviewProtectionType.CloudAuth,
    label: visitorAccess.logInRequired,
    description: visitorAccess.visitorMustHaveGatsbyAccount,
    icon: MdLock,
  },
]
