import React from "react"
import { formatDuration } from "./useBuildDuration"

export const useTimer = startMs => {
  const [duration, setDuration] = React.useState("")

  React.useEffect(() => {
    const tick = () => {
      const now = Date.now() / 1000
      const actualDuration = Math.floor(now - startMs / 1000)

      setDuration(formatDuration(actualDuration).duration)
    }

    const id = setInterval(tick, 1000)
    return () => clearInterval(id)
  }, [])

  return duration
}
