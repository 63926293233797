// THIS IS A GENERATED FILE
import * as Types from "../graphql/types"

import gql from "graphql-tag"
import { useApolloFragment } from "apollo-fragment-react"

export type OrganizationDetailsFragment = {
  __typename?: "Organization"
} & Pick<Types.Organization, "id" | "name" | "status" | "enforceCap"> & {
    repositories?: Types.Maybe<
      Array<
        Types.Maybe<
          { __typename?: "SourceControlRepository" } & Pick<
            Types.SourceControlRepository,
            "id" | "name" | "url" | "provider"
          > & {
              sites?: Types.Maybe<
                Array<
                  Types.Maybe<
                    { __typename?: "Site" } & Pick<
                      Types.Site,
                      | "id"
                      | "name"
                      | "publicName"
                      | "branch"
                      | "updatedAt"
                      | "previewUrl"
                      | "previewStatus"
                      | "previewProtection"
                      | "repositoryId"
                      | "buildsEnabled"
                      | "previewBuildsEnabled"
                    > & {
                        latestBuild?: Types.Maybe<
                          { __typename?: "Build" } & Pick<
                            Types.Build,
                            | "id"
                            | "buildStatus"
                            | "createdAt"
                            | "startedAt"
                            | "endedAt"
                            | "duration"
                          >
                        >
                        latestPreview?: Types.Maybe<
                          { __typename?: "Build" } & Pick<
                            Types.Build,
                            "id" | "buildStatus" | "createdAt" | "startedAt"
                          >
                        >
                      }
                  >
                >
              >
            }
        >
      >
    >
    rteSummary?: Types.Maybe<
      { __typename?: "RealTimeEditSummary" } & Pick<
        Types.RealTimeEditSummary,
        "id" | "RTEsInCurrentDay" | "RTEsInCurrentPeriod" | "limit"
      >
    >
    billing?: Types.Maybe<
      { __typename?: "BillingInformation" } & {
        creditCard?: Types.Maybe<
          { __typename?: "CreditCard" } & CreditCardFieldsFragment
        >
        plan?: Types.Maybe<
          { __typename?: "SubscriptionPlan" } & BillingPlanFieldsFragment
        >
      } & BillingFieldsFragment
    >
    permissions?: Types.Maybe<
      { __typename?: "PermissionsDigest" } & PermissionsForOrganizationFragment
    >
  }

export type BillingFieldsFragment = {
  __typename?: "BillingInformation"
} & Pick<
  Types.BillingInformation,
  | "id"
  | "status"
  | "trialDaysRemaining"
  | "trialStart"
  | "trialEnd"
  | "nextBillingDate"
  | "canceledAt"
>

export type CreditCardFieldsFragment = { __typename?: "CreditCard" } & Pick<
  Types.CreditCard,
  "name" | "lastFour" | "brand" | "expMonth" | "expYear"
>

export type BillingPlanFieldsFragment = {
  __typename?: "SubscriptionPlan"
} & Pick<
  Types.SubscriptionPlan,
  | "id"
  | "planId"
  | "name"
  | "interval"
  | "amount"
  | "formattedAmount"
  | "features"
  | "nextBillingDate"
  | "buildsTier"
  | "hostingTier"
>

export type PermissionsForOrganizationFragment = {
  __typename?: "PermissionsDigest"
} & Pick<Types.PermissionsDigest, "id" | "role"> & {
    billing?: Types.Maybe<
      { __typename?: "ResourcePermissionMap" } & Pick<
        Types.ResourcePermissionMap,
        "read" | "create" | "edit"
      >
    >
    organization?: Types.Maybe<
      { __typename?: "ResourcePermissionMap" } & Pick<
        Types.ResourcePermissionMap,
        "create" | "read" | "delete" | "edit"
      >
    >
    sites?: Types.Maybe<
      { __typename?: "ResourcePermissionMap" } & Pick<
        Types.ResourcePermissionMap,
        "edit" | "read" | "delete" | "create"
      >
    >
    contributor?: Types.Maybe<
      { __typename?: "ContributorPermissionMap" } & Pick<
        Types.ContributorPermissionMap,
        "read" | "invite" | "delete" | "deleteSelf"
      >
    >
  }

export type OrganizationPermissionsFieldsFragment = {
  __typename?: "Organization"
} & Pick<Types.Organization, "id"> & {
    permissions?: Types.Maybe<
      { __typename?: "PermissionsDigest" } & PermissionsForOrganizationFragment
    >
  }

export type OrganizationSitesNameFieldsFragment = {
  __typename?: "Organization"
} & Pick<Types.Organization, "id"> & {
    repositories?: Types.Maybe<
      Array<
        Types.Maybe<
          { __typename?: "SourceControlRepository" } & Pick<
            Types.SourceControlRepository,
            "id"
          > & {
              sites?: Types.Maybe<
                Array<
                  Types.Maybe<
                    { __typename?: "Site" } & Pick<
                      Types.Site,
                      "id" | "name" | "publicName"
                    >
                  >
                >
              >
            }
        >
      >
    >
  }

export const BillingFieldsFragmentDoc = gql`
  fragment billingFields on BillingInformation {
    id
    status
    trialDaysRemaining
    trialStart
    trialEnd
    nextBillingDate
    canceledAt
  }
`
export const CreditCardFieldsFragmentDoc = gql`
  fragment creditCardFields on CreditCard {
    name
    lastFour
    brand
    expMonth
    expYear
  }
`
export const BillingPlanFieldsFragmentDoc = gql`
  fragment billingPlanFields on SubscriptionPlan {
    id
    planId
    name
    interval
    amount
    formattedAmount
    features
    nextBillingDate
    buildsTier
    hostingTier
  }
`
export const PermissionsForOrganizationFragmentDoc = gql`
  fragment permissionsForOrganization on PermissionsDigest {
    id
    role
    billing {
      read
      create
      edit
    }
    organization {
      create
      read
      delete
      edit
    }
    sites {
      edit
      read
      delete
      create
    }
    contributor {
      read
      invite
      delete
      deleteSelf
    }
  }
`
export const OrganizationDetailsFragmentDoc = gql`
  fragment organizationDetails on Organization {
    id
    name
    status
    enforceCap
    repositories {
      id
      name
      url
      provider
      sites {
        id
        name
        publicName
        branch
        updatedAt
        previewUrl
        previewStatus
        previewProtection
        repositoryId
        buildsEnabled
        previewBuildsEnabled
        latestBuild {
          id
          buildStatus
          createdAt
          startedAt
          endedAt
          duration
        }
        latestPreview {
          id
          buildStatus
          createdAt
          startedAt
        }
      }
    }
    rteSummary {
      id
      RTEsInCurrentDay
      RTEsInCurrentPeriod
      limit
    }
    billing {
      ...billingFields
      creditCard {
        ...creditCardFields
      }
      plan {
        ...billingPlanFields
      }
    }
    permissions {
      ...permissionsForOrganization
    }
  }
  ${BillingFieldsFragmentDoc}
  ${CreditCardFieldsFragmentDoc}
  ${BillingPlanFieldsFragmentDoc}
  ${PermissionsForOrganizationFragmentDoc}
`
export const OrganizationPermissionsFieldsFragmentDoc = gql`
  fragment OrganizationPermissionsFields on Organization {
    id
    permissions {
      ...permissionsForOrganization
    }
  }
  ${PermissionsForOrganizationFragmentDoc}
`
export const OrganizationSitesNameFieldsFragmentDoc = gql`
  fragment OrganizationSitesNameFields on Organization {
    id
    repositories {
      id
      sites {
        id
        name
        publicName
      }
    }
  }
`

/**
 * __useBillingFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useBillingFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useBillingFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useBillingFieldsFragment('fragment-id');
 */
export function useBillingFieldsFragment(id: string) {
  return useApolloFragment<BillingFieldsFragment>(BillingFieldsFragmentDoc, id)
}
export type BillingFieldsFragmentHookResult = ReturnType<
  typeof useBillingFieldsFragment
>

/**
 * __useCreditCardFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useCreditCardFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useCreditCardFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useCreditCardFieldsFragment('fragment-id');
 */
export function useCreditCardFieldsFragment(id: string) {
  return useApolloFragment<CreditCardFieldsFragment>(
    CreditCardFieldsFragmentDoc,
    id
  )
}
export type CreditCardFieldsFragmentHookResult = ReturnType<
  typeof useCreditCardFieldsFragment
>

/**
 * __useBillingPlanFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useBillingPlanFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useBillingPlanFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useBillingPlanFieldsFragment('fragment-id');
 */
export function useBillingPlanFieldsFragment(id: string) {
  return useApolloFragment<BillingPlanFieldsFragment>(
    BillingPlanFieldsFragmentDoc,
    id
  )
}
export type BillingPlanFieldsFragmentHookResult = ReturnType<
  typeof useBillingPlanFieldsFragment
>

/**
 * __usePermissionsForOrganizationFragment__
 * To read a fragment data from Apollo Cache, call `usePermissionsForOrganizationFragment` and pass it the ID of the cached object.
 * When your component renders, `usePermissionsForOrganizationFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = usePermissionsForOrganizationFragment('fragment-id');
 */
export function usePermissionsForOrganizationFragment(id: string) {
  return useApolloFragment<PermissionsForOrganizationFragment>(
    PermissionsForOrganizationFragmentDoc,
    id
  )
}
export type PermissionsForOrganizationFragmentHookResult = ReturnType<
  typeof usePermissionsForOrganizationFragment
>

/**
 * __useOrganizationDetailsFragment__
 * To read a fragment data from Apollo Cache, call `useOrganizationDetailsFragment` and pass it the ID of the cached object.
 * When your component renders, `useOrganizationDetailsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useOrganizationDetailsFragment('fragment-id');
 */
export function useOrganizationDetailsFragment(id: string) {
  return useApolloFragment<OrganizationDetailsFragment>(
    OrganizationDetailsFragmentDoc,
    id
  )
}
export type OrganizationDetailsFragmentHookResult = ReturnType<
  typeof useOrganizationDetailsFragment
>

/**
 * __useOrganizationPermissionsFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useOrganizationPermissionsFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useOrganizationPermissionsFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useOrganizationPermissionsFieldsFragment('fragment-id');
 */
export function useOrganizationPermissionsFieldsFragment(id: string) {
  return useApolloFragment<OrganizationPermissionsFieldsFragment>(
    OrganizationPermissionsFieldsFragmentDoc,
    id
  )
}
export type OrganizationPermissionsFieldsFragmentHookResult = ReturnType<
  typeof useOrganizationPermissionsFieldsFragment
>

/**
 * __useOrganizationSitesNameFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useOrganizationSitesNameFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useOrganizationSitesNameFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useOrganizationSitesNameFieldsFragment('fragment-id');
 */
export function useOrganizationSitesNameFieldsFragment(id: string) {
  return useApolloFragment<OrganizationSitesNameFieldsFragment>(
    OrganizationSitesNameFieldsFragmentDoc,
    id
  )
}
export type OrganizationSitesNameFieldsFragmentHookResult = ReturnType<
  typeof useOrganizationSitesNameFieldsFragment
>
