import React from "react"

import { SkipNavTarget } from "@modules/a11y"

export default function PageContent({ children, ...rest }) {
  return (
    <main
      css={{
        marginTop: `5rem`,
      }}
      {...rest}
    >
      <SkipNavTarget />
      {children}
    </main>
  )
}

const positionerInnerCss = theme => ({
  margin: `0 auto`,
  maxWidth: `90rem`,
  padding: `0 ${theme.space[8]}`,
  [theme.mediaQueries.phablet]: {
    width: `90%`,
  },
})

PageContent.Positioner = function({ children, ...rest }) {
  return (
    <div
      css={{
        width: `100%`,
        position: `relative`,
      }}
      {...rest}
    >
      <div css={positionerInnerCss}>{children}</div>
    </div>
  )
}
