import { SiteDetailsTab, tabsConfig } from "../constants"

export function getSiteDetailsTabs(flags, options = {}) {
  const settingsTabs = options?.permissions?.sites?.edit
    ? [tabsConfig[SiteDetailsTab.Settings]]
    : []

  if (!options.buildsEnabled) {
    return [tabsConfig[SiteDetailsTab.CmsPreview], ...settingsTabs]
  }

  if (flags.hostingFunctions) {
    return [
      tabsConfig[SiteDetailsTab.Deploys],
      tabsConfig[SiteDetailsTab.Functions],
      tabsConfig[SiteDetailsTab.CmsPreview],
      ...settingsTabs,
    ]
  }

  return [
    tabsConfig[SiteDetailsTab.Deploys],
    ...settingsTabs,
    tabsConfig[SiteDetailsTab.CmsPreview],
  ]
}

export function getPathToSiteDetails(siteId, organizationId) {
  return `/dashboard/${organizationId}/sites/${siteId}`
}

export function getSiteDetailsTabBasePath(tab, siteId, organizationId) {
  return `${getPathToSiteDetails(siteId, organizationId)}/${tab}`
}

export function getPathToSiteDetailsTab(tab, siteId, organizationId) {
  const basePath = getSiteDetailsTabBasePath(tab, siteId, organizationId)
  const tabConfig = tabsConfig[tab]

  if (!tabConfig || !tabConfig.hasSections || !tabConfig.initialSection) {
    return basePath
  }
  return `${basePath}/${tabConfig.initialSection}`
}

export function getPathToBuildDetails(buildId, siteId, organizationId) {
  return `${getPathToSiteDetailsTab(
    SiteDetailsTab.Builds,
    siteId,
    organizationId
  )}/${buildId}/details`
}

export function getPathToBuildsForBranch(branch, siteId, organizationId) {
  const encodedBranchName = encodeURIComponent(branch)
  return `/dashboard/${organizationId}/sites/${siteId}/branches/${encodedBranchName}/builds`
}

export function getPathToBuildsForPR(pullRequestId, siteId, organizationId) {
  return `/dashboard/${organizationId}/sites/${siteId}/pull-requests/${pullRequestId}/builds`
}

export function getPathToSiteSettingsSection(section, siteId, organizationId) {
  return `${getPathToSiteDetailsTab(
    SiteDetailsTab.Settings,
    siteId,
    organizationId
  )}#${section}`
}
