import * as React from "react"
import ScalableSvg from "@modules/ui/components/ScalableSvg"

export function AzureLogoIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <ScalableSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M11.103 19.5966C14.1864 19.0519 16.7332 18.6012 16.7624 18.595L16.8156 18.5837L13.9045 15.1211C12.3035 13.2166 10.9935 11.6511 10.9935 11.642C10.9935 11.625 13.9994 3.34728 14.0163 3.31783C14.0219 3.30798 16.0676 6.8397 18.975 11.8789C21.697 16.5968 23.9412 20.4867 23.9621 20.5231L24 20.5894L14.7484 20.5882L5.49674 20.587L11.103 19.5966ZM0 18.5407C0 18.5358 1.37168 16.1547 3.04819 13.2493L6.09637 7.96673L9.64868 4.98563C11.6025 3.34602 13.2063 2.00252 13.2127 2.00007C13.2191 1.99762 13.1934 2.0623 13.1556 2.14382C13.1177 2.22533 11.3819 5.94841 9.29826 10.4173L5.50976 18.5426L2.75488 18.5461C1.2397 18.548 0 18.5456 0 18.5407Z"
        fill="#0089D6"
      />
    </ScalableSvg>
  )
}
