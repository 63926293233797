import * as React from "react"
import { EmptyState, Link, RawLogs, Spacer, ThemeCss } from "gatsby-interface"
import { MdArrowBack } from "react-icons/md"
import { SiteDetailsTab } from "@modules/site/details/constants"
import { getPathToSiteDetailsTab } from "@modules/site/details/utils"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"
import Loading from "@modules/ui/components/Loading"
import {
  StandardPageContentSection,
  StandardSingleColumn,
} from "@modules/ui/layouts/Containers"
import { functionsView as text } from "@modules/locales/default.json"
import { useFunctionDetailsQuery } from "../queries.generated"
import { FunctionCard } from "./FunctionCard"
import { FunctionInvokationStatus } from "@modules/site/details/components/mocks/functions.mocks"
import { interpolateMessage } from "@modules/locales"

export type FunctionDetailsProps = {
  organizationId: string
  siteId: string
  functionId: string
}

export function FunctionDetails({
  organizationId,
  siteId,
  functionId,
}: FunctionDetailsProps) {
  const { data, loading, error } = useFunctionDetailsQuery({
    variables: {
      siteId,
      functionId,
    },
  })

  if (loading) {
    return (
      <FunctionDetailsLayout organizationId={organizationId} siteId={siteId}>
        <Loading />
      </FunctionDetailsLayout>
    )
  }

  if (error) {
    return (
      <FunctionDetailsLayout organizationId={organizationId} siteId={siteId}>
        <ErrorAlert>{error.message}</ErrorAlert>
      </FunctionDetailsLayout>
    )
  }

  const functionEntry = data?.functionEntry
  const functionLogs = data?.functionLogs ?? []

  if (!functionEntry) {
    return null
  }

  return (
    <FunctionDetailsLayout organizationId={organizationId} siteId={siteId}>
      <FunctionCard
        organizationId={organizationId}
        siteId={siteId}
        {...functionEntry}
        name={functionEntry.path}
        // @TODO use proper value instead of mocked one
        lastInvocationStatus={FunctionInvokationStatus.Success}
        // @TODO use proper value instead of mocked one
        lastInvokedAt={new Date().toString()}
        showLinkToDetails={false}
      />
      <Spacer size={5} />
      {functionLogs.length === 0 ? (
        <EmptyState
          variant="BORDERED"
          heading={text.headers.noFunctionLogs}
          text={text.messages.noFunctionLogsDescription}
        />
      ) : (
        <RawLogs
          logItems={functionLogs}
          aria-label={interpolateMessage<"functionName">(
            text.headers.functionLogsTitle,
            { functionName: functionEntry.path }
          )}
        />
      )}
    </FunctionDetailsLayout>
  )
}

const linkCss: ThemeCss = _theme => ({
  display: `flex`,
  alignItems: `center`,
  textDecoration: `none`,
  textTransform: `capitalize`,
})

type FunctionDetailsLayoutProps = {
  organizationId: string
  siteId: string
  children: React.ReactNode
}

function FunctionDetailsLayout({
  organizationId,
  siteId,
  children,
}: FunctionDetailsLayoutProps) {
  return (
    <StandardPageContentSection>
      <StandardSingleColumn>
        <Link
          variant="SIMPLE"
          to={getPathToSiteDetailsTab(
            SiteDetailsTab.Functions,
            siteId,
            organizationId
          )}
          css={linkCss}
        >
          <MdArrowBack />
          <Spacer size={3} direction="horizontal" />
          {text.actions.goBackToFunctions}
        </Link>
        <Spacer size={5} />
        {children}
      </StandardSingleColumn>
    </StandardPageContentSection>
  )
}
