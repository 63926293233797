import * as React from "react"
import * as qs from "query-string"
import { Modal, ModalCard } from "gatsby-interface"
import { useTracker } from "@modules/analytics"
import {
  InvitationTokenError,
  InvitationMismatchError,
  OAuthError,
  InvitationRequired,
  UserBlocked,
  InvitationExpiredError,
  PreviewAccessError,
  InvitationAcceptedError,
  TicketNotFoundError,
} from "./ErrorTemplate"
import { QueryError } from "./Errors.helpers"
import { navigate } from "gatsby"

export type LoginErrorMessageProps = {
  queryString: string
}

export function LoginErrorMessage({ queryString }: LoginErrorMessageProps) {
  const { trackPageViewed } = useTracker()
  const query = qs.parse(queryString)

  let Component: React.ComponentType<{
    closeModal: () => void
    query: qs.ParsedQuery
  }> | null

  switch (query.error) {
    case QueryError.InvitationError:
      Component = InvitationTokenError
      break
    case QueryError.InvitationMismatch:
      Component = InvitationMismatchError
      break
    case QueryError.OauthError:
      Component = OAuthError
      break
    case QueryError.InvitationRequired:
      Component = InvitationRequired
      break
    case QueryError.UserBlocked:
      Component = UserBlocked
      break
    case QueryError.InvitationExpired:
      Component = InvitationExpiredError
      break
    case QueryError.InvitationAccepted:
      Component = InvitationAcceptedError
      break
    case QueryError.PreviewAccessError:
      Component = PreviewAccessError
      break
    case QueryError.TicketNotFoundError:
      Component = TicketNotFoundError
      break
    default:
      Component = null
  }

  React.useEffect(() => {
    if (Component) {
      trackPageViewed(`Login error: ${query.error}`)
    }
  }, [])

  return Component ? (
    <Modal
      isOpen={Boolean(Component)}
      aria-label={`Something went wrong during the authentication process`}
      onDismiss={() => navigate(`/dashboard/login`)}
      type="error"
    >
      <ModalCard>
        <Component
          closeModal={() => navigate(`/dashboard/login`)}
          query={query}
        />
      </ModalCard>
    </Modal>
  ) : null
}
