import { navigate } from "@reach/router"
import { useGenericError } from "@modules/modal"
import { UserOrganizationsDocument } from "@modules/header/queries.generated"
import { useAcceptResourceInvitationMutation } from "@modules/invitation/queries.generated"

export function useAcceptInvite() {
  const [mutate] = useAcceptResourceInvitationMutation({
    refetchQueries: [{ query: UserOrganizationsDocument }],
  })

  const throwError = useGenericError()

  const acceptInvite = (
    invitationId: string,
    setRedirectPath: (value: string) => void,
    setSuccess: (value: boolean) => void,
    setError: (value: boolean) => void
  ) => {
    const showError = (message: string) => {
      setError(true)

      return throwError({ message, onDismiss: () => navigate(`/dashboard`) })
    }

    mutate({
      variables: {
        invitationId,
      },
    })
      .then(({ data }) => {
        const resultData = data?.acceptResourceInvitation
        const mutationResult = resultData?.mutationResult

        if (mutationResult?.success) {
          const redirectPath = resultData?.redirectPath
          if (redirectPath) {
            setRedirectPath(redirectPath)
          }
          return setSuccess(true)
        }

        return showError(mutationResult?.message ?? `Something went wrong`)
      })
      .catch(e => showError(e.message))
  }

  return [acceptInvite] as const
}
