import React from "react"
import ScalableSvg from "@modules/ui/components/ScalableSvg"

export default function SanityLogoIcon(props) {
  return (
    <ScalableSvg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <circle cx="8" cy="8" r="8" fill="#FF4433" />
      <path
        opacity="0.6"
        d="M10.1243 9.61903C10.8739 10.0969 11.2133 10.7716 11.2133 11.7415C10.5769 12.5427 9.48779 12.9784 8.20071 12.9784C6.03671 12.9784 4.49505 11.9102 4.16974 10.0688H6.24887C6.5176 10.9122 7.22479 11.3058 8.18656 11.3058C9.33221 11.3198 10.1101 10.7013 10.1243 9.61903Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M6.06499 6.2737C5.3578 5.85202 4.94763 5.093 4.97592 4.27775C5.5841 3.49061 6.63074 3.01271 7.90368 3.01271C10.1242 3.01271 11.3972 4.17936 11.7225 5.80985H9.71408C9.48778 5.16328 8.93617 4.65726 7.93197 4.65726C6.8429 4.67132 6.10742 5.28978 6.06499 6.2737Z"
        fill="white"
      />
      <path
        d="M4.97594 4.29182C4.97594 5.62714 5.81042 6.42833 7.47938 6.85001L9.24735 7.25764C10.8315 7.62309 11.7932 8.52268 11.7932 9.9845C11.8074 10.617 11.5952 11.2355 11.2133 11.7415C11.2133 10.2797 10.4496 9.49254 8.62503 9.01464L6.88535 8.62107C5.48511 8.31184 4.41019 7.56687 4.41019 5.97854C4.41019 5.37414 4.6082 4.76973 4.97594 4.29182Z"
        fill="white"
      />
    </ScalableSvg>
  )
}
