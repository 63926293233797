import React from "react"
import ScalableSvg from "@modules/ui/components/ScalableSvg"

export default function CosmicJsLogoIcon(props) {
  return (
    <ScalableSvg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#CosmicJsLogoIcon__clip)">
        <path
          d="M6.19884 6.02071C6.57611 5.70148 6.92437 5.38224 7.30165 5.09203C7.01144 4.88888 6.75024 4.71475 6.48905 4.54062C6.16981 4.80181 5.85058 5.09203 5.53134 5.38224C5.85058 5.49833 6.08275 5.7305 6.19884 6.02071Z"
          fill="#29ABE2"
        />
        <path
          d="M5.26698 7.47177C5.95619 7.47358 6.5163 6.94233 6.51804 6.28518C6.51977 5.62802 5.96246 5.09382 5.27325 5.09201C4.58404 5.09019 4.02392 5.62144 4.02219 6.2786C4.02046 6.93575 4.57777 7.46995 5.26698 7.47177Z"
          fill="#29ABE2"
        />
        <path
          d="M6.40202 12.9569C4.68975 13.8855 3.20966 14.1177 2.54217 13.4792C1.61348 12.5796 2.4551 10.2288 4.48661 7.84907C4.16737 7.76201 3.90618 7.52984 3.76107 7.23962C0.800878 10.5481 -0.53411 13.8855 0.771857 15.1335C1.84565 16.1782 4.42856 15.4817 7.27267 13.6243C6.98245 13.4212 6.69224 13.189 6.40202 12.9569Z"
          fill="#29ABE2"
        />
        <path
          d="M8.43348 5.03398C8.52054 4.71475 8.75271 4.45355 9.04293 4.30845C5.61839 1.49337 2.13582 0.245443 0.829851 1.49337C-0.243944 2.53814 0.452571 5.00496 2.39701 7.73298C2.62918 7.44276 2.86135 7.18157 3.09353 6.89136C2.13582 5.23713 1.87462 3.81508 2.54212 3.14759C3.49983 2.24792 5.93763 3.08955 8.43348 5.03398Z"
          fill="#29ABE2"
        />
        <path
          d="M10.7552 5.84659C10.6391 6.1368 10.378 6.36897 10.0877 6.48506C10.5231 6.92038 10.9294 7.32668 11.2776 7.76201C11.4808 7.50081 11.6839 7.23962 11.829 6.97843C11.5098 6.60115 11.1325 6.22387 10.7552 5.84659Z"
          fill="#29ABE2"
        />
        <path
          d="M10.0587 7.00744C10.7552 7.00744 11.3066 6.48506 11.3066 5.81756C11.3066 5.15007 10.7552 4.62769 10.0587 4.62769C9.3622 4.62769 8.81079 5.15007 8.81079 5.81756C8.81079 6.45604 9.3622 7.00744 10.0587 7.00744Z"
          fill="#29ABE2"
        />
        <path
          d="M13.2801 3.17661C14.2088 4.07627 13.3672 6.42701 11.3357 8.80677C11.6549 8.89384 11.9161 9.12601 12.0612 9.41622C14.9924 6.10778 16.3273 2.74129 15.0214 1.49336C13.9476 0.477612 11.3647 1.17413 8.54959 3.0315C8.83981 3.23465 9.13002 3.46682 9.42024 3.69899C11.1325 2.77031 12.6126 2.53814 13.2801 3.17661Z"
          fill="#29ABE2"
        />
        <path
          d="M9.82653 10.432C9.39121 10.8383 8.92687 11.2156 8.49155 11.5638C8.78176 11.767 9.04295 11.9411 9.33317 12.1152C9.73947 11.796 10.1167 11.4477 10.5231 11.0704C10.2038 10.9544 9.97164 10.7222 9.82653 10.432Z"
          fill="#29ABE2"
        />
        <path
          d="M10.5408 9.1992C9.85156 9.1973 9.29137 9.72849 9.28956 10.3856C9.28775 11.0428 9.845 11.5771 10.5342 11.579C11.2234 11.5809 11.7836 11.0497 11.7854 10.3925C11.7872 9.73537 11.23 9.2011 10.5408 9.1992Z"
          fill="#29ABE2"
        />
        <path
          d="M5.73454 10.1998C5.29922 9.76447 4.89292 9.35817 4.54466 8.92285C4.34151 9.18404 4.13836 9.44524 3.99326 9.70643C4.34151 10.0837 4.68977 10.461 5.06705 10.8383C5.18313 10.548 5.4153 10.3159 5.73454 10.1998Z"
          fill="#29ABE2"
        />
        <path
          d="M13.4252 8.95187C13.193 9.24209 12.9609 9.50328 12.7287 9.7935C13.6864 11.4477 13.9476 12.8408 13.2511 13.5082C12.2934 14.4079 9.85557 13.5663 7.35973 11.6509C7.27266 11.9701 7.04049 12.2313 6.75027 12.3764C10.2038 15.1915 13.6864 16.4684 14.9924 15.2205C16.0662 14.1467 15.3697 11.6509 13.4252 8.95187Z"
          fill="#29ABE2"
        />
        <path
          d="M7.01688 10.8539C7.01861 10.1968 6.46129 9.66256 5.77208 9.66075C5.08287 9.65894 4.52276 10.1902 4.52103 10.8474C4.51931 11.5045 5.07663 12.0387 5.76584 12.0405C6.45505 12.0423 7.01516 11.5111 7.01688 10.8539Z"
          fill="#29ABE2"
        />
      </g>
      <defs>
        <clipPath id="CosmicJsLogoIcon__clip">
          <rect
            width="16"
            height="14.7692"
            fill="white"
            transform="translate(0 1)"
          />
        </clipPath>
      </defs>
    </ScalableSvg>
  )
}
