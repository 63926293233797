// THIS IS A GENERATED FILE
import * as Types from "../../../graphql/types"

import { PermissionsForSiteFragment } from "../../shared/fragments.generated"
import gql from "graphql-tag"
import { PermissionsForSiteFragmentDoc } from "../../shared/fragments.generated"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type RepositoriesForInstallationQueryVariables = Types.Exact<{
  installationId: Types.Scalars["String"]
  provider: Types.SourceControlProvider
  isUserTeam?: Types.Maybe<Types.Scalars["Boolean"]>
}>

export type RepositoriesForInstallationQuery = { __typename?: "Query" } & {
  repositoriesForInstallation: Array<
    Types.Maybe<
      { __typename?: "Repository" } & Pick<
        Types.Repository,
        "id" | "name" | "nameWithOwner" | "isPrivate"
      >
    >
  >
}

export type SourceOrganizationRepositoryUpdatedSubscriptionVariables = Types.Exact<{
  organizationInstallationId: Types.Scalars["String"]
}>

export type SourceOrganizationRepositoryUpdatedSubscription = {
  __typename?: "Subscription"
} & Pick<Types.Subscription, "sourceOrganizationRepositoryUpdated">

export type RepositoryFromSiteQueryVariables = Types.Exact<{
  siteId: Types.Scalars["UUID"]
}>

export type RepositoryFromSiteQuery = { __typename?: "Query" } & {
  repositoryFromSite?: Types.Maybe<
    { __typename?: "Repository" } & Pick<
      Types.Repository,
      "id" | "nameWithOwner"
    >
  >
}

export type SourceProviderTeamsForUserQueryVariables = Types.Exact<{
  provider: Types.SourceControlProvider
}>

export type SourceProviderTeamsForUserQuery = { __typename?: "Query" } & {
  sourceProviderTeamsForUser: Array<
    Types.Maybe<
      { __typename?: "SourceProviderTeam" } & Pick<
        Types.SourceProviderTeam,
        "id" | "name" | "isUserTeam"
      >
    >
  >
}

export type AvailableSourceProvidersQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type AvailableSourceProvidersQuery = { __typename?: "Query" } & {
  availableSourceProviders?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "SourceControl" } & Pick<
          Types.SourceControl,
          "source" | "connected"
        >
      >
    >
  >
}

export type BranchesForSourceRepositoryQueryVariables = Types.Exact<{
  endCursor?: Types.Maybe<Types.Scalars["String"]>
  searchText?: Types.Maybe<Types.Scalars["String"]>
  nameWithOwner: Types.Scalars["String"]
  provider: Types.SourceControlProvider
}>

export type BranchesForSourceRepositoryQuery = { __typename?: "Query" } & {
  branchesForSourceRepository: { __typename?: "BranchListResults" } & Pick<
    Types.BranchListResults,
    "branches"
  > & {
      pageInfo?: Types.Maybe<
        { __typename?: "GithubPagination" } & Pick<
          Types.GithubPagination,
          "lastId" | "hasNextPage"
        >
      >
    }
}

export type ImportedSiteDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
}>

export type ImportedSiteDetailsQuery = { __typename?: "Query" } & {
  siteDetails?: Types.Maybe<
    { __typename?: "Site" } & Pick<
      Types.Site,
      | "id"
      | "organizationId"
      | "name"
      | "previewUrl"
      | "branch"
      | "previewStatus"
      | "buildsEnabled"
    > & {
        permissions?: Types.Maybe<
          { __typename?: "PermissionsDigest" } & PermissionsForSiteFragment
        >
        repository?: Types.Maybe<
          { __typename?: "SourceControlRepository" } & Pick<
            Types.SourceControlRepository,
            "id" | "provider" | "nameWithOwner"
          >
        >
      }
  >
}

export type PublishSiteMutationVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
  environmentVariables: Array<Types.TagInput>
}>

export type PublishSiteMutation = { __typename?: "Mutation" } & {
  publishSite?: Types.Maybe<
    { __typename?: "Site" } & Pick<Types.Site, "id" | "organizationId">
  >
}

export const RepositoriesForInstallationDocument = gql`
  query RepositoriesForInstallation(
    $installationId: String!
    $provider: SourceControlProvider!
    $isUserTeam: Boolean
  ) {
    repositoriesForInstallation: repositoriesForSourceProviderTeam(
      teamId: $installationId
      provider: $provider
      isUserTeam: $isUserTeam
    ) {
      id
      name
      nameWithOwner
      isPrivate
    }
  }
`

/**
 * __useRepositoriesForInstallationQuery__
 *
 * To run a query within a React component, call `useRepositoriesForInstallationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRepositoriesForInstallationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRepositoriesForInstallationQuery({
 *   variables: {
 *      installationId: // value for 'installationId'
 *      provider: // value for 'provider'
 *      isUserTeam: // value for 'isUserTeam'
 *   },
 * });
 */
export function useRepositoriesForInstallationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RepositoriesForInstallationQuery,
    RepositoriesForInstallationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    RepositoriesForInstallationQuery,
    RepositoriesForInstallationQueryVariables
  >(RepositoriesForInstallationDocument, baseOptions)
}
export function useRepositoriesForInstallationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RepositoriesForInstallationQuery,
    RepositoriesForInstallationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RepositoriesForInstallationQuery,
    RepositoriesForInstallationQueryVariables
  >(RepositoriesForInstallationDocument, baseOptions)
}
export type RepositoriesForInstallationQueryHookResult = ReturnType<
  typeof useRepositoriesForInstallationQuery
>
export type RepositoriesForInstallationLazyQueryHookResult = ReturnType<
  typeof useRepositoriesForInstallationLazyQuery
>
export type RepositoriesForInstallationQueryResult = ApolloReactCommon.QueryResult<
  RepositoriesForInstallationQuery,
  RepositoriesForInstallationQueryVariables
>
export const SourceOrganizationRepositoryUpdatedDocument = gql`
  subscription SourceOrganizationRepositoryUpdated(
    $organizationInstallationId: String!
  ) {
    sourceOrganizationRepositoryUpdated(
      organizationInstallationId: $organizationInstallationId
    )
  }
`

/**
 * __useSourceOrganizationRepositoryUpdatedSubscription__
 *
 * To run a query within a React component, call `useSourceOrganizationRepositoryUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSourceOrganizationRepositoryUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceOrganizationRepositoryUpdatedSubscription({
 *   variables: {
 *      organizationInstallationId: // value for 'organizationInstallationId'
 *   },
 * });
 */
export function useSourceOrganizationRepositoryUpdatedSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    SourceOrganizationRepositoryUpdatedSubscription,
    SourceOrganizationRepositoryUpdatedSubscriptionVariables
  >
) {
  return ApolloReactHooks.useSubscription<
    SourceOrganizationRepositoryUpdatedSubscription,
    SourceOrganizationRepositoryUpdatedSubscriptionVariables
  >(SourceOrganizationRepositoryUpdatedDocument, baseOptions)
}
export type SourceOrganizationRepositoryUpdatedSubscriptionHookResult = ReturnType<
  typeof useSourceOrganizationRepositoryUpdatedSubscription
>
export type SourceOrganizationRepositoryUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<
  SourceOrganizationRepositoryUpdatedSubscription
>
export const RepositoryFromSiteDocument = gql`
  query repositoryFromSite($siteId: UUID!) {
    repositoryFromSite(siteId: $siteId) {
      id
      nameWithOwner
    }
  }
`

/**
 * __useRepositoryFromSiteQuery__
 *
 * To run a query within a React component, call `useRepositoryFromSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useRepositoryFromSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRepositoryFromSiteQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useRepositoryFromSiteQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RepositoryFromSiteQuery,
    RepositoryFromSiteQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    RepositoryFromSiteQuery,
    RepositoryFromSiteQueryVariables
  >(RepositoryFromSiteDocument, baseOptions)
}
export function useRepositoryFromSiteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RepositoryFromSiteQuery,
    RepositoryFromSiteQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    RepositoryFromSiteQuery,
    RepositoryFromSiteQueryVariables
  >(RepositoryFromSiteDocument, baseOptions)
}
export type RepositoryFromSiteQueryHookResult = ReturnType<
  typeof useRepositoryFromSiteQuery
>
export type RepositoryFromSiteLazyQueryHookResult = ReturnType<
  typeof useRepositoryFromSiteLazyQuery
>
export type RepositoryFromSiteQueryResult = ApolloReactCommon.QueryResult<
  RepositoryFromSiteQuery,
  RepositoryFromSiteQueryVariables
>
export const SourceProviderTeamsForUserDocument = gql`
  query sourceProviderTeamsForUser($provider: SourceControlProvider!) {
    sourceProviderTeamsForUser(provider: $provider) {
      id
      name
      isUserTeam
    }
  }
`

/**
 * __useSourceProviderTeamsForUserQuery__
 *
 * To run a query within a React component, call `useSourceProviderTeamsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceProviderTeamsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceProviderTeamsForUserQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useSourceProviderTeamsForUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SourceProviderTeamsForUserQuery,
    SourceProviderTeamsForUserQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SourceProviderTeamsForUserQuery,
    SourceProviderTeamsForUserQueryVariables
  >(SourceProviderTeamsForUserDocument, baseOptions)
}
export function useSourceProviderTeamsForUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SourceProviderTeamsForUserQuery,
    SourceProviderTeamsForUserQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SourceProviderTeamsForUserQuery,
    SourceProviderTeamsForUserQueryVariables
  >(SourceProviderTeamsForUserDocument, baseOptions)
}
export type SourceProviderTeamsForUserQueryHookResult = ReturnType<
  typeof useSourceProviderTeamsForUserQuery
>
export type SourceProviderTeamsForUserLazyQueryHookResult = ReturnType<
  typeof useSourceProviderTeamsForUserLazyQuery
>
export type SourceProviderTeamsForUserQueryResult = ApolloReactCommon.QueryResult<
  SourceProviderTeamsForUserQuery,
  SourceProviderTeamsForUserQueryVariables
>
export const AvailableSourceProvidersDocument = gql`
  query availableSourceProviders {
    availableSourceProviders {
      source
      connected
    }
  }
`

/**
 * __useAvailableSourceProvidersQuery__
 *
 * To run a query within a React component, call `useAvailableSourceProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableSourceProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableSourceProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableSourceProvidersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AvailableSourceProvidersQuery,
    AvailableSourceProvidersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AvailableSourceProvidersQuery,
    AvailableSourceProvidersQueryVariables
  >(AvailableSourceProvidersDocument, baseOptions)
}
export function useAvailableSourceProvidersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AvailableSourceProvidersQuery,
    AvailableSourceProvidersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AvailableSourceProvidersQuery,
    AvailableSourceProvidersQueryVariables
  >(AvailableSourceProvidersDocument, baseOptions)
}
export type AvailableSourceProvidersQueryHookResult = ReturnType<
  typeof useAvailableSourceProvidersQuery
>
export type AvailableSourceProvidersLazyQueryHookResult = ReturnType<
  typeof useAvailableSourceProvidersLazyQuery
>
export type AvailableSourceProvidersQueryResult = ApolloReactCommon.QueryResult<
  AvailableSourceProvidersQuery,
  AvailableSourceProvidersQueryVariables
>
export const BranchesForSourceRepositoryDocument = gql`
  query BranchesForSourceRepository(
    $endCursor: String
    $searchText: String
    $nameWithOwner: String!
    $provider: SourceControlProvider!
  ) {
    branchesForSourceRepository(
      endCursor: $endCursor
      searchText: $searchText
      nameWithOwner: $nameWithOwner
      provider: $provider
    ) {
      branches @connection(key: "branchesForSourceRepository")
      pageInfo {
        lastId
        hasNextPage
      }
    }
  }
`

/**
 * __useBranchesForSourceRepositoryQuery__
 *
 * To run a query within a React component, call `useBranchesForSourceRepositoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesForSourceRepositoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesForSourceRepositoryQuery({
 *   variables: {
 *      endCursor: // value for 'endCursor'
 *      searchText: // value for 'searchText'
 *      nameWithOwner: // value for 'nameWithOwner'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useBranchesForSourceRepositoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BranchesForSourceRepositoryQuery,
    BranchesForSourceRepositoryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    BranchesForSourceRepositoryQuery,
    BranchesForSourceRepositoryQueryVariables
  >(BranchesForSourceRepositoryDocument, baseOptions)
}
export function useBranchesForSourceRepositoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BranchesForSourceRepositoryQuery,
    BranchesForSourceRepositoryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    BranchesForSourceRepositoryQuery,
    BranchesForSourceRepositoryQueryVariables
  >(BranchesForSourceRepositoryDocument, baseOptions)
}
export type BranchesForSourceRepositoryQueryHookResult = ReturnType<
  typeof useBranchesForSourceRepositoryQuery
>
export type BranchesForSourceRepositoryLazyQueryHookResult = ReturnType<
  typeof useBranchesForSourceRepositoryLazyQuery
>
export type BranchesForSourceRepositoryQueryResult = ApolloReactCommon.QueryResult<
  BranchesForSourceRepositoryQuery,
  BranchesForSourceRepositoryQueryVariables
>
export const ImportedSiteDetailsDocument = gql`
  query ImportedSiteDetails($id: UUID!) {
    siteDetails(id: $id) {
      id
      organizationId
      name
      previewUrl
      branch
      previewStatus
      buildsEnabled
      permissions {
        ...permissionsForSite
      }
      repository {
        id
        provider
        nameWithOwner
      }
    }
  }
  ${PermissionsForSiteFragmentDoc}
`

/**
 * __useImportedSiteDetailsQuery__
 *
 * To run a query within a React component, call `useImportedSiteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportedSiteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportedSiteDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useImportedSiteDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ImportedSiteDetailsQuery,
    ImportedSiteDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ImportedSiteDetailsQuery,
    ImportedSiteDetailsQueryVariables
  >(ImportedSiteDetailsDocument, baseOptions)
}
export function useImportedSiteDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ImportedSiteDetailsQuery,
    ImportedSiteDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ImportedSiteDetailsQuery,
    ImportedSiteDetailsQueryVariables
  >(ImportedSiteDetailsDocument, baseOptions)
}
export type ImportedSiteDetailsQueryHookResult = ReturnType<
  typeof useImportedSiteDetailsQuery
>
export type ImportedSiteDetailsLazyQueryHookResult = ReturnType<
  typeof useImportedSiteDetailsLazyQuery
>
export type ImportedSiteDetailsQueryResult = ApolloReactCommon.QueryResult<
  ImportedSiteDetailsQuery,
  ImportedSiteDetailsQueryVariables
>
export const PublishSiteDocument = gql`
  mutation PublishSite($id: UUID!, $environmentVariables: [TagInput!]!) {
    publishSite(id: $id, environmentVariables: $environmentVariables) {
      id
      organizationId
    }
  }
`
export type PublishSiteMutationFn = ApolloReactCommon.MutationFunction<
  PublishSiteMutation,
  PublishSiteMutationVariables
>

/**
 * __usePublishSiteMutation__
 *
 * To run a mutation, you first call `usePublishSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishSiteMutation, { data, loading, error }] = usePublishSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      environmentVariables: // value for 'environmentVariables'
 *   },
 * });
 */
export function usePublishSiteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PublishSiteMutation,
    PublishSiteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    PublishSiteMutation,
    PublishSiteMutationVariables
  >(PublishSiteDocument, baseOptions)
}
export type PublishSiteMutationHookResult = ReturnType<
  typeof usePublishSiteMutation
>
export type PublishSiteMutationResult = ApolloReactCommon.MutationResult<
  PublishSiteMutation
>
export type PublishSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PublishSiteMutation,
  PublishSiteMutationVariables
>
