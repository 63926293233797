export enum KeyboardKey {
  BACKSPACE = 8,
  TAB = 9,
  RETURN = 13,
  SHIFT = 16,
  ESC = 27,
  SPACE = 32,
  PAGE_UP = 33,
  PAGE_DOWN = 34,
  END = 35,
  HOME = 36,
  LEFT = 37,
  UP = 38,
  RIGHT = 39,
  DOWN = 40,
  DELETE = 46,
}
