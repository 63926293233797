import React from "react"
import PropTypes from "prop-types"
import { useApolloFragment } from "apollo-fragment-react"
import styled from "@emotion/styled"
import {
  Avatar,
  DropdownMenu,
  DropdownMenuPopover,
  DropdownMenuButton as BaseMenuButton,
  DropdownMenuItem,
  DropdownMenuItemsLowLevel,
  DropdownMenuLink,
} from "gatsby-interface"
import { keyframes } from "@emotion/core"
import { logout } from "@modules/auth"
import { MdArrowDropDown, MdPowerSettingsNew, MdSettings } from "react-icons/md"
import {
  useTracker,
  SegmentEventType,
  GAEventType,
  BigQueryEventType,
} from "@modules/analytics"
import { positionRight } from "@reach/popover"
import { header as text } from "@modules/locales/default.json"
import { USER_DROPDOWN_FRAGMENT } from "../queries"

const menuButtonEntry = keyframes`
   to {
     opacity: 1;
   }
 `

const MenuButton = styled(BaseMenuButton)`
  animation: ${menuButtonEntry} 0.5s ease forwards;
  align-items: center;
  background: none;
  border: 0;
  color: ${props => props.theme.colors.grey[90]};
  cursor: pointer;
  display: flex;
  line-height: 1;
  opacity: 0;
  padding: ${props => props.theme.space[2]};
  padding-left: ${props => props.theme.space[4]};
  position: relative;
  white-space: nowrap;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  * + * {
    margin-left: ${props => props.theme.space[2]};
  }

  svg {
    flex-shrink: 0;
    height: ${props => props.theme.space[5]};
    width: ${props => props.theme.space[5]};
  }
`

const MenuLabel = styled(`div`)`
  color: ${props => props.theme.colors.grey[60]};
  display: flex;
  flex-direction: column;
  font-size: ${props => props.theme.fontSizes[1]};
  padding: ${props => props.theme.space[4]} ${props => props.theme.space[5]};
  position: relative;
`

const Email = styled(`span`)`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

function UserMenu() {
  const { trackSegment, trackBigQuery, trackGoogleAnalytics } = useTracker()
  const { data } = useApolloFragment(
    USER_DROPDOWN_FRAGMENT,
    localStorage.getItem(`gatsby:userid`)
  )

  const name = data && data.name
  const avatarUrl = data && data.avatarUrl
  const email = data && data.email

  if (!name) {
    return null
  }

  return (
    <DropdownMenu id="user-menu">
      <MenuButton
        id="user-menu-button"
        onClick={() => {
          trackGoogleAnalytics({
            eventType: GAEventType.Click,
            category: `Product Engagement - Dashboard`,
            label: { loc: `Header`, text: `profile dropdown` },
          })
          trackBigQuery({
            eventType: BigQueryEventType.ButtonClicked,
            buttonName: `Open User Menu`,
            uiSource: `User Menu`,
          })
        }}
        data-testid="user-menu-button"
      >
        <Avatar data-cy="signed-in-user-avatar" src={avatarUrl} label={name} />
        <MdArrowDropDown />
      </MenuButton>

      <DropdownMenuPopover
        data-testid="user-menu-list"
        position={positionRight}
      >
        <DropdownMenuItemsLowLevel size="MAX_CONTENT">
          <MenuLabel>
            <small>{text.loggedInAs}</small>
            <Email data-cy="signed-in-user-email" className="fs-block">
              {email}
            </Email>
          </MenuLabel>
          <DropdownMenuLink
            as={`a`}
            href={`${window.location.origin}/dashboard/account`}
            onClick={() => {
              trackGoogleAnalytics({
                eventType: GAEventType.Click,
                category: `Product Engagement - Dashboard`,
                label: {
                  loc: `Header`,
                  text: `profile dropdown - go to user settings`,
                },
              })
              trackSegment({
                type: SegmentEventType.Track,
                event: `Click to open user settings`,
                properties: {
                  location: `Dashboard header`,
                },
              })
            }}
            Icon={MdSettings}
          >
            {text.userSettings}
          </DropdownMenuLink>
          <DropdownMenuItem
            onClick={() => {
              trackBigQuery({
                eventType: BigQueryEventType.ButtonClicked,
                buttonName: text.signOut,
                uiSource: `User Menu`,
              })
              trackGoogleAnalytics({
                eventType: GAEventType.Click,
                category: `Product Engagement - Dashboard`,
                label: { loc: `Header`, text: `profile dropdown - Sign out` },
              })
            }}
            onSelect={logout}
            data-cy="navbar-logout-menu-button"
            Icon={MdPowerSettingsNew}
          >
            {text.signOut}
          </DropdownMenuItem>
        </DropdownMenuItemsLowLevel>
      </DropdownMenuPopover>
    </DropdownMenu>
  )
}

UserMenu.propTypes = {
  organizationId: PropTypes.string,
}

export default UserMenu
