import * as React from "react"
import { navigate } from "@reach/router"
import { usePersisentState } from "@modules/toolkit/hooks/userPersistentState"

export const useAnnouncement = (name: string) => {
  const [isOpened, setIsOpened] = React.useState(false)
  const [lastViewedAnnouncement, setLastViewedAnnouncement] = usePersisentState<
    string
  >("gatsbyjs:announcement", "")

  const alreadyViewed = lastViewedAnnouncement === name

  const handleOpenAnnouncement = () => {
    if (alreadyViewed) {
      return navigate(`/release-notes`)
    }

    setIsOpened(true)
  }

  const handleCloseModal = () => {
    setLastViewedAnnouncement(name)
    setIsOpened(false)
  }

  return {
    isNewAnnouncement: !alreadyViewed,
    isOpened,
    openAnnouncement: handleOpenAnnouncement,
    closeAnnouncement: handleCloseModal,
  }
}
