import { orgDetails as orgDetailsText } from "@modules/locales/default.json"

export const OrgDetailsTab = {
  Sites: `sites`,
  Settings: `settings`,
  Members: `members`,
}

export const tabsConfig = {
  [OrgDetailsTab.Sites]: {
    tab: OrgDetailsTab.Sites,
    label: orgDetailsText.labels.sites,
    hasSections: false,
  },
  [OrgDetailsTab.Settings]: {
    tab: OrgDetailsTab.Settings,
    label: orgDetailsText.labels.settings,
    hasSections: true,
  },
  [OrgDetailsTab.Members]: {
    tab: OrgDetailsTab.Members,
    label: orgDetailsText.labels.members,
    hasSections: false,
  },
}

export const ORG_DETAILS_DEFAULT_TAB = OrgDetailsTab.Sites
