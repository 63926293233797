import * as React from "react"
import {
  DropdownDivider,
  DropdownHeader,
  DropdownMenu,
  DropdownMenuPopover,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItemsLowLevel,
  DropdownMenuLink,
  GatsbyCloudIcon,
  MonogramIcon,
  ThemeCss,
} from "gatsby-interface"
import { positionRight } from "@reach/popover"
import { MdArrowDropDown } from "react-icons/md"
import { header as text } from "@modules/locales/default.json"
import {
  useTriggerZendeskWidget,
  ZendeskContext,
  ZendeskStatus,
} from "@modules/zendesk"
import { useTracker, GAEventType, BigQueryEventType } from "@modules/analytics"
import { useCurrentUser } from "@modules/auth"

const menuButtonCss: ThemeCss = theme => ({
  background: "transparent",
  border: "none",
  cursor: "pointer",
  fontSize: theme.fontSizes[1],
})

const dropdownItemsCss: ThemeCss = theme => ({
  maxHeight: `100%`,
})

const dropdownDividerCss: ThemeCss = theme => ({
  borderWidth: 0,
  background: theme.colors.grey[20],
})

export function HelpMenu() {
  const { trackBigQuery, trackGoogleAnalytics } = useTracker()

  const { status, prepareForm } = React.useContext(ZendeskContext)
  const { currentUser } = useCurrentUser()

  const name = currentUser?.name
  const email = currentUser?.email

  React.useEffect(() => {
    if (status === ZendeskStatus.Ready && name && email) {
      prepareForm({
        name: {
          value: name,
        },
        email: {
          value: email,
        },
      })
    }
  }, [name, email, status])

  const triggerZendeskWidget = useTriggerZendeskWidget()

  return (
    <DropdownMenu>
      <DropdownMenuButton css={menuButtonCss}>
        Help <MdArrowDropDown />
      </DropdownMenuButton>
      <DropdownMenuPopover position={positionRight}>
        <DropdownMenuItemsLowLevel size="MAX_CONTENT" css={dropdownItemsCss}>
          <DropdownHeader>{text.documentation}</DropdownHeader>
          <DropdownMenuLink
            onSelect={() => {
              triggerZendeskWidget()
              trackGoogleAnalytics({
                eventType: GAEventType.Click,
                category: `Product Engagement - Dashboard`,
                label: {
                  loc: `Header`,
                  text: `help dropdown - go to cloud docs`,
                },
              })
            }}
            as={`a`}
            target={`_blank`}
            href={`https://support.gatsbyjs.com/hc/`}
            Icon={GatsbyCloudIcon}
          >
            {text.cloudDocumentation}
          </DropdownMenuLink>
          <DropdownMenuLink
            onClick={() => {
              trackBigQuery({
                eventType: BigQueryEventType.ButtonClicked,
                buttonName: text.ossDocumentation,
                uiSource: `User DropdownMenu`,
              })
              trackGoogleAnalytics({
                eventType: GAEventType.Click,
                category: `Product Engagement - Dashboard`,
                label: {
                  loc: `Header`,
                  text: `help dropdown - go to oss docs`,
                },
              })
            }}
            as={`a`}
            target={`_blank`}
            href={`${window.location.origin}/docs/`}
            Icon={MonogramIcon}
          >
            {text.ossDocumentation}
          </DropdownMenuLink>
          <DropdownDivider css={dropdownDividerCss} />
          <DropdownMenuLink
            onClick={() => {
              trackBigQuery({
                eventType: BigQueryEventType.ButtonClicked,
                buttonName: text.productRoadmap,
                uiSource: `User DropdownMenu`,
              })
              trackGoogleAnalytics({
                eventType: GAEventType.Click,
                category: `Product Engagement - Dashboard`,
                label: {
                  loc: `Header`,
                  text: `help dropdown - go to product roadmap`,
                },
              })
            }}
            as={`a`}
            target={`_blank`}
            href={`https://portal.gatsbyjs.com/tabs/5-in-progress`}
          >
            {text.productRoadmap}
          </DropdownMenuLink>
          <DropdownMenuLink
            onClick={() => {
              trackBigQuery({
                eventType: BigQueryEventType.ButtonClicked,
                buttonName: text.statusPage,
                uiSource: `User DropdownMenu`,
              })
              trackGoogleAnalytics({
                eventType: GAEventType.Click,
                category: `Product Engagement - Dashboard`,
                label: {
                  loc: `Header`,
                  text: `help dropdown - go to status page`,
                },
              })
            }}
            as={`a`}
            target={`_blank`}
            href={`https://status.gatsbyjs.com`}
          >
            {text.statusPage}
          </DropdownMenuLink>
          <DropdownDivider css={dropdownDividerCss} />
          <DropdownMenuItem
            onSelect={() => {
              triggerZendeskWidget()
              trackGoogleAnalytics({
                eventType: GAEventType.Click,
                category: `Product Engagement - Dashboard`,
                label: {
                  loc: `Header`,
                  text: `help dropdown - customer support`,
                },
              })
            }}
          >
            Contact Support
          </DropdownMenuItem>
        </DropdownMenuItemsLowLevel>
      </DropdownMenuPopover>
    </DropdownMenu>
  )
}
