import * as React from "react"
import * as uuid from "uuid"

export function GatsbyCloudLogo(props: React.ComponentPropsWithoutRef<"svg">) {
  const gradientIdRef = React.useRef(uuid.v4())
  const clipIdRef = React.useRef(uuid.v4())

  const gradientId = gradientIdRef?.current
  const clipId = clipIdRef?.current

  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin meet"
      {...props}
    >
      <g clipPath={`url(#${clipId})`}>
        <circle cx="8.52831" cy="9.13292" r="3.93938" fill="#159CF4" />
        <circle cx="7.70404" cy="14.9474" r="3.52099" fill="white" />
        <circle cx="16.6135" cy="12.3802" r="6.28055" fill="white" />
        <rect
          x="7.7041"
          y="14.9686"
          width="9.57392"
          height="3.50049"
          fill="white"
        />
        <circle cx="10.1014" cy="12.0086" r="1.15763" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4852 9.20257C20.4255 7.60548 18.6112 6.55255 16.5509 6.55255C13.5395 6.55255 11.2761 8.82913 10.9731 10.7701C10.9049 11.2074 10.7362 11.4943 10.4468 11.8009C10.2092 11.9564 10.0267 11.993 9.81179 11.9564C9.66581 11.9316 9.54215 11.8797 9.40428 11.8217C9.13422 11.7082 8.80966 11.5718 8.15607 11.5718C6.32393 11.5718 4.8387 13.057 4.8387 14.8891C4.8387 16.7213 6.32393 18.2065 8.15607 18.2065H12.1223C12.1426 18.7791 12.2181 19.3376 12.3437 19.8769L8.15629 19.8769C5.40506 19.8769 3.17474 17.6466 3.17474 14.8954C3.17474 12.1441 5.40506 9.91382 8.15629 9.91382C8.56319 9.91382 8.9587 9.9626 9.33729 10.0546C10.3373 7.07983 13.1486 4.93701 16.4604 4.93701C19.5866 4.93701 22.2669 6.84649 23.3986 9.56278C22.7868 9.37443 22.1463 9.25162 21.4852 9.20257Z"
          fill={`url(#${gradientId})`}
        />
        <circle cx="9.63411" cy="2.49739" r="1.10579" fill="#159BF3" />
        <circle cx="1.89375" cy="9.13216" r="1.10579" fill="#159BF3" />
        <circle cx="4.10555" cy="3.60518" r="1.10579" fill="#159BF3" />
        <path
          d="M26.413 17.9969H22.913V18.9969H25.313C24.963 20.497 23.863 21.747 22.413 22.247L16.663 16.4969C17.263 14.7469 18.963 13.4969 20.913 13.4969C22.413 13.4969 23.763 14.2469 24.613 15.3969L25.363 14.7469C24.363 13.3969 22.763 12.4969 20.913 12.4969C18.313 12.4969 16.113 14.3469 15.563 16.7969L22.163 23.397C24.563 22.797 26.413 20.597 26.413 17.9969Z"
          fill="white"
        />
        <path
          d="M15.413 18.0469C15.413 19.4469 15.963 20.7969 17.013 21.8469C18.063 22.8969 19.463 23.4469 20.813 23.4469L15.413 18.0469Z"
          fill="white"
        />
        <path
          d="M20.913 10.9969C17.063 10.9969 13.913 14.1469 13.913 17.9969C13.913 21.8469 17.063 24.9969 20.913 24.9969C24.763 24.9969 27.913 21.8469 27.913 17.9969C27.913 14.1469 24.763 10.9969 20.913 10.9969ZM17.013 21.8969C15.963 20.8469 15.413 19.4469 15.413 18.0969L20.863 23.4969C19.463 23.4469 18.063 22.9469 17.013 21.8969ZM22.113 23.347L15.563 16.7969C16.113 14.3469 18.313 12.4969 20.913 12.4969C22.763 12.4969 24.363 13.3969 25.363 14.7469L24.613 15.3969C23.763 14.2469 22.413 13.4969 20.913 13.4969C18.963 13.4969 17.313 14.7469 16.663 16.4969L22.413 22.2469C23.863 21.7469 24.963 20.4969 25.313 18.9969H22.913V17.9969H26.413C26.413 20.5969 24.563 22.797 22.113 23.347Z"
          fill="#663399"
        />
      </g>
      <defs>
        <linearGradient
          id={gradientId}
          x1="23.2288"
          y1="8.96956"
          x2="11.433"
          y2="8.96956"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#663399" />
          <stop offset="1" stopColor="#159BF3" />
        </linearGradient>
        <clipPath id={clipId}>
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
