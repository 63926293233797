import React from "react"
import { format } from "date-fns"
import PropTypes from "prop-types"
import { BuildStatus } from "@modules/graphql/types"
import useBuildDuration from "../hooks/useBuildDuration"
import { getBuildStatusLabels } from "@modules/build/shared/utils"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { useFlags } from "@modules/featureFlags"

const propTypes = {
  buildStatus: PropTypes.oneOf(Object.values(BuildStatus)).isRequired,
  createdAt: PropTypes.string,
  startedAt: PropTypes.string,
  endedAt: PropTypes.string,
  duration: PropTypes.number,
}

export default function BuildCardStatusInfo({
  buildStatus,
  createdAt,
  startedAt,
  endedAt,
  duration,
}) {
  const { flags } = useFlags()
  const { label, durationLabel, startingLabel } = getBuildStatusLabels(
    buildStatus
  )

  if (
    !flags.siteBuildCardsUpdate &&
    (!startedAt || buildStatus === BuildStatus.Queued)
  ) {
    return <span>{label}</span>
  }

  if (
    flags.siteBuildCardsUpdate &&
    (buildStatus === BuildStatus.Queued || !startedAt) &&
    !endedAt
  ) {
    return (
      <span>
        {startingLabel} {createdAt && format(new Date(createdAt), `h:mm a`)}
      </span>
    )
  }

  return (
    <BuildFormattedDuration
      durationLabel={durationLabel}
      startingLabel={startingLabel}
      startedAt={startedAt}
      endedAt={endedAt}
      duration={duration}
    />
  )
}

export function BuildFormattedDuration({
  durationLabel,
  startingLabel,
  startedAt,
  endedAt,
  duration,
}) {
  const { flags } = useFlags()
  const formattedDuration = useBuildDuration({
    startedAt,
    endedAt,
    duration,
  })

  let displayLabel
  let ariaLabel

  if (flags.siteBuildCardsUpdate) {
    const label = `${startingLabel} ${format(new Date(startedAt), `h:mm a`)}`

    displayLabel = startingLabel
      ? label
      : `${durationLabel} ${formattedDuration.formatted}`
    ariaLabel = startingLabel
      ? label
      : `${durationLabel} ${formattedDuration.accessible}`
  } else {
    displayLabel = `${durationLabel} ${formattedDuration.formatted}`
    ariaLabel = `${durationLabel} ${formattedDuration.accessible}`
  }

  return (
    <React.Fragment>
      <span css={visuallyHiddenCss}>
        {ariaLabel} {ariaLabel}
      </span>
      <span aria-hidden={true}>{displayLabel}</span>
    </React.Fragment>
  )
}

BuildCardStatusInfo.propTypes = propTypes
