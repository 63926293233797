import * as React from "react"
import { Link } from "gatsby"
import { MdArrowForward } from "react-icons/md"

import { Theme, ThemeCss } from "gatsby-interface"
import { getPathToOrgSettings } from "@modules/organization/shared/utils"
import {
  Organization as OrganizationType,
  SubscriptionPlan,
} from "@modules/graphql/types"

const orgCss: ThemeCss = theme => ({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridGap: theme.space[5],
  alignItems: "center",
  fontSize: theme.fontSizes[1],
})

const linkCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.bold,
  wordBreak: "break-word",
})

export type OrganizationProps = Pick<
  OrganizationType,
  "id" | "name" | "permissions"
> & {
  plan?: Pick<SubscriptionPlan, "name" | "formattedAmount"> | null
}

function Organization({ name, id, permissions, plan }: OrganizationProps) {
  return (
    <div css={orgCss}>
      <MdArrowForward
        size={16}
        css={(theme: Theme) => ({
          color: theme.colors.gatsby,
          fontSize: theme.fontSizes[2],
          position: `absolute`,
          left: `-${theme.space[7]}`,
        })}
      />
      <Link css={linkCss} to={getPathToOrgSettings(id)}>
        {name}
      </Link>

      <div>{permissions?.role}</div>

      {plan && (
        <div>
          {plan.name} - {plan.formattedAmount}
        </div>
      )}
    </div>
  )
}

export default Organization
