import React from "react"
import PropTypes from "prop-types"
import { Breadcrumb as BaseBreadcrumb } from "gatsby-interface"
import { SITE_BREADCRUMB_FRAGMENT } from "../queries"
import { getPathToOrgSites } from "@modules/organization/shared/utils"
import { visitorAccessOptions } from "@modules/site/settings/constants"
import ToggleTip from "@modules/ui/components/ToggleTip"
import { PreviewProtectionType } from "@modules/graphql/types"
import { useApolloFragment } from "apollo-fragment-react"
import { idRegex } from "@modules/toolkit/constants"

function getSiteID(path) {
  const siteMatch =
    path.match(
      new RegExp(
        `(${idRegex})/sites/(${idRegex})/(deploys|settings|builds|production|branches|cmsPreview|functions).*$`,
        `i`
      )
    ) ||
    path.match(
      new RegExp(
        `(${idRegex})/sites/(${idRegex})/(builds)/(${idRegex}).*$`,
        `i`
      )
    )

  // Return the orgId depending on the path
  if (siteMatch && siteMatch[3].includes(`-`)) return siteMatch[3]
  if (siteMatch && siteMatch[2].includes(`-`)) return siteMatch[2]
  return null
}

const breadcrumbItemCss = theme => ({
  fontWeight: `bold`,
  marginBottom: 0,
  fontSize: theme.fontSizes[4],
})

function Breadcrumb({ orgName, orgId, path }) {
  const siteId = getSiteID(path)
  const { data: siteDetails } = useApolloFragment(
    SITE_BREADCRUMB_FRAGMENT,
    siteId
  )

  if (!siteDetails) return null

  const siteName = siteDetails.publicName || siteDetails.name || ``

  const siteProtection = siteDetails?.previewProtection || ``
  const siteProtectionDetails = visitorAccessOptions.find(
    option => option.value === siteProtection
  )
  const SiteProtectionIcon =
    ![PreviewProtectionType.Public].includes(siteProtection) &&
    siteProtectionDetails.icon

  return (
    <BaseBreadcrumb
      css={theme => {
        return {
          padding: `0 ${theme.space[8]}`,
          gridColumn: `1 / 3`,
          gridRow: `2 / 2`,
          justifySelf: `center`,
          div: {
            fontWeight: `bold`,
          },
        }
      }}
    >
      {orgId && orgName && (
        <BaseBreadcrumb.Item
          to={getPathToOrgSites(orgId)}
          css={breadcrumbItemCss}
        >
          {orgName}
        </BaseBreadcrumb.Item>
      )}
      {siteId && siteName && (
        <BaseBreadcrumb.Item active={true} css={breadcrumbItemCss}>
          {siteName}
          {SiteProtectionIcon && (
            <span>
              <ToggleTip
                variant="SECONDARY"
                tip={`${siteProtectionDetails.description}`}
                css={theme => {
                  return {
                    marginLeft: theme.space[2],
                    button: {
                      height: `${theme.fontSizes[2]}`,
                      width: `${theme.fontSizes[2]}`,
                    },
                  }
                }}
              >
                <SiteProtectionIcon
                  css={theme => {
                    return {
                      display: `block !important`,
                      marginLeft: `0 !important`,
                      fill: `${theme.colors.grey[40]} !important`,
                    }
                  }}
                />
              </ToggleTip>
            </span>
          )}
        </BaseBreadcrumb.Item>
      )}
    </BaseBreadcrumb>
  )
}

Breadcrumb.propTypes = {
  orgName: PropTypes.string,
  orgId: PropTypes.string,
  path: PropTypes.string,
}

export default Breadcrumb
