/**
 * These dots fill in a corner, like behind the logo or to add a bit of flair
 * to an empty page
 */
import React from "react"
import { DecorativeDots } from "gatsby-interface"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import { Sizes, SizeIds, DotSizes, Corners, CornerIds } from "../constants"

const propTypes = {
  corner: PropTypes.oneOf(CornerIds).isRequired,
  size: PropTypes.oneOf(SizeIds),
  opacity: PropTypes.number,
}

const cornerData = {
  [Corners.TopLeft]: {
    angle: 225,
    styles: {
      top: 0,
      left: 0,
    },
  },
  [Corners.TopRight]: {
    angle: 305,
    styles: {
      top: 0,
      right: 0,
    },
  },
  [Corners.BottomLeft]: {
    angle: 135,
    styles: {
      bottom: 0,
      left: 0,
    },
  },
  [Corners.BottomRight]: {
    angle: 45,
    styles: {
      bottom: 0,
      right: 0,
    },
  },
}

const Wrapper = styled.div`
  position: absolute;
  z-index: 0;
`

function CornerDots({ corner, size = Sizes.Medium, opacity = 0.5 }) {
  const { angle, styles } = cornerData[corner]

  const dotSize = DotSizes[size]

  // DecorativeDots produces random values, which can mess up snapshot tests
  // which expect the same output every time.
  // Because this component is decorative anyway, not core functionality, we
  // don't need to worry about testing it.
  if (process.env.NODE_ENV === `test`) {
    return null
  }

  return (
    <Wrapper style={{ ...styles, opacity }}>
      <DecorativeDots
        width={225}
        height={225}
        dotSize={dotSize}
        angle={angle}
        fadeStrength={0.2}
      />
    </Wrapper>
  )
}

CornerDots.propTypes = propTypes

export default CornerDots
