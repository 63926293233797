import React from "react"
import PropTypes from "prop-types"

import { Heading } from "gatsby-interface"
import PageContent from "./PageContent"

const VARIANTS = [`LEFT`, `CENTER`]

function PageHeader({
  variant = `LEFT`,
  children = undefined,
  heading = undefined,
  backgrounds = undefined,
  className = undefined,
}) {
  return (
    <PageContent.Positioner
      css={theme => ({
        padding: `${theme.space[10]} 0 ${theme.space[9]}`,

        "& > div": {
          display: `flex`,
          flexDirection: `column`,
          alignItems: variant === `CENTER` ? `center` : `left`,
          textAlign: variant === `CENTER` ? `center` : `flex-start`,
        },
      })}
      className={className}
    >
      {backgrounds && backgrounds}
      {heading && <PageHeader.Heading>{heading}</PageHeader.Heading>}
      {children}
    </PageContent.Positioner>
  )
}

PageHeader.propTypes = {
  variant: PropTypes.oneOf(VARIANTS),
}

const headingCss = theme => ({
  maxWidth: `34rem`,
  fontSize: theme.fontSizes[9],
  position: `relative`,
  letterSpacing: `-0.02em`,

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[10],
  },

  b: {
    color: theme.colors.purple[50],
    fontWeight: `inherit`,
  },
})

PageHeader.Heading = function({ children, ...rest }) {
  return (
    <Heading variant="EMPHASIZED" as="h1" css={headingCss} {...rest}>
      {children}
    </Heading>
  )
}

const ledeCss = theme => ({
  maxWidth: `24rem`,
  fontSize: theme.fontSizes[4],
  fontFamily: theme.fonts.heading,
  color: theme.colors.grey[50],
  marginTop: theme.space[7],
  lineHeight: 1.4,
  position: `relative`,
})

PageHeader.Lede = function({ children, ...rest }) {
  return (
    <div css={ledeCss} {...rest}>
      {children}
    </div>
  )
}

export default PageHeader
