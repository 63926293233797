import loadable from "@loadable/component"
import { ImportSiteWizardProps } from "@modules/site/create/Import/components/ImportSiteWizard"

export { SiteCreationTypePicker } from "@modules/site/create/shared/components/SiteCreationTypePicker"

export const ImportSiteWizard = loadable<ImportSiteWizardProps>(() =>
  import(
    /* webpackChunkName: "import-site-chunk" */
    /* webpackPrefetch: true */
    "@modules/site/create/Import/components/ImportSiteWizard"
  ).then(mod => mod.ImportSiteWizard)
)

// TODO "ProvisionSite" code should be moved to modules/site/create/Provision
export const ProvisionSiteWizard = loadable(() =>
  import(
    /* webpackChunkName: "provision-site-chunk" */
    /* webpackPrefetch: true */
    "../ProvisionSite"
  )
)
