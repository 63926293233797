import * as React from "react"

export type QueuedIconProps = React.ComponentPropsWithoutRef<"svg"> & {
  a11yId?: string
}

export default function QueuedIcon({ a11yId, ...rest }: QueuedIconProps) {
  const id = a11yId ? a11yId : `SuccessIcon`

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      {...rest}
    >
      <circle cx="10" cy="10" r="10" fill="#F5F5F5" />
      <rect x="6.75" y="6" width="2" height="8" fill="#B7B5BD" />
      <rect x="11.25" y="6" width="2" height="8" fill="#B7B5BD" />
    </svg>
  )
}
