import * as React from "react"
import { ThemeCss } from "gatsby-interface"

const cardCss: ThemeCss = theme => [
  theme.cardStyles.frame,
  {
    overflow: `hidden`,
  },
]

const clickableCardCss: ThemeCss = () => ({
  cursor: "pointer",
})

const cardHeaderCss: ThemeCss = theme => ({
  padding: `${theme.space[5]} ${theme.space[7]}`,
  fontSize: theme.fontSizes[1],
})

const cardContentCss: ThemeCss = theme => ({
  padding: `${theme.space[5]} ${theme.space[7]}`,
  fontSize: theme.fontSizes[1],
})

const cardFooterCss: ThemeCss = theme => ({
  padding: `${theme.space[5]} ${theme.space[7]}`,
  fontSize: theme.fontSizes[1],
})

const cardSeparatorCss: ThemeCss = theme => ({
  height: "1px",
  background: theme.colors.grey[20],
  padding: 0,
  margin: 0,
})

export type CardProps = {
  clickable?: boolean
  as?: "article" | "div"
} & React.HTMLAttributes<HTMLElement>

/**
 * @deprecated Use Card from gatsby-interface
 */
export function Card({
  children,
  clickable,
  as: Component = "div",
  ...props
}: CardProps) {
  return (
    <Component
      css={theme => [
        cardCss(theme),
        clickable ? clickableCardCss(theme) : null,
      ]}
      {...props}
    >
      {children}
    </Component>
  )
}

/**
 * @deprecated Use Card from gatsby-interface
 */
export function CardHeader({ children }: { children?: React.ReactNode }) {
  return <div css={cardHeaderCss}>{children}</div>
}

/**
 * @deprecated Use Card from gatsby-interface
 */
export function CardContent({ children }: { children?: React.ReactNode }) {
  return <div css={cardContentCss}>{children}</div>
}

/**
 * @deprecated Use Card from gatsby-interface
 */
export function CardSeparator({ spacing = 0 }: { spacing?: number }) {
  return (
    <hr
      aria-hidden={true}
      css={theme => [
        cardSeparatorCss(theme),
        { margin: `0 ${theme.space[spacing]}` },
      ]}
    />
  )
}

/**
 * @deprecated Use Card from gatsby-interface
 */
export function CardFooter({ children }: { children?: React.ReactNode }) {
  return <div css={cardFooterCss}>{children}</div>
}
