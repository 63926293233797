// THIS IS A GENERATED FILE
import * as Types from "../../graphql/types"

import gql from "graphql-tag"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type UpdateUserWelcomeFieldsMutationVariables = Types.Exact<{
  user: Types.UserWelcomeInput
}>

export type UpdateUserWelcomeFieldsMutation = { __typename?: "Mutation" } & {
  updateUserWelcomeFields?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
  >
}

export type CreateSiteMutationVariables = Types.Exact<{
  organizationId?: Types.Maybe<Types.Scalars["UUID"]>
  provider?: Types.Maybe<Types.SourceControlProvider>
  nameWithOwner: Types.Scalars["String"]
  selectedInstallationId: Types.Scalars["String"]
  branch?: Types.Maybe<Types.Scalars["String"]>
  directoryPath?: Types.Maybe<Types.Scalars["String"]>
  publicName?: Types.Maybe<Types.Scalars["String"]>
}>

export type CreateSiteMutation = { __typename?: "Mutation" } & {
  createSite: { __typename?: "CreateSiteResult" } & {
    site?: Types.Maybe<{ __typename?: "Site" } & Pick<Types.Site, "id">>
    validation?: Types.Maybe<
      { __typename?: "MutationResult" } & Pick<
        Types.MutationResult,
        "success" | "message" | "errorType"
      >
    >
  }
}

export type EnableSubscriptionTrialMutationVariables = Types.Exact<{
  organizationId: Types.Scalars["UUID"]
  planName: Types.Scalars["String"]
}>

export type EnableSubscriptionTrialMutation = { __typename?: "Mutation" } & {
  enableSubscriptionTrial?: Types.Maybe<
    { __typename?: "MutationResult" } & Pick<
      Types.MutationResult,
      "success" | "message"
    >
  >
}

export const UpdateUserWelcomeFieldsDocument = gql`
  mutation updateUserWelcomeFields($user: UserWelcomeInput!) {
    updateUserWelcomeFields(user: $user) {
      success
      message
    }
  }
`
export type UpdateUserWelcomeFieldsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserWelcomeFieldsMutation,
  UpdateUserWelcomeFieldsMutationVariables
>

/**
 * __useUpdateUserWelcomeFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateUserWelcomeFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserWelcomeFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserWelcomeFieldsMutation, { data, loading, error }] = useUpdateUserWelcomeFieldsMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserWelcomeFieldsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserWelcomeFieldsMutation,
    UpdateUserWelcomeFieldsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserWelcomeFieldsMutation,
    UpdateUserWelcomeFieldsMutationVariables
  >(UpdateUserWelcomeFieldsDocument, baseOptions)
}
export type UpdateUserWelcomeFieldsMutationHookResult = ReturnType<
  typeof useUpdateUserWelcomeFieldsMutation
>
export type UpdateUserWelcomeFieldsMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserWelcomeFieldsMutation
>
export type UpdateUserWelcomeFieldsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserWelcomeFieldsMutation,
  UpdateUserWelcomeFieldsMutationVariables
>
export const CreateSiteDocument = gql`
  mutation createSite(
    $organizationId: UUID
    $provider: SourceControlProvider
    $nameWithOwner: String!
    $selectedInstallationId: String!
    $branch: String
    $directoryPath: String
    $publicName: String
  ) {
    createSite(
      organizationId: $organizationId
      provider: $provider
      selectedInstallationId: $selectedInstallationId
      nameWithOwner: $nameWithOwner
      branch: $branch
      directoryPath: $directoryPath
      publicName: $publicName
    ) {
      site {
        id
      }
      validation {
        success
        message
        errorType
      }
    }
  }
`
export type CreateSiteMutationFn = ApolloReactCommon.MutationFunction<
  CreateSiteMutation,
  CreateSiteMutationVariables
>

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      provider: // value for 'provider'
 *      nameWithOwner: // value for 'nameWithOwner'
 *      selectedInstallationId: // value for 'selectedInstallationId'
 *      branch: // value for 'branch'
 *      directoryPath: // value for 'directoryPath'
 *      publicName: // value for 'publicName'
 *   },
 * });
 */
export function useCreateSiteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSiteMutation,
    CreateSiteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateSiteMutation,
    CreateSiteMutationVariables
  >(CreateSiteDocument, baseOptions)
}
export type CreateSiteMutationHookResult = ReturnType<
  typeof useCreateSiteMutation
>
export type CreateSiteMutationResult = ApolloReactCommon.MutationResult<
  CreateSiteMutation
>
export type CreateSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSiteMutation,
  CreateSiteMutationVariables
>
export const EnableSubscriptionTrialDocument = gql`
  mutation enableSubscriptionTrial($organizationId: UUID!, $planName: String!) {
    enableSubscriptionTrial(
      organizationId: $organizationId
      planName: $planName
    ) {
      success
      message
    }
  }
`
export type EnableSubscriptionTrialMutationFn = ApolloReactCommon.MutationFunction<
  EnableSubscriptionTrialMutation,
  EnableSubscriptionTrialMutationVariables
>

/**
 * __useEnableSubscriptionTrialMutation__
 *
 * To run a mutation, you first call `useEnableSubscriptionTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableSubscriptionTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableSubscriptionTrialMutation, { data, loading, error }] = useEnableSubscriptionTrialMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      planName: // value for 'planName'
 *   },
 * });
 */
export function useEnableSubscriptionTrialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EnableSubscriptionTrialMutation,
    EnableSubscriptionTrialMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    EnableSubscriptionTrialMutation,
    EnableSubscriptionTrialMutationVariables
  >(EnableSubscriptionTrialDocument, baseOptions)
}
export type EnableSubscriptionTrialMutationHookResult = ReturnType<
  typeof useEnableSubscriptionTrialMutation
>
export type EnableSubscriptionTrialMutationResult = ApolloReactCommon.MutationResult<
  EnableSubscriptionTrialMutation
>
export type EnableSubscriptionTrialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EnableSubscriptionTrialMutation,
  EnableSubscriptionTrialMutationVariables
>
