import * as React from "react"
import SuccessIcon from "@modules/ui/assets/SuccessIcon"
import FailedIcon from "@modules/ui/assets/FailedIcon"
import { FunctionInvokationStatus } from "@modules/site/details/components/mocks/functions.mocks"

export type FunctionStatusIconProps = {
  status?: FunctionInvokationStatus
}

export function FunctionStatusIcon({
  status,
  ...rest
}: FunctionStatusIconProps) {
  if (status === FunctionInvokationStatus.Success) {
    return <SuccessIcon {...rest} />
  }

  if (
    status === FunctionInvokationStatus.Failure ||
    status === FunctionInvokationStatus.TimedOut
  ) {
    return <FailedIcon {...rest} />
  }

  return null
}
