import React from "react"
import { MdErrorOutline } from "react-icons/md"
import { css } from "@emotion/core"

export const baseErrorCss = theme => css`
  align-items: center;
  color: ${theme.colors.warning};
  display: flex;
  font-size: ${theme.fontSizes[0]};
  line-height: 1;
  white-space: nowrap;

  svg {
    color: currentColor;
    flex-shrink: 0;
    margin-right: ${theme.space[0]};
  }
`

export default function ErrorMessage({
  children,
  hideIcon = false,
  className,
}) {
  return (
    <span css={baseErrorCss} className={className}>
      {!hideIcon && (
        <React.Fragment>
          <MdErrorOutline />
          {` `}
        </React.Fragment>
      )}
      {children}
    </span>
  )
}
