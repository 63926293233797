import * as React from "react"
import {
  BaseAnchor,
  Heading,
  HeadingProps,
  Notification,
  Text,
} from "gatsby-interface"
import { ThemeCss } from "gatsby-interface"
import { errorAlert as errorAlertText } from "@modules/locales/default.json"
import { FormattedMessage } from "@modules/locales"

const baseCss: ThemeCss = theme => ({
  boxShadow: `0 0 0 1px ${theme.tones.DANGER.light} inset`,
  marginBottom: theme.space[5],
})

const headlineCss: ThemeCss = theme => ({
  fontFamily: theme.fonts.body,
  fontWeight: theme.fontWeights.bold,
  fontSize: theme.fontSizes[2],
  marginTop: 0,
  marginBottom: theme.space[3],
})

const supportSeparatorCss: ThemeCss = theme => ({
  backgroundColor: theme.colors.grey[30],
  marginTop: theme.space[5],
  marginBottom: theme.space[5],
})

const supportTextCss: ThemeCss = _theme => ({
  color: `inherit`,
  fontSize: `inherit`,
  margin: 0,
})

const supportLinkCss: ThemeCss = _theme => ({
  textDecoration: `none`,
})

export type ErrorAlertProps = {
  headline?: React.ReactNode
  headlineAs?: HeadingProps["as"]
  children?: React.ReactNode
  includeSupport?: boolean
  className?: string
}

export function ErrorAlert({
  headline = errorAlertText.headers.defaultHeader,
  headlineAs = `h3`,
  children,
  includeSupport = true,
  className,
}: ErrorAlertProps) {
  return (
    <Notification
      content={
        <div>
          <Heading css={headlineCss} as={headlineAs}>
            {headline}
          </Heading>
          {children}
          {includeSupport && (
            <React.Fragment>
              <hr css={supportSeparatorCss} />
              <Text css={supportTextCss}>
                {errorAlertText.messages.supportIntro}
              </Text>
              <Text css={supportTextCss}>
                <FormattedMessage<never, "link">
                  message={errorAlertText.messages.supportContact}
                  tags={{
                    link: function(content) {
                      return (
                        <BaseAnchor
                          href="mailto:support@gatsbyjs.com"
                          css={supportLinkCss}
                        >
                          {content}
                        </BaseAnchor>
                      )
                    },
                  }}
                />
              </Text>
            </React.Fragment>
          )}
        </div>
      }
      variant="SECONDARY"
      tone="DANGER"
      role="alert"
      className={className}
      css={baseCss}
    />
  )
}

export function useTriggerErrorAlert<T extends { message: string }>() {
  const [error, setError] = React.useState<T | null>(null)

  const errorAlert = error ? <ErrorAlert>{error.message}</ErrorAlert> : null

  return [setError, errorAlert, error] as const
}
