import { ThemeCss } from "gatsby-interface"

export const createDeprecationCss = (
  deprecationMessage = `deprecated`
): ThemeCss => theme => ({
  border: `2px solid red !important`,
  "&:hover": {
    "&::before": {
      content: `"${deprecationMessage}"`,
      position: `absolute`,
      transform: `translate3d(0, calc(-100% - 2px), 0)`,
      background: `white`,
      color: `red`,
      fontSize: theme.fontSizes[0],
      fontWeight: theme.fontWeights.body,
      fontFamily: theme.fonts.body,
    },
  },
})
