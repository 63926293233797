import React from "react"
import { BannerLayout, BannerLink } from "@modules/ui/components/Banner"
import { useFlags } from "@modules/featureFlags"
import { useSurvey } from "./hooks/useSurvey"

export function SurveyBanner() {
  const { isVisible, hideSurveyBanner } = useSurvey()

  return isVisible ? (
    <BannerLayout data-testid="survey-banner" onClose={hideSurveyBanner}>
      Hello! Will you help Gatsby improve by taking a four question survey? It
      takes less than five minutes and your ideas and feedback will be very
      valuable:{" "}
      <BannerLink href="https://gatsby.dev/cloud-feedback">
        https://gatsby.dev/cloud-feedback
      </BannerLink>
    </BannerLayout>
  ) : null
}

function FlaggedSurveyBanner() {
  const { flags } = useFlags()

  return flags.showBannerSurvey ? <SurveyBanner /> : null
}

export default FlaggedSurveyBanner
