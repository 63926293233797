import * as React from "react"
import {
  CreateOrganizationMutationVariables,
  useCreateOrganizationMutation,
} from "../queries.generated"
import {
  Organization,
  SubscriptionBootstrapInput,
} from "@modules/graphql/types"
import { Stripe, StripeCardNumberElement } from "@stripe/stripe-js"
import { useTriggerErrorAlert } from "@modules/ui/components/ErrorAlert"
import { SegmentEventType, useTracker } from "@modules/analytics"
import { UserOrganizationsDocument } from "@modules/header/queries.generated"

type BillingAddress = {
  address_line1: string
  address_line2?: string
  address_city: string
  address_state: string
  address_zip: string
  address_country: string
}
type CreateOrganizationArgs = {
  orgName: string
  stripe?: Stripe
  cardElement?: StripeCardNumberElement
  subscriptionInput?: Omit<SubscriptionBootstrapInput, "token">
  billingAddress?: BillingAddress
}

export function useCreateOrganization() {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [setError, errorAlert] = useTriggerErrorAlert()
  const [mutate] = useCreateOrganizationMutation()
  const { trackSegment } = useTracker()

  const createOrganization = async (
    {
      orgName,
      stripe,
      cardElement,
      subscriptionInput,
      billingAddress,
    }: CreateOrganizationArgs,
    onSuccess?: (organization: Pick<Organization, "id" | "name">) => void
  ) => {
    setIsSubmitting(true)

    const create = async (
      subscription: CreateOrganizationMutationVariables["subscription"]
    ) => {
      let mutationResult
      try {
        mutationResult = await mutate({
          variables: {
            name: orgName,
            subscription,
          },
          refetchQueries: [{ query: UserOrganizationsDocument }],
        })
      } catch (err) {
        setIsSubmitting(false)
        setError(err)
        return
      }

      setIsSubmitting(false)

      const organization = mutationResult.data?.createGatsbyOrganization
      if (onSuccess && organization) {
        trackSegment({
          type: SegmentEventType.Track,
          event: `Created Workspace`,
          properties: {
            organizationId: organization.id,
          },
        })

        onSuccess(organization)
      }
    }

    try {
      if (subscriptionInput && cardElement && stripe) {
        const tokenResult = await stripe.createToken(cardElement, {
          name: subscriptionInput.customerName || undefined,
          ...billingAddress,
        })

        create({
          ...subscriptionInput,
          token: tokenResult.token?.id || "",
        })
      } else {
        create(null)
      }
    } catch (err) {
      setIsSubmitting(false)
      if (err?.message) setError(err)
    }
  }

  return [createOrganization, { loading: isSubmitting, errorAlert }] as const
}
