import capitalizeStr from "./capitalizeStr"

const unzipCamelCaseStr = ({ str, exceptions = [], capitalize = true }) => {
  if (typeof str !== `string`) {
    throw new Error(`param.str must be typeof \`string\``)
  }

  if (!Array.isArray(exceptions)) {
    throw new Error(`param.exceptions must be \`Array\``)
  }

  let normalizedStr = str

  exceptions.forEach(item => {
    normalizedStr = normalizedStr.replace(item, capitalizeStr({ str: item }))
  })

  const unzippedStr = normalizedStr.replace(/([A-Z])/g, ` $1`)

  return unzippedStr
    .split(` `)
    .map(item =>
      exceptions.includes(item.toUpperCase())
        ? ` ${item.toUpperCase()}`
        : ` ${capitalize ? capitalizeStr({ str: item }) : item}`
    )
    .join(``)
    .trim()
}

export default unzipCamelCaseStr
