/**
 * This component is a thin wrapper around DotsAlongScreenEdge.
 * It removes the 'size' prop and provides two sets of dots, stacked.
 * One large, one small.
 */
import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import { Sizes } from "../constants"
import DotsAlongScreenEdge from "./DotsAlongScreenEdge"

const propTypes = {
  side: PropTypes.oneOf([`left`, `right`]).isRequired,
}

const BackWrapper = styled.div`
  position: absolute;
  z-index: -1;
  opacity: 0.25;
`

function StackedDotsAlongScreenEdge({ side }) {
  // DecorativeDots produces random values, which can mess up snapshot tests
  // which expect the same output every time.
  // Because this component is decorative anyway, not core functionality, we
  // don't need to worry about testing it.
  if (process.env.NODE_ENV === `test`) {
    return null
  }

  return (
    <React.Fragment>
      <DotsAlongScreenEdge
        size={Sizes.Small}
        side={side}
        verticalOffset="-25vh"
        fadeStrength={0.25}
      />
      <BackWrapper>
        <DotsAlongScreenEdge
          size={Sizes.Large}
          side={side}
          verticalOffset="-28vh"
          fadeStrength={0.2}
        />
      </BackWrapper>
    </React.Fragment>
  )
}

StackedDotsAlongScreenEdge.propTypes = propTypes

export default StackedDotsAlongScreenEdge
