import React from "react"
import PropTypes from "prop-types"

import { DateTimeDistanceToNow } from "@modules/ui/components/DateTimeDistance"
import { Text } from "gatsby-interface"

const propTypes = {
  buildPublishedDate: PropTypes.string,
}

function BuildPublishedDate({ buildPublishedDate }) {
  return (
    <Text data-testid="build-publish-date" size={"S"} as="span">
      Last published <DateTimeDistanceToNow fromDate={buildPublishedDate} />
    </Text>
  )
}

BuildPublishedDate.propTypes = propTypes

export default BuildPublishedDate
