import * as React from "react"
import {
  StyledModal,
  StyledModalBody,
  StyledModalActions,
  StyledModalHeader,
  AnchorButton,
  Modal,
  ModalCard,
  Text,
  Heading,
  Spacer,
  CampaignIcon,
} from "gatsby-interface"
import { useFlags } from "@modules/featureFlags"
import { MdRemoveRedEye, MdArrowForward } from "react-icons/md"
import workspaces from "./assets/workspaces.png"
import { announcementWorkspaces as text } from "@modules/locales/default.json"
import { useAnnouncement } from "./useAnnouncement"
import {
  featureTitleCss,
  newFeatureIconCss,
  headingCss,
  imageCss,
  imageWrapperCss,
  contentCss,
  footerCss,
  listCss,
  listItemCss,
  newAnnouncementBtnCss,
  redBallCss,
} from "./Announcement.styles"

export function Announcement() {
  const {
    isNewAnnouncement,
    isOpened,
    openAnnouncement,
    closeAnnouncement,
  } = useAnnouncement("workspaces")
  const { flags } = useFlags()

  if (!flags.announcement) {
    return null
  }

  return (
    <React.Fragment>
      <button
        onClick={openAnnouncement}
        css={newAnnouncementBtnCss}
        aria-label={
          isNewAnnouncement
            ? text.actions.openNewAnnouncement
            : text.actions.seeReleaseNotes
        }
      >
        {isNewAnnouncement && <div css={redBallCss} aria-hidden={true} />}
        <CampaignIcon aria-hidden={true} />
      </button>
      <Modal
        aria-label={text.headers.title}
        onDismiss={closeAnnouncement}
        isOpen={isOpened && isNewAnnouncement}
      >
        <ModalCard>
          <StyledModal>
            <StyledModalHeader onCloseButtonClick={closeAnnouncement}>
              <Text css={featureTitleCss}>
                <MdRemoveRedEye css={newFeatureIconCss} /> {text.headers.title}
              </Text>
            </StyledModalHeader>

            <div css={{ maxHeight: "60vh", overflowY: "scroll" }}>
              <Heading as="h3" css={headingCss}>
                {text.headers.featureTitle}
              </Heading>

              <Spacer size={8} />

              <div css={imageWrapperCss} aria-hidden={true}>
                <img src={workspaces} css={imageCss} alt="" />
              </div>

              <StyledModalBody>
                <Text css={contentCss}>{text.messages.catchPhrase}</Text>

                <ul css={theme => [listCss(theme), contentCss(theme)]}>
                  {(text.messages.features as string[]).map(
                    (feature: string, index: number) => (
                      <li css={listItemCss} key={`announcement-${index}`}>
                        {feature}
                      </li>
                    )
                  )}
                </ul>
              </StyledModalBody>
            </div>

            <div css={footerCss}>
              <StyledModalActions>
                <AnchorButton
                  variant="SECONDARY"
                  href={`/release-notes`}
                  target="_self"
                  onClick={closeAnnouncement}
                >
                  {text.actions.goToReleaseNotes}
                </AnchorButton>
                <AnchorButton
                  href={`/dashboard/sites`}
                  target="_self"
                  onClick={closeAnnouncement}
                  rightIcon={<MdArrowForward />}
                >
                  {text.actions.viewWorkspaces}
                </AnchorButton>
              </StyledModalActions>
            </div>
          </StyledModal>
        </ModalCard>
      </Modal>
    </React.Fragment>
  )
}
