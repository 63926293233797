import * as React from "react"
import { ThemeCss } from "gatsby-interface"

const contentSectionCss: ThemeCss = theme => ({
  gridArea: `content`,
  minWidth: `300px`,
  justifySelf: `center`,
  width: `90%`,

  [theme.mediaQueries.tablet]: {
    width: `100%`,
  },
})

export type ContentSectionProps = {
  children: React.ReactNode
}

function ContentSection({ children }: ContentSectionProps) {
  return <section css={contentSectionCss}>{children}</section>
}

export default ContentSection
