import React from "react"

export function DeploymentSuccess(props) {
  return (
    <svg
      data-testid="deployment-success"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Successful deployment</title>
      <rect width="20" height="20" fill="transparent" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1C6.96667 1 4.44167 3.15833 3.875 6.03333C1.70833 6.18333 0 7.96667 0 10.1667C0 12.4108 1.77716 14.2425 4 14.3301V12.6612C2.69473 12.5758 1.66667 11.494 1.66667 10.1667C1.66667 8.78333 2.78333 7.66667 4.16667 7.66667H5.41667V7.25C5.41667 4.71667 7.46667 2.66667 10 2.66667C12.1 2.66667 13.8583 4.075 14.4083 6H15C16.8417 6 18.3333 7.49167 18.3333 9.33333C18.3333 10.8266 17.3527 12.0897 16 12.5142V14.233C18.2803 13.7689 20 11.7493 20 9.33333C20 6.75833 18.05 4.63333 15.5417 4.36667C14.5 2.36667 12.4083 1 10 1Z"
        fill="#B7B5BD"
      />
      <line
        x1="6.53033"
        y1="11.4697"
        x2="10.0659"
        y2="15.0052"
        stroke="#37B635"
        strokeWidth="1.5"
      />
      <line
        x1="9.00519"
        y1="15.0053"
        x2="14.662"
        y2="9.34846"
        stroke="#37B635"
        strokeWidth="1.5"
      />
    </svg>
  )
}
