import * as React from "react"
import { Button } from "gatsby-interface"
import { LoginOption, LoginOptionLabels } from "@modules/userAccount/constants"
import { SourceControlProviderIcon } from "@modules/sourceControlProvider"
import { SourceControlProvider } from "@modules/graphql/types"
import { auth as authText } from "@modules/locales/default.json"
import { interpolateMessage } from "@modules/locales"
import { useAvailableLoginOptions } from "../hooks/useAvailableLoginOptions"

const loginOptionIcons: Record<LoginOption, React.ReactNode> = {
  GITHUB: (
    <SourceControlProviderIcon
      sourceControlProvider={SourceControlProvider.Github}
      color="initial"
    />
  ),
  GITLAB: (
    <SourceControlProviderIcon
      sourceControlProvider={SourceControlProvider.Gitlab}
      color="initial"
    />
  ),
  BITBUCKET: (
    <SourceControlProviderIcon
      sourceControlProvider={SourceControlProvider.Bitbucket}
      color="initial"
    />
  ),
}

export type LoginButtonsProps = {
  actionType?: `signup` | `login`
  onLoginOptionClick: (loginOption: LoginOption) => void
}

export function LoginButtons({
  actionType = "login",
  onLoginOptionClick,
}: LoginButtonsProps) {
  const isSignUp = actionType === `signup`

  const availableLoginOptions = useAvailableLoginOptions()

  return (
    <React.Fragment>
      {availableLoginOptions.map(loginOption => (
        <Button
          key={loginOption}
          onClick={() => {
            onLoginOptionClick(loginOption)
          }}
          leftIcon={loginOptionIcons[loginOption]}
          variant="SECONDARY"
        >
          {interpolateMessage<"oauthProvider">(
            isSignUp ? authText.actions.signUpWith : authText.actions.logInWith,
            { oauthProvider: LoginOptionLabels[loginOption] }
          )}
        </Button>
      ))}
    </React.Fragment>
  )
}
