import * as React from "react"

export type FailedIconProps = React.ComponentPropsWithoutRef<"svg"> & {
  a11yId?: string
}

export default function FailedIcon({ a11yId, ...rest }: FailedIconProps) {
  const id = a11yId ? a11yId : `FailedIcon`

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      {...rest}
    >
      <circle cx="10" cy="10" r="10" fill="#FA2915" />
      <path
        d="M14.6663 6.2735L13.7263 5.3335L9.99967 9.06016L6.27301 5.3335L5.33301 6.2735L9.05967 10.0002L5.33301 13.7268L6.27301 14.6668L9.99967 10.9402L13.7263 14.6668L14.6663 13.7268L10.9397 10.0002L14.6663 6.2735Z"
        fill="white"
      />
    </svg>
  )
}
