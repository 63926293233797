import React from "react"
import { Redirect } from "@reach/router"
import { logout } from "@modules/auth"

export default class Logout extends React.Component {
  async componentDidMount() {
    return logout()
  }

  render() {
    return <Redirect to="/dashboard/login" noThrow />
  }
}
