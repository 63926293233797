import * as React from "react"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { ThemeCss } from "gatsby-interface"

const baseCss: ThemeCss = _theme => ({
  display: `flex`,
  alignItems: `stretch`,
  flexDirection: `column`,
  justifyContent: `center`,
  minHeight: `100vh`,
})

export type ServiceRouteLayoutProps = {
  children: React.ReactNode
}

export function ServiceRouteLayout({ children }: ServiceRouteLayoutProps) {
  return <StandardSingleColumn css={baseCss}>{children}</StandardSingleColumn>
}
