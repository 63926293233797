import React from "react"
import ScalableSvg from "@modules/ui/components/ScalableSvg"

export default function ContentstackLogoIcon(props) {
  return (
    <ScalableSvg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M12.3905 6.96382C12.3905 7.12357 12.2929 7.23007 12.1466 7.23007H7.51237C7.36603 7.23007 7.26846 7.12357 7.26846 6.96382V6.37809C7.26846 6.21834 7.36603 6.11185 7.51237 6.11185H12.1466C12.2929 6.11185 12.3905 6.21834 12.3905 6.37809V6.96382Z"
        fill="#EE5644"
      />
      <path
        d="M14.4392 8.61458C14.4392 8.77433 14.3417 8.88083 14.1953 8.88083H8.4879C8.34155 8.88083 8.24399 8.77433 8.24399 8.61458V8.02885C8.24399 7.8691 8.34155 7.7626 8.4879 7.7626H14.1465C14.2929 7.7626 14.3904 7.8691 14.3904 8.02885L14.4392 8.61458Z"
        fill="#EE5644"
      />
      <path
        d="M16 10.3185C16 10.4783 15.9024 10.5848 15.7561 10.5848H9.56086C9.41452 10.5848 9.31696 10.4783 9.31696 10.3185V9.73279C9.31696 9.57305 9.41452 9.46655 9.56086 9.46655H15.7561C15.9024 9.46655 16 9.57305 16 9.73279V10.3185Z"
        fill="#EE5644"
      />
      <path
        d="M16 12.0225C16 12.1822 15.9024 12.2887 15.7561 12.2887H9.56086C9.41452 12.2887 9.31696 12.1822 9.31696 12.0225V11.4367C9.31696 11.277 9.41452 11.1705 9.56086 11.1705H15.7561C15.9024 11.1705 16 11.277 16 11.4367V12.0225Z"
        fill="#EE5644"
      />
      <path
        d="M14.4392 13.6732C14.4392 13.833 14.3417 13.9395 14.1953 13.9395H8.4879C8.34155 13.9395 8.24399 13.833 8.24399 13.6732V13.0875C8.24399 12.9278 8.34155 12.8213 8.4879 12.8213H14.1465C14.2929 12.8213 14.3904 12.9278 14.3904 13.0875L14.4392 13.6732Z"
        fill="#EE5644"
      />
      <path
        d="M12.3905 15.377C12.3905 15.5367 12.2929 15.6432 12.1466 15.6432H7.51237C7.36603 15.6432 7.26846 15.5367 7.26846 15.377V14.7912C7.26846 14.6315 7.36603 14.525 7.51237 14.525H12.1466C12.2929 14.525 12.3905 14.6315 12.3905 14.7912V15.377Z"
        fill="#EE5644"
      />
      <path
        d="M3.60989 1.85198C3.60989 2.01172 3.70746 2.11822 3.8538 2.11822H8.48803C8.63438 2.11822 8.73194 2.01172 8.73194 1.85198V1.26624C8.73194 1.1065 8.63438 1 8.48803 1H3.8538C3.70746 1 3.60989 1.1065 3.60989 1.26624V1.85198Z"
        fill="#EE5644"
      />
      <path
        d="M1.5614 3.55593C1.5614 3.71567 1.65896 3.82217 1.80531 3.82217H7.46394C7.61028 3.82217 7.70785 3.71567 7.70785 3.55593V2.97019C7.70785 2.81045 7.61028 2.70395 7.46394 2.70395H1.80531C1.65896 2.70395 1.5614 2.81045 1.5614 2.97019V3.55593Z"
        fill="#EE5644"
      />
      <path
        d="M0 5.25987C0 5.41962 0.0975628 5.52612 0.243907 5.52612H6.43915C6.5855 5.52612 6.68306 5.41962 6.68306 5.25987V4.67414C6.68306 4.51439 6.5855 4.4079 6.43915 4.4079H0.243907C0.0975628 4.4079 0 4.51439 0 4.67414V5.25987Z"
        fill="#EE5644"
      />
      <path
        d="M0 6.96382C0 7.12357 0.0975628 7.23007 0.243907 7.23007H6.43915C6.5855 7.23007 6.68306 7.12357 6.68306 6.96382V6.37809C6.68306 6.21834 6.5855 6.11185 6.43915 6.11185H0.243907C0.0975628 6.11185 0 6.21834 0 6.37809V6.96382Z"
        fill="#EE5644"
      />
      <path
        d="M1.5614 8.61458C1.5614 8.77432 1.65896 8.88082 1.80531 8.88082H7.46394C7.61028 8.88082 7.70785 8.77432 7.70785 8.61458V8.02884C7.70785 7.8691 7.61028 7.7626 7.46394 7.7626H1.80531C1.65896 7.7626 1.5614 7.8691 1.5614 8.02884V8.61458Z"
        fill="#EE5644"
      />
      <path
        d="M3.60989 10.3183C3.60989 10.478 3.70746 10.5845 3.8538 10.5845H8.48803C8.63438 10.5845 8.73194 10.478 8.73194 10.3183V9.73255C8.73194 9.57281 8.63438 9.46631 8.48803 9.46631H3.8538C3.70746 9.46631 3.60989 9.57281 3.60989 9.73255V10.3183Z"
        fill="#EE5644"
      />
    </ScalableSvg>
  )
}
