/**
 * Pattern: https://www.w3.org/TR/wai-aria-practices/#feed
 */
import * as React from "react"
import { KeyboardKey } from "../constants"
const FeedContext = React.createContext<number>(0)

export type FeedProps = {
  loading?: boolean
  labelledBy: string
  count: number
} & React.HTMLAttributes<HTMLDivElement>

export function Feed({
  children,
  loading = false,
  labelledBy,
  count,
  ...props
}: FeedProps) {
  const feedRef = React.useRef<HTMLDivElement>(null)
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const nextElement = event.target as HTMLDivElement

    const focusedArticle =
      nextElement.tagName === "article"
        ? nextElement
        : nextElement.closest("article")

    if (!focusedArticle) return

    const focusedIndex = Number(focusedArticle.getAttribute("aria-posinset"))
    const keyCode = event.keyCode

    switch (keyCode) {
      case KeyboardKey.PAGE_UP: {
        event.preventDefault()
        feedRef?.current
          ?.querySelector<HTMLDivElement>(
            `[aria-posinset="${focusedIndex - 1}"]`
          )
          ?.focus()
        break
      }
      case KeyboardKey.PAGE_DOWN: {
        event.preventDefault()
        feedRef?.current
          ?.querySelector<HTMLDivElement>(
            `[aria-posinset="${focusedIndex + 1}"]`
          )
          ?.focus()
        break
      }

      case KeyboardKey.HOME: {
        if (event.ctrlKey) {
          event.preventDefault()
          feedRef?.current
            ?.querySelector<HTMLDivElement>(`[aria-posinset="1"]`)
            ?.focus()
        }
        break
      }

      case KeyboardKey.END: {
        if (event.ctrlKey) {
          event.preventDefault()
          feedRef?.current
            ?.querySelector<HTMLDivElement>(`[aria-posinset="${count}"]`)
            ?.focus()
        }
        break
      }
    }
  }

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <FeedContext.Provider value={count}>
      <div
        ref={feedRef}
        role="feed"
        aria-busy={loading}
        aria-labelledby={labelledBy}
        onKeyDown={handleKeyDown}
        {...props}
      >
        {children}
      </div>
    </FeedContext.Provider>
  )
}

export type FeedItemProps = {
  labelledBy: string
  describedBy: string
  position: number
  id?: string
} & React.HTMLAttributes<HTMLDivElement>

export function FeedItem({
  children,
  labelledBy,
  describedBy,
  position,
  ...props
}: FeedItemProps) {
  const count = React.useContext(FeedContext)

  return (
    <article
      tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
      aria-labelledby={labelledBy}
      aria-describedby={describedBy}
      aria-posinset={position}
      aria-setsize={count}
      {...props}
    >
      {children}
    </article>
  )
}
