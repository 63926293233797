import { MdRefresh, MdCheck, MdDoNotDisturb, MdTimer } from "react-icons/md"

import { BuildStatus, PreviewStatus } from "@modules/graphql/types"

export const oldBuildStatusVariants = {
  [BuildStatus.Queued]: {
    label: `Queued`,
    badgeVariant: `yellow`,
    Icon: MdTimer,
  },
  [BuildStatus.Canceled]: {
    label: `Canceled`,
    badgeVariant: `red`,
    Icon: MdDoNotDisturb,
  },
  [BuildStatus.Success]: {
    label: `Completed`,
    badgeVariant: `green`,
    Icon: MdCheck,
  },
  [BuildStatus.Error]: {
    label: `Failed`,
    badgeVariant: `red`,
    Icon: MdDoNotDisturb,
  },
  [BuildStatus.UploadError]: {
    label: `Upload Failed`,
    badgeVariant: `red`,
    Icon: MdDoNotDisturb,
  },
  [BuildStatus.PublishError]: {
    label: `Deploy Error`,
    badgeVariant: `red`,
    Icon: MdDoNotDisturb,
  },
  [BuildStatus.PublishCanceled]: {
    label: `Deploy Canceled`,
    badgeVariant: `red`,
    Icon: MdDoNotDisturb,
  },
  [BuildStatus.TimedOut]: {
    label: `Timed Out`,
    badgeVariant: `red`,
    Icon: MdTimer,
  },
  [BuildStatus.Building]: {
    label: `In Progress`,
    badgeVariant: `purple`,
    Icon: MdRefresh,
  },
  [BuildStatus.Uploading]: {
    label: `Uploading`,
    badgeVariant: `purple`,
    Icon: MdRefresh,
  },
  [BuildStatus.Publishing]: {
    label: `Deploying`,
    badgeVariant: `purple`,
    Icon: MdRefresh,
  },
}

export const buildStatusVariants = {
  [BuildStatus.Queued]: {
    ...oldBuildStatusVariants[BuildStatus.Queued],
  },
  [BuildStatus.Canceled]: {
    ...oldBuildStatusVariants[BuildStatus.Canceled],
  },
  [BuildStatus.Success]: {
    ...oldBuildStatusVariants[BuildStatus.Success],
    label: `Live`,
  },
  [BuildStatus.Error]: {
    ...oldBuildStatusVariants[BuildStatus.Error],
  },
  [BuildStatus.GitPullError]: {
    ...oldBuildStatusVariants[BuildStatus.Error],
  },
  [BuildStatus.DepInstallError]: {
    ...oldBuildStatusVariants[BuildStatus.Error],
  },
  [BuildStatus.UploadError]: {
    ...oldBuildStatusVariants[BuildStatus.UploadError],
  },
  [BuildStatus.PublishError]: {
    ...oldBuildStatusVariants[BuildStatus.PublishError],
  },
  [BuildStatus.PublishCanceled]: {
    ...oldBuildStatusVariants[BuildStatus.PublishCanceled],
  },
  [BuildStatus.TimedOut]: {
    ...oldBuildStatusVariants[BuildStatus.TimedOut],
  },
  [BuildStatus.Building]: {
    ...oldBuildStatusVariants[BuildStatus.Building],
  },
  [BuildStatus.Uploading]: {
    ...oldBuildStatusVariants[BuildStatus.Uploading],
  },
  [BuildStatus.Publishing]: {
    ...oldBuildStatusVariants[BuildStatus.Publishing],
  },
  [BuildStatus.GitClone]: {
    ...oldBuildStatusVariants[BuildStatus.Building],
  },
  [BuildStatus.GitPull]: {
    ...oldBuildStatusVariants[BuildStatus.Building],
  },
  [BuildStatus.DepInstall]: {
    ...oldBuildStatusVariants[BuildStatus.Building],
  },
  [PreviewStatus.Ready]: {
    ...oldBuildStatusVariants[BuildStatus.Success],
    label: `Live`,
  },
  [PreviewStatus.Pending]: {
    ...oldBuildStatusVariants[BuildStatus.Queued],
    label: `Pending`,
  },
  [PreviewStatus.Building]: {
    ...oldBuildStatusVariants[BuildStatus.Building],
    label: `Updating`,
  },
  [PreviewStatus.Stopped]: oldBuildStatusVariants[BuildStatus.Canceled],
  [PreviewStatus.Rebuilding]: {
    ...oldBuildStatusVariants[BuildStatus.Building],
    label: `Updating`,
  },
  [PreviewStatus.BuildError]: {
    ...oldBuildStatusVariants[BuildStatus.UploadError],
    label: `Error`,
  },
  [PreviewStatus.SystemError]: {
    ...oldBuildStatusVariants[BuildStatus.UploadError],
    label: `Error`,
  },
  [PreviewStatus.Unauthorized]: {
    ...oldBuildStatusVariants[BuildStatus.Error],
    label: `Unauthorized`,
  },
}

export const emptyBuildStatusVariant = {
  ...buildStatusVariants[PreviewStatus.Pending],
}
