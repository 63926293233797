import * as React from "react"

export default function ScalableSvg({
  style,
  ...rest
}: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      style={{
        verticalAlign: `middle`,
        ...(style || {}),
      }}
      aria-hidden
      {...rest}
    />
  )
}
