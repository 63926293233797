import { Log, LogSourceStream } from "@modules/graphql/types"

export type Function = {
  id: string
  name: string
  lastInvocationStatus?: FunctionInvokationStatus
  lastInvokedAt?: string
}

export enum FunctionInvokationStatus {
  TimedOut = "TIMED_OUT",
  Success = "SUCCESS",
  Failure = "FAILURE",
}

export const data: Function[] = [
  {
    id: `12345`,
    name: `Function One`,
    lastInvocationStatus: FunctionInvokationStatus.Success,
    lastInvokedAt: `2021-01-07T12:17:35.860Z`,
  },
  {
    id: `23456`,
    name: `Function Two`,
    lastInvocationStatus: FunctionInvokationStatus.Failure,
    lastInvokedAt: `2021-01-07T12:17:35.860Z`,
  },
  {
    id: `34567`,
    name: `Function Three`,
    lastInvocationStatus: FunctionInvokationStatus.TimedOut,
    lastInvokedAt: `2021-01-07T12:17:35.860Z`,
  },
]

const LOG_ITEMS: Log[] = [
  {
    id: "40c0e589-20cf-40fe-8466-260c6baf22eb",
    message:
      "Cloning into '/tmp/www/952da5c7-5d37-4ebd-a868-2d0130d68e23-BUILDS-master'...\n",
    timestamp: "2020-08-26T15:52:51.851Z",
    sourceStream: LogSourceStream.Stderr,
    command: "git",
    __typename: "Log",
  },
  {
    id: "52b14a20-0427-477e-ab37-115713030a5d",
    message:
      "warning package-lock.json found. Your project contains lock files generated by tools other than Yarn. It is advised not to mix package managers in order to avoid resolution inconsistencies caused by unsynchronized lock files. To clear this warning, remove package-lock.json.\n",
    timestamp: "2020-08-26T15:52:54.980Z",
    sourceStream: LogSourceStream.Stderr,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "3ad7ba4d-9585-4493-86e3-6e26b1811da1",
    message: "[1/4] Resolving packages...\n",
    timestamp: "2020-08-26T15:52:54.986Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "0887f725-4cc7-4376-aeeb-8deebb066631",
    message: "[2/4] Fetching packages...\n",
    timestamp: "2020-08-26T15:52:55.534Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "ecd66443-0c27-4bba-a102-eaa40c3a4790",
    message: "[3/4] Linking dependencies...\n",
    timestamp: "2020-08-26T15:52:56.437Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "bc1c694c-7f53-47f4-9fc4-85566dc11ff5",
    message:
      'warning "gatsby > react-hot-loader@4.12.20" has unmet peer dependency "@types/react@^15.0.0 || ^16.0.0".\n',
    timestamp: "2020-08-26T15:52:56.446Z",
    sourceStream: LogSourceStream.Stderr,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "0ddafb6e-9445-4644-9e64-2b0bd47702bf",
    message:
      'warning "gatsby > @typescript-eslint/eslint-plugin > tsutils@3.17.1" has unmet peer dependency "typescript@>=2.8.0 || >= 3.2.0-dev || >= 3.3.0-dev || >= 3.4.0-dev || >= 3.5.0-dev || >= 3.6.0-dev || >= 3.6.0-beta || >= 3.7.0-dev || >= 3.7.0-beta".\n',
    timestamp: "2020-08-26T15:52:56.447Z",
    sourceStream: LogSourceStream.Stderr,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "69082670-e38e-443b-bbb3-79071cfb8650",
    message: "[4/4] Building fresh packages...\n",
    timestamp: "2020-08-26T15:53:09.532Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "bb056415-cbe0-4a62-aa65-06bd6b25f178",
    message: "success Saved lockfile.\n",
    timestamp: "2020-08-26T15:53:38.867Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "e43f0078-b317-4b3b-81ba-44d325cb88d6",
    message:
      "From https://github.com/Elanhant/aleksei-blog-03-24\n * branch            master     -> FETCH_HEAD\n",
    timestamp: "2020-08-26T15:53:52.134Z",
    sourceStream: LogSourceStream.Stderr,
    command: "git",
    __typename: "Log",
  },
  {
    id: "eb45adfd-3df2-460c-8b5f-fc5dc95eac28",
    message: "HEAD is now at e6a9c6a Initial Commit\n",
    timestamp: "2020-08-26T15:53:52.202Z",
    sourceStream: LogSourceStream.Stdout,
    command: "git",
    __typename: "Log",
  },
  {
    id: "be06e1f6-d1c9-4a86-bebe-d7cdbf9e51f2",
    message: "Already on 'master'\n",
    timestamp: "2020-08-26T15:53:52.230Z",
    sourceStream: LogSourceStream.Stderr,
    command: "git",
    __typename: "Log",
  },
  {
    id: "9dcd7297-43ef-44cf-a845-7990c66cb7a2",
    message: "Your branch is up to date with 'origin/master'.\n",
    timestamp: "2020-08-26T15:53:52.238Z",
    sourceStream: LogSourceStream.Stdout,
    command: "git",
    __typename: "Log",
  },
  {
    id: "a7dd8d51-73bd-4870-89e1-140ad97a5d46",
    message:
      "warning package-lock.json found. Your project contains lock files generated by tools other than Yarn. It is advised not to mix package managers in order to avoid resolution inconsistencies caused by unsynchronized lock files. To clear this warning, remove package-lock.json.\n",
    timestamp: "2020-08-26T15:53:58.028Z",
    sourceStream: LogSourceStream.Stderr,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "5a3965de-bd07-4f0b-b4b5-e69cf1232a80",
    message: "[1/4] Resolving packages...\n",
    timestamp: "2020-08-26T15:53:58.038Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "5c728228-1384-4568-bcc4-7396eeda319f",
    message: "[2/4] Fetching packages...\n",
    timestamp: "2020-08-26T15:53:58.750Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "17de0bb7-095e-49f8-89ee-740bbc1779f5",
    message: "[3/4] Linking dependencies...\n",
    timestamp: "2020-08-26T15:53:59.991Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "535775ce-4cb2-4780-83ed-470afafc1005",
    message:
      'warning "gatsby > react-hot-loader@4.12.20" has unmet peer dependency "@types/react@^15.0.0 || ^16.0.0".\nwarning "gatsby > @typescript-eslint/eslint-plugin > tsutils@3.17.1" has unmet peer dependency "typescript@>=2.8.0 || >= 3.2.0-dev || >= 3.3.0-dev || >= 3.4.0-dev || >= 3.5.0-dev || >= 3.6.0-dev || >= 3.6.0-beta || >= 3.7.0-dev || >= 3.7.0-beta".\n',
    timestamp: "2020-08-26T15:53:59.992Z",
    sourceStream: LogSourceStream.Stderr,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "79b97236-2670-4303-a698-228891134ca2",
    message: "[4/4] Building fresh packages...\n",
    timestamp: "2020-08-26T15:54:11.381Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "3000feb7-c47d-474f-a66e-6704d365dd51",
    message: "success Saved lockfile.\n",
    timestamp: "2020-08-26T15:54:34.455Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Dependencies",
    __typename: "Log",
  },
  {
    id: "2ebaadf4-b52e-4d58-9357-04090d809bbd",
    message: "Node version v11.10.1",
    timestamp: "2020-08-26T15:54:34.639Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "1baa239c-d7cb-4336-a1cf-d555a453983d",
    message:
      "Gatsby version Gatsby CLI version: 2.10.11\nGatsby version: 2.19.45\n  Note: this is the Gatsby version for the site at: /private/tmp/www/952da5c7-5d37-4ebd-a868-2d0130d68e23-BUILDS-master",
    timestamp: "2020-08-26T15:54:35.449Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "8c8cc786-c360-456a-bca5-ca3f3bbd5c0a",
    message: "BUILDS",
    timestamp: "2020-08-26T15:54:35.450Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "667845d7-2a3a-4958-86e6-226812331741",
    message: "success open and validate gatsby-configs - 0.033s",
    timestamp: "2020-08-26T15:54:38.291Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "9bd8efb6-dfbc-4075-a195-3a5261af627f",
    message: "success load plugins - 3.036s",
    timestamp: "2020-08-26T15:54:41.304Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "c503b5d6-14c2-49e9-b64c-c0d154148e9d",
    message:
      "warning The Google Analytics plugin requires a tracking ID. Did you mean to add it?",
    timestamp: "2020-08-26T15:54:41.341Z",
    sourceStream: LogSourceStream.Stderr,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "6ec7e0d4-cf2e-4010-bbcd-4eaa9f2f4e11",
    message:
      "success onPreInit - 0.017s\nsuccess delete html and css files from previous builds - 0.020s",
    timestamp: "2020-08-26T15:54:41.342Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "61f53259-8c4d-4ff6-937d-6656c694bf03",
    message: "success initialize cache - 0.025s",
    timestamp: "2020-08-26T15:54:41.399Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "36921b9a-45ad-4356-a9cb-675e4b06ddc8",
    message:
      "success copy gatsby files - 0.077s\nsuccess onPreBootstrap - 0.007s",
    timestamp: "2020-08-26T15:54:41.453Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "fe7e043b-d2c1-43e4-8abf-06568c031048",
    message:
      "warning gatsby-plugin-feed was initialized in gatsby-config.js without a feeds option.\nThis means that the plugin will use the internal RSS feed creation, which may not match your use case.\nThis behavior will be removed in the next major release of gatsby-plugin-feed.\nFor more info, check out: https://gatsby.dev/adding-rss-feed",
    timestamp: "2020-08-26T15:54:41.456Z",
    sourceStream: LogSourceStream.Stderr,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "b6440bc6-c8b8-4b79-8da5-9859cd40836b",
    message: "success createSchemaCustomization - 0.198s",
    timestamp: "2020-08-26T15:54:41.653Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "7c810567-9c9e-4ed6-8974-ee2566114e51",
    message: "info source and transform nodes - 0.091s",
    timestamp: "2020-08-26T15:54:41.744Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "675b5ddf-5220-40b1-947c-d12b63f3d9e5",
    message: "info building schema - 0.252s",
    timestamp: "2020-08-26T15:54:41.997Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "7db274f8-7ca9-4a18-8436-661e3b0ee316",
    message: "success createPages - 0.026s",
    timestamp: "2020-08-26T15:54:42.042Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "7cb4d0f9-3054-48fd-9276-beb2f0562fc7",
    message:
      "success createPagesStatefully - 0.046s\nsuccess onPreExtractQueries - 0.000s\nsuccess update schema - 0.019s",
    timestamp: "2020-08-26T15:54:42.093Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "5881cec4-27b4-4a82-a839-0b2c5c8845de",
    message:
      "success extract queries from components - 0.236s\nsuccess write out requires - 0.003s\nsuccess write out redirect data - 0.000s",
    timestamp: "2020-08-26T15:54:42.338Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "861c3a03-f0e2-4540-abe2-57d4c5e8e6cf",
    message:
      'error "gatsby-plugin-manifest" threw an error while running the onPostBootstrap lifecycle:\n\nInput file contains unsupported image format',
    timestamp: "2020-08-26T15:54:42.343Z",
    sourceStream: LogSourceStream.Stderr,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "1ff1d663-292c-4fb1-9a2f-9ac2b4ea187c",
    message:
      "\n\n  Error: Input file contains unsupported image format\n\nnot finished onPostBootstrap - 0.013s\nnot finished Build manifest and related icons - 0.013s",
    timestamp: "2020-08-26T15:54:42.354Z",
    sourceStream: LogSourceStream.Stdout,
    command: "Gatsby",
    __typename: "Log",
  },
  {
    id: "a552155e-b3a2-4fba-b1bb-053e1ecafeba",
    message: "ERROR Failed to compile: Error: Exited with code 1",
    timestamp: "2020-08-26T15:54:42.440Z",
    sourceStream: LogSourceStream.Stdout,
    command: "StateMachine",
    __typename: "Log",
  },
]

export const mockLogs: Record<string, Log[]> = {
  12345: LOG_ITEMS,
  23456: [],
  34567: LOG_ITEMS.slice(5, 9),
}
