import * as Yup from "yup"
import { UserWelcomeInput } from "@modules/graphql/types"
import {
  leadCapture as text,
  ui as uiText,
} from "@modules/locales/default.json"

export type LeadCaptureFormValues = UserWelcomeInput

export const validationSchema = Yup.object<LeadCaptureFormValues>().shape({
  firstName: Yup.string().required(uiText.messages.validationIsRequired),
  lastName: Yup.string().required(uiText.messages.validationIsRequired),
  companyEmail: Yup.string()
    .email(uiText.messages.validationInvalidEmail)
    .required(uiText.messages.validationIsRequired),
  projectType: Yup.string().required(uiText.messages.validationIsRequired),
})

export const ProjectType = {
  CompanyProject: `COMPANY_PROJECT`,
  ClientProject: `CLIENT_PROJECT`,
  PersonalProject: `PERSONAL_PROJECT`,
}

export const projectTypeOptions = [
  {
    label: text.labels.projectTypeCompany,
    value: ProjectType.CompanyProject,
  },
  {
    label: text.labels.projectTypeClient,
    value: ProjectType.ClientProject,
  },
  {
    label: text.labels.projectTypePersonal,
    value: ProjectType.PersonalProject,
  },
]
