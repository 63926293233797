import * as React from "react"
import { ThemeCss, Spacer } from "gatsby-interface"
import useLighthouseScoresForBuild from "@modules/build/metrics/hooks/useLighthouseScoresForBuild"
import DonutChart from "@modules/metrics/components/DonutChart"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"

const contentCss: ThemeCss = theme => ({
  flex: 3,
  padding: 0,
  margin: 0,
  color: theme.colors.grey[70],
  fontSize: theme.fontSizes[0],
})

const contentErrorCss: ThemeCss = theme => ({
  flex: 3,
  padding: 0,
  margin: 0,
  color: theme.colors.red[70],
  fontSize: theme.fontSizes[0],
})

const rowCss: ThemeCss = theme => ({
  [theme.mediaQueries.phablet]: { display: "flex", flexDirection: "row" },
})

const titleCss: ThemeCss = theme => [contentCss(theme), { flex: 1 }]

const metricsCss: ThemeCss = theme => ({
  display: "flex",
  flexDirection: "column",
  [theme.mediaQueries.phablet]: {
    flex: 3,
    flexDirection: "row",
  },
})

export type DeployLighthouseRowProps = {
  siteId: string
  buildId: string
  branch: string
}

export function DeployLighthouseRow({
  siteId,
  buildId,
  branch,
}: DeployLighthouseRowProps) {
  const [lighthouseScores, { loading, error }] = useLighthouseScoresForBuild(
    siteId,
    buildId,
    branch
  )

  if (loading) {
    return (
      <div css={rowCss}>
        <div css={titleCss}>
          <strong>Lighthouse</strong>
        </div>
        <p css={contentCss}>Loading the Lighthouse scores...</p>
      </div>
    )
  }

  if (error) {
    return (
      <div css={rowCss}>
        <div css={titleCss}>
          <strong>Lighthouse</strong>
        </div>
        <p css={contentErrorCss}>
          Something went wrong when trying to load the Lighthouse scores
        </p>
      </div>
    )
  }

  if (lighthouseScores.length === 0) {
    return (
      <div css={rowCss}>
        <div css={titleCss}>
          <strong>Lighthouse</strong>
        </div>
        <p css={contentCss}>No report for this build</p>
      </div>
    )
  }

  return (
    <div css={rowCss}>
      <div css={titleCss}>
        <strong>Lighthouse</strong>
      </div>
      <div css={metricsCss}>
        {lighthouseScores.map(([label, value]: [string, number]) => {
          return (
            <React.Fragment key={label}>
              <span css={visuallyHiddenCss}>
                {`Metric ${label} is ${value} on 100`}
              </span>
              <span aria-hidden={true}>
                <span css={contentCss}>{label}</span>

                <Spacer size={3} direction="horizontal" />

                <DonutChart
                  value={value}
                  size={16}
                  strokewidth={4}
                  css={{ verticalAlign: `text-top` }}
                />

                <Spacer size={3} direction="horizontal" />

                <strong>{value}</strong>

                <Spacer size={7} direction="horizontal" />
              </span>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
