import { cdnConfigRequiredKeys } from "@modules/cdn/shared/constants"

export const isProperlyConnected = integration => {
  if (!integration.connected || !integration.config) {
    return false
  }

  return cdnConfigRequiredKeys[integration.vendor].every(requiredConfigKey =>
    Boolean(integration.config[requiredConfigKey])
  )
}
