import React from "react"
import PropTypes from "prop-types"
import { Redirect } from "@reach/router"
import { isAfter } from "date-fns"
import { setIdentify } from "@modules/fullStory"
import { useCurrentUserQuery } from "@modules/auth/queries.generated"
import { useMaintenanceMode } from "@modules/serviceRoutes/utils"
import { LocalStorageItems } from "@modules/localStorage/constants"
import { logout } from "@modules/auth"
import Loading from "@modules/ui/components/Loading"
import { FadeOnMount } from "@modules/animation"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { SkipNavTarget } from "@modules/a11y"
import { Spacer } from "gatsby-interface"

function ProtectedRoute({ location, ...rest }) {
  const tokenExpiration = localStorage.getItem(
    LocalStorageItems.GatsbyTokenExpiration
  )
  const shouldLogoutUser =
    !tokenExpiration || isAfter(new Date(), new Date(tokenExpiration))

  // If we do not have token expiration, log the user out
  if (shouldLogoutUser) {
    logout(rest.uri ? `?loginRedirectUrl=${rest.uri}` : ``)

    return null
  }

  if (!localStorage.getItem(LocalStorageItems.GatsbyToken)) {
    // If we’re not logged in, redirect to the home page.
    return (
      <Redirect to={`/dashboard/login?loginRedirectUrl=${rest.uri}`} noThrow />
    )
  }

  return <AuthenticatedRoute {...rest} location={location} />
}

function AuthenticatedRoute({ location, ...rest }) {
  const { data, loading, error } = useCurrentUserQuery()

  useMaintenanceMode({ error })

  const currentUser = data?.currentUser

  if (loading && !currentUser) {
    return (
      <FadeOnMount delay={1000}>
        <Loading delay={1000} message="authenticating ..." />
      </FadeOnMount>
    )
  }

  if (error) {
    return (
      <main>
        <SkipNavTarget />
        <StandardSingleColumn>
          <Spacer size={10} />
          <ErrorAlert>{error.message}</ErrorAlert>
        </StandardSingleColumn>
      </main>
    )
  }

  if (!currentUser) {
    return <Redirect to="/dashboard/logout" noThrow />
  }

  // Call identify API from fullstory
  if (location.search.includes(`loggedin=1`)) {
    setIdentify(currentUser)
  }

  if (currentUser.banned) {
    logout(`?error=blocked`)
    return null
  }

  const Component = rest.component

  return <Component {...rest} user={currentUser} location={location} />
}

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default ProtectedRoute
