import { useShowSuccessToast } from "gatsby-interface"
import { useGenericError } from "@modules/modal"
import { ui as uiText } from "@modules/locales/default.json"
import { SiteDetailsDocument } from "@modules/site/shared/queries.generated"

import {
  useTriggerBuildForSiteBranchMutation,
  useTriggerBuildForSitePullRequestMutation,
} from "@modules/build/shared/queries.generated"

export type TriggerCloudBuilderArgs = {
  siteId: string
  branch?: string
  pullRequestId?: string
  successMessage: React.ReactNode
  deleteCache?: boolean
}

export type TriggerBuildForSiteBranchArgs = {
  siteId: string
  branch: string
  deleteCache?: boolean
}

export type TriggerBuildForSitePullRequestArgs = {
  siteId: string
  pullRequestId: string
  deleteCache?: boolean
}

const useTriggerBuildForSiteBranch = () => {
  const [
    triggerCloudBuilderMutation,
    { loading },
  ] = useTriggerBuildForSiteBranchMutation()

  const triggerBuildForSiteBranch = async ({
    siteId,
    branch,
    deleteCache,
  }: TriggerBuildForSiteBranchArgs) => {
    const { data } = await triggerCloudBuilderMutation({
      variables: {
        siteId,
        deleteCache,
        branch,
      },
      refetchQueries: [
        {
          query: SiteDetailsDocument,
          variables: { id: siteId },
        },
      ],
    })
    const success = data?.triggerCloudBuilder?.success
    const message = data?.triggerCloudBuilder?.message

    return { success, message }
  }

  return [triggerBuildForSiteBranch, { loading }] as const
}

const useTriggerBuildForSitePullRequest = () => {
  const [
    triggerCloudBuilderMutation,
    { loading },
  ] = useTriggerBuildForSitePullRequestMutation()

  const triggerBuildForSiteBranch = async ({
    siteId,
    pullRequestId,
    deleteCache,
  }: TriggerBuildForSitePullRequestArgs) => {
    const { data } = await triggerCloudBuilderMutation({
      variables: {
        siteId,
        deleteCache,
        pullRequestId,
      },
    })
    const success = data?.triggerDeployPreview?.success
    const message = data?.triggerDeployPreview?.message

    return { success, message }
  }

  return [triggerBuildForSiteBranch, { loading }] as const
}

/**
 * Allows to trigger a build for a branch OR a pullRequestId depending on the arguments it receives
 * Relies on the two (private) hooks defined above:
 * - useTriggerBuildForSitePullRequest
 * - useTriggerBuildForSiteBranch
 */
export const useTriggerCloudBuilder = () => {
  const [
    triggerBuildForSiteBranch,
    { loading: loadingForSiteBranch },
  ] = useTriggerBuildForSiteBranch()

  const [
    triggerBuildForPullRequest,
    { loading: loadingForPullRequest },
  ] = useTriggerBuildForSitePullRequest()

  const throwError = useGenericError()
  const showSuccessToast = useShowSuccessToast()

  const triggerCloudBuilder = async ({
    siteId,
    branch,
    successMessage,
    deleteCache,
    pullRequestId,
  }: TriggerCloudBuilderArgs) => {
    try {
      let success = false
      let message = ""

      if (pullRequestId) {
        const result = await triggerBuildForPullRequest({
          siteId,
          pullRequestId,
          deleteCache,
        })

        success = Boolean(result.success)
        message = result.message || `Internal server error`
      } else if (branch) {
        const result = await triggerBuildForSiteBranch({
          siteId,
          branch: branch,
          deleteCache,
        })

        success = Boolean(result.success)
        message = result.message || uiText.messages.internalServerError
      }

      if (success) {
        return showSuccessToast(successMessage)
      }

      return throwError({ message })
    } catch (err) {
      throwError({ message: err.message })
    }
  }

  return [
    triggerCloudBuilder,
    loadingForPullRequest || loadingForSiteBranch,
  ] as const
}
