import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { MdArrowForward, MdCheck } from "react-icons/md"
import {
  Button,
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalActions,
  Text,
} from "gatsby-interface"
import { ModalMessage } from "@modules/modal"

import {
  loginErrors as text,
  ui as uiText,
} from "@modules/locales/default.json"
import { QueryError } from "./Errors.helpers"
import AlertHexagon from "@modules/assets/icons/AlertHexagon"
import { LoginOption, LoginOptionLabels } from "@modules/userAccount/constants"
import { interpolateMessage, FormattedMessage } from "@modules/locales"

function ErrorTemplate({
  query,
  title,
  description,
  buttonLabel,
  locales,
  closeModal,
}) {
  let finalTitle
  let finalDescription
  let finalButtonLabel

  if (locales) {
    const { heading, message, label } = locales

    finalTitle = heading && heading.join(" ")
    finalDescription = (
      <React.Fragment>
        <Text variant="EMPHASIZED">
          <AlertHexagon /> {message[0]}
        </Text>
        <Text>{message[1]}</Text>
      </React.Fragment>
    )
    finalButtonLabel = label
  } else {
    finalTitle = title
    finalDescription = description
    finalButtonLabel = buttonLabel
  }

  return (
    <StyledModal variant="ERROR">
      <StyledModalHeader onCloseButtonClick={closeModal}>
        {finalTitle}
      </StyledModalHeader>
      <StyledModalBody>
        <ModalMessage>{finalDescription}</ModalMessage>
        <StyledModalActions>
          <Button
            variant="PRIMARY"
            tone="NEUTRAL"
            onClick={closeModal}
            rightIcon={
              query === QueryError.InvitationMismatch ? (
                <MdArrowForward />
              ) : (
                <MdCheck />
              )
            }
          >
            {finalButtonLabel}
          </Button>
        </StyledModalActions>
      </StyledModalBody>
    </StyledModal>
  )
}

ErrorTemplate.propTypes = {
  query: PropTypes.string,
  locales: PropTypes.object,
  closeModal: PropTypes.func,
}

export function InvitationTokenError({ closeModal }) {
  return (
    <ErrorTemplate
      query={`invitation-error`}
      locales={text.invitationError}
      closeModal={() => {
        closeModal()
        navigate(`/dashboard/login/`)
      }}
    />
  )
}

export function InvitationRequired() {
  return (
    <ErrorTemplate
      query={`invitation-required`}
      locales={text.invitationRequired}
      closeModal={() => navigate(`/preview/`)}
    />
  )
}

InvitationTokenError.propTypes = {
  closeModal: PropTypes.func,
}

export function InvitationMismatchError() {
  return (
    <ErrorTemplate
      query={`invitation-mismatch`}
      locales={text.invitationMismatch}
      closeModal={() => navigate(`/preview/`)}
    />
  )
}

export function InvitationAcceptedError({ closeModal }) {
  return (
    <ErrorTemplate
      query={`invitation_accepted`}
      locales={text.invitationAccepted}
      closeModal={() => {
        closeModal()
        navigate(`/dashboard/login/`)
      }}
    />
  )
}

export function InvitationExpiredError({ closeModal }) {
  return (
    <ErrorTemplate
      query={`invitation_expired`}
      locales={text.invitationExpired}
      closeModal={() => {
        closeModal()
        navigate(`/dashboard/login/`)
      }}
    />
  )
}

export function PreviewAccessError({ closeModal }) {
  return (
    <ErrorTemplate
      query={`preview-access`}
      locales={text.previewAccess}
      closeModal={() => {
        closeModal()
      }}
    />
  )
}

export function UserBlocked({ closeModal }) {
  return (
    <ErrorTemplate
      query={`blocked`}
      locales={text.blocked}
      closeModal={() => {
        closeModal()
        navigate(`/dashboard/login/`)
      }}
    />
  )
}

export function TicketNotFoundError({ closeModal }) {
  return (
    <ErrorTemplate
      locales={text.ticketNotFound}
      closeModal={() => {
        closeModal()
        navigate(`/dashboard/login/`)
      }}
    />
  )
}

export function OAuthError({ closeModal, query }) {
  const oauthProvider = query.provider || LoginOption.Github
  const oauthProviderName = LoginOptionLabels[oauthProvider]

  return (
    <ErrorTemplate
      query={`oauth-error`}
      title={interpolateMessage(text.headers.oauthError, {
        oauthProvider: oauthProviderName,
      })}
      description={
        <FormattedMessage
          message={text.messages.oauthError}
          values={{ oauthProvider: oauthProviderName }}
          tags={{
            strong: function(content) {
              return <Text variant="EMPHASIZED">{content}</Text>
            },
          }}
        />
      }
      buttonLabel={uiText.actions.gotIt}
      closeModal={() => {
        closeModal()
        navigate(`/dashboard/login/`)
      }}
    />
  )
}

OAuthError.propTypes = {
  closeModal: PropTypes.func,
}
