import React from "react"
import { ZendeskContext } from "../context"
import { ZendeskStatus } from "../constants"

export function useTriggerZendeskWidget() {
  const { activateWidget, status, showWidget } = React.useContext(
    ZendeskContext
  )

  const toggleZendeskWidget = () => {
    if (status === ZendeskStatus.Initial) {
      activateWidget()
    } else if (status === ZendeskStatus.Ready) {
      showWidget()
    }
  }

  return toggleZendeskWidget
}
