import { useEffect } from "react"
import { navigate } from "@reach/router"

export function isMaintenanceError(error) {
  if (error) {
    console.info(error)
  }

  const networkError = error && error.networkError
  const statusCode = networkError && networkError.statusCode
  return statusCode && statusCode === 503
}

export function useMaintenanceMode({ error }) {
  useEffect(() => {
    if (isMaintenanceError(error)) {
      navigate(`/dashboard/maintenance`)
    }
  }, [error])
}
