import React from "react"
import PropTypes from "prop-types"
import { keyframes } from "@emotion/core"
import { getStandardSingleColumnStyles } from "../stylesheets/page"
import LoadingDots from "../assets/LoadingDots"
import BabyLoadingDots from "../assets/BabyLoadingDots"
import { dimensions } from "gatsby-interface"

const PADDED_PADDINGS = 12

const noDelayedEntry = keyframes`
  to {
    opacity: 1;
  }
`

const getContainerStyles = ({ bufferSize, variant, show, delay, theme }) => ({
  ...getStandardSingleColumnStyles,
  animation: `${noDelayedEntry} 0.25s ease-out forwards`,
  alignItems: `center`,
  display: variant === `baby` ? `inline-flex` : `flex`,
  flexDirection: variant === `baby` ? `row-reverse` : `column`,
  flexWrap: variant === `baby` ? `wrap` : undefined,
  justifyContent: `center`,
  minHeight:
    bufferSize === `full-page` &&
    `calc(100vh - ${dimensions.siteHeader.height})`,
  paddingTop:
    bufferSize === `padded` ? theme.space[PADDED_PADDINGS] : undefined,
  paddingBottom:
    bufferSize === `padded` ? theme.space[PADDED_PADDINGS] : undefined,
  opacity: show && delay ? 1 : 0,
  transition: `opacity 0.25s`,
})

const getDotsWrapperStyles = ({ variant, theme }) => ({
  display: variant === `baby` ? `flex` : undefined,
  marginTop: variant === `baby` ? theme.space[2] : undefined,
  marginBottom: variant === `baby` ? theme.space[2] : undefined,
})

const dotsStyles = {
  "& > path, & > circle": {
    opacity: 1,
    transition: `opacity 0.5s`,

    "&.off": {
      opacity: 0,
    },
  },
}

const getMessageStyles = ({ theme, variant }) => ({
  color: theme.colors.grey[50],
  fontFamily: theme.fonts.heading,
  fontSize: theme.fontSizes[4],
  lineHeight: theme.lineHeights.dense,
  marginTop: variant === `standard` ? theme.space[8] : undefined,
  marginRight: variant === `baby` ? theme.space[3] : undefined,
  textAlign: `center`,
  maxWidth: `500px`,
})

const actionStyles = theme => ({
  ...getMessageStyles({ theme }),
  marginTop: theme.space[3],
})

export const propTypes = {
  message: PropTypes.string,
  action: PropTypes.element,
  // While our spinner is always the same size, we can customize how much space
  // the container takes up
  // - `none` doesn't include any top/bottom padding
  // - `padded` adds a good amount of top/bottom padding
  // - `full-page` (default) increases the height to fill the screen below the
  //   header.
  bufferSize: PropTypes.oneOf([`none`, `padded`, `full-page`]),
  variant: PropTypes.oneOf([`standard`, `baby`]),
  delay: PropTypes.number,
}

function Loading({
  variant = `standard`,
  message = variant === `baby` ? `Loading` : `Loading...`,
  bufferSize = variant === `baby` ? `none` : `full-page`,
  delay = null,
  action = undefined,
  ...rest
}) {
  const elementsRef = React.useRef()
  const animationInterval = React.useRef()
  const [show, setShow] = React.useState(delay ? false : true)
  const [elements, setElements] = React.useState([])
  const [timeouts, setTimeouts] = React.useState([])

  React.useEffect(() => {
    const refElements = elementsRef.current
      ? Array.from(elementsRef.current.childNodes[0].childNodes)
      : []

    setElements(refElements.map((el, idx) => idx))

    if (delay) {
      const showTimeout = setTimeout(() => {
        setShow(true)
        clearTimeout(showTimeout)
      }, delay)

      return () => clearTimeout(showTimeout)
    }
  }, [])

  React.useEffect(() => {
    if (!animationInterval.current && elements.length > 0) {
      animationInterval.current = setInterval(() => {
        const randomIdx = Math.floor(Math.random() * elements.length)

        animateElement(elements[randomIdx])
      }, 200)
    }

    return function cleanup() {
      clearInterval(animationInterval.current)
    }
  }, [elements])

  const animateElement = idx => {
    elementsRef.current.childNodes[0].childNodes[idx].classList.add(`off`)

    setTimeouts({
      ...timeouts,
      [idx]: setTimeout(() => {
        elementsRef.current &&
          elementsRef.current.childNodes[0].childNodes[idx].classList.remove(
            `off`
          )
        clearTimeout(timeouts[idx])
      }, 400),
    })
  }

  const Dots = variant === `baby` ? BabyLoadingDots : LoadingDots

  return (
    <div
      css={theme =>
        getContainerStyles({ theme, show, variant, delay, bufferSize })
      }
      {...rest}
    >
      <div
        ref={elementsRef}
        aria-hidden="true"
        css={theme => getDotsWrapperStyles({ theme, variant })}
      >
        <Dots css={dotsStyles} />
      </div>

      {message && (
        <span css={theme => getMessageStyles({ theme, variant })}>
          {message}
        </span>
      )}
      {action && <span css={actionStyles}>{action}</span>}
    </div>
  )
}

Loading.propTypes = propTypes

export default Loading
