import React from "react"
import qs from "query-string"
import PropTypes from "prop-types"
import { Redirect } from "@reach/router"

export default class AuthRedirect extends React.Component {
  async componentDidMount() {
    const searchQuery = this.props.location.search

    const query = qs.parse(searchQuery)

    localStorage.setItem(`gatsby:token`, query.token)
    localStorage.setItem(`gatsby:userid`, query.id)
    if (query.expiration) {
      localStorage.setItem(`gatsby:token-expiration`, query.expiration)
    }
  }

  render() {
    const {
      location: { search: searchQuery, origin },
    } = this.props

    const query = qs.parse(searchQuery)

    const {
      token,
      loginRedirectUrl,
      selectedPlan,
      organizationId,
      new_user,
    } = query

    if (token) {
      if (!selectedPlan && Boolean(organizationId)) {
        return (
          <Redirect
            to={`/dashboard/welcome/upgrade/${organizationId}`}
            noThrow
          />
        )
      }

      if (loginRedirectUrl) {
        const { search, pathname } = createLoginRedirectUrlObject(
          origin,
          loginRedirectUrl
        )
        const stringifiedQuery = createLoginRedirectQuery(search, new_user)

        return <Redirect to={`${pathname}?${stringifiedQuery}`} noThrow />
      }

      return (
        <Redirect
          to={`/dashboard/sites?loggedin=1${new_user ? `&new_user=1` : ``}`}
          noThrow
        />
      )
    } else {
      return <Redirect to="/dashboard/login" noThrow />
    }
  }
}

AuthRedirect.propTypes = {
  location: PropTypes.object,
}

function createLoginRedirectUrlObject(origin, loginRedirectUrl) {
  return new URL(`${origin}${loginRedirectUrl}`)
}

function createLoginRedirectQuery(search, isNewUser) {
  const redirectQuery = qs.parse(search)

  redirectQuery.loggedin = `1`
  if (isNewUser) {
    redirectQuery.new_user = `1`
  }

  return qs.stringify(redirectQuery)
}
