import React from "react"
import Zendesk, { ZendeskAPI } from "react-zendesk"
import { isProduction } from "@modules/env/constants"
import { getTheme } from "gatsby-interface"
import { ZENDESK_KEY, ZendeskStatus } from "../constants"
import { ZendeskContext } from "../context"

const settings = {
  color: {
    launcher: getTheme().colors.grey[80],
  },
}

export function ZendeskProvider({ children }) {
  const isFloating = React.useRef(false)
  const [status, setStatus] = React.useState(ZendeskStatus.Initial)

  const activateWidget = () => setStatus(ZendeskStatus.Loading)
  const finishLoading = () => setStatus(ZendeskStatus.Ready)
  const markWidgetIsFloating = value => (isFloating.current = value)

  const showWidget = () => {
    ZendeskAPI("webWidget", "show")
    ZendeskAPI("webWidget", "open")
  }

  const hideWidget = () => {
    ZendeskAPI("webWidget", "hide")
  }

  const prepareForm = data => {
    ZendeskAPI("webWidget", "prefill", data)
  }

  React.useEffect(() => {
    if (status === ZendeskStatus.Ready) {
      showWidget()
    }
  }, [status])

  if (!isProduction && !ZENDESK_KEY) {
    console.error(
      `Invalid Zendesk API key; make sure to provide ZENDESK_KEY environment variable`
    )
  }

  return (
    <React.Fragment>
      <ZendeskContext.Provider
        value={{
          hideWidget,
          showWidget,
          prepareForm,
          markWidgetIsFloating,
          status,
          activateWidget,
          finishLoading,
        }}
      >
        {children}
      </ZendeskContext.Provider>

      {status !== ZendeskStatus.Initial && (
        <Zendesk
          {...settings}
          zendeskKey={ZENDESK_KEY}
          onLoaded={() => {
            finishLoading()
            // Hide the widget by default.
            ZendeskAPI("webWidget", "hide")

            ZendeskAPI("webWidget:on", "open", () => {
              document.getElementById(`webWidget`).focus()
            })

            ZendeskAPI("webWidget:on", "close", () => {
              // We do not want to hide the "Help" widget if it "floating", i.e.
              // not triggered by some other element
              if (!isFloating.current) {
                ZendeskAPI("webWidget", "hide")
              }
            })
          }}
        />
      )}
    </React.Fragment>
  )
}
