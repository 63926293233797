import React from "react"

export default function(props) {
  return (
    <svg
      width="412"
      height="382"
      viewBox="0 0 412 382"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="248" cy="247" r="135" fill="white" />
      <path
        d="M199 192C205.627 192 211 186.627 211 180C211 173.373 205.627 168 199 168C192.373 168 187 173.373 187 180C187 186.627 192.373 192 199 192Z"
        fill="#D9BAE8"
      />
      <path
        d="M179.531 123.406C179.531 123.406 210.994 132.752 215.619 135.162C220.33 137.523 227.037 139.54 225.425 142.549C223.814 145.557 212.543 143.635 212.543 143.635"
        fill="white"
      />
      <path
        d="M179.531 123.406C179.531 123.406 210.994 132.752 215.619 135.162C220.33 137.523 227.037 139.54 225.425 142.549C223.814 145.557 212.543 143.635 212.543 143.635"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M189.96 128.046L216.785 146.383C216.785 146.383 230.597 156.306 227.919 159.468C225.192 162.544 211.766 153.09 211.766 153.09"
        fill="white"
      />
      <path
        d="M189.96 128.046L216.785 146.383C216.785 146.383 230.597 156.306 227.919 159.468C225.192 162.544 211.766 153.09 211.766 153.09"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <path
        d="M-18.9887 195.211C-18.9887 195.211 -11.6969 161.441 32.7809 127.679C49.7165 114.823 66.4875 108.08 81.7045 104.642C94.8107 101.114 110.687 98.8801 128.219 100.983C130.902 101.305 133.482 101.703 135.983 102.154C136.532 102.165 150.408 104.693 157.899 108.155C175.771 114.885 186.124 123.016 189.843 126.719C193.563 130.421 202.19 138.519 209.08 148.637C219.361 163.736 222.06 170.357 219.132 172.048C217.389 173.054 212.164 167.179 207.102 161.211C202.039 155.242 192.911 140.074 182.948 145.826C175.154 150.326 175.873 160.908 176.53 164.659C178.439 175.565 187.235 190.001 179.095 194.701C179.095 194.701 169.625 176.898 168.38 174.843C159.195 159.684 149.385 150.193 130.039 144.735C100.639 136.439 78.4664 150.608 70.399 155.035C62.3315 159.461 47.2306 172.106 44.4309 178.457"
        fill="white"
      />
      <path
        d="M-18.9887 195.211C-18.9887 195.211 -11.6969 161.441 32.7809 127.679C49.7165 114.823 66.4875 108.08 81.7045 104.642C94.8107 101.114 110.687 98.8801 128.219 100.983C130.902 101.305 133.482 101.703 135.983 102.154C136.532 102.165 150.408 104.693 157.899 108.155C175.771 114.885 186.124 123.016 189.843 126.719C193.563 130.421 202.19 138.519 209.08 148.637C219.361 163.736 222.06 170.357 219.132 172.048C217.389 173.054 212.164 167.179 207.102 161.211C202.039 155.242 192.911 140.074 182.948 145.826C175.154 150.326 175.873 160.908 176.53 164.659C178.439 175.565 187.235 190.001 179.095 194.701C179.095 194.701 169.625 176.898 168.38 174.843C159.195 159.684 149.385 150.193 130.039 144.735C100.639 136.439 78.4664 150.608 70.399 155.035C62.3315 159.461 47.2306 172.106 44.4309 178.457"
        stroke="#D9BAE8"
        strokeMiterlimit="10"
      />
      <circle
        cx="333.519"
        cy="156.519"
        r="14.5"
        transform="rotate(-90 333.519 156.519)"
        stroke="#D9BAE8"
      />
      <circle
        cx="353.519"
        cy="156.519"
        r="14.5"
        transform="rotate(-90 353.519 156.519)"
        stroke="#D9BAE8"
      />
      <circle
        cx="373.519"
        cy="156.519"
        r="14.5"
        transform="rotate(-90 373.519 156.519)"
        stroke="#D9BAE8"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="375"
        y="143"
        width="31"
        height="31"
      >
        <circle
          cx="390.519"
          cy="158.519"
          r="13"
          transform="rotate(-90 390.519 158.519)"
          fill="white"
          stroke="#D9BAE8"
          strokeWidth="4"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect
          x="397.144"
          y="189.937"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 397.144 189.937)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="396.012"
          y="191.069"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 396.012 191.069)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="399.407"
          y="187.674"
          width="42.1469"
          height="0.266754"
          transform="rotate(-135 399.407 187.674)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266754"
        />
        <rect
          x="398.275"
          y="188.805"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 398.275 188.805)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="401.67"
          y="185.41"
          width="42.1469"
          height="0.266753"
          transform="rotate(-135 401.67 185.41)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266753"
        />
        <rect
          x="400.539"
          y="186.542"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 400.539 186.542)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="403.934"
          y="183.147"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 403.934 183.147)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="402.802"
          y="184.278"
          width="42.1469"
          height="0.266756"
          transform="rotate(-135 402.802 184.278)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266756"
        />
        <rect
          x="406.197"
          y="180.883"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 406.197 180.883)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="405.066"
          y="182.015"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 405.066 182.015)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="408.461"
          y="178.62"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 408.461 178.62)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="407.329"
          y="179.751"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 407.329 179.751)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="410.724"
          y="176.356"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 410.724 176.356)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="409.593"
          y="177.488"
          width="42.1469"
          height="0.266759"
          transform="rotate(-135 409.593 177.488)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266759"
        />
        <rect
          x="412.988"
          y="174.093"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 412.988 174.093)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="411.856"
          y="175.225"
          width="42.1469"
          height="0.266751"
          transform="rotate(-135 411.856 175.225)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266751"
        />
        <rect
          x="415.251"
          y="171.829"
          width="42.1469"
          height="0.266753"
          transform="rotate(-135 415.251 171.829)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266753"
        />
        <rect
          x="414.119"
          y="172.961"
          width="42.1469"
          height="0.266751"
          transform="rotate(-135 414.119 172.961)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266751"
        />
        <rect
          x="417.515"
          y="169.566"
          width="42.1469"
          height="0.266753"
          transform="rotate(-135 417.515 169.566)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266753"
        />
        <rect
          x="416.383"
          y="170.698"
          width="42.1469"
          height="0.266751"
          transform="rotate(-135 416.383 170.698)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266751"
        />
        <rect
          x="419.778"
          y="167.302"
          width="42.1469"
          height="0.266754"
          transform="rotate(-135 419.778 167.302)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266754"
        />
        <rect
          x="418.646"
          y="168.434"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 418.646 168.434)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="422.042"
          y="165.039"
          width="42.1469"
          height="0.266752"
          transform="rotate(-135 422.042 165.039)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.266752"
        />
        <rect
          x="420.91"
          y="166.171"
          width="42.1469"
          height="0.26675"
          transform="rotate(-135 420.91 166.171)"
          fill="#663399"
          stroke="#D9BAE8"
          strokeWidth="0.26675"
        />
      </g>
      <circle
        cx="388.519"
        cy="156.519"
        r="14.5"
        transform="rotate(-90 388.519 156.519)"
        fill="white"
        stroke="#D9BAE8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M404.826 170.802L411.537 168.417L393.5 158.5L398.758 178.4L402.696 172.466L407.411 178.5L409.541 176.836L404.826 170.802Z"
        fill="#D9BAE8"
      />
    </svg>
  )
}
