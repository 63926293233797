function getElementFontSize(context) {
  return parseFloat(
    getComputedStyle(context || document.documentElement).fontSize
  )
}

function convertRemToPx(fontSize) {
  return convertEmToPx(fontSize)
}

function convertEmToPx(fontSize, context) {
  return parseFloat(fontSize) * getElementFontSize(context)
}

export function convertFontSizeToPx(fontSize) {
  if (!fontSize) {
    return fontSize
  }
  if (/^(\d.)+rem$/.test(fontSize)) {
    return `${convertRemToPx(fontSize)}px`
  }
  if (/^(\d.)+em$/.test(fontSize)) {
    return `${convertEmToPx(fontSize)}px`
  }
  return fontSize
}
