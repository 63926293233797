// THIS IS A GENERATED FILE
import * as Types from "../graphql/types"

import { PermissionsForOrganizationFragment } from "../organization/fragments.generated"
import gql from "graphql-tag"
import { PermissionsForOrganizationFragmentDoc } from "../organization/fragments.generated"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type UserOrganizationsQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type UserOrganizationsQuery = { __typename?: "Query" } & {
  currentOrganizations?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "Organization" } & Pick<
          Types.Organization,
          "id" | "name"
        > & {
            billing?: Types.Maybe<
              { __typename?: "BillingInformation" } & Pick<
                Types.BillingInformation,
                "id" | "status"
              > & {
                  plan?: Types.Maybe<
                    { __typename?: "SubscriptionPlan" } & Pick<
                      Types.SubscriptionPlan,
                      "id" | "name"
                    >
                  >
                }
            >
            permissions?: Types.Maybe<
              {
                __typename?: "PermissionsDigest"
              } & PermissionsForOrganizationFragment
            >
          }
      >
    >
  >
}

export const UserOrganizationsDocument = gql`
  query UserOrganizations {
    currentOrganizations {
      id
      name
      billing {
        id
        status
        plan {
          id
          name
        }
      }
      permissions {
        ...permissionsForOrganization
      }
    }
  }
  ${PermissionsForOrganizationFragmentDoc}
`

/**
 * __useUserOrganizationsQuery__
 *
 * To run a query within a React component, call `useUserOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOrganizationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserOrganizationsQuery,
    UserOrganizationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    UserOrganizationsQuery,
    UserOrganizationsQueryVariables
  >(UserOrganizationsDocument, baseOptions)
}
export function useUserOrganizationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserOrganizationsQuery,
    UserOrganizationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    UserOrganizationsQuery,
    UserOrganizationsQueryVariables
  >(UserOrganizationsDocument, baseOptions)
}
export type UserOrganizationsQueryHookResult = ReturnType<
  typeof useUserOrganizationsQuery
>
export type UserOrganizationsLazyQueryHookResult = ReturnType<
  typeof useUserOrganizationsLazyQuery
>
export type UserOrganizationsQueryResult = ApolloReactCommon.QueryResult<
  UserOrganizationsQuery,
  UserOrganizationsQueryVariables
>
