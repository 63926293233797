import * as React from "react"
import { ThemeCss, Text } from "gatsby-interface"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { createSite as createSiteText } from "@modules/locales/default.json"
import {
  useSupportedSourceControlProviders,
  SourceControlProviderIcon,
  sourceControlProviderLabels,
} from "@modules/sourceControlProvider"
import { SourceControl, SourceControlProvider } from "@modules/graphql/types"

export enum SiteCreationType {
  Provision = `provision`,
  Import = `import`,
}

type DescriptionProps = {
  available?: boolean
}

export const siteTypes = [
  {
    value: SiteCreationType.Import,
    label: createSiteText.labels.siteTypeImport,
    Description: function() {
      return <ImportSiteDescription />
    },
    providers: [SourceControlProvider.Github, SourceControlProvider.Gitlab],
  },
  {
    value: SiteCreationType.Provision,
    label: createSiteText.labels.siteTypeProvision,
    Description: function({ available }: DescriptionProps) {
      return (
        <SiteTypeDescription
          available={available}
          supportedProviders={[SourceControlProvider.Github]}
          description={createSiteText.messages.provisionDescription}
        />
      )
    },
    providers: [SourceControlProvider.Github],
  },
]

function ImportSiteDescription() {
  const supportedProviders = useSupportedSourceControlProviders()

  return (
    <SiteTypeDescription
      supportedProviders={supportedProviders}
      description={createSiteText.messages.importDescription}
    />
  )
}

const spaceCss: ThemeCss = theme => ({
  marginTop: 0,
  marginBottom: theme.space[3],
})
const iconSpaceCss: ThemeCss = theme => ({
  marginRight: theme.space[3],
})

type SiteTypeDescriptionProps = {
  supportedProviders: SourceControlProvider[]
  description: React.ReactNode
  available?: boolean
}

function SiteTypeDescription({
  supportedProviders,
  description,
  available,
}: SiteTypeDescriptionProps) {
  return (
    <React.Fragment>
      <p css={spaceCss}>
        <span css={visuallyHiddenCss}>
          {createSiteText.labels.siteTypeSupportedProviders}
        </span>
        {available === false ? (
          <Text css={theme => ({ color: theme.colors.red[50] })}>
            {createSiteText.messages.notAvailableForGitlabUsers}
          </Text>
        ) : (
          supportedProviders.map(provider => {
            const label = sourceControlProviderLabels[provider]
            return (
              <SourceControlProviderIcon
                key={provider}
                sourceControlProvider={provider}
                aria-label={label}
                size="small"
                css={iconSpaceCss}
              />
            )
          })
        )}
      </p>
      {description}
    </React.Fragment>
  )
}

export const isConnectedToSourceProvider = (
  sourceProviders: Pick<SourceControl, "source" | "connected">[],
  providers: SourceControlProvider[]
) =>
  sourceProviders
    .filter(sourceProvider => sourceProvider.connected)
    .reduce(
      (acc, curr) =>
        acc || providers.includes(curr.source || SourceControlProvider.Github),
      false
    )
