import React from "react"
import differenceInMonths from "date-fns/differenceInMonths"

const MONTH_DISTANCE = 3

export const useSurvey = () => {
  const [isVisible, setIsVisible] = React.useState(false)

  React.useEffect(() => {
    const lastSurveyDateStr = window.localStorage.getItem(
      `gatsby:last-survey-date`
    )

    if (!lastSurveyDateStr) {
      setIsVisible(true)
    } else if (lastSurveyDateStr) {
      const lastSurveyDate = Number(lastSurveyDateStr)
      const monthDifference = differenceInMonths(Date.now(), lastSurveyDate)

      if (monthDifference >= MONTH_DISTANCE) {
        setIsVisible(true)
      }
    }
  }, [])

  const hideSurveyBanner = () => {
    setIsVisible(false)
    window && window.localStorage.setItem(`gatsby:last-survey-date`, Date.now())
  }

  return { isVisible, hideSurveyBanner }
}
