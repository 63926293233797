import * as React from "react"
import { Redirect } from "@reach/router"

import Loading from "@modules/ui/components/Loading"
import { recordFirstTimeVisitor } from "@modules/fullStory"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { acceptInvitation as acceptInvitationText } from "@modules/locales/default.json"
import { useAcceptInvite } from "./InvitationRedirect.helpers"

export type InvitationRedirectProps = {
  invitationId: string
}

export function InvitationRedirect({ invitationId }: InvitationRedirectProps) {
  const { trackSegment } = useTracker()
  const [redirectPath, setRedirectPath] = React.useState<string>(``)
  const [success, setSuccess] = React.useState<boolean>(false)
  const [error, setError] = React.useState<boolean>(false)

  const [acceptInvite] = useAcceptInvite()

  React.useEffect(() => {
    acceptInvite(invitationId, setRedirectPath, setSuccess, setError)
  }, [])

  if (error) {
    return null
  }

  if (success) {
    recordFirstTimeVisitor()
    trackSegment({
      type: SegmentEventType.Track,
      event: `Invitation to Gatsby Cloud accepted, authenticated user`,
      properties: {
        invitationId,
      },
    })

    if (redirectPath) {
      return <Redirect to={redirectPath} noThrow />
    }
    return <Redirect to="/dashboard/sites" noThrow />
  }

  return (
    <Loading
      delay={1000}
      message={acceptInvitationText.messages.acceptingInvitation}
    />
  )
}
