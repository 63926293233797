import * as React from "react"
import { ErrorModal } from "@modules/modal"
import {
  useMaintenanceMode,
  isMaintenanceError,
} from "@modules/serviceRoutes/utils"
import { UserOrganizationsDropdown } from "./UserOrganizationsDropdown"
import { useOrganizationInfo } from "./UserOrganizationsHeader.helpers"

export type UserOrganizationsHeaderProps = {
  pathname: string
}

export function UserOrganizationsHeader({
  pathname,
}: UserOrganizationsHeaderProps) {
  const {
    currentOrganizations,
    selectedOrganizationName,
    selectedOrganizationId,
    error,
  } = useOrganizationInfo()

  useMaintenanceMode({ error })

  if (error) {
    if (isMaintenanceError(error)) {
      return null
    }
    return <ErrorModal errMsg={error.message} />
  }

  return selectedOrganizationId && selectedOrganizationName ? (
    <UserOrganizationsDropdown
      userOrganizations={currentOrganizations}
      selectedOrganizationId={selectedOrganizationId}
      path={pathname}
    />
  ) : null
}
