import * as React from "react"
import { functionsView as text } from "@modules/locales/default.json"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { FunctionInvokationStatus } from "@modules/site/details/components/mocks/functions.mocks"

export type FunctionStatusLabelProps = {
  status?: FunctionInvokationStatus
}

export function FunctionStatusLabel({
  status,
  ...rest
}: FunctionStatusLabelProps) {
  return (
    <span {...rest}>
      <span css={visuallyHiddenCss}>{text.labels.lastInvocationStatus}:</span>
      {getLabel(status)}
    </span>
  )
}

function getLabel(status?: FunctionInvokationStatus) {
  switch (status) {
    case FunctionInvokationStatus.Success:
      return text.labels.runSuccessfully
    case FunctionInvokationStatus.Failure:
      return text.labels.failed
    case FunctionInvokationStatus.TimedOut:
      return text.labels.timedOut
    default:
      return null
  }
}
