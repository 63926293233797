import * as React from "react"
import { ThemeCss, Spacer, LinkButton, Link } from "gatsby-interface"
import {
  BuildStatus,
  BuildCommit,
  BuildRunnerType,
  BuildType,
  User,
  BuildSource,
} from "@modules/graphql/types"
import {
  Card,
  CardHeader,
  CardFooter,
  CardSeparator,
  CardContent,
} from "@modules/ui/components/Card"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import BuildStatusIndicator from "@modules/build/shared/components/BuildStatusIndicator"
import { DateTimeDistanceToNow } from "@modules/ui/components/DateTimeDistance"
import { BuildEventText } from "@modules/build/shared/components/BuildEventText"
import BuildAuthor from "./BuildAuthor"
import BuildCardStatusInfo from "./BuildCardStatusInfo"
import { MdArrowForward, MdFilterList } from "react-icons/md"
import { GoGitPullRequest } from "react-icons/go"
import useBuildChangedSubscription from "@modules/build/shared/hooks/useBuildChangedSubscription"
import { usePullRequestChangedSubscription } from "@modules/build/shared/hooks/usePullRequestChangedSubscription"
import { DeployLighthouseRow } from "./DeployLighthouseRow"
import { DeploymentStatus } from "./DeploymentStatus"
import { DeploymentTiming } from "./DeploymentTiming"
import { DeployCommit } from "./DeployCommit"
import { isIncrementalUpdate } from "@modules/build/shared/utils"
import IncrementalBuildChip from "@modules/build/shared/components/IncrementalBuildChip"
import { deploysView as deploysViewText } from "@modules/locales/default.json"
import { FormattedMessage } from "@modules/locales"
import { useFlags } from "@modules/featureFlags"
import { getContextuallyFormatedDate } from "@modules/ui/utils/getContextuallyFormatedDate"
import { LighthouseScores } from "@modules/build/shared/components/LighthouseScores"
import BuildCommitInfo from "@modules/build/shared/components/BuildCommitInfo"

export type DeployCardProps = {
  id: string
  siteId: string
  organizationId: string
  title: string
  buildId: string
  status: BuildStatus
  branch: string
  runnerType: BuildRunnerType
  buildType: BuildType
  isProductionBranch: boolean
  viewDetailsHref: string
  viewAllBuildsHref?: string
  repositoryUrl?: string
  createdAt: string

  // optional fields
  pullRequestId?: string
  commit?: BuildCommit
  author?: Partial<User>
  source?: BuildSource
  duration?: number
  startedAt?: string // coming as ISOstring
  endedAt?: string // coming as ISOstring
  deployStartedAt?: string
  deployEndedAt?: string
  onBuildSucceed?: () => void
}

const deployCardHeaderCss: ThemeCss = theme => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",

  a: {
    textDecoration: `none`,

    "&:hover": {
      textDecoration: `underline`,
    },
  },

  [theme.mediaQueries.tablet]: {
    flexDirection: "row",
  },
})

const headerActionCss: ThemeCss = theme => ({
  padding: `${theme.space[2]} 0`,
  marginTop: theme.space[2],
  display: `flex`,

  [theme.mediaQueries.phablet]: {
    padding: 0,
    marginTop: 0,
  },
})

const headerActionIconCss: ThemeCss = theme => ({
  display: "none",
  [theme.mediaQueries.phablet]: {
    display: "inline",
    verticalAlign: "middle",
  },
})

const deployCardContentCss: ThemeCss = theme => ({
  display: "flex",
  flexDirection: "column",
  color: theme.colors.grey[60],

  [theme.mediaQueries.phablet]: {
    flexDirection: "row",
  },
})

const deployCardStatusCss: ThemeCss = () => ({
  flex: 1,
})

const deployCardStatusRow: ThemeCss = theme => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
})

const deployCardDetailsCss: ThemeCss = theme => ({
  [theme.mediaQueries.phablet]: {
    flex: 3,
  },
})

const deployCardCommitDetailsCss: ThemeCss = theme => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.space[5],

  [theme.mediaQueries.phablet]: {
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 0,
  },
})

const headerTitleCss: ThemeCss = theme => ({
  [theme.mediaQueries.tablet]: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    paddingRight: theme.space[5],
    flex: 1,
  },
})

const titleCss: ThemeCss = theme => ({
  margin: 0,
  fontSize: theme.fontSizes[2],
  fontWeight: theme.fontWeights.semiBold,
  fontFamily: theme.fonts.body,
  display: `flex`,
  justifyContent: `flex-start`,
})

const titleLinkCss: ThemeCss = theme => ({
  display: `block`,
  overflow: `hidden`,
  textOverflow: `ellipsis`,
})

export function DeployCard({
  id,
  siteId,
  organizationId,
  title,
  buildId,
  pullRequestId,
  status,
  startedAt,
  createdAt,
  duration,
  endedAt,
  branch,
  commit,
  runnerType,
  buildType,
  author,
  source,
  isProductionBranch,
  viewDetailsHref,
  viewAllBuildsHref,
  deployStartedAt,
  deployEndedAt,
  onBuildSucceed,
  repositoryUrl,
}: DeployCardProps) {
  const { flags } = useFlags()
  const creationDate = getContextuallyFormatedDate(createdAt)

  useBuildChangedSubscription(buildId, runnerType, onBuildSucceed)
  usePullRequestChangedSubscription(pullRequestId)

  return (
    <Card as={`article`} data-cy={buildId}>
      <CardHeader>
        <div css={deployCardHeaderCss}>
          <div id={id} css={headerTitleCss}>
            {flags.siteBuildCardsUpdate ? (
              <h3 css={titleCss}>
                <Link to={viewDetailsHref} css={titleLinkCss}>
                  <BuildEventText
                    commit={commit}
                    buildType={buildType}
                    buildSource={source}
                    runnerType={runnerType}
                    truncate={false}
                  />
                </Link>
              </h3>
            ) : (
              <React.Fragment>
                {isProductionBranch ? (
                  <React.Fragment>
                    <span css={visuallyHiddenCss}>
                      <FormattedMessage<"title">
                        message={deploysViewText.labels.buildOfTitle}
                        values={{
                          title,
                        }}
                      />
                    </span>
                    <strong aria-hidden={true}>{title}</strong>
                  </React.Fragment>
                ) : (
                  <span data-testid="not-production-branch-title">
                    <GoGitPullRequest />{" "}
                    <FormattedMessage<"title", "strong">
                      message={deploysViewText.headers.pullRequest}
                      values={{
                        title: title,
                      }}
                      tags={{
                        strong: function(content) {
                          return <strong>{content}</strong>
                        },
                      }}
                    />
                  </span>
                )}
              </React.Fragment>
            )}
          </div>

          <div css={headerActionCss}>
            {viewAllBuildsHref && (
              <Link to={viewAllBuildsHref}>
                {flags.siteBuildCardsUpdate ? (
                  deploysViewText.labels.history
                ) : (
                  <React.Fragment>
                    {deploysViewText.labels.allBuilds}
                    <MdFilterList css={headerActionIconCss} />
                  </React.Fragment>
                )}
              </Link>
            )}

            {!flags.siteBuildCardsUpdate && (
              <React.Fragment>
                <Spacer direction="horizontal" size={5} />
                <Link to={viewDetailsHref} css={{ textDecoration: "none" }}>
                  {deploysViewText.labels.buildDetails}{" "}
                  <MdArrowForward css={headerActionIconCss} />
                </Link>
              </React.Fragment>
            )}

            {flags.siteBuildCardsUpdate && status === BuildStatus.Success && (
              <React.Fragment>
                <Spacer direction="horizontal" size={5} />
                <Link
                  href={`https://build-${buildId}${process.env.GATSBY_PREVIEW_DOMAIN}`}
                  css={{ textDecoration: "none" }}
                >
                  {deploysViewText.actions.viewDeploy}
                </Link>
              </React.Fragment>
            )}
          </div>
        </div>
      </CardHeader>

      <CardSeparator />

      <CardContent>
        <div css={deployCardContentCss}>
          <div css={deployCardStatusCss}>
            <div css={deployCardStatusRow} data-cy="deploy-build-status">
              <BuildStatusIndicator
                id={`deploy-status-${buildId}`}
                a11yId={buildId}
                buildStatus={status}
                runnerType={runnerType}
                branch={branch}
              />
              <Spacer direction="horizontal" size={3} />
              <BuildCardStatusInfo
                buildStatus={status}
                createdAt={createdAt}
                startedAt={startedAt}
                endedAt={endedAt}
                duration={duration}
              />
            </div>

            <Spacer size={3} />

            {deployStartedAt && (
              <div css={deployCardStatusRow}>
                <DeploymentStatus value={status} />

                <Spacer direction="horizontal" size={3} />

                <DeploymentTiming
                  status={status}
                  startDate={new Date(deployStartedAt)}
                  endDate={deployEndedAt ? new Date(deployEndedAt) : undefined}
                  vendor={""}
                />
              </div>
            )}

            {flags.siteBuildCardsUpdate && status === BuildStatus.Error && (
              <LinkButton
                to={`${viewDetailsHref}#errors`}
                variant="SECONDARY"
                size="S"
                tone="DANGER"
                rightIcon={<MdArrowForward />}
              >
                {deploysViewText.actions.viewErrors}
              </LinkButton>
            )}
          </div>

          <div css={deployCardDetailsCss}>
            {!flags.siteBuildCardsUpdate && (
              <React.Fragment>
                <div css={{ display: "flex", flexDirection: "row" }}>
                  <BuildEventText
                    commit={commit}
                    buildType={buildType}
                    buildSource={source}
                    runnerType={runnerType}
                  />

                  {isIncrementalUpdate(runnerType, buildType) && (
                    <React.Fragment>
                      <Spacer size={5} direction="horizontal" />
                      <IncrementalBuildChip />
                    </React.Fragment>
                  )}
                </div>

                <Spacer size={3} />
              </React.Fragment>
            )}

            <div css={deployCardCommitDetailsCss}>
              <BuildAuthor
                buildType={buildType}
                commit={commit}
                author={author}
                source={source}
              />

              {!flags.siteBuildCardsUpdate && startedAt && (
                <DateTimeDistanceToNow fromDate={startedAt} />
              )}

              {flags.siteBuildCardsUpdate && creationDate && (
                <React.Fragment>
                  <span css={visuallyHiddenCss}>
                    {deploysViewText.messages.buildTriggered}
                  </span>
                  <span>{creationDate}</span>
                </React.Fragment>
              )}

              <Spacer size={5} direction="horizontal" />

              {flags.siteBuildCardsUpdate &&
                isIncrementalUpdate(runnerType, buildType) && (
                  <IncrementalBuildChip />
                )}
            </div>

            {commit?.sha && (
              <React.Fragment>
                <Spacer size={3} />

                {flags.siteBuildCardsUpdate ? (
                  <BuildCommitInfo
                    branch={branch}
                    commit={commit}
                    repositoryUrl={repositoryUrl || ""}
                    withLink={true}
                    withIcon={true}
                  />
                ) : (
                  <DeployCommit branch={branch} sha={commit.sha.substr(0, 7)} />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </CardContent>

      {flags.siteBuildCardsUpdate && status === BuildStatus.Success && (
        <LighthouseScores
          siteId={siteId}
          organizationId={organizationId}
          buildId={buildId}
          branch={branch}
        />
      )}

      {!flags.siteBuildCardsUpdate && (
        <React.Fragment>
          <CardSeparator spacing={7} />

          <CardFooter>
            {status === BuildStatus.Success ? (
              <DeployLighthouseRow
                siteId={siteId}
                buildId={buildId}
                branch={branch}
              />
            ) : (
              "\u00A0"
            )}
          </CardFooter>
        </React.Fragment>
      )}
    </Card>
  )
}
