import { useQuery, useSubscription } from "react-apollo"
import { useGenericError } from "@modules/modal"
import { METRIC_SETTINGS } from "@modules/metrics/metrics"
import unzipCamelCaseStr from "@modules/toolkit/helpers/unzipCamelCaseStr"
import {
  REPORTS_FOR_BUILD,
  BUILD_REPORT_CREATED,
} from "@modules/metrics/queries"
import { updateCacheOnReportAdded } from "@modules/metrics/apollo/updateCacheOnReportAdded"

const extractLighthouseScores = ({ data, settings }) =>
  data.reduce((acc, item) => {
    if (settings[item.key].featuredOnBuild) {
      return [...acc, [unzipCamelCaseStr({ str: item.key }), item.value]]
    } else {
      return acc
    }
  }, [])

export default function useLighthouseScoresForBuild(siteId, buildId, branch) {
  const throwError = useGenericError()
  const { data, ...restData } = useQuery(REPORTS_FOR_BUILD, {
    variables: {
      siteId,
      buildId,
    },
    onError: error => throwError({ message: error.message }),
  })

  useSubscription(BUILD_REPORT_CREATED, {
    variables: {
      buildId,
    },
    onSubscriptionData: updateCacheOnReportAdded({
      query: REPORTS_FOR_BUILD,
      resolverName: `reportsForBuild`,
      payloadName: `buildReportCreated`,
      siteId,
      buildId,
      branch,
    }),
  })

  const reportsForBuild = (data && data.reportsForBuild) || []

  const lighthouseScores =
    reportsForBuild.length > 0
      ? extractLighthouseScores({
          data: reportsForBuild[0].metrics,
          settings: METRIC_SETTINGS,
        })
      : []

  return [lighthouseScores || [], restData]
}
