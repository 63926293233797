import { Organization } from "@modules/graphql/types"
import { LocalStorageItems } from "@modules/localStorage/constants"

export function getPathToOrgDetails(organizationId: string) {
  return `/dashboard/organization/${organizationId}`
}

export function getPathToOrgDetailsTab(organizationId: string, tab: string) {
  return `${getPathToOrgDetails(organizationId)}/${tab}`
}

export function getPathToOrgSites(organizationId: string) {
  return getPathToOrgDetailsTab(organizationId, `sites`)
}

export function getPathToOrgSettings(organizationId: string) {
  return getPathToOrgDetailsTab(organizationId, `settings`)
}

export function getPersistentOrganizationId() {
  if (typeof window === `undefined`) {
    return null
  }

  return window.localStorage.getItem(LocalStorageItems.GatsbyOrganizationId)
}

export function setPersistentOrganizationId(organizationId: string) {
  if (typeof window === `undefined`) {
    return
  }

  window.localStorage.setItem(
    LocalStorageItems.GatsbyOrganizationId,
    organizationId
  )
}

export function getNumberOfSitesInOrganization(organization: Organization) {
  return organization.repositories?.reduce((accu, repo) => {
    return accu + (repo.sites?.length ?? 0)
  }, 0)
}
