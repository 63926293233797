import { ThemeCss } from "gatsby-interface"
import { SupportedPlanTier } from "@modules/billing/constants"
import { getColorsForPlanTier } from "../utils"

export function getPlanButtonCss(
  tier: SupportedPlanTier,
  variant = `PRIMARY`
): ThemeCss {
  const getPlanColors = getColorsForPlanTier(tier)

  return theme => [
    {
      color: theme.colors.white,
      border: `none`,
      borderRadius: theme.radii[2],
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
      minHeight: `36px`,
      width: `100%`,
      margin: 0,
      fontSize: theme.fontSizes[2],
      lineHeight: theme.lineHeights.solid,
      fontWeight: theme.fontWeights.semiBold,
      textDecoration: `none`,
      backgroundColor: getPlanColors(theme).primaryColor,
      "&:hover": {
        backgroundColor: getPlanColors(theme).primaryColorDark,
      },
    },
    variant === `SECONDARY` && {
      background: `transparent`,
      border: `1px solid ${getPlanColors(theme).primaryColor}`,
      color: getPlanColors(theme).primaryColor,
      ":hover": {
        borderColor: getPlanColors(theme).primaryColorDark,
        background: theme.colors.grey[10],
        color: getPlanColors(theme).primaryColor,
      },
    },
  ]
}
