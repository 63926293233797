import React from "react"
import { Modal, ModalCard } from "gatsby-interface"
import { DefaultError, GlobalError } from "./ErrorModal"
import { isProduction } from "@modules/env/constants"

const GenericErrorContext = React.createContext()

function GenericError({ errorDetails, onDismiss }) {
  const ErrorComponent =
    errorDetails && errorDetails.type !== undefined
      ? GlobalError({ errorType: errorDetails.type })
      : DefaultError

  const { message, ...props } = errorDetails

  const handleDismiss = () => {
    onDismiss()

    if (typeof props.onDismiss === "function") {
      props.onDismiss()
    }
  }

  return (
    <Modal
      isOpen={Boolean(errorDetails)}
      aria-label={`Something wrong occured`}
      data-testid={"generic-error-modal"}
      onDismiss={handleDismiss}
      type="error"
    >
      <ModalCard className="error">
        <ErrorComponent
          closeModal={handleDismiss}
          errMsg={message}
          {...props}
        />
      </ModalCard>
    </Modal>
  )
}

export function GenericErrorProvider({ children }) {
  const [activeError, setActiveError] = React.useState()

  /**
   * @type {object} errorDetails
   * @property {string} [type] - An optional type that can be used to create a GlobalError modal
   * @property {string} [message] - An optional message (not every modals needs it)
   */
  const throwError = errorDetails => {
    setActiveError(errorDetails)
  }

  return (
    <React.Fragment>
      <GenericErrorContext.Provider value={throwError}>
        {children}
      </GenericErrorContext.Provider>

      {activeError && (
        <GenericError
          errorDetails={activeError}
          onDismiss={() => setActiveError(null)}
        />
      )}
    </React.Fragment>
  )
}

export const useGenericError = () => {
  if (!isProduction) {
    console.warn(`useGenericError should not be used for anything other than extremely severe errors.
Use useTriggerErrorAlert hook or ErrorAlert component instead.    
`)
  }
  return React.useContext(GenericErrorContext)
}
