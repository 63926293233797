import styled from "@emotion/styled"
import { Link } from "gatsby"

export const LoginRoot = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`

export const Main = styled.main`
  max-width: 100%;
  padding: ${props => props.theme.space[7]};
  width: 50rem;
`

export const Content = styled.div`
  max-width: 100%;
  padding: ${props => props.theme.space[7]};
  width: 50rem;
`

// The logo needs to be precisely positioned to line up with the
// decorative dots behind it.
const logoOffsetBy = 22

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-grow: 0;
  text-decoration: none;
  position: absolute;
  left: ${logoOffsetBy}px;
  top: ${logoOffsetBy}px;
`
