import { CmsVendor } from "@modules/graphql/types"
import ContentfulLogoIcon from "@modules/logos/components/ContentfulLogoIcon"
import CosmicJsLogoIcon from "@modules/logos/components/CosmicJsLogoIcon"
import DatoCmsLogoIcon from "@modules/logos/components/DatoCmsLogoIcon"
import DrupalLogoIcon from "@modules/logos/components/DrupalLogoIcon"
import SanityLogoIcon from "@modules/logos/components/SanityLogoIcon"
import StrapiLogoIcon from "@modules/logos/components/StrapiLogoIcon"
import ContentstackLogoIcon from "@modules/logos/components/ContentstackLogoIcon"
import WordpressLogoIcon from "@modules/logos/components/WordpressLogoIcon"

export const CmsVendorName: Partial<Record<CmsVendor, string>> = {
  [CmsVendor.Agility]: "AgilityCMS",
  [CmsVendor.Contentful]: "Contentful",
  [CmsVendor.Cosmicjs]: "Cosmic",
  [CmsVendor.Datocms]: "Dato CMS",
  [CmsVendor.Drupal]: "Drupal",
  [CmsVendor.Flotiq]: "Flotiq",
  [CmsVendor.Prismic]: "Prismic",
  [CmsVendor.Kontent]: "Kontent",
  [CmsVendor.Sanity]: "Sanity",
  [CmsVendor.Shopify]: "Shopify",
  [CmsVendor.Contentstack]: "Contentstack",
  [CmsVendor.Strapi]: "Strapi",
  [CmsVendor.Wordpress]: "WordPress",
}

export const CmsVendorLogoIcon: Partial<Record<
  CmsVendor,
  React.ComponentType<React.ComponentPropsWithoutRef<"svg">>
>> = {
  [CmsVendor.Contentstack]: ContentstackLogoIcon,
  [CmsVendor.Strapi]: StrapiLogoIcon,
  [CmsVendor.Contentful]: ContentfulLogoIcon,
  [CmsVendor.Cosmicjs]: CosmicJsLogoIcon,
  [CmsVendor.Datocms]: DatoCmsLogoIcon,
  [CmsVendor.Drupal]: DrupalLogoIcon,
  [CmsVendor.Sanity]: SanityLogoIcon,
  [CmsVendor.Wordpress]: WordpressLogoIcon,
}
