import { isToday, isYesterday, isThisYear, format } from "date-fns"

export const getContextuallyFormatedDate = (date?: string) => {
  if (!date) {
    return null
  }

  const dateObj = new Date(date)
  const isTodayDate = isToday(dateObj)
  const isYesterdayDate = isYesterday(dateObj)
  const isThisYearDate = isThisYear(dateObj)

  if (isTodayDate) {
    return `Today at ${format(dateObj, `h:mm a`)}`
  } else if (isYesterdayDate) {
    return `Yesterday at ${format(dateObj, `h:mm a`)}`
  } else if (isThisYearDate) {
    return format(dateObj, `MMMM do 'at' h:mm a`)
  } else {
    return format(dateObj, `MMMM do yyyy 'at' h:mm a`)
  }
}
