import { useState, useEffect } from "react"

export const usePersisentState = <T>(key: string, initialValue: T) => {
  const [state, setState] = useState<T>()

  useEffect(() => {
    const rawData = window.localStorage.getItem(key)

    if (rawData) {
      const data = JSON.parse(rawData)
      setState(data)
    } else {
      setState(initialValue)
    }
  }, [key, initialValue])

  const modifyState = (newValue: T) => {
    setState(() => {
      if (typeof window !== undefined) {
        window.localStorage.setItem(key, JSON.stringify(newValue))
      }

      return newValue
    })
  }

  return [state, modifyState] as const
}
