// THIS IS A GENERATED FILE
import * as Types from "../graphql/types"

import gql from "graphql-tag"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type AcceptResourceInvitationMutationVariables = Types.Exact<{
  invitationId: Types.Scalars["UUID"]
}>

export type AcceptResourceInvitationMutation = { __typename?: "Mutation" } & {
  acceptResourceInvitation?: Types.Maybe<
    { __typename?: "AcceptInvitationResult" } & Pick<
      Types.AcceptInvitationResult,
      "redirectPath"
    > & {
        mutationResult?: Types.Maybe<
          { __typename?: "MutationResult" } & Pick<
            Types.MutationResult,
            "success" | "message"
          >
        >
      }
  >
}

export const AcceptResourceInvitationDocument = gql`
  mutation AcceptResourceInvitation($invitationId: UUID!) {
    acceptResourceInvitation(invitationId: $invitationId) {
      mutationResult {
        success
        message
      }
      redirectPath
    }
  }
`
export type AcceptResourceInvitationMutationFn = ApolloReactCommon.MutationFunction<
  AcceptResourceInvitationMutation,
  AcceptResourceInvitationMutationVariables
>

/**
 * __useAcceptResourceInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptResourceInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptResourceInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptResourceInvitationMutation, { data, loading, error }] = useAcceptResourceInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useAcceptResourceInvitationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AcceptResourceInvitationMutation,
    AcceptResourceInvitationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AcceptResourceInvitationMutation,
    AcceptResourceInvitationMutationVariables
  >(AcceptResourceInvitationDocument, baseOptions)
}
export type AcceptResourceInvitationMutationHookResult = ReturnType<
  typeof useAcceptResourceInvitationMutation
>
export type AcceptResourceInvitationMutationResult = ApolloReactCommon.MutationResult<
  AcceptResourceInvitationMutation
>
export type AcceptResourceInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AcceptResourceInvitationMutation,
  AcceptResourceInvitationMutationVariables
>
