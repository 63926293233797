/** @jsx jsx */
import { jsx } from "@emotion/core"
import PropTypes from "prop-types"

import SuccessIcon from "@modules/ui/assets/SuccessIcon"
import QueuedIcon from "@modules/ui/assets/QueuedIcon"
import FailedIcon from "@modules/ui/assets/FailedIcon"

import { InProgressIcon } from "gatsby-interface"
import {
  BuildStatus,
  PreviewStatus,
  BuildRunnerType,
} from "@modules/graphql/types"
import { getBuildStatusVariant } from "../utils"

const propTypes = {
  buildStatus: PropTypes.oneOf([
    ...Object.values(BuildStatus),
    ...Object.values(PreviewStatus),
  ]),
  runnerType: PropTypes.oneOf(Object.values(BuildRunnerType)).isRequired,
  a11yId: PropTypes.string.isRequired,
}

export default function BuildStatusIcon({
  buildStatus,
  runnerType,
  a11yId,
  ...rest
}) {
  const Icon = getIconComponent(buildStatus)

  if (!Icon) {
    if (process.env.NODE_ENV !== `production`) {
      console.error(`Could not find an icon for build status "${buildStatus}"`)
    }
    return null
  }

  const statusVariant = getBuildStatusVariant(buildStatus, runnerType)

  // InProgressIcon uses "id" prop to get unique IDs for its inner elements
  const a11yIdProp =
    Icon === InProgressIcon
      ? undefined
      : { a11yId: `BuildStatusIcon--${a11yId}` }

  return (
    <Icon
      {...a11yIdProp}
      id={Icon === InProgressIcon ? `BuildStatusIcon--${a11yId}` : undefined}
      aria-label={statusVariant && statusVariant.label}
      css={theme => [
        {
          width: theme.space[5],
          height: theme.space[5],
        },
        statusVariant && {
          color: getIconColor(theme)[statusVariant.badgeVariant],
        },
      ]}
      data-build-status-icon="true"
      {...rest}
    />
  )
}

BuildStatusIcon.propTypes = propTypes

function getIconComponent(status) {
  switch (status) {
    case BuildStatus.Queued:
    case PreviewStatus.Pending:
      return QueuedIcon
    // PublishError means that the build has been successful but not the third party deployment
    case BuildStatus.Publishing:
    case BuildStatus.PublishCanceled:
    case BuildStatus.PublishError:
    case BuildStatus.Success:
    case PreviewStatus.Ready:
      return SuccessIcon
    case BuildStatus.Failed:
    case BuildStatus.Error:
    case BuildStatus.UploadError:
    case BuildStatus.GitCloneError:
    case BuildStatus.GitPullError:
    case BuildStatus.DepInstallError:
    case BuildStatus.TimedOut:
    case BuildStatus.Canceled:
    case PreviewStatus.Stopped:
    case PreviewStatus.BuildError:
    case PreviewStatus.SystemError:
    case PreviewStatus.Unauthorized:
      return FailedIcon
    case BuildStatus.Building:
    case BuildStatus.Uploading:
    case PreviewStatus.Building:
    case PreviewStatus.Rebuilding:
    case BuildStatus.GitClone:
    case BuildStatus.GitPull:
    case BuildStatus.DepInstall:
      return InProgressIcon
    default:
      return QueuedIcon
  }
}

const getIconColor = theme => ({
  grey: theme.colors.grey[80],
  lightgrey: theme.colors.grey[40],
  green: theme.colors.green[60],
  yellow: theme.colors.yellow[90],
  purple: theme.colors.purple[50],
  blue: theme.colors.blue[80],
  red: theme.colors.red[60],
})
