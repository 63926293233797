/** @jsx jsx */
import { jsx } from "@emotion/core"
import { Fragment } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import SVGInline from "react-svg-inline"
import { Global, css } from "@emotion/core"
import { useTheme } from "gatsby-interface"

import logo from "!raw-loader!svgo-loader!./logo.svg"

export function Logo({ isInverted }) {
  const { space, colors } = useTheme()

  return (
    <Fragment>
      <Global
        styles={css`
          .nav-open .logo-holder {
            position: fixed !important;
            z-index: 100 !important;
          }

          .nav-open .logo {
            fill: ${colors.white} !important;
          }

          .nav-open .logo .st0 {
            fill: transparent !important;
          }

          .nav-open .logo .st1 {
            fill: ${colors.white} !important;
          }

          .logo-holder,
          .logo-holder .logo {
            /*
            Inline elements add a few pixels of spacing below.
            We don't want that.
          */
            display: flex;
          }

          .nav-open svg > path {
            display: none;
          }
        `}
      />
      <Link
        to="/"
        css={{
          alignItems: `center`,
          color: `inherit`,
          display: `flex`,
          marginRight: space[4],
          textDecoration: `none`,
          "& .logo": {
            fill: isInverted ? colors.white : colors.black,
          },
          "& .st0": {
            fill: isInverted ? `transparent` : colors.white,
          },
          "& .st1": {
            fill: isInverted ? colors.white : colors.gatsby,
          },
        }}
        className="logo-holder"
        aria-label="Link to home"
      >
        <SVGInline svg={logo} height={space[7]} className="logo" />
      </Link>
    </Fragment>
  )
}

Logo.propTypes = {
  isInverted: PropTypes.bool,
}
