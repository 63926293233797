import * as React from "react"
import { format } from "date-fns"
import { ThemeCss, Avatar, Text } from "gatsby-interface"
import { StackedDotsAlongScreenEdge } from "@modules/decorative"
import {
  userSettings as text,
  organizations as orgText,
} from "@modules/locales/default.json"
import { User, PermissionsStats } from "@modules/graphql/types"

import { useUserStatsQuery } from "@modules/user-settings/queries.generated"

const userDetailsContainerCss: ThemeCss = theme => ({
  position: `relative`,
  // Ensure that this container floats above the background dot elements
  zIndex: 1,
  display: `flex`,
  flexDirection: `row`,
  flexWrap: `wrap`,
  padding: 0,
  width: `90%`,
  alignItems: `center`,
  justifySelf: `center`,

  [theme.mediaQueries.tablet]: {
    flexDirection: `column`,
    paddingLeft: theme.space[5],
    paddingRight: theme.space[5],
    alignItems: `flex-start`,
    justifySelf: `flex-end`,
    width: `auto`,
  },

  [theme.mediaQueries.desktop]: {
    paddingLeft: theme.space[9],
    paddingRight: theme.space[9],
  },
})

const userDetailsTitleCss: ThemeCss = theme => ({
  width: `100%`,
})

const detailsMetadataCss: ThemeCss = theme => ({
  margin: `${theme.space[8]} 0`,
})

const metadataCss: ThemeCss = theme => ({
  margin: theme.space[2],
  fontSize: theme.fontSizes[1],
})

// Naive pluralize function, assumes that every word's plural form simply adds
// an `s`.
const pluralize = (word: string, num: number) => (num === 1 ? word : `${word}s`)

export type UserStatsProps = {
  createdAt: string
  userStats: PermissionsStats
}

function UserStats({ createdAt, userStats }: UserStatsProps) {
  return (
    <div css={detailsMetadataCss}>
      <Text css={metadataCss}>
        {text.joined} {format(new Date(createdAt), `MMMM do, yyyy`)}
      </Text>
      <Text css={metadataCss}>
        {text.owns}
        {` `}
        {userStats.totalOrganizationsOwnedCount}
        {` `}
        {pluralize(
          orgText.morphemes.organization,
          userStats?.totalOrganizationsOwnedCount || 0
        )}
      </Text>
      <Text css={metadataCss}>
        {text.created}
        {` `}
        {userStats.totalSitesOwnedCount}
        {` `}
        {pluralize(text.site, userStats?.totalSitesOwnedCount || 0)}
      </Text>
    </div>
  )
}

export type UserDetailsProps = {
  user?: User
}

function UserDetails({ user }: UserDetailsProps) {
  const { loading, data } = useUserStatsQuery()

  if (!user) {
    return null
  }

  return (
    <React.Fragment>
      <div css={userDetailsContainerCss}>
        <h2 css={userDetailsTitleCss}>{text.accountOverview}</h2>
        <Avatar size="XXL" src={user.avatarUrl || ""} label={`user avatar`} />

        {data?.currentUser?.stats && !loading && (
          <UserStats
            createdAt={user.createdAt}
            userStats={data.currentUser.stats}
          />
        )}
      </div>
      <StackedDotsAlongScreenEdge side="left" />
    </React.Fragment>
  )
}

export default UserDetails
