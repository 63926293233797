import React from "react"
import ScalableSvg from "@modules/ui/components/ScalableSvg"

export default function DrupalLogoIcon(props) {
  return (
    <ScalableSvg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M7.66883 9.90662C6.27482 9.90662 5.14429 11.0372 5.14429 12.4312C5.14429 13.8252 6.27482 14.9557 7.66883 14.9557C9.06284 14.9557 10.1934 13.8252 10.1934 12.4312C10.1934 11.0372 9.06284 9.90662 7.66883 9.90662Z"
        fill="#2BA9E0"
      />
      <path
        d="M10.8114 9.42277C11.5587 10.2036 12.0186 11.2623 12.0186 12.4311C12.0186 13.9114 11.2808 15.2144 10.1551 16C12.2389 15.3581 13.9635 13.7916 14.7347 11.8946C15.803 9.26947 14.8066 7.29582 13.1395 5.50421C13.1922 5.73415 13.221 5.97846 13.221 6.22277C13.2162 7.74612 12.2006 9.02516 10.8114 9.42277Z"
        fill="#2BA9E0"
      />
      <path
        d="M7.9563 6.22753C7.9563 7.29579 8.82336 8.15807 9.88683 8.15807C10.9503 8.15807 11.8222 7.291 11.8222 6.22753C11.8222 5.15927 10.9551 4.297 9.89162 4.297C8.82815 4.297 7.9563 5.15927 7.9563 6.22753Z"
        fill="#2BA9E0"
      />
      <path
        d="M4.33952 15.2335C3.7024 14.4766 3.31917 13.4994 3.31917 12.4311C3.31917 10.2036 4.99102 8.36884 7.15149 8.11495C6.78263 7.57843 6.56227 6.92693 6.56227 6.22753C6.56227 4.38801 8.05209 2.90299 9.89161 2.90299C10.0449 2.90299 10.1934 2.91257 10.3371 2.93173C9.24969 1.99281 8.16227 1.03952 7.30479 0C7.74072 4.56047 3.1515 2.90299 1.45091 7.10897C0.315581 9.92573 1.34073 13.4084 4.33952 15.2335Z"
        fill="#2BA9E0"
      />
    </ScalableSvg>
  )
}
