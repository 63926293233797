import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import DecorA from "./backgrounds/GetStarterHeaderDecorA"
import DecorB from "./backgrounds/GetStarterHeaderDecorB"
import Background from "./backgrounds/GetStarterBackground"

const containerCss: ThemeCss = theme => ({
  backgroundColor: theme.colors.secondaryBackground,
})

const decorContainerCss: ThemeCss = () => ({
  display: `flex`,
  position: `absolute`,
  top: 0,
  bottom: 0,
  maxWidth: `100vw`,
  overflow: `hidden`,
})

const decorAContainerCss: ThemeCss = theme => [
  decorContainerCss(theme),
  {
    left: 0,
    alignItems: `flex-start`,
    [theme.mediaQueries.desktop]: {
      alignItems: `flex-start`,
    },
    [theme.mediaQueries.hd]: {
      alignItems: `center`,
    },
  },
]

const decorBContainerCss: ThemeCss = theme => [
  decorContainerCss(theme),
  {
    right: 0,
    alignItems: `flex-end`,
    display: `none`,
    [theme.mediaQueries.desktop]: {
      display: `flex`,
      alignItems: `flex-end`,
    },
    [theme.mediaQueries.hd]: {
      alignItems: `center`,
    },
  },
]

export type LoginScreenContainerProps = {
  children: React.ReactNode
}

export function LoginScreenContainer({ children }: LoginScreenContainerProps) {
  return (
    <div css={containerCss}>
      {children}

      <Background
        css={{ position: `absolute`, right: 0, top: 0 }}
        aria-hidden
      />
      <div css={decorAContainerCss} aria-hidden>
        <DecorA />
      </div>
      <div css={decorBContainerCss} aria-hidden>
        <DecorB />
      </div>
    </div>
  )
}
