import { useSubscription } from "react-apollo"
import { PULL_REQUEST_CHANGED_SUBSCRIPTION } from "@modules/build/shared/queries"

export const usePullRequestChangedSubscription = (pullRequestId?: string) => {
  useSubscription(PULL_REQUEST_CHANGED_SUBSCRIPTION, {
    variables: {
      pullRequestId,
    },
    fetchPolicy: "network-only",
    skip: !pullRequestId,
  })
}
