// THIS IS A GENERATED FILE
import * as Types from "../../graphql/types"

import gql from "graphql-tag"
import { useApolloFragment } from "apollo-fragment-react"

export type BuildFieldsFragment = { __typename: "Build" } & Pick<
  Types.Build,
  | "id"
  | "siteId"
  | "siteName"
  | "branch"
  | "buildType"
  | "buildStatus"
  | "structuredBuild"
  | "deployStartedAt"
  | "deployEndedAt"
  | "startedAt"
  | "cdnVendor"
  | "createdAt"
  | "duration"
  | "endedAt"
  | "runnerType"
  | "source"
> & {
    commit?: Types.Maybe<
      { __typename: "BuildCommit" } & Pick<
        Types.BuildCommit,
        "id" | "sha" | "name" | "avatarUrl" | "message"
      >
    >
    author?: Types.Maybe<
      { __typename: "User" } & Pick<Types.User, "id" | "name" | "avatarUrl">
    >
    pullRequest?: Types.Maybe<
      { __typename: "PullRequest" } & Pick<Types.PullRequest, "id" | "title">
    >
  }

export type BuildActivityFieldsFragment = {
  __typename?: "BuildActivity"
} & Pick<
  Types.BuildActivity,
  | "id"
  | "name"
  | "type"
  | "message"
  | "status"
  | "statusText"
  | "duration"
  | "current"
  | "total"
>

export type StructuredLogFieldsFragment = {
  __typename?: "StructuredLog"
} & Pick<
  Types.StructuredLog,
  | "id"
  | "message"
  | "code"
  | "type"
  | "filePath"
  | "docsUrl"
  | "errorUrl"
  | "context"
  | "level"
> & {
    location?: Types.Maybe<
      { __typename?: "SourceCodeLocation" } & {
        start?: Types.Maybe<
          { __typename?: "SourceCodePosition" } & Pick<
            Types.SourceCodePosition,
            "column" | "line"
          >
        >
      }
    >
  }

export const BuildFieldsFragmentDoc = gql`
  fragment buildFields on Build {
    id
    siteId
    siteName
    branch
    buildType
    buildStatus
    structuredBuild
    deployStartedAt
    deployEndedAt
    startedAt
    cdnVendor
    createdAt
    duration
    endedAt
    runnerType
    source
    commit {
      id
      sha
      name
      avatarUrl
      message
      __typename
    }
    author {
      id
      name
      avatarUrl
      __typename
    }
    pullRequest {
      id
      title
      __typename
    }
    __typename
  }
`
export const BuildActivityFieldsFragmentDoc = gql`
  fragment buildActivityFields on BuildActivity {
    id
    name
    type
    message
    status
    statusText
    duration
    current
    total
  }
`
export const StructuredLogFieldsFragmentDoc = gql`
  fragment structuredLogFields on StructuredLog {
    id
    message
    code
    type
    filePath
    docsUrl
    errorUrl
    context
    level
    location {
      start {
        column
        line
      }
    }
  }
`

/**
 * __useBuildFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useBuildFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useBuildFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useBuildFieldsFragment('fragment-id');
 */
export function useBuildFieldsFragment(id: string) {
  return useApolloFragment<BuildFieldsFragment>(BuildFieldsFragmentDoc, id)
}
export type BuildFieldsFragmentHookResult = ReturnType<
  typeof useBuildFieldsFragment
>

/**
 * __useBuildActivityFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useBuildActivityFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useBuildActivityFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useBuildActivityFieldsFragment('fragment-id');
 */
export function useBuildActivityFieldsFragment(id: string) {
  return useApolloFragment<BuildActivityFieldsFragment>(
    BuildActivityFieldsFragmentDoc,
    id
  )
}
export type BuildActivityFieldsFragmentHookResult = ReturnType<
  typeof useBuildActivityFieldsFragment
>

/**
 * __useStructuredLogFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useStructuredLogFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useStructuredLogFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useStructuredLogFieldsFragment('fragment-id');
 */
export function useStructuredLogFieldsFragment(id: string) {
  return useApolloFragment<StructuredLogFieldsFragment>(
    StructuredLogFieldsFragmentDoc,
    id
  )
}
export type StructuredLogFieldsFragmentHookResult = ReturnType<
  typeof useStructuredLogFieldsFragment
>
