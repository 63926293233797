import * as React from "react"
import { EmptyStateGraphic } from "@modules/ui/components/EmptyStateGraphic"
import { EmptyState } from "gatsby-interface"
import { organizationStatus as organizationStatusText } from "@modules/locales/default.json"
import { ServiceRouteLayout } from "./ServiceRouteLayout"

export function OrganizationOverage() {
  return (
    <ServiceRouteLayout>
      <EmptyState
        heading={organizationStatusText.headers.orgInOverage}
        text={organizationStatusText.messages.overageStatusInfo}
        graphic={<EmptyStateGraphic />}
        variant="BORDERED"
      />
    </ServiceRouteLayout>
  )
}
