import * as React from "react"
import { Form as FormikForm, FormikFormProps } from "formik"
import { ThemeCss } from "gatsby-interface"

const formCss: ThemeCss = _theme => ({
  margin: 0,
  width: `100%`,
})

export type StyledFormProps = React.ComponentPropsWithoutRef<"form">

export function StyledForm(props: StyledFormProps) {
  return <form css={formCss} {...props} />
}

export type StyledFormikFormProps = FormikFormProps

export function StyledFormikForm(props: StyledFormikFormProps) {
  return <FormikForm css={formCss} {...props} />
}
