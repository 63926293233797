import * as React from "react"
import { FunctionStatusIcon } from "./FunctionStatusIcon"
import { FunctionStatusLabel } from "./FunctionStatusLabel"
import { Card, LinkButton, ThemeCss } from "gatsby-interface"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { functionsView as text } from "@modules/locales/default.json"
import { getContextuallyFormatedDate } from "@modules/ui/utils/getContextuallyFormatedDate"
import { FunctionInvokationStatus } from "@modules/site/details/components/mocks/functions.mocks"
import { SiteFunctionEntry } from "@modules/graphql/types"
import { interpolateMessage } from "@modules/locales"
import { MdArrowForward } from "react-icons/md"
import { getPathToSiteDetailsTab } from "@modules/site/details/utils"
import { SiteDetailsTab } from "@modules/site/details/constants"

const mainPaddingCss: ThemeCss = theme => ({
  // @TODO perhaps these styles should be added in gatsby-interface theme.cardStyles.space?
  paddingTop: theme.space[5],
  paddingBottom: theme.space[5],
  paddingLeft: theme.space[7],
  paddingRight: theme.space[7],
})

const titleRowCss: ThemeCss = theme => ({
  display: `flex`,
  justifyContent: `space-between`,
  marginBottom: theme.space[3],
  flexWrap: `wrap`,
})

const titleCss: ThemeCss = theme => ({
  color: theme.colors.grey[90],
  fontFamily: theme.fonts.body,
  fontSize: theme.fontSizes[2],
  margin: 0,
  fontWeight: theme.fontWeights.semiBold,
})

const dateCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[0],
  color: theme.colors.grey[60],
})

const statusRowCss: ThemeCss = _theme => ({
  display: `flex`,
  alignItems: `center`,
})

const statusLabelCss: ThemeCss = theme => ({
  fontSize: theme.fontSizes[1],
  marginLeft: theme.space[3],
  color: theme.colors.grey[90],
})

export type FunctionCardProps = Pick<SiteFunctionEntry, "id"> & {
  organizationId: string
  siteId: string
  name: string
  lastInvocationStatus?: FunctionInvokationStatus
  lastInvokedAt?: string
  showLinkToDetails?: boolean
}

export function FunctionCard({
  organizationId,
  siteId,
  id,
  name,
  lastInvocationStatus,
  lastInvokedAt,
  showLinkToDetails = true,
}: FunctionCardProps) {
  return (
    <Card as={`article`}>
      <div css={mainPaddingCss}>
        <div css={titleRowCss}>
          <h3 css={titleCss}>{name}</h3>
          <span css={dateCss}>
            <span css={visuallyHiddenCss}>
              {text.labels.lastInvocationDate}:
            </span>
            {getContextuallyFormatedDate(lastInvokedAt)}
          </span>
        </div>
        <div css={statusRowCss}>
          <FunctionStatusIcon status={lastInvocationStatus} />
          <FunctionStatusLabel
            status={lastInvocationStatus}
            css={statusLabelCss}
          />
          {showLinkToDetails && (
            <LinkButton
              to={`${getPathToSiteDetailsTab(
                SiteDetailsTab.Functions,
                siteId,
                organizationId
              )}/${id}/details`}
              variant="SECONDARY"
              size="S"
              rightIcon={<MdArrowForward />}
              css={{ marginLeft: `auto` }}
              aria-label={interpolateMessage<"functionName">(
                text.labels.functionDetails,
                { functionName: name }
              )}
            >
              {text.labels.functionDetailsShort}
            </LinkButton>
          )}
        </div>
      </div>
    </Card>
  )
}
