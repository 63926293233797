import styled from "@emotion/styled"
import { isProduction } from "@modules/env/constants"
import { createDeprecationCss } from "@modules/ui/stylesheets/devStyles"

const headerDeprecationCss = createDeprecationCss(
  `Deprecated, use Header from gatsby-interface`
)
const textDeprecationCss = createDeprecationCss(
  `Deprecated, use Text from gatsby-interface`
)

/**
 * @deprecated Use Heading from gatsby-interface instead (apply styles using Emotion if necessary)
 */
export const EmphaticHeading = styled(`h2`)(props => [
  {
    color: props.theme.colors.grey[90],
    fontFamily: props.theme.fonts.heading,
    fontSize: props.theme.fontSizes[7],
    fontWeight: 800,
    letterSpacing: `-0.02em`,
    lineHeight: 1,
    margin: 0,

    [props.theme.mediaQueries.desktop]: {
      fontSize: props.theme.fontSizes[9],
    },
  },
  !isProduction && headerDeprecationCss(props.theme),
])

/**
 * @deprecated Use Heading from gatsby-interface instead (apply styles using Emotion if necessary)
 */
export const Heading = styled(`h2`)(props => [
  {
    color: props.theme.colors.grey[90],
    fontFamily: props.theme.fonts.heading,
    fontSize: props.theme.fontSizes[5],
    margin: 0,

    strong: {
      color: props.theme.colors.lilac,
    },

    small: {
      color: props.theme.colors.grey[50],
      display: `block`,
      fontSize: props.theme.fontSizes[1],
      fontWeight: `normal`,
      letterSpacing: `0.03em`,
      marginBottom: props.theme.space[3],
      textTransform: `uppercase`,
    },
  },
  !isProduction && headerDeprecationCss(props.theme),
])

/**
 * @deprecated Use Heading from gatsby-interface instead (apply styles using Emotion if necessary)
 */
export const Subheading = styled(`h3`)(props => [
  {
    color: props.theme.colors.grey[90],
    fontFamily: props.theme.fonts.heading,
    fontSize: props.theme.fontSizes[3],
    margin: `1.5rem 0 0`,

    small: {
      color: props.theme.colors.grey[50],
      display: `block`,
      fontSize: props.theme.fontSizes[0],
      fontWeight: `normal`,
      letterSpacing: `0.03em`,
      marginBottom: props.theme.space[3],
      textTransform: `uppercase`,
    },
  },
  !isProduction && headerDeprecationCss(props.theme),
])

/**
 * @deprecated Use Text from gatsby-interface instead (apply styles using Emotion if necessary)
 */
export const Lede = styled(`p`)(props => [
  {
    color: props.theme.colors.grey[70],
    fontFamily: props.theme.fonts.system,
    fontSize: props.theme.fontSizes[3],
    margin: `0.5rem 0 0`,
  },
  !isProduction && textDeprecationCss(props.theme),
])

/**
 * @deprecated Use Text from gatsby-interface instead (apply styles using Emotion if necessary)
 */
export const Text = styled(`p`)(props => [
  {
    color: props.theme.colors.grey[70],
    fontFamily: props.theme.fonts.system,
    fontSize: props.theme.fontSizes[2],
    lineHeight: 1.5,
    margin: `1rem 0 0`,
  },
  !isProduction && textDeprecationCss(props.theme),
])

/**
 * @deprecated Use Text from gatsby-interface instead (apply styles using Emotion if necessary)
 */
export const ErrorText = styled(Text)(props => [
  {
    fontWeight: `bold`,
    svg: {
      marginRight: props.theme.space[3],
      verticalAlign: `middle`,
      marginTop: `-0.1rem`,
      fill: props.theme.colors.red[50],
    },
  },
  !isProduction && textDeprecationCss(props.theme),
])
