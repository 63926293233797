import * as React from "react"
import {
  ThemeCss,
  Spacer,
  Link,
  NumberBadge,
  NumberBadgeTone,
} from "gatsby-interface"
import { MdErrorOutline } from "react-icons/md"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import { lighthouseScoreTones } from "@modules/metrics/lighthouse"
import getLighthouseScore from "@modules/metrics/helpers/getLighthouseScore"
import useLighthouseScoresForBuild from "@modules/build/metrics/hooks/useLighthouseScoresForBuild"
import { getSiteDetailsTabBasePath } from "@modules/site/details/utils"
import { sites as text } from "@modules/locales/default.json"
import { FormattedMessage } from "@modules/locales"
import { SiteDetailsTab } from "@modules/site/details/constants"

const rootCss: ThemeCss = theme => ({
  alignItems: `center`,
  borderTop: `1px solid ${theme.colors.grey[20]}`,
  background: theme.colors.grey[5],
  display: `grid`,
  gridTemplateColumns: `1fr auto`,
  fontSize: theme.fontSizes[0],
  padding: `${theme.space[4]} ${theme.space[7]}`,
  rowGap: theme.space[3],

  [theme.mediaQueries.tablet]: {
    display: `flex`,
  },
})

const textCss: ThemeCss = theme => ({
  color: theme.colors.grey[60],
  padding: 0,
  margin: 0,
})

const titleCss: ThemeCss = theme => [
  textCss(theme),
  {
    color: theme.colors.grey[80],
    fontFamily: theme.fonts.body,
    fontSize: theme.fontSizes[0],
    marginRight: theme.space[8],
    fontWeight: theme.fontWeights.body,
  },
]

const errorIconCss: ThemeCss = theme => ({
  color: theme.colors.red[70],
  verticalAlign: `middle`,
})

const metricsCss: ThemeCss = theme => ({
  gridColumn: `1 / 3`,
  gridRow: `2 / 3`,
  justifyContent: `space-between`,
  display: `grid`,
  rowGap: theme.space[2],

  [theme.mediaQueries.tablet]: {
    display: `flex`,
  },
})

const metricCss: ThemeCss = theme => ({
  display: `flex`,
  alignItems: `center`,
  fontSize: theme.fontSizes[0],
  flexShrink: 0,
})

export type LighthouseScoresProps = {
  siteId: string
  organizationId: string
  buildId: string
  branch: string
}

enum ScoresDisplayState {
  Loading = `LOADING`,
  Error = `ERROR`,
  Empty = `EMPTY`,
  Ready = `READY`,
}

export function LighthouseScores({
  siteId,
  organizationId,
  buildId,
  branch,
}: LighthouseScoresProps) {
  const [scores, { loading, error }] = useLighthouseScoresForBuild(
    siteId,
    buildId,
    branch
  )

  let displayState: ScoresDisplayState

  if (loading) {
    displayState = ScoresDisplayState.Loading
  } else if (error) {
    displayState = ScoresDisplayState.Error
  } else if (scores.length === 0) {
    displayState = ScoresDisplayState.Empty
  } else {
    displayState = ScoresDisplayState.Ready
  }

  return (
    <div css={rootCss}>
      <h4 css={titleCss}>{text.headers.lighthouse}: </h4>

      {displayState === ScoresDisplayState.Loading && (
        <p css={textCss}>{text.messages.loadingScores}</p>
      )}

      {displayState === ScoresDisplayState.Error && (
        <p css={textCss}>
          <MdErrorOutline css={errorIconCss} />{" "}
          {text.messages.somethingWentWrongWhenTryingToLoadTheLighthouseScores}
        </p>
      )}

      {displayState === ScoresDisplayState.Empty && (
        <p css={textCss}>
          <FormattedMessage<never, "link">
            message={text.messages.noReportForSite}
            tags={{
              link: function(content) {
                return (
                  <Link
                    to={`${getSiteDetailsTabBasePath(
                      SiteDetailsTab.Settings,
                      siteId,
                      organizationId
                    )}/reports`}
                  >
                    {content}
                  </Link>
                )
              },
            }}
          />
        </p>
      )}

      {displayState === ScoresDisplayState.Ready && (
        <React.Fragment>
          <div css={metricsCss}>
            {scores.map(([label, value]: [string, number]) => {
              const tone: NumberBadgeTone =
                lighthouseScoreTones[getLighthouseScore(value)]

              return (
                <React.Fragment key={label}>
                  <span css={visuallyHiddenCss}>
                    {`Metric ${label} is ${value} on 100`}
                  </span>
                  <span aria-hidden={true} css={metricCss}>
                    <span css={textCss}>{label}</span>
                    <Spacer size={3} direction="horizontal" />
                    <NumberBadge withBorder={true} tone={tone}>
                      {value}
                    </NumberBadge>
                    <Spacer size={7} direction="horizontal" />
                  </span>
                </React.Fragment>
              )
            })}
          </div>
          <Link
            href={`https://build-${buildId}${process.env.GATSBY_PREVIEW_DOMAIN}/reports/lighthouse/index.html`}
            variant="SIMPLE"
            css={theme => ({
              marginLeft: `auto`,
            })}
          >
            {text.actions.viewFullReport}
          </Link>
        </React.Fragment>
      )}
    </div>
  )
}
