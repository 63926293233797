// THIS IS A GENERATED FILE
import * as Types from "../../graphql/types"

import gql from "graphql-tag"
import { useApolloFragment } from "apollo-fragment-react"

export type ReviewOrganizationPlanFieldsFragment = {
  __typename?: "SubscriptionPlan"
} & Pick<Types.SubscriptionPlan, "id" | "name" | "formattedAmount" | "interval">

export const ReviewOrganizationPlanFieldsFragmentDoc = gql`
  fragment reviewOrganizationPlanFields on SubscriptionPlan {
    id
    name
    formattedAmount
    interval
  }
`

/**
 * __useReviewOrganizationPlanFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useReviewOrganizationPlanFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useReviewOrganizationPlanFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useReviewOrganizationPlanFieldsFragment('fragment-id');
 */
export function useReviewOrganizationPlanFieldsFragment(id: string) {
  return useApolloFragment<ReviewOrganizationPlanFieldsFragment>(
    ReviewOrganizationPlanFieldsFragmentDoc,
    id
  )
}
export type ReviewOrganizationPlanFieldsFragmentHookResult = ReturnType<
  typeof useReviewOrganizationPlanFieldsFragment
>
