import {
  BuildStatus,
  PreviewStatus,
  BuildRunnerType,
  StructuredLogLevel,
  BuildType,
} from "@modules/graphql/types"
import { buildStatusVariants, emptyBuildStatusVariant } from "../constants"

export const getLinkToCommit = ({ repositoryUrl, commitSha }) => {
  return {
    url: `${repositoryUrl}/commit/${commitSha}`,
    anchorText: commitSha.substring(0, 7),
  }
}

export const isBuildRunnerType = runnerType =>
  [BuildRunnerType.Builds, BuildRunnerType.IncrementalBuilds].includes(
    runnerType
  )

export const successfulBuild = buildStatus =>
  buildStatus === BuildStatus.Success

export const isInProgressBuild = status =>
  [
    BuildStatus.Building,
    BuildStatus.Uploading,
    BuildStatus.GitClone,
    BuildStatus.GitPull,
    BuildStatus.DepInstall,
  ].includes(status)

export const isInProgressPreview = status =>
  [
    PreviewStatus.Building,
    PreviewStatus.Rebuilding,
    PreviewStatus.Publishing,
    PreviewStatus.Uploading,
  ].includes(status)

export const isInProgress = status =>
  isInProgressBuild(status) || isInProgressPreview(status)

export const getBuildStatusVariant = (status, runnerType) => {
  let statusVariant = buildStatusVariants[status]

  statusVariant = statusVariant || emptyBuildStatusVariant

  if (!statusVariant) {
    if (process.env.NODE_ENV !== `production`) {
      console.error(
        `Could not find a status variant for build status "${status}"`
      )
    }
    return null
  }

  const statusIsInProgress = isInProgress(status)
  let { badgeVariant } = statusVariant

  if (statusIsInProgress) {
    badgeVariant = runnerType === BuildRunnerType.Preview ? `purple` : `blue`
  }

  return {
    ...statusVariant,
    badgeVariant,
  }
}

export function getBuildURL(buildId, omitProtocol = false) {
  return `${omitProtocol ? `` : `https://`}build-${buildId}${
    process.env.GATSBY_PREVIEW_DOMAIN
  }`
}

export function formatRunnerType(runnerType, omitPlural = false) {
  return omitPlural
    ? `${runnerType.charAt(0).toUpperCase()}${runnerType
        .slice(1)
        .toLowerCase()
        .replace(`s`, ``)}`
    : `${runnerType.charAt(0).toUpperCase()}${runnerType
        .slice(1)
        .toLowerCase()}`
}

export function getBuildStatusLabels(buildStatus) {
  let label
  let durationLabel
  let startingLabel

  switch (buildStatus) {
    case BuildStatus.GitCloneError:
    case BuildStatus.GitPullError:
    case BuildStatus.DepInstallError:
    case BuildStatus.UploadError:
    case BuildStatus.Error:
      label = `Failed`
      durationLabel = `Failed after`
      break
    case BuildStatus.TimedOut:
      label = `Timed out`
      durationLabel = `Timed out after`
      break
    case BuildStatus.Queued:
      label = `Queued`
      durationLabel = `Queued`
      startingLabel = `Queued since`
      break
    case BuildStatus.Canceled:
      label = `Canceled`
      durationLabel = `Canceled after`
      break
    // PublishError means that the build has been successful but not the third party deployment
    case BuildStatus.Publishing:
    case BuildStatus.PublishError:
    case BuildStatus.PublishCanceled:
    case BuildStatus.Success:
      label = `Built`
      durationLabel = `Built in`
      break
    case BuildStatus.Building:
    case BuildStatus.Uploading:
    case BuildStatus.GitClone:
    case BuildStatus.GitPull:
    case BuildStatus.DepInstall:
      label = `In progress`
      durationLabel = ``
      startingLabel = `Building since`
      break
    default:
      label = `Pending`
      durationLabel = ``
  }

  return {
    label,
    durationLabel,
    startingLabel,
  }
}

export const isInProgressStructuredLogs = status =>
  isInProgressBuild(status) || [BuildStatus.Queued].includes(status)

export const formatLogLevel = (logLevel, totalLogs = 0) => {
  const logLevelDisplay = [StructuredLogLevel.All].includes(logLevel)
    ? `log`
    : logLevel
  if (totalLogs === 1) return `${logLevelDisplay.toLowerCase()}`
  return `${logLevelDisplay.toLowerCase().concat(`s`)}`
}

export function isIncrementalUpdate(runnerType, buildType) {
  return (
    runnerType === BuildRunnerType.IncrementalBuilds &&
    buildType === BuildType.DataUpdate
  )
}

export function getPreviewStatus(previewBuildStatus, sitePreviewStatus) {
  if (sitePreviewStatus === PreviewStatus.Stopped) {
    return PreviewStatus.Stopped
  }
  return previewBuildStatus
}
