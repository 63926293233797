import * as React from "react"
import { Link } from "gatsby"

import { MdArrowDropDown, MdDone, MdAdd } from "react-icons/md"
import {
  header as text,
  organizationPlan as organizationPlanText,
} from "@modules/locales/default.json"
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItems,
  DropdownMenuLink,
  ThemeCss,
} from "gatsby-interface"
import { useTracker, GAEventType, BigQueryEventType } from "@modules/analytics"
import Breadcrumb from "./Breadcrumb"
import { getPathToOrgSites } from "@modules/organization/shared/utils"
import { SubscriptionStatus } from "@modules/graphql/types"
import { UserOrganizationsQuery } from "@modules/header/queries.generated"
import { idRegex } from "@modules/toolkit/constants"
import { interpolateMessage } from "@modules/locales"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"

const menuButtonCss: ThemeCss = theme => ({
  margin: 0,
  padding: 0,
  alignItems: "center",
  background: `none`,
  border: `0`,
  color: theme.colors.grey[90],
  cursor: `pointer`,
  display: `flex`,
  fontFamily: theme.fonts.heading,
  fontSize: theme.fontSizes[4],
  fontWeight: theme.fontWeights.bold,

  whiteSpace: `nowrap`,
  position: `relative`,
  gridColumn: `1 / 3`,
  gridRow: `2 / 2`,
  justifySelf: `center`,
  minWidth: `16rem`,
  justifyContent: `center`,

  [theme.mediaQueries.desktop]: {
    marginLeft: theme.space[8],
    minWidth: `auto`,
  },
})

const menuButtonIconCss: ThemeCss = theme => ({
  flexShrink: 0,
  fontSize: theme.space[5],
  marginLeft: theme.space[3],
})

const menuListCss: ThemeCss = theme => ({
  position: "absolute",
  zIndex: 20,
  [theme.mediaQueries.desktop]: {
    maxWidth: `15rem`,
  },
})

const itemIconCss: ThemeCss = theme => ({
  color: theme.colors.purple[50],
  marginInlineStart: theme.space[5],
  flexShrink: 0,
  fontSize: `20px`,
})

const menuLinkCss: ThemeCss = _theme => ({
  display: `flex`,
  alignItems: `baseline`,
  justifyContent: `space-between`,
})

const planNameCss: ThemeCss = theme => ({
  display: `block`,
  fontSize: theme.fontSizes[0],
  lineHeight: theme.lineHeights.default,
  color: theme.colors.grey[60],
})

const createButtonCss: ThemeCss = theme => [
  menuLinkCss(theme),
  {
    borderTop: `1px solid ${theme.colors.grey[20]}`,
    color: theme.colors.gatsby,
    alignItems: `center`,
  },
]

export type UserOrganizationsDropdownProps = {
  userOrganizations: UserOrganizationsQuery["currentOrganizations"]
  selectedOrganizationId: string
  path: string
}

export function UserOrganizationsDropdown({
  userOrganizations,
  selectedOrganizationId,
  path,
}: UserOrganizationsDropdownProps) {
  const {
    trackDropdownButtonClick,
    trackOrganizationItemClick,
  } = useUserOrgsDropdownAnalytics()

  if (!userOrganizations) {
    return null
  }

  const selectedOrganizationName = userOrganizations.find(
    ({ id }) => id === selectedOrganizationId
  )?.name

  if (
    (path.includes(`builds`) && !path.includes(`dashboard/builds`)) ||
    new RegExp(`sites/${idRegex}/deploys`).test(path) ||
    new RegExp(`sites/${idRegex}/settings`).test(path) ||
    new RegExp(`sites/${idRegex}/branches`).test(path) ||
    new RegExp(`sites/${idRegex}/cmsPreview`).test(path) ||
    new RegExp(`sites/${idRegex}/functions`).test(path)
  ) {
    return (
      <Breadcrumb
        orgName={selectedOrganizationName}
        orgId={selectedOrganizationId}
        path={path}
      />
    )
  }

  return (
    <DropdownMenu id="user-orgs-dropdown">
      <DropdownMenuButton
        css={menuButtonCss}
        onClick={() => {
          trackDropdownButtonClick()
        }}
        data-testid="user-orgs-dropdown-button"
      >
        {selectedOrganizationName
          ? selectedOrganizationName
          : text.allOrganizations}
        <MdArrowDropDown css={menuButtonIconCss} />
      </DropdownMenuButton>

      <DropdownMenuItems css={menuListCss} size="MAX_CONTENT">
        {userOrganizations.map(org => {
          const isActive = org.id === selectedOrganizationId
          const isTrialing =
            org?.billing?.status === SubscriptionStatus.Trialing
          const planName = org?.billing?.plan?.name || ""
          const planLabel = isTrialing
            ? interpolateMessage<"planName">(
                organizationPlanText.labels.triallingPlan,
                { planName }
              )
            : planName

          return (
            <DropdownMenuLink
              as={Link}
              onClick={() => {
                trackOrganizationItemClick(org.id)
              }}
              key={org.id}
              to={getPathToOrgSites(org.id)}
              aria-current={isActive ? "location" : undefined}
              css={menuLinkCss}
            >
              <span>
                {org.name}
                {isActive && (
                  <span css={visuallyHiddenCss}>
                    {text.labels.currentOrgIndicator}
                  </span>
                )}
                <span
                  css={planNameCss}
                  aria-label={interpolateMessage<"planName">(
                    organizationPlanText.labels.currentPlanName,
                    { planName: planLabel }
                  )}
                >
                  {planLabel}
                </span>
              </span>
              {isActive && <MdDone aria-hidden css={itemIconCss} />}
            </DropdownMenuLink>
          )
        })}
        <DropdownMenuLink
          key="new-space"
          as={Link}
          to={`/dashboard/organization/create`}
          css={createButtonCss}
        >
          {text.actions.createOrganization}
          <MdAdd css={itemIconCss} />
        </DropdownMenuLink>
      </DropdownMenuItems>
    </DropdownMenu>
  )
}

function useUserOrgsDropdownAnalytics() {
  const { trackGoogleAnalytics, trackBigQuery } = useTracker()

  const trackDropdownButtonClick = () => {
    trackBigQuery({
      eventType: BigQueryEventType.ButtonClicked,
      buttonName: `Organization Menu`,
      uiSource: `Navbar`,
    })
    trackGoogleAnalytics({
      eventType: GAEventType.Click,
      category: `Product Engagement - Dashboard`,
      label: { loc: `Header`, text: `all organizations dropdown` },
    })
  }

  const trackOrganizationItemClick = (orgId: string) => {
    trackBigQuery<{ organizationId: string }>({
      eventType: BigQueryEventType.ButtonClicked,
      buttonName: `Select Organization`,
      uiSource: `Navbar`,
      organizationId: orgId,
    })
    trackGoogleAnalytics({
      eventType: GAEventType.Click,
      category: `Product Engagement - Dashboard`,
      label: { loc: `Header`, text: `all organizations - go to org` },
    })
  }

  return {
    trackDropdownButtonClick,
    trackOrganizationItemClick,
  }
}
