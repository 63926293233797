// THIS IS A GENERATED FILE
import * as Types from "../graphql/types"

import {
  OrganizationDetailsFragment,
  PermissionsForOrganizationFragment,
} from "./fragments.generated"
import gql from "graphql-tag"
import {
  OrganizationDetailsFragmentDoc,
  PermissionsForOrganizationFragmentDoc,
} from "./fragments.generated"
import * as ApolloReactCommon from "@apollo/react-common"
import * as ApolloReactHooks from "@apollo/react-hooks"

export type OrganizationDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
}>

export type OrganizationDetailsQuery = { __typename?: "Query" } & {
  organizationDetails?: Types.Maybe<
    { __typename?: "Organization" } & OrganizationDetailsFragment
  >
}

export type OrganizationPermissionsInfoQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"]
}>

export type OrganizationPermissionsInfoQuery = { __typename?: "Query" } & {
  organizationDetails?: Types.Maybe<
    { __typename?: "Organization" } & Pick<Types.Organization, "id"> & {
        permissions?: Types.Maybe<
          {
            __typename?: "PermissionsDigest"
          } & PermissionsForOrganizationFragment
        >
      }
  >
}

export type PlatformLimitSummariesQueryVariables = Types.Exact<{
  workspaceId: Types.Scalars["UUID"]
}>

export type PlatformLimitSummariesQuery = { __typename?: "Query" } & {
  platformLimitSummaries?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "PlatformLimitSummary" } & Pick<
          Types.PlatformLimitSummary,
          | "name"
          | "id"
          | "overage"
          | "limit"
          | "formattedLimit"
          | "overageCount"
          | "formattedOverageCount"
        > & {
            workspace: { __typename?: "PlatformUsage" } & Pick<
              Types.PlatformUsage,
              "count" | "formattedCount" | "percentageOfLimit"
            >
          }
      >
    >
  >
}

export const OrganizationDetailsDocument = gql`
  query organizationDetails($id: UUID!) {
    organizationDetails(id: $id) {
      ...organizationDetails
    }
  }
  ${OrganizationDetailsFragmentDoc}
`

/**
 * __useOrganizationDetailsQuery__
 *
 * To run a query within a React component, call `useOrganizationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationDetailsQuery,
    OrganizationDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    OrganizationDetailsQuery,
    OrganizationDetailsQueryVariables
  >(OrganizationDetailsDocument, baseOptions)
}
export function useOrganizationDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationDetailsQuery,
    OrganizationDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationDetailsQuery,
    OrganizationDetailsQueryVariables
  >(OrganizationDetailsDocument, baseOptions)
}
export type OrganizationDetailsQueryHookResult = ReturnType<
  typeof useOrganizationDetailsQuery
>
export type OrganizationDetailsLazyQueryHookResult = ReturnType<
  typeof useOrganizationDetailsLazyQuery
>
export type OrganizationDetailsQueryResult = ApolloReactCommon.QueryResult<
  OrganizationDetailsQuery,
  OrganizationDetailsQueryVariables
>
export const OrganizationPermissionsInfoDocument = gql`
  query OrganizationPermissionsInfo($id: UUID!) {
    organizationDetails(id: $id) {
      id
      permissions {
        ...permissionsForOrganization
      }
    }
  }
  ${PermissionsForOrganizationFragmentDoc}
`

/**
 * __useOrganizationPermissionsInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationPermissionsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationPermissionsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationPermissionsInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationPermissionsInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrganizationPermissionsInfoQuery,
    OrganizationPermissionsInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    OrganizationPermissionsInfoQuery,
    OrganizationPermissionsInfoQueryVariables
  >(OrganizationPermissionsInfoDocument, baseOptions)
}
export function useOrganizationPermissionsInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrganizationPermissionsInfoQuery,
    OrganizationPermissionsInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    OrganizationPermissionsInfoQuery,
    OrganizationPermissionsInfoQueryVariables
  >(OrganizationPermissionsInfoDocument, baseOptions)
}
export type OrganizationPermissionsInfoQueryHookResult = ReturnType<
  typeof useOrganizationPermissionsInfoQuery
>
export type OrganizationPermissionsInfoLazyQueryHookResult = ReturnType<
  typeof useOrganizationPermissionsInfoLazyQuery
>
export type OrganizationPermissionsInfoQueryResult = ApolloReactCommon.QueryResult<
  OrganizationPermissionsInfoQuery,
  OrganizationPermissionsInfoQueryVariables
>
export const PlatformLimitSummariesDocument = gql`
  query PlatformLimitSummaries($workspaceId: UUID!) {
    platformLimitSummaries(workspaceId: $workspaceId) {
      name
      id
      overage
      limit
      formattedLimit
      overageCount
      formattedOverageCount
      workspace {
        count
        formattedCount
        percentageOfLimit
      }
    }
  }
`

/**
 * __usePlatformLimitSummariesQuery__
 *
 * To run a query within a React component, call `usePlatformLimitSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatformLimitSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatformLimitSummariesQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function usePlatformLimitSummariesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PlatformLimitSummariesQuery,
    PlatformLimitSummariesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    PlatformLimitSummariesQuery,
    PlatformLimitSummariesQueryVariables
  >(PlatformLimitSummariesDocument, baseOptions)
}
export function usePlatformLimitSummariesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PlatformLimitSummariesQuery,
    PlatformLimitSummariesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PlatformLimitSummariesQuery,
    PlatformLimitSummariesQueryVariables
  >(PlatformLimitSummariesDocument, baseOptions)
}
export type PlatformLimitSummariesQueryHookResult = ReturnType<
  typeof usePlatformLimitSummariesQuery
>
export type PlatformLimitSummariesLazyQueryHookResult = ReturnType<
  typeof usePlatformLimitSummariesLazyQuery
>
export type PlatformLimitSummariesQueryResult = ApolloReactCommon.QueryResult<
  PlatformLimitSummariesQuery,
  PlatformLimitSummariesQueryVariables
>
