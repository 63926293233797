import * as React from "react"
import { Button, ThemeCss, Theme, Heading } from "gatsby-interface"
import { NarrowSingleColumn } from "@modules/ui/layouts/Containers"
import { BillingInterval } from "@modules/graphql/types"
import { useReviewOrganizationPlanFieldsFragment } from "../fragments.generated"
import { List, ListItem } from "@modules/ui/components/List"
import {
  PaymentForm,
  PaymentFormElement,
  PaymentFormFields,
} from "@modules/payment/components/PaymentForm"
import { WizardStepHeader } from "@modules/ui/components/WizardStepHeader"
import { WizardFooter } from "@modules/ui/components/WizardFooter"
import { MdModeEdit, MdArrowForward } from "react-icons/md"
import {
  FREE_PLAN_ID,
  FREE_PLAN,
} from "@modules/billing/components/Billing.helpers"
import { useCreateOrganization } from "./ReviewOrganization.helpers"
import {
  createOrganization as createOrgText,
  billing as billingText,
  ui as uiText,
} from "@modules/locales/default.json"
import { PaymentProvider } from "@modules/payment"

const reviewItemCss: ThemeCss = theme => ({
  display: `grid`,
  gridTemplateColumns: `160px auto auto`,
  paddingTop: theme.space[7],
  paddingBottom: theme.space[7],
})

const reviewItemActionCss: ThemeCss = _theme => ({
  marginInlineStart: `auto`,
})

type ReviewItemProps = {
  label: React.ReactNode
  labelAlign?: `center` | `baseline`
  children: React.ReactNode
  action?: React.ReactNode
  "data-testid"?: string
}

function ReviewItem({
  label,
  labelAlign,
  children,
  action,
  "data-testid": dataTestId,
}: ReviewItemProps) {
  return (
    <ListItem css={reviewItemCss} data-testid={dataTestId}>
      <div css={{ alignSelf: labelAlign }}>{label}:</div>
      <div>{children}</div>
      <div css={reviewItemActionCss}>{action}</div>
    </ListItem>
  )
}

export type ReviewOrganizationProps = {
  selectedName: string
  selectedPlanId: string | null
  onEditName: () => void
  onChangePlan: () => void
  onGoNext: (organizationId: string) => void
  onGoBack: () => void
}

export function ReviewOrganization({
  selectedName,
  selectedPlanId,
  onEditName,
  onChangePlan,
  onGoNext,
  onGoBack,
}: ReviewOrganizationProps) {
  const [
    createOrganization,
    { loading: isSubmitting, errorAlert },
  ] = useCreateOrganization()

  const commonSections = (
    <React.Fragment>
      <WizardStepHeader title={createOrgText.headers.review}>
        {createOrgText.messages.reviewDescription}
      </WizardStepHeader>

      <OrganizationReviewItems
        selectedName={selectedName}
        selectedPlanId={selectedPlanId}
        onEditName={onEditName}
        onChangePlan={onChangePlan}
      />
    </React.Fragment>
  )

  const goBackButton = (
    <Button variant="SECONDARY" onClick={() => onGoBack()}>
      {uiText.actions.back}
    </Button>
  )

  const isFreePlan = selectedPlanId === FREE_PLAN_ID

  if (isFreePlan) {
    return (
      <React.Fragment>
        {commonSections}
        <NarrowSingleColumn>{errorAlert}</NarrowSingleColumn>
        <WizardFooter
          goBackButton={goBackButton}
          goNextButton={
            <Button
              type="button"
              onClick={async () => {
                await createOrganization(
                  {
                    orgName: selectedName,
                  },
                  organization => onGoNext(organization.id)
                )
              }}
              loading={isSubmitting}
              disabled={isSubmitting}
              rightIcon={<MdArrowForward />}
            >
              {createOrgText.actions.createFreeSpace}
            </Button>
          }
        />
      </React.Fragment>
    )
  }

  return (
    <PaymentProvider>
      <PaymentForm
        onSubmit={(paymentInfo, stripe, cardElement) => {
          createOrganization(
            {
              orgName: selectedName,
              stripe,
              cardElement,
              subscriptionInput: {
                planId: selectedPlanId,
                companyName: paymentInfo.companyName,
                customerName: paymentInfo.name,
                billingEmail: paymentInfo.email,
              },
              billingAddress: {
                address_line1: paymentInfo.address_line1,
                address_line2: paymentInfo.address_line2,
                address_city: paymentInfo.address_city,
                address_state: paymentInfo.address_state,
                address_zip: paymentInfo.address_zip,
                address_country: paymentInfo.address_country,
              },
            },
            organization => onGoNext(organization.id)
          )
        }}
      >
        <React.Fragment>
          {commonSections}

          <PaymentFormElement>
            <NarrowSingleColumn
              css={(theme: Theme) => ({
                marginTop: 0,
                marginBottom: theme.space[10],
              })}
            >
              <div
                css={(theme: Theme) => ({
                  padding: theme.space[7],
                  background: theme.colors.grey[10],
                })}
              >
                <Heading
                  as="h3"
                  css={(theme: Theme) => ({
                    fontFamily: theme.fonts.heading,
                    fontSize: theme.fontSizes[3],
                    fontWeight: theme.fontWeights.semiBold,
                    lineHeight: theme.lineHeights.dense,
                  })}
                >
                  {billingText.addPayment}
                </Heading>
                <PaymentFormFields />
              </div>
              {errorAlert}
            </NarrowSingleColumn>

            <WizardFooter
              goBackButton={goBackButton}
              goNextButton={
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  rightIcon={<MdArrowForward />}
                >
                  {createOrgText.actions.createPaidSpace}
                </Button>
              }
            />
          </PaymentFormElement>
        </React.Fragment>
      </PaymentForm>
    </PaymentProvider>
  )
}

function OrganizationReviewItems({
  selectedName,
  selectedPlanId,
  onEditName,
  onChangePlan,
}: Pick<
  ReviewOrganizationProps,
  "selectedName" | "selectedPlanId" | "onEditName" | "onChangePlan"
>) {
  const isFreePlan = selectedPlanId === FREE_PLAN_ID

  const { data } = useReviewOrganizationPlanFieldsFragment(selectedPlanId || ``)

  const selectedPlan = isFreePlan ? FREE_PLAN : data

  return (
    <NarrowSingleColumn css={{ marginBottom: 0 }}>
      <List>
        <ReviewItem
          label={createOrgText.labels.reviewName}
          action={
            <Button
              variant="GHOST"
              size="S"
              leftIcon={<MdModeEdit />}
              type="button"
              onClick={() => onEditName()}
            >
              {createOrgText.actions.editName}
            </Button>
          }
          data-testid="reviewItem__name"
        >
          {selectedName}
        </ReviewItem>
        <ReviewItem
          label={createOrgText.labels.reviewPlan}
          action={
            <Button
              variant="GHOST"
              size="S"
              leftIcon={<MdModeEdit />}
              type="button"
              onClick={() => onChangePlan()}
            >
              {createOrgText.actions.changePlan}
            </Button>
          }
          data-testid="reviewItem__plan"
        >
          {selectedPlan?.name || `none`}
        </ReviewItem>
        {!isFreePlan && (
          <ReviewItem
            label={createOrgText.labels.reviewTotal}
            labelAlign="baseline"
            data-testid="reviewItem__total"
          >
            <span
              css={(theme: Theme) => ({
                fontSize: theme.fontSizes[2],
                fontWeight: theme.fontWeights.semiBold,
              })}
            >
              {selectedPlan?.formattedAmount} /{" "}
              {selectedPlan?.interval === BillingInterval.Annual
                ? "year"
                : `month`}
            </span>
            <br />
            Billed{" "}
            {selectedPlan?.interval === BillingInterval.Annual
              ? `annually`
              : `monthly`}{" "}
            via credit card
          </ReviewItem>
        )}
      </List>
    </NarrowSingleColumn>
  )
}
