export const SITE_BREADCRUMB_FRAGMENT = `
  fragment siteBreadcrumbFragment on Site {
    id
    name
    publicName
    previewProtection
  }
`

export const USER_DROPDOWN_FRAGMENT = `
  fragment userDropdownFields on User {
    id
    name
    email
    avatarUrl
  }
`
