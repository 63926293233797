export const Sizes = {
  Small: `small`,
  Medium: `medium`,
  Large: `large`,
}

export const SizeIds = Object.values(Sizes)

export const Corners = {
  TopLeft: `top-left`,
  TopRight: `top-right`,
  BottomLeft: `bottom-left`,
  BottomRight: `bottom-right`,
}

export const CornerIds = Object.values(Corners)

export const DotSizes = {
  [Sizes.Small]: 14,
  [Sizes.Medium]: 20,
  [Sizes.Large]: 27,
}
