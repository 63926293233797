export const METRIC_SETTINGS = {
  firstContentfulPaint: {
    uiUnit: `SECONDS`,
    featured: true,
    description: `First Contentful Paint marks the time at which the first text or image is painted.`,
    descriptionUrl: `https://developers.google.com/web/tools/lighthouse/audits/first-contentful-paint`,
  },
  firstMeaningfulPaint: {
    uiUnit: `SECONDS`,
    featured: false,
    description: `First Meaningful Paint measures when the primary content of a page is visible.`,
    descriptionUrl: `https://developers.google.com/web/tools/lighthouse/audits/first-meaningful-paint`,
  },
  speedIndex: {
    featured: false,
    uiUnit: `SECONDS`,
    description: `Speed Index shows how quickly the contents of a page are visibly populated.`,
    descriptionUrl: `https://developers.google.com/web/tools/lighthouse/audits/speed-index`,
  },
  firstCpuIdle: {
    name: `First CPU Idle`,
    uiUnit: `SECONDS`,
    description: `First CPU Idle marks the first time at which the page's main thread is quiet enough to handle input.`,
    descriptionUrl: `https://developers.google.com/web/tools/lighthouse/audits/first-interactive`,
  },
  maxPotentialFid: {
    name: `Max Potential First Input Delay`,
    description: `The maximum potential First Input Delay that your users could experience is the duration, in milliseconds, of the longest task.`,
    descriptionUrl: `https://developers.google.com/web/updates/2018/05/first-input-delay`,
  },
  interactive: {
    name: `Time to Interactive`,
    uiUnit: `SECONDS`,
    description: `Time to interactive is the amount of time it takes for the page to become fully interactive. `,
    descriptionUrl: `https://developers.google.com/web/tools/lighthouse/audits/consistently-interactive`,
  },
  performance: {
    featured: true,
    featuredOnBuild: true,
  },
  accessibility: {
    featured: true,
    featuredOnBuild: true,
    description: `Note: Only a subset of accessibility issues can be automatically detected. Additional manual testing is crucial.`,
    descriptionUrl: `https://www.matuzo.at/blog/building-the-most-inaccessible-site-possible-with-a-perfect-lighthouse-score/`,
  },
  bestPractices: {
    featured: false,
    featuredOnBuild: true,
  },
  seo: {},
}

export const METRIC_UNITS = {
  MILLISECONDS: {
    shortcut: `ms`,
  },
  SECONDS: {
    shortcut: `s`,
  },
  MINUTES: {
    shortcut: `m`,
  },
  COUNT: {
    shortcut: ``,
  },
  OTHER: {
    shortcut: ``,
  },
}
