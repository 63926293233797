import { ThemeCss } from "gatsby-interface"

export const newFeatureIconCss: ThemeCss = theme => ({
  background: theme.colors.blue[10],
  padding: theme.space[2],
  borderRadius: theme.radii[5],
  marginRight: theme.space[3],
  fontSize: theme.fontSizes[5],
})

export const featureTitleCss: ThemeCss = theme => ({
  padding: 0,
  margin: 0,
  color: theme.colors.blue[50],
  fontSize: theme.fontSizes[0],
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontWeight: theme.fontWeights.heading,
  textTransform: "uppercase",
})

export const headingCss: ThemeCss = theme => ({
  display: "block",
  fontSize: theme.fontSizes[7],
  margin: 0,
  padding: `0 ${theme.space[7]}`,
})

export const contentCss: ThemeCss = theme => ({ color: theme.colors.grey[90] })

export const listCss: ThemeCss = () => ({
  marginBottom: 0,
})

export const listItemCss: ThemeCss = theme => ({
  marginBottom: theme.space[2],
})

export const footerCss: ThemeCss = theme => ({
  borderTop: `1px solid ${theme.colors.grey[20]}`,
  padding: `0 ${theme.space[7]} ${theme.space[7]} ${theme.space[7]}`,
})

export const imageCss: ThemeCss = () => ({
  width: "274px",
  margin: 0,
  padding: 0,
})

export const imageWrapperCss: ThemeCss = theme => ({
  background: theme.colors.purple[5],
  display: "block",
  textAlign: "center",
  paddingTop: theme.space[7],
})

export const newAnnouncementBtnCss: ThemeCss = theme => ({
  background: "transparent",
  border: "none",
  cursor: "pointer",
  borderRadius: theme.radii[5],
  width: "42px",
  height: "42px",
  position: "relative",

  ":hover,:focus": {
    background: theme.colors.grey[10],
  },
})

export const redBallCss: ThemeCss = theme => ({
  background: "red",
  width: "12px",
  height: "12px",
  borderRadius: theme.radii[5],
  position: "absolute",
  right: 0,
  border: `2px solid ${theme.colors.white}`,
})
