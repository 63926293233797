import fastlyLogo from "@modules/cdn/fastly/assets/fastlyLogo.svg"
import s3Logo from "@modules/cdn/amazonS3/assets/s3Logo.svg"
import gcsLogo from "@modules/cdn/gcloudStorage/assets/gcsLogo.svg"
import firebaseLogo from "@modules/cdn/firebase/assets/firebaseLogo.svg"
import netlifyLogo from "@modules/cdn/netlify/assets/netlifyLogo.svg"
import vercelLogo from "@modules/cdn/vercel/assets/vercelLogo.svg"
import azureLogo from "@modules/cdn/azure/assets/azureLogo.svg"
import { S3LogoIcon } from "@modules/cdn/amazonS3/assets/S3LogoIcon"
import { AzureLogoIcon } from "@modules/cdn/azure/assets/AzureLogoIcon"
import { FastlyLogoIcon } from "@modules/cdn/fastly/assets/FastlyLogoIcon"
import { FirebaseLogoIcon } from "@modules/cdn/firebase/assets/FirebaseLogoIcon"
import { GatsbyCloudCdnLogoIcon } from "@modules/cdn/gatsbyCloudCdn/assets/GatsbyCloudCdnLogoIcon"
import { GCSLogoIcon } from "@modules/cdn/gcloudStorage/assets/GCSLogoIcon"
import { NetlifyLogoIcon } from "@modules/cdn/netlify/assets/NetlifyLogoIcon"
import { VercelLogoIcon } from "@modules/cdn/vercel/assets/VercelLogoIcon"
import { CdnVendor } from "@modules/graphql/types"
import { cdnIntegration as cdnIntegrationText } from "@modules/locales/default.json"

export const cdnVendorNames: Record<CdnVendor, string> = {
  [CdnVendor.Fastly]: `Fastly`,
  [CdnVendor.Netlify]: `Netlify`,
  [CdnVendor.Firebase]: `Firebase`,
  [CdnVendor.S3]: `S3`,
  [CdnVendor.Gcs]: `Google Storage`,
  [CdnVendor.Now]: `Vercel`,
  [CdnVendor.Azure]: `Azure`,
  [CdnVendor.CloudCdn]: `Gatsby Hosting`,
}

export const cdnVendorLogo: Record<CdnVendor, string> = {
  [CdnVendor.Netlify]: netlifyLogo,
  [CdnVendor.Fastly]: fastlyLogo,
  [CdnVendor.Firebase]: firebaseLogo,
  [CdnVendor.S3]: s3Logo,
  [CdnVendor.Gcs]: gcsLogo,
  [CdnVendor.Now]: vercelLogo,
  [CdnVendor.Azure]: azureLogo,
  [CdnVendor.CloudCdn]: "",
}

export const cdnVendorThumbnails: Record<
  CdnVendor,
  React.ComponentType<React.ComponentPropsWithoutRef<"svg">>
> = {
  [CdnVendor.Netlify]: NetlifyLogoIcon,
  [CdnVendor.Fastly]: FastlyLogoIcon,
  [CdnVendor.Firebase]: FirebaseLogoIcon,
  [CdnVendor.S3]: S3LogoIcon,
  [CdnVendor.Gcs]: GCSLogoIcon,
  [CdnVendor.Now]: VercelLogoIcon,
  [CdnVendor.Azure]: AzureLogoIcon,
  [CdnVendor.CloudCdn]: GatsbyCloudCdnLogoIcon,
}

export const cdnConfigRequiredKeys: Record<CdnVendor, string[]> = {
  [CdnVendor.Netlify]: [`serviceName`],
  [CdnVendor.Fastly]: [`serviceName`],
  [CdnVendor.Firebase]: [`serviceName`],
  [CdnVendor.S3]: [`bucket`],
  [CdnVendor.Gcs]: [`bucket`],
  [CdnVendor.Now]: [`serviceName`],
  [CdnVendor.Azure]: [`storageAccount`],
  [CdnVendor.CloudCdn]: [],
}

export const cdnConfigLabels: Record<CdnVendor, { [k: string]: string }> = {
  [CdnVendor.Netlify]: {
    serviceName: cdnIntegrationText.morphemes.netlifySite,
    accountName: cdnIntegrationText.morphemes.netlifyAccount,
  },
  [CdnVendor.Fastly]: {
    serviceName: cdnIntegrationText.morphemes.fastlyService,
  },
  [CdnVendor.Firebase]: {
    serviceName: cdnIntegrationText.morphemes.firebaseProject,
    accountName: cdnIntegrationText.morphemes.firebaseSite,
  },
  [CdnVendor.S3]: {
    bucket: cdnIntegrationText.morphemes.amazonS3Bucket,
  },
  [CdnVendor.Gcs]: {
    bucket: cdnIntegrationText.morphemes.gcloudStorageBucket,
  },
  [CdnVendor.Now]: {
    serviceName: cdnIntegrationText.morphemes.vercelProject,
    accountName: cdnIntegrationText.morphemes.vercelTeam,
  },
  [CdnVendor.Azure]: {
    storageAccount: cdnIntegrationText.morphemes.azureStorageAccount,
  },
  [CdnVendor.CloudCdn]: {},
}

export const cdnVendorBasePaths: Record<CdnVendor, string> = {
  [CdnVendor.Fastly]: `https://manage.fastly.com/`,
  [CdnVendor.Netlify]: `https://app.netlify.com/`,
  [CdnVendor.CloudCdn]: `https://gatsbyjs.com/dashboard/`,
  [CdnVendor.Firebase]: `https://console.firebase.google.com/`,
  [CdnVendor.S3]: `https://console.aws.amazon.com/`,
  [CdnVendor.Gcs]: `https://console.cloud.google.com/`,
  [CdnVendor.Now]: `https://vercel.com/dashboard`,
  [CdnVendor.Azure]: `https://portal.azure.com/`,
}
