import { SourceControlProvider } from "@modules/graphql/types"
import { sourceControl as sourceControlText } from "@modules/locales/default.json"

export const sourceControlProviderLabels: Record<
  SourceControlProvider,
  string
> = {
  [SourceControlProvider.Github]: sourceControlText.morphemes.github,
  [SourceControlProvider.Gitlab]: sourceControlText.morphemes.gitlab,
  [SourceControlProvider.Bitbucket]: sourceControlText.morphemes.bitbucket,
}
