import { dimensions } from "gatsby-interface"

export const getStandardSingleColumnStyles = theme => ({
  margin: `0 auto`,
  maxWidth: `64rem`,
  paddingLeft: dimensions.pagePadding.mobile,
  paddingRight: dimensions.pagePadding.mobile,

  [theme.mediaQueries.tablet]: {
    paddingLeft: dimensions.pagePadding.tablet,
    paddingRight: dimensions.pagePadding.tablet,
  },
})
