import React, { useEffect } from "react"
import { LinkButton, Text } from "gatsby-interface"
import { makeClosableWindow } from "./window"

const GITHUB_APP_URL = `https://github.com/apps/${process.env.GATSBY_GITHUB_APP_NAME}/installations/new`

const contentCss = theme => ({
  textAlign: "center",
  margin: theme.space[10],
})

export default function Close({ location }) {
  const [, closeGithubWindow] = makeClosableWindow(GITHUB_APP_URL)

  useEffect(() => {
    closeGithubWindow(location?.search || "")
  }, [])

  return (
    <main css={contentCss}>
      <h1>You will be redirected in a moment.</h1>

      <Text>If you are not redirected, click this button:</Text>

      <LinkButton to="/dashboard/sites">Return to Gatsby Cloud</LinkButton>
    </main>
  )
}
