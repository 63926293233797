import React from "react"
import PropTypes from "prop-types"

import { billing as text } from "@modules/locales/default.json"
import { CardLabel, CardNumber, CardExpiry, CardCVC } from "./PaymentElements"
import { convertFontSizeToPx } from "../../ui/utils/fonts"
import ErrorMessage from "../../ui/components/ErrorMessage.js"
import { fieldErrorMessageCss } from "../../form/components/FieldError.js"
import { CC_VALID_VALUE } from "./CheckoutForm.helpers.js"
import { isProduction } from "@modules/env/constants"
import { useTheme } from "gatsby-interface"

function StripeError({ error }) {
  return (
    error && <ErrorMessage css={fieldErrorMessageCss}>{error}</ErrorMessage>
  )
}

const inputFieldsConfig = {
  cardNumber: { Component: CardNumber, Label: `${text.cardNumber}` },
  cardExpiry: { Component: CardExpiry, Label: `${text.expirationDate}` },
  cardCvc: { Component: CardCVC, Label: `${text.cvcCode}` },
}

const InputFields = [
  { ...inputFieldsConfig.cardNumber, Name: `cardNumber` },
  {
    ...inputFieldsConfig.cardExpiry,
    Name: `cardExpiry`,
  },
  { ...inputFieldsConfig.cardCvc, Name: `cardCvc` },
]

function StripeElements({ formState, validateCard }) {
  const style = useStripeStyles()

  return (
    <React.Fragment>
      {InputFields.map(({ Name, Component, Label }) => (
        <CardLabel key={Name} htmlFor={Name} data-cy={Name}>
          {Label}
          <Component name={Name} onChange={validateCard} options={{ style }} />
          <StripeError
            error={
              formState[Name] !== CC_VALID_VALUE && formState[Name].length > 0
                ? formState[Name]
                : null
            }
          />
        </CardLabel>
      ))}
    </React.Fragment>
  )
}

/**
 * Unlike StripeElements, this component renders only one field at a time,
 * allowing us to control it in a more granular way, e.g. connect it to a Formik state
 */
export function StripeElementField({
  elementName,
  value,
  error,
  touched,
  onChange,
  onBlur,
}) {
  const style = useStripeStyles()

  const { Component, Label } = inputFieldsConfig[elementName]

  if (!Component) {
    if (!isProduction) {
      console.error(
        `Unable to find component for Stripe ${elementName} element`
      )
    }
    return null
  }

  return (
    <CardLabel key={elementName} htmlFor={elementName} data-cy={elementName}>
      {Label}
      <Component onChange={onChange} onBlur={onBlur} options={{ style }} />
      {touched && (
        <StripeError
          error={value !== CC_VALID_VALUE && error?.length > 0 ? error : null}
        />
      )}
    </CardLabel>
  )
}

function useStripeStyles() {
  const [fontSize, setFontSize] = React.useState()
  const { fonts, fontSizes } = useTheme()
  const baseFontSize = fontSizes[2]

  React.useEffect(() => {
    // Have to convert "em"/"rem" since those values would be based off Stripe's iframe
    setFontSize(convertFontSizeToPx(baseFontSize))
  }, [baseFontSize])

  return {
    base: {
      fontSize,
      fontFamily: fonts.system,
    },
  }
}

StripeElements.propTypes = {
  errors: PropTypes.object,
  validateCard: PropTypes.func,
}

export default StripeElements
