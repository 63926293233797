import * as React from "react"
import {
  PermissionsResource,
  PermissionsCheck,
  PermissionsCheckProps,
} from "@modules/permissions"
import { useSitePermissionsFieldsFragment } from "@modules/site/shared/fragments.generated"

type SitePermissionsResource = Extract<
  PermissionsResource,
  "sites" | "contributor"
>

export type SitePermissionsProps<T extends SitePermissionsResource> = Omit<
  PermissionsCheckProps<T>,
  "digest"
> & {
  id: string
}

export function SitePermissions<T extends SitePermissionsResource>({
  id,
  ...rest
}: SitePermissionsProps<T>) {
  const { data } = useSitePermissionsFieldsFragment(id)

  return <PermissionsCheck digest={data?.permissions ?? null} {...rest} />
}
