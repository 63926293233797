import { FullStoryAPI } from "react-fullstory"
import { FullStoryEvent } from "../constants"
import { fullStoryEnabled } from "../../env/constants"

function GatsbyFullStoryAPI(fn, ...args) {
  if (fullStoryEnabled) {
    FullStoryAPI(fn, ...args)
  } else if (process.env.NODE_ENV === `development`) {
    console.info(`FullStory.${fn}:`, ...args)
  }
}

function setIdentify(currentUser) {
  const { id, name, email } = currentUser
  GatsbyFullStoryAPI(`identify`, id, {
    displayName: name,
    email: email,
  })
}

function setSiteEnvVars(role) {
  GatsbyFullStoryAPI(`setUserVars`, {
    user_site_role: role,
  })
}

function setOrgEnvVars(name, plan, status, trialDaysRemaining, role) {
  GatsbyFullStoryAPI(`setUserVars`, {
    user_organization_name: name,
    user_organization_plan: plan,
    user_organization_plan_status: status,
    user_organization_trial_days_remaining:
      status === `TRIALING` ? trialDaysRemaining : 0,
    user_organization_role: role,
  })
}

function recordEvent(event, ...args) {
  GatsbyFullStoryAPI(`event`, event, ...args)
}

function recordCreateSite() {
  recordEvent(FullStoryEvent.CreateSite, {})
}

function recordFirstTimeVisitor() {
  recordEvent(FullStoryEvent.FirstTimeVisitor, {})
}

export {
  setIdentify,
  setSiteEnvVars,
  setOrgEnvVars,
  recordEvent,
  recordCreateSite,
  recordFirstTimeVisitor,
}
