import { keyframes } from "@emotion/core"
import styled from "@emotion/styled"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const DEFAULT_DURATION = 400

export const FadeOnMount = styled.div(p => ({
  opacity: 0,
  animation: `${fadeIn} ${p.duration || DEFAULT_DURATION}ms ${p.delay ||
    100}ms forwards`,
}))

const fadeInBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(3%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`

export const FadeFromBottom = styled.div(p => ({
  opacity: 0,
  animation: `${fadeInBottom} ${p.duration || DEFAULT_DURATION}ms ${p.delay ||
    0}ms forwards`,
}))
