import React from "react"
import PropTypes from "prop-types"
import { FaCodeBranch } from "react-icons/fa"

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
}

const siteBranchCss = theme => ({
  display: `inline-block`,
  wordBreak: `break-all`,
  lineHeight: theme.lineHeights.dense,
})

const siteBranchIconCss = theme => ({
  fontSize: theme.fontSizes[1],
  fill: theme.colors.grey[60],
  marginRight: theme.space[2],
  verticalAlign: `middle`,
})

const siteBranchContentCss = theme => ({
  fontSize: theme.fontSizes[1],
  color: theme.colors.grey[60],
})

function SiteBranch({ children, className }) {
  return (
    <span
      data-testid="site-branch"
      data-cy="site-branch"
      className={className}
      css={siteBranchCss}
    >
      <FaCodeBranch css={siteBranchIconCss} />
      <span css={siteBranchContentCss}>{children}</span>
    </span>
  )
}

SiteBranch.propTypes = propTypes

export default SiteBranch
