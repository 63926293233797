import styled from "@emotion/styled"
import { DASHBOARD_HEADER_HEIGHT } from "./constants"

const dimensions = {
  pageNav: {
    minHeight: `5rem`,
  },
  pagePadding: {
    mobile: `1.4rem`,
    tablet: `2rem`,
  },
}

export const StandardSingleColumn = styled.div`
  margin: 0 auto;
  max-width: 64rem;
  padding-left: ${dimensions.pagePadding.mobile};
  padding-right: ${dimensions.pagePadding.mobile};

  ${p => p.theme.mediaQueries.tablet} {
    padding-left: ${dimensions.pagePadding.tablet};
    padding-right: ${dimensions.pagePadding.tablet};
  }
`

export const NarrowSingleColumn = styled(StandardSingleColumn)`
  margin: ${p => p.theme.space[8]} auto;
  max-width: 46rem;
`

export const PageWithTabsNavSection = styled.div`
  align-items: flex-end;
  border-bottom: 1px solid ${p => p.theme.colors.grey[20]};
  display: flex;
  min-height: ${dimensions.pageNav.minHeight};
`

export const StandardPageContentSection = styled.div`
  border-bottom: 1px solid ${p => p.theme.colors.grey[20]};
  min-height: calc(100vh - ${DASHBOARD_HEADER_HEIGHT});
  padding: ${p => p.theme.space[9]} 0 ${p => p.theme.space[10]};
`

export const PageWithTabsContentSection = styled(StandardPageContentSection)`
  background: ${p => p.theme.colors.secondaryBackground};
  min-height: calc(
    100vh - (${DASHBOARD_HEADER_HEIGHT} + ${dimensions.pageNav.minHeight})
  );
`
