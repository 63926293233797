import gql from "graphql-tag"
import { BuildFieldsFragmentDoc } from "@modules/build/shared/fragments.generated"

export const BUILD_CHANGED_SUBSCRIPTION = gql`
  subscription buildChanged($id: UUID!, $runnerType: BuildRunnerType!) {
    buildChanged(id: $id, runnerType: $runnerType) {
      id
      buildStatus
      structuredBuild
      startedAt
      createdAt
      endedAt
      deployStartedAt
      deployEndedAt
    }
  }
`

export const PULL_REQUEST_CREATED_SUBSCRIPTION = gql`
  subscription pullRequestCreated($siteId: UUID!) {
    pullRequestCreated(siteId: $siteId) {
      id
      title
      latestBuild {
        id
      }
    }
  }
`

export const PULL_REQUEST_CHANGED_SUBSCRIPTION = gql`
  subscription pullRequestChanged($pullRequestId: UUID!) {
    pullRequestChanged(pullRequestId: $pullRequestId) {
      id
      title
      latestBuild {
        ...buildFields
      }
    }
  }

  ${BuildFieldsFragmentDoc}
`

export const PREVIEW_BUILDS_QUERY = gql`
  query previewBuildsForSite($siteId: UUID!, $pagination: CursorPagination) {
    previewBuildsForSite(siteId: $siteId, pagination: $pagination) {
      builds {
        ...buildFields
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  ${BuildFieldsFragmentDoc}
`

export const LATEST_BUILD_BY_STATUS_QUERY = gql`
  query latestBuildByStatus(
    $siteId: UUID!
    $branch: String!
    $runnerType: BuildRunnerType!
    $status: BuildStatus!
  ) {
    latestBuildByStatus(
      siteId: $siteId
      branch: $branch
      runnerType: $runnerType
      status: $status
    ) {
      ...buildFields
    }
  }

  ${BuildFieldsFragmentDoc}
`
