import React from "react"
import PropTypes from "prop-types"
import { keyframes } from "@emotion/core"
import { MdCheck } from "react-icons/md"
import {
  StyledModal,
  StyledModalBody,
  StyledModalHeader,
  StyledModalActions,
  Button,
  AnchorButton,
} from "gatsby-interface"
import { modalErrors as text } from "@modules/locales/default.json"

const entry = keyframes`
  100% {
     opacity: 1
  }
`

const confirmButton = _theme => ({
  animation: `${entry} 0.75s 0.9s ease forwards`,
  opacity: 0,
})

export function ModalError({
  heading,
  children,
  label = text.gotIt,
  closeModal,
  onClose,
  hideSupportContact,
}) {
  return (
    <StyledModal variant="ERROR">
      <StyledModalHeader onCloseButtonClick={closeModal}>
        {heading}
      </StyledModalHeader>
      <StyledModalBody>
        {children}
        <StyledModalActions>
          {!hideSupportContact && (
            <AnchorButton
              tone="NEUTRAL"
              variant="SECONDARY"
              href="mailto:support@gatsbyjs.com"
            >
              {text.contactSupport}
            </AnchorButton>
          )}
          <Button
            tone="NEUTRAL"
            onClick={() => {
              closeModal()

              if (onClose) {
                return onClose()
              }
            }}
            css={confirmButton}
            rightIcon={<MdCheck />}
          >
            {label}
          </Button>
        </StyledModalActions>
      </StyledModalBody>
    </StyledModal>
  )
}

ModalError.propTypes = {
  locales: PropTypes.object,
  closeModal: PropTypes.func,
}
