import * as React from "react"
import { SourceControlProvider } from "@modules/graphql/types"
import {
  GitHubIcon,
  GitLabIcon,
  BitbucketIcon,
  IconProps,
} from "gatsby-interface"

const iconComponents: Record<SourceControlProvider, React.ComponentType> = {
  [SourceControlProvider.Github]: GitHubIcon,
  [SourceControlProvider.Gitlab]: GitLabIcon,
  [SourceControlProvider.Bitbucket]: BitbucketIcon,
}

export type SourceControlProviderIconProps = IconProps & {
  sourceControlProvider: SourceControlProvider
}

export function SourceControlProviderIcon({
  sourceControlProvider,
  ...rest
}: SourceControlProviderIconProps) {
  const IconComponent = iconComponents[sourceControlProvider]

  return <IconComponent {...rest} />
}
