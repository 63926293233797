import React from "react"
import PropTypes from "prop-types"
import { ExternalLink } from "@modules/ui/components/ExternalLink"

import { getBuildURL } from "@modules/build/shared/utils"

const propTypes = {
  buildId: PropTypes.string.isRequired,
  className: PropTypes.string,
  truncate: PropTypes.bool,
}

function BuildUrl({ buildId, truncate, className }) {
  return (
    <ExternalLink
      data-cy="build-url"
      data-testid="build-url"
      href={getBuildURL(buildId)}
      truncate={truncate}
      className={className}
    />
  )
}

BuildUrl.propTypes = propTypes

export default BuildUrl
